import controller from './chart.controller';

export default {
    bindings: {
        onChartLoaded: '&',
        config: '<',
        useStockChart: '<'
    },
    template: '<div class="chart-container"></div>',
    controller
};
