import moment from 'moment';
import 'moment-timezone';

// Used through "window.Highcharts"
import Highcharts from 'highcharts/js/highstock'; // eslint-disable-line 

import { DEFAULT_TOKEN, getPageSize, persistPageSize } from 'services/PaginationHelper';
import { DELETE_MEMBERSHIP, DELETE_PROJECT, UPDATE_PROJECT } from 'services/Permissions';
import { cloneProject } from 'services/ProjectHelper';
import { scrollContainerToActiveChild } from 'services/utils';
import { States } from '../../app.router';

const PAGE_SIZE_SUFFIX = 'project-access';

/* @ngInject */
export default class DataConnectorsController {
    constructor(IAMService, AuthService, EventEmitter, ProjectManager, Loader, DialogService, ToastService, $q, $state, RoleManager, $timeout, $rootScope) {
        this.IAMService = IAMService;
        this.AuthService = AuthService;
        this.eventEmitter = EventEmitter;
        this.ProjectManager = ProjectManager;
        this.Loader = Loader;
        this.DialogService = DialogService;
        this.toastService = ToastService;
        this.$q = $q;
        this.$state = $state;
        this.RoleManager = RoleManager;
        this.$timeout = $timeout;
        this.$rootScope = $rootScope;
    }

    get canUpdateProject() {
        return this.RoleManager.can(UPDATE_PROJECT);
    }

    get canDeleteProject() {
        return this.RoleManager.can(DELETE_PROJECT);
    }

    get canDeleteMember() {
        return this.RoleManager.hasProjectPermissionTo(DELETE_MEMBERSHIP);
    }

    get isDetailView() {
        return this.$state.is(States.PROJECT_SETTINGS_MEMBER);
    }

    isOrganizationMemberProfile(member) {
        const [role] = member.roles;
        if (role) {
            return this.RoleManager.getOrganizationRoles().map(orgRole => orgRole.name).includes(role);
        }
        return false;
    }

    isActive(id) {
        return this.$state.params.memberId === id;
    }

    $onInit() {


        this.project = this.ProjectManager.currentProject;
        this.organization = this.ProjectManager.currentProject.organization;

        this.projectName = this.project.displayName;
        this.selectedUsers = [];

        // Project members

        this.query = '';
        this.labelsLoaded = false;
        this.addFormVisible = false;
        this.pageState = States.PROJECT_SETTINGS;

        this.emptyState = false;

        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;

        this.requestParams = {
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        };

        this.userEmail = ''
        const authData = this.AuthService.getAuthData();
        if (authData) {
            this.userEmail = authData.profile.email;
        }

        this.users = [];
        this.roles = this.RoleManager.getProjectRoles();

        this.loadUsers().then(() => this.$timeout(scrollContainerToActiveChild));

        // Perform a background fetch to ensure the project details are up to date
        this.IAMService.getProject(this.ProjectManager.currentProject.id).then(project => {
            this.project = project
        })

        if(this.isDetailView) {
            this.$state.go(States.PROJECT_SETTINGS);
        }
        
        this.timeZones = moment.tz.names()
        this.timeZone = this.project.location.timeLocation
        this.latitude = this.project.location.latitude
        this.longitude = this.project.location.longitude
        this.showConfirmLocationDialog = false
        this.timeZoneToBeConfirmed = null
        this.loadingTimeZone = false

        // Access token is limited to DT domains: *.disruptive-technologies.com
        mapboxgl.accessToken = 'pk.eyJ1IjoidG9yZC1kdCIsImEiOiJjbHgxbWtvOGcwZXRuMmlzYXBvemQwNG9yIn0.bxWUKCEJfN7LbQQqji6PSA'; // eslint-disable-line
        this.map = new mapboxgl.Map({ // eslint-disable-line
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [0, 35],
            zoom: 0.1,
            projection: 'mercator' // Flat style
        });

        this.marker = new mapboxgl.Marker({ // eslint-disable-line
            draggable: this.canUpdateProject
        })

        if (this.latitude && this.longitude) {
            this.marker.setLngLat([this.longitude, this.latitude]).addTo(this.map);
        }

        this.marker.on('dragend', this.onDragEnd.bind(this));

        this.map.on('click', (e) => {
            if (!this.canUpdateProject) {
                return
            }
            const lngLat = e.lngLat;
            this.geocoder.clear()
            this.marker.setLngLat(lngLat).addTo(this.map);
            this.showConfirmLocationDialog = true
            this.updateCoordinates(lngLat)
            this.$rootScope.$applyAsync()
        });

        // Add Mapbox GL Geocoder for search functionality
        this.geocoder = new MapboxGeocoder({ // eslint-disable-line
            accessToken: mapboxgl.accessToken, // eslint-disable-line
            mapboxgl: mapboxgl, // eslint-disable-line
            marker: false  // Disable the default marker
        });
    
        this.map.addControl(this.geocoder);
    
        this.geocoder.on('result', (e) => {
            if (!this.canUpdateProject) {
                return
            }
            const lngLat = e.result.center;
            this.map.flyTo({ center: lngLat, zoom: 3 });
            this.marker.setLngLat(lngLat).addTo(this.map);
            this.updateCoordinates({lng: lngLat[0], lat: lngLat[1]});
            this.showConfirmLocationDialog = true
        });


        // Ensure the map is resized when the layout is populated 
        // Ugly to call resize multiple times, but it works 
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 50);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 250);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            if (this.latitude && this.longitude) {
                this.map.flyTo({ center: [this.longitude, this.latitude], zoom: 5 });
            }
        }, 400);
    }

    cancelNewLocation() {
        this.showConfirmLocationDialog = false
        this.resetMarkerToPreviousLocation()
    }

    confirmNewLocation() {
        this.showConfirmLocationDialog = false
        this.updateCoordinates(this.marker.getLngLat());
        this.saveProjectTimeZone(this.timeZoneToBeConfirmed, this.marker.getLngLat().lat, this.marker.getLngLat().lng);
    }

    updateCoordinates(lngLat) {
        this.getTimeZone(lngLat);
    }

    onDragEnd() {
        const lngLat = this.marker.getLngLat();
        this.updateCoordinates(lngLat);
        this.showConfirmLocationDialog = true
        this.$rootScope.$applyAsync()
    }

    getTimeZone(lngLat) {
        const lat = lngLat.lat;
        const lng = lngLat.lng;
        this.loadingTimeZone = true
  
        // Use the Mapbox Tilequery API to get the time zone information
        fetch(`https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`) // eslint-disable-line
            .then(response => response.json())
            .then(data => {
                if (data && data.features && data.features.length > 0) {
                    const timeZoneFeature = data.features[0];
                    const timeZoneId = timeZoneFeature.properties.TZID;
                    this.timeZone = timeZoneId
                    this.timeZoneToBeConfirmed = timeZoneId
                } else {
                    this.resetMarkerToPreviousLocation()
                    this.showConfirmLocationDialog = false
                    this.toastService.showSimpleTranslated('project_no_valid_timezone_found');
                }
            })
            .catch(error => {
                console.error('Error fetching time zone:', error); // eslint-disable-line no-console
                this.resetMarkerToPreviousLocation()
                this.showConfirmLocationDialog = false
                this.toastService.showSimpleTranslated('project_timezone_wasnt_updated');
            }).finally(() => {
                this.loadingTimeZone = false
                this.$rootScope.$applyAsync()
            })
    }

    resetMarkerToPreviousLocation() {
        this.timeZone = this.project.location.timeLocation
        if (this.latitude && this.longitude) {
            this.marker.setLngLat([this.longitude, this.latitude]).addTo(this.map);
        } else {
            this.marker.remove()
        }
    }

    updateTimeZone(timeZone) {
        this.timeZone = timeZone
        moment.tz.setDefault(timeZone); // Update Moment timezone globally for Studio
        if (window.Highcharts) {
            window.Highcharts.setOptions({
                time: {
                    useUTC: true,
                    timezone: timeZone
                }
            })
        }

        this.$rootScope.$applyAsync();
        this.$rootScope.$broadcast('TIME_ZONE_CHANGED', { timeZone: this.timeZone });
    }

    clearLocation() {
        this.timeZone = null
        this.map.flyTo({ center: [0, 35], zoom: 0.1 })
        this.marker.remove()
        this.geocoder.clear()
        this.saveProjectTimeZone('');
        this.$rootScope.$broadcast('TIME_ZONE_CHANGED', { timeZone: null });
    }

    deleteProject() {
        
        this.IAMService
            .deleteProject(this.project.name)
            .then(() => {
                // Redirect user to the project selector
                this.ProjectManager.setCurrentProject({});
                this.$state.go(States.PROJECTS);
                this.toastService.showSimpleTranslated('project_was_removed');
            }, (serverResponse) => {
                this.toastService.showSimpleTranslated('project_wasnt_removed', {
                    serverResponse
                });
            });
        this.cancelEdit();
    }

    saveProject() {

        if (this.projectName && this.project.displayName !== this.projectName) {
            this.IAMService
                .updateProject(this.project.name, {displayName: this.projectName})
                .then(() => {
                
                    if (this.ProjectManager.currentProjectId === this.project.id) {
                        this.ProjectManager.setCurrentProject(cloneProject(this.project));
                    }

                    this.toastService.showSimpleTranslated('project_was_updated');
                }, (serverResponse) => {
                    this.toastService.showSimpleTranslated('project_wasnt_updated', {
                        serverResponse
                    });
                });

            this.project.displayName = this.projectName;
        }
    }

    saveProjectTimeZone(timeZone, lat = null, lng = null) {
        this.IAMService.updateProject(this.project.name, { 
            location: { 
                timeLocation: timeZone, 
                latitude: lat, 
                longitude: lng
            }
        }).then(project => {
            this.longitude = project.location.longitude
            this.latitude = project.location.latitude
            this.ProjectManager.setCurrentProject(project);
            this.updateTimeZone(timeZone);
            this.toastService.showSimpleTranslated('project_timezone_was_updated');
        }).catch((serverResponse) => {
            this.toastService.showSimpleTranslated('project_timezone_wasnt_updated', {
                serverResponse
            });
        });
    }

    showDeleteConfirmationProject() {
        this.DialogService.confirm({
            title: 'Delete Project?',
            textContent: `Do you really want to delete Project "${this.project.displayName}"?`,
            ariaLabel: 'delete-project',
            ok: 'Delete',
            cancel: 'Cancel'
        }).then(() => {
            this.deleteProject();
        }).catch(() => {});
    }

    cancelEdit() {
        this.$state.go(States.PROJECTS);
    }


    setPageToken({ pageToken }) {
        this.requestParams.pageToken = pageToken;
        this.loadUsers();
    }

    setPageSize({ pageSize }) {
        persistPageSize(pageSize, PAGE_SIZE_SUFFIX);
        this.requestParams.pageToken = DEFAULT_TOKEN;
        this.requestParams.pageSize = pageSize;
        this.loadUsers();
    }

    updateUser({ member, patch }) {
        const promise = this.IAMService.updateMember(member.name, patch)
            .then((updatedUser) => {
                const userRef = this.users.find(item => item.name === updatedUser.name);
                Object.assign(userRef, updatedUser);

                this.toastService.showSimpleTranslated('member_was_updated');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('member_wasnt_updated', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    addMember({ member }) {
        const promise = this.IAMService.createProjectMember(member)
            .then((createdMember) => {
                this.users.unshift(createdMember);

                this.toastService.showSimpleTranslated('member_was_created');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('member_wasnt_created', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    showDeleteConfirmationMember({ member }) {
        this.DialogService.confirm({
            title: 'Remove project access?',
            textContent: `Do you really want to remove access from "${member.email}"?`,
            ariaLabel: 'remove-access',
            ok: 'Remove',
            cancel: 'Cancel'
        }).then(() => {
            this.deleteMember(member);
        }).catch(() => {});
    }

    deleteMember(member) {
        this.Loader.promise = this.IAMService.deleteMember(member.name)
            .then(() => {
                this.users = this.users.filter(user => user.name !== member.name);

                this.toastService.showSimpleTranslated('member_was_removed');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('member_wasnt_removed', {
                    serverResponse
                });
            });
        this.$state.go(States.PROJECT_SETTINGS);
    }

    loadUsers() {
        const promise = this.IAMService
            .projectMembers(this.requestParams)
            .then(({ data, nextPageToken }) => {
                this.currentPageSize = this.requestParams.pageSize;
                this.currentPageToken = this.requestParams.pageToken;
                this.nextPageToken = nextPageToken;
                this.users = data;
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('members_wasnt_fetched', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    showAddForm() {
        this.addFormVisible = true;
    }

    resendInvite({ member }) {
        const promise = this.IAMService
            .deleteMember(member.name)
            .then(() => this.IAMService.createProjectMember({
                email: member.email,
                roles: member.roles
            }))
            .then((data) => {
                this.toastService.showSimpleTranslated('member_invite_was_sent');
                return {
                    ...data,
                    createTime: data.createTime || moment().format()
                };
            })
            .catch((serverResponse) => {
                this.toastService.showSimpleTranslated('member_invite_wasnt_sent', {
                    serverResponse
                });
                return null;
            });
        this.Loader.promise = promise;
        return promise;
    }
}
