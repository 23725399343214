import { States } from '../../../../../app.router';

/* @ngInject */
export default class LiveProximityCardController {
    constructor(EventEmitter, $scope, $state) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$state = $state;
    }

    $onInit() {
        this.showDeviceList = false
        this.totalDevices = this.card.content.devices.length
        this.totalOpen = 0
        this.totalClosed = 0
        this.totalOffline = 0
        this.updateCardStatus()
        this.hoveringOnCard = false

        // GraphLoading is set to true by dashboard controller when new events are received
        this.$scope.$watch("$ctrl.card.graphLoading", () => {
            this.updateCardStatus()
            this.$scope.$applyAsync()        
        });
    }

    updateCardStatus() {
        let newTotalOffline = 0
        let newTotalOpen = 0
        let newTotalClosed = 0
        this.card.content.devices.forEach(device => {
            if (device.offline) {
                newTotalOffline += 1
                return
            }
            if (device.reported.objectPresent?.state === 'NOT_PRESENT' || device.reported.contact?.state === 'OPEN') {
                newTotalOpen += 1
            }
            if (device.reported.objectPresent?.state === 'PRESENT' || device.reported.contact?.state === 'CLOSED') {
                newTotalClosed += 1
            }
        });
        this.totalOffline = newTotalOffline
        this.totalOpen = newTotalOpen
        this.totalClosed = newTotalClosed
    }

    hoverStarted() {
        this.hoveringOnCard = true
        setTimeout(() => {
            if (this.hoveringOnCard) {
                this.showDeviceList = true
                this.$scope.$applyAsync()
            }
        }, 250);
    }

    hoverStopped() {
        this.hoveringOnCard = false
        this.showDeviceList = false
    }

    getStatusForDevice(device) { // eslint-disable-line class-methods-use-this
        if (device.offline) {
            return 'Offline'
        }
        if (device.reported.objectPresent?.state === 'NOT_PRESENT' || device.reported.contact?.state === 'OPEN') {
            return 'Open'
        }
        if (device.reported.objectPresent?.state === 'PRESENT' || device.reported.contact?.state === 'CLOSED') {
            return 'Closed'
        }
        return ''
    }

    goToSensorPage(device) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: device.name.split('/')[1],
            sensorId: device.name.split('/')[3],
        });
    }
}
