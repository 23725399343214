import angular from 'angular';
import featureFlag from './feature-flag.directive';
import featureFlagsProvider from './feature-flag.provider';
import dtState from '../state';

const dtFeatureFlags = 'dtFeatureFlags';
export default dtFeatureFlags;

angular.module(dtFeatureFlags, [dtState])
    .provider('FeatureFlags', featureFlagsProvider)
    .directive('featureFlag', featureFlag);

