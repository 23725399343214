import { buildApiUrl, extractData } from 'services/api/helper';

/* @ngInject */
export default class FeatureFlagsApiService {
    /**
     * @param {$http} $http
     */
    constructor($http) {
        this.http = $http;
    }


    flags(parameters) {
        const params = new URLSearchParams();
        Object.keys(parameters)
            .forEach((key) => {
                params.append(key, parameters[key]);
            });
        const baseUrl = buildApiUrl('feature-flags');
        return this.http.get(`${baseUrl}?${params}`)
            .then(extractData);
    }

}
