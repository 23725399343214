import moment from 'moment';
import _filter from 'lodash/filter';
import { DEFAULT_TOKEN, getPageSize, persistPageSize } from 'services/PaginationHelper';
import { getHoursMinutesFormat } from 'services/utils';
import { States } from '../../app.router';

const PAGE_SIZE_SUFFIX = 'exports';

/**
 * Here we subtract 29 days to get 30 days period
 * and subtract 6 days for 7 days period.
 * The extra day comes from rounding the start day to the beginning of the day (00:00)
 * and end day to its end (23:59).
 * Will be improved when we integrate timepicker to the page instead of md-datepicker we use now.
 */

/* @ngInject */
export default class ExportsController {
    constructor(
        ExportService,
        Loader,
        DialogService,
        ToastService,
        $q,
        $state,
        $timeout,
        AnalyticsService,
        RoleManager
    ) {
        this.ExportService = ExportService;
        this.Loader = Loader;
        this.DialogService = DialogService;
        this.toastService = ToastService;
        this.$q = $q;
        this.$state = $state;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.RoleManager = RoleManager;
    }

    get isDetailView() {
        return this.$state.is(States.EXPORT);
    }

    $onInit() {
        this.pageState = States.EXPORTS;

        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;

        this.requestParams = {
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        };

        this.exports = [];
        this.stopRefresh = false;
        this.Loader.promise = this.loadExports();
    }

    $onDestory() {
        this.stopRefresh = true;
    }

    setPageToken({ pageToken }) {
        this.requestParams.pageToken = pageToken;
        this.Loader.promise = this.loadExports();
    }

    setPageSize({ pageSize }) {
        persistPageSize(pageSize, PAGE_SIZE_SUFFIX);
        this.requestParams.pageToken = DEFAULT_TOKEN;
        this.requestParams.pageSize = pageSize;
        this.Loader.promise = this.loadExports();
    }

    formatCreateTime(createTime) {
        return moment(createTime).format(`ll ${getHoursMinutesFormat()}`);
    }

    formatType(type) { // eslint-disable-line class-methods-use-this
        return type.toLowerCase().replace('_', ' ');
    }

    formatFileFormat(format) {
        if (format === 'EXCEL') {
            return this.formatType(format);
        }
        return format;
    }

    formatRange(exportObj) {
        let startTime = moment()
        let endTime = moment()
        switch (exportObj.exportType) {
            case 'AGGREGATES':
                startTime = exportObj.aggregates.startTime
                endTime = exportObj.aggregates.endTime
                break;
            case 'EVENTS':
                startTime = exportObj.events.startTime
                endTime = exportObj.events.endTime
                break;
            case 'ALL_EVENTS':
                startTime = exportObj.legacyAllEvents.startTime
                endTime = exportObj.legacyAllEvents.endTime
                break;
            case 'TOUCH':
                startTime = exportObj.legacyTouch.startTime
                endTime = exportObj.legacyTouch.endTime
                break;
            case 'PROXIMITY':
                startTime = exportObj.legacyProximity.startTime
                endTime = exportObj.legacyProximity.endTime
                break;
            case 'TEMPERATURE':
                startTime = exportObj.legacyTemperature.startTime
                endTime = exportObj.legacyTemperature.endTime
                break;
            default:
                break;
        }
        const start = moment(startTime);
        const end = moment(endTime);
        const duration = moment.duration(end.diff(start)).humanize();
        return `${start.format(`MMM D, ${getHoursMinutesFormat()}`)} – ${end.format(`MMM D, ${getHoursMinutesFormat()}`)} (${duration})`;
    }

    createExport(exportObj) {
        const promise = this.ExportService.createExport(exportObj)
            .then((newExport) => {
                this.exports.unshift(newExport);
                this.$state.go(States.EXPORTS);
                this.startExportsRefresh();
                this.toastService.showSimpleTranslated('export_was_created');
                this.AnalyticsService.trackEvent(`exports.started.${newExport.exportType}`);
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('export_wasnt_created', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    loadExports() {
        return this.ExportService
            .exports(this.requestParams)
            .then(({ data, nextPageToken }) => {
                this.currentPageSize = this.requestParams.pageSize;
                this.currentPageToken = this.requestParams.pageToken;
                this.nextPageToken = nextPageToken;
                const inProgress = _filter(data, { 'status': 'IN_PROGRESS' });
                if (inProgress.length > 0) {
                    this.startExportsRefresh();
                }
                this.exports = data;
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('exports_wasnt_fetched', {
                    serverResponse
                });
            });
    }

    startExportsRefresh() {
        if (this.stopRefresh === true) {
            return;
        }
        this.$timeout(() => {
            this.loadExports();
        }, 3000);
    }

    trackDownload() {
        this.AnalyticsService.trackEvent("exports.download");
    }
}
