import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _round from 'lodash/round';
import moment from 'moment'
import { celsiusToFahrenheit, fahrenheitToCelsius, getTemperatureUnitSuffix } from 'services/utils';
import { MEASUREMENT_SYSTEM_CHANGED_EVENT } from 'services/StudioEvents';

/* @ngInject */
export default class EmulatorTemperatureController {
    constructor(
        EventEmitter,
        $scope,
        $rootScope,
        $state,
        $timeout,
        UserPreferencesManager
    ) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.UserPreferencesManager = UserPreferencesManager;
    }
    
    $onInit() {
        this.determineValues()
        
        // A fixed heartbeat interval is used for calculations
        this.heatbeatInterval = 15

        this.inputSamplesText = ''
        this.samples = []
        this.previewVisible = false
        this.shouldIncludeSamples = false
        
        this.$rootScope.$on(MEASUREMENT_SYSTEM_CHANGED_EVENT, () => {
            this.determineValues()
        })
    }

    determineValues() {
        this.event = {
            temperature: {
                value: this.convertedTemperature(_get(this.device, 'reported.temperature.value', 0))
            }
        }
        this.min = this.convertedTemperature(-40)
        this.max = this.convertedTemperature(85)
        this.unitSuffix = getTemperatureUnitSuffix()
    }

    updateSamples() {
        const eventValue = this.event.temperature.value
        if (eventValue === null || eventValue === undefined) {
            return
        }
        // Replace everything except numerics
        this.inputSamplesText = this.inputSamplesText.replace(/[^0-9\.\,\-/\s]+/g, '') // eslint-disable-line no-useless-escape
        const trimmedInput = this.inputSamplesText.replace(/\s+/g, '') // Trim input for spaces 
        this.samples = trimmedInput.split(',').filter(Boolean)
        this.samples.unshift(eventValue.toFixed(1)) // Include event value as first sample
        this.samples.splice(30) // Ignore elements after we hit 30 samples
        this.output = this.generateSamplesObject(true)
        
        this.outputJSON = JSON.stringify({temperature: { value: eventValue, updateTime: this.output[0].sampleTime, samples: this.output}}, null, 4)
    }

    // Create an array of samples [{}, {}, ...]. Used both for the event preview & the published event
    // Can be generated with explainatory timestamps (<Now>, <Now - 5m), actual ISO 8601 timestamps 
    generateSamplesObject(useDummyTimestamps = false) { 

        const sampleInterval = this.heatbeatInterval / this.samples.length
        const sampleIntervalDuration = moment.duration(sampleInterval, "minutes")
        this.formattedSampleInterval = `${sampleIntervalDuration.minutes()}m ${sampleIntervalDuration.seconds()}s`

        const output = []
        this.samples.forEach((sample, index) => {
            if (sample !== '') {

                // Calculate offset from event timestamp
                const sampleTimeOffset = this.heatbeatInterval - (sampleInterval * (this.samples.length - index)) 
                const duration = moment.duration(sampleTimeOffset, "minutes")
                const formatedDuration = `${duration.minutes()}m ${duration.seconds()}s`

                let sampleTime = ''
                
                // First sample is equal to event value
                if (useDummyTimestamps) { 
                    index === 0 ? sampleTime = '<Now>' : sampleTime = `<Now - ${formatedDuration}>` // eslint-disable-line no-unused-expressions
                } else { 
                    index === 0 ? sampleTime = moment().toISOString() : sampleTime = moment().subtract(duration).toISOString() // eslint-disable-line no-unused-expressions
                }

                output.push({
                    value: Number(sample),
                    sampleTime
                })
            }
        }); 

        return output
    }

    addRandomSamples(count) { // Populates the comma separated input field with a specific number of values (centered around event value)
        this.inputSamplesText = ''
        const eventValue = this.event.temperature.value
        for (let i = 0; i < count; i++) {
            const sample = Math.sin( (i+1)/2 ) + eventValue // Values are +/- 0.5
            this.inputSamplesText += `${sample.toFixed(1)}, `
        }
        this.updateSamples()
    }

    toggleEventPreview() {
        this.previewVisible = !this.previewVisible
    }

    convertedTemperature(temperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? _round(celsiusToFahrenheit(temperature), 2)
            : temperature;
    }

    ensureCelsius(temperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? _round(fahrenheitToCelsius(temperature), 2)
            : temperature;
    }

    sendTemperatureEvent() {
        // Send value as Celsius, keep local state if Fahrenheit
        const sendEvent = _cloneDeep(this.event)
        sendEvent.temperature.value = this.ensureCelsius(sendEvent.temperature.value)
        
        if (this.shouldIncludeSamples) {
            // Check if event should include logged samples
            const samples = this.generateSamplesObject(false) // Generate samples with real timestamps
            if (samples.length > 1) {
                sendEvent.temperature.samples = samples
                // Ensure timestamp of event and first sample is equal
                sendEvent.temperature.updateTime = samples[0].sampleTime 
            }
        }
        
        this.onPublishEvent(
            this.EventEmitter({
                event: sendEvent
            })
        )
    }
}