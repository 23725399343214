import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';
import { hasOwnProperty } from 'services/utils';
import DeviceFilterController from './device-filter-popover/controller';
import DeviceFilterTemplate from './device-filter-popover/template.html';
import { States } from '../../../app.router';

/* @ngInject */
export default class SubscriptionController {
    constructor(DialogService, IAMService, SensorService, ToastService, $mdPanel, $state, $window, $scope, subscription, AnalyticsService) {
        this.DialogService = DialogService;
        this.IAMService = IAMService;
        this.SensorService = SensorService;
        this.ToastService = ToastService;
        this.$mdPanel = $mdPanel;
        this.$state = $state;
        this.$window = $window;
        this.$scope = $scope;
        this.subscription = subscription;
        this.AnalyticsService = AnalyticsService;

        this.updateFilters = this.updateFilters.bind(this)
        this.onReorder = this.onReorder.bind(this)
        this.exportDevices = this.exportDevices.bind(this)
    }

    get projectCount() {
        if (this.projects) {
            return Object.keys(this.projects).length
        }
        return '(...)'
    }

    get renewalQuantitySensor() {
        if (!this.loadingDevices) {
            return this.devices.filter(device => device.renewSubscription && device.hasActiveSubscription && device.type !== 'ccon').length
        }
        return this.subscription.renewalQuantitySensor
    }
    
    get renewalQuantityCloudConnector() {
        if (!this.loadingDevices) {
            return this.devices.filter(device => device.renewSubscription && device.hasActiveSubscription && device.type === 'ccon').length
        } 
        return this.subscription.renewalQuantityCloudConnector
    }

    get hiddenDeviceCount() {
        if (!this.loadingDevices) {
            return (this.deviceCount) - this.filteredDevices.length
        }
        return 0
    }

    $onInit() {
        
        this.order = ['-hasActiveSubscription','projectId'];
        this.filterCount = 0
        this.filters = []
        this.filteredDevices = []
        this.deviceCount = 0

        this.loadingDevices = true

        this.fetchAllSubscriptionDevices()

        this.AnalyticsService.trackEvent('billing.subscription.device_modal.open');
    }

    fetchAllSubscriptionDevices() {
        this.progress = this.IAMService.getSubscriptionDevices(this.subscription.subscriptionId).then((response) => {
            
            this.deviceCount = response.devices.length
            // Divide devices into projects to be able to fetch in bulk. Structure: {'projectId': {'devices' : [{ device... }]}}
            this.projects = {} 
            response.devices.forEach(device => {
                const projectId = device.name.split('/')[1]
                const deviceId = device.name.split('/')[3]
                if (!hasOwnProperty(this.projects, projectId)) {
                    this.projects[projectId] = {'devices': []}
                }
                this.projects[projectId].devices.push({   
                    id: deviceId, 
                    "hasActiveSubscription": device.hasActiveSubscription, 
                    "renewSubscription": device.renewSubscription,
                    "deviceType": device.deviceType
                })
            });
            
            // Iterate over all projects and fetch the relevant devices based on ID
            this.devices = []
            const promises = [] // Use promises to indication loading in UI
            Object.keys(this.projects).forEach(projectId => {
                promises.push(this.IAMService.getProject(projectId).then(project => {                    
                    this.projects[projectId].displayName = project.displayName
                    const deviceIds = this.projects[projectId].devices.map(device => device.id)
                    return this.fetchDevicesFromProject(deviceIds, project)

                }).catch(serverResponse => {
                    // User should be able to access all projects
                    // Handle case where devices are removed from the organization
                    this.projects[projectId].devices.forEach(device => {

                        // Construct a basic device that can be shown as "Removed from Organization"
                        device.outsideOrganization = true
                        device.projectDisplayName = "– –"
                        device.projectId = "– –"

                        if (device.deviceType === 'ccon') {
                            device.type = "ccon"
                            device.typeIcon = "cloud-connector"
                            device.typeName = "Cloud Connector"
                        } else {
                            device.typeName = "Unknown Sensor type"
                        }
                        this.devices.push(device)
                    });

                    console.error(serverResponse) // eslint-disable-line no-console
                }))
            });

            this.progress = Promise.all(promises).then(() => {
                this.loadingDevices = false
            })

        }).catch((serverResponse) => {
            console.error(`Failed to load devices for subscription: ${this.subscription.subscriptionId}`) // eslint-disable-line no-console
            console.error(serverResponse) // eslint-disable-line no-console
            this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
                serverResponse
            });
            this.loadingDevices = false
        })        
    }

    fetchDevicesFromProject(deviceIds, project, pageToken = '', previousData = []) {
        // Use deviceIds to fetch in bulk from project
        // Calls itself recursivly to get all devices
        this.loadingDevices = true
        return this.SensorService.sensors({deviceIds, pageToken}, project.id).then(({ data, nextPageToken}) => { // eslint-disable-line consistent-return
            
            const fetchedDevices = [...previousData, ...data]
            if (nextPageToken !== '') {
                return this.fetchDevicesFromProject(deviceIds, project, nextPageToken, fetchedDevices);
            }

            // Set subscription properties for fetched devices
            fetchedDevices.forEach(device => {

                device.projectDisplayName = project.displayName
                device.projectId = project.id

                // Set device subscription details
                const subscriptionDeviceInfo = this.projects[project.id].devices.find(subscriptionDevice => subscriptionDevice.id === device.id)
                device.hasActiveSubscription = subscriptionDeviceInfo.hasActiveSubscription
                device.renewSubscription = subscriptionDeviceInfo.renewSubscription
                device.updatingRenewStatus = false
            });

            this.devices = this.devices.concat(fetchedDevices)
            
        }).catch(serverResponse => {
            console.error(`Failed to sensors from project: ${project.id}`) // eslint-disable-line no-console
            console.error(serverResponse) // eslint-disable-line no-console
            this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
                serverResponse
            });
        })
    }

    getFilteredDevices() {

        // Starts off with all devices and removes based on which filters are currently active
        this.filteredDevices = this.devices
        this.filters.forEach(filter => {
            
            if (!filter.active) {
                return
            }

            // Defining variables here due to ESLint's no-case-declarations
            let activeProjectIds; 
            let timeValue;

            switch (filter.displayName) {
                case 'Project':
                    activeProjectIds = filter.options.filter(option => option.active).map(option => option.id)
                    this.filteredDevices = this.filteredDevices.filter(device => activeProjectIds.includes(device.projectId))
                    break;
                case 'Device type':
                    this.filteredDevices = this.filteredDevices.filter(device => device.type === filter.optionValue)
                    break;
                case 'Battery status':
                    this.filteredDevices = this.filteredDevices.filter(device => device.reported.batteryStatus?.percentage < filter.optionValue)
                    break;
                case 'Last seen online':
                    timeValue = filter.optionValue.split('_')
                    this.filteredDevices = this.filteredDevices.filter(device => moment(device.lastSeen).isBefore(moment().subtract(timeValue[0], timeValue[1])))
                    break;
                case 'Connectivity status':
                    this.filteredDevices = this.filteredDevices.filter(device => device.offline && filter.optionValue === 'Offline' || !device.offline && filter.optionValue === 'Online')
                    break;
                case 'Auto-renew subscription':
                    this.filteredDevices = this.filteredDevices.filter(device => device.renewSubscription && filter.optionValue === "On" || !device.renewSubscription && filter.optionValue === "Off")
                    break;
            
                default:
                    break;
            }
        });

        // Entered search query looks for a matching name or deviceIds, handles multiple Ids
        if (this.searchQuery) {
            this.filteredDevices = this.filteredDevices.filter(device => 
                device.labels?.name?.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
                this.searchQuery.split(/,| /).includes(device.id)
            )
        }
        
        return this.filteredDevices
    }

    search(event) {
        this.searchQuery = event.query
    }

    clearSearchResult() {
        this.searchQuery = ''
    }

    onReorder(order) {
        this.AnalyticsService.trackEvent(`billing.subscription.device_modal.sort.${order}`);
        this.order = order || ['-hasActiveSubscription','projectId']
    }

    showDeviceFilterPopover() {
        const button = document.getElementById('deviceFilterButton')
        const position = this.$mdPanel.newPanelPosition()
            .relativeTo(button)
            .addPanelPosition(this.$mdPanel.xPosition.ALIGN_END , this.$mdPanel.yPosition.BELOW).withOffsetY('12px');;
            
        const panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(button)
            .withAnimation('md-panel--animation');

        const config = {
            attachTo: document.body,
            controller: DeviceFilterController,
            controllerAs: '$ctrl',
            template: DeviceFilterTemplate,
            panelClass: 'md-panel-container',
            animation: panelAnimation,
            position,
            openFrom: button,
            clickOutsideToClose: true,
            escapeToClose: true,
            onRemoving: this.closeFilterPopover.bind(this),
            focusOnOpen: true,
            zIndex: 101,
            locals: {
                projects: this.projects,
                filters: _cloneDeep(this.filters),
                onUpdateFilterCount: this.updateFilterCount.bind(this),
                onUpdateFilters: this.updateFilters.bind(this),
                onClose: this.closeFilterPopover.bind(this)
            }
        };

        this.$mdPanel.open(config).then(reference => {
            this.panelRef = reference;
        });
    }

    updateFilterCount(count) {
        this.filterCount = count
    }

    updateFilters(filters) {
        this.AnalyticsService.trackEvent('billing.subscription.device_modal.update_filters');
        this.filters = filters
    }

    exportDevices() {
        this.AnalyticsService.trackEvent('billing.subscription.device_modal.export')
        
        // Creates a CSV based on the filteredDevices and downloads it automatically 
        let csv = 'Device ID,Device Type,Device name,Project name,Project ID,Last seen (UTC),Battery percentage, Auto-renew subscription\r\n'

        this.filteredDevices.forEach(device => {
            csv += `${device.id},${device.type},${device.labels.name || ''},${device.projectDisplayName},${device.projectId},${device.lastSeen || ''},${device.reported.batteryStatus?.percentage || ''},${device.renewSubscription}\r\n`
        });

        const exportedFilename = 'Device_export.csv'
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    viewDevice(projectId, sensorId) {
        const url = this.$state.href(States.SENSOR_DETAILS, {
            projectId,
            sensorId
        });
        this.$window.open(url,'_blank');
    }

    toggleRenew(device) {
        device.updatingRenewStatus = true
        if (device.renewSubscription) {
            this.IAMService.activateDeviceRenewal(this.subscription.subscriptionId, device.id).then(() => {
                this.$scope.$applyAsync()
            }).catch(error => {
                device.renewSubscription = !device.renewSubscription // Reset to original state
                this.ToastService.showSimpleTranslated('subscription_device_renew_wasnt_updated', { error })
            }).finally(() => {
                device.updatingRenewStatus = false
                this.subscription.renewalQuantitySensor = this.renewalQuantitySensor
                this.subscription.renewalQuantityCloudConnector = this.renewalQuantityCloudConnector
            })

        } else {
            this.IAMService.deactivateDeviceRenewal(this.subscription.subscriptionId, device.id).then(() => {
                this.$scope.$applyAsync()
            }).catch(error => {
                device.renewSubscription = !device.renewSubscription // Reset to original state
                this.ToastService.showSimpleTranslated('subscription_device_renew_wasnt_updated', { error })
            }).finally(() => {
                device.updatingRenewStatus = false
                this.subscription.renewalQuantitySensor = this.renewalQuantitySensor
                this.subscription.renewalQuantityCloudConnector = this.renewalQuantityCloudConnector
            })
        }
    }

    closeFilterPopover() {
        this.panelRef.close()
    }

    closeModal() {
        this.DialogService.cancel();
    }
}
