/* @ngInject */
export default class RoleManager {
    constructor(IAMService, $q) {
        this.IAMService = IAMService;
        this.$q = $q;

        this.roleMap = {};
        this.projectPermissions = [];
        this.organizationPermissions = [];
    }

    loadRoles() {
        return this.IAMService.roles().then(({ data }) => {
            this.roles = data;
            this.roleMap = data.reduce((acc, org) => {
                acc[org.name] = org;
                return acc;
            }, {});
        });
    }

    loadPermissions(optimisticProjectPermissions = null) {
        this.projectPermissions = [];
        this.organizationPermissions = [];
        return this.$q.all([
            this.IAMService.projectPermissions(),
            this.IAMService.organizationPermissions()
        ]).then(([projectPermissions, organizationPermissions]) => {
            if (optimisticProjectPermissions && !projectPermissions.data.length) {
                this.projectPermissions = optimisticProjectPermissions;
            } else {
                this.projectPermissions = projectPermissions.data;
            }
            this.organizationPermissions = organizationPermissions.data;
        });
    }

    hasProjectPermissionTo(action) {
        return this.projectPermissions.includes(action);
    }

    hasOrganizationPermissionTo(action) {
        return this.organizationPermissions.includes(action);
    }

    can(action) {
        return this.hasProjectPermissionTo(action) || this.hasOrganizationPermissionTo(action);
    }

    getProjectRoles() {
        return this.roles.filter(role => role.name.startsWith('roles/project.'));
    }

    getOrganizationRoles(includeSelectProjects = false) {
        const roles = this.roles.filter(role => role.name.startsWith('roles/organization.'));
        if (includeSelectProjects) { // Imaginary role
            roles.unshift({
                name: 'roles/projects',
                displayName: 'Specific Projects',
                description: 'Has access to specified projects within the organization.'
            });
        }
        return roles
    }

    getDisplayName(name) {
        return this.roleMap[name] ? this.roleMap[name].displayName : '';
    }
}
