import { UPDATE_DEVICE } from 'services/Permissions';

/* @ngInject */
export default class ThingEditDescriptionController {
    constructor(
        EventEmitter,
        RoleManager
    ) {
        this.eventEmitter = EventEmitter;
        this.RoleManager = RoleManager;
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    $onChanges({ description }) {
        this.originalDescription = description.currentValue;
    }

    save() {
        if (this.description !== this.originalDescription) {
            this.onUpdate(
                this.eventEmitter({
                    patch: {
                        description: this.description
                    }
                })
            );
        }
    }
}
