import controller from './duration.controller';

const template = require('./duration.html');

export default {
    selector: 'dtDuration',
    options: {
        require: {
            ngModelCtrl: "ngModel",
            parentForm: '?^form'
        },
        bindings: {
            min: '@',
            max: '@',
        },
        controller,
        template
    }
};
