/**
 * Plural filter used to convert word into plural meaning
 * @returns {Function}
 */
function pluralFilter() {
    return function (value) {
        return `${value}s`;
    };
}

/**
 * Plural filter settings
 */
export default {
    name: 'pluralFilter',
    definition: pluralFilter
};
