import controller from './radio-ccon.controller';

const template = require('./radio-ccon.html');

export default {
    bindings: {
        radio: '<'
    },
    template,
    controller
};
