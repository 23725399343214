/**
 * DialogService class.
 * Use this service to create dialogs.
 */
/* @ngInject */
export default class DialogService {
    /**
     * @param {$mdDialog} $mdDialog - Angular-Material dialog service
     * @constructor
     */
    constructor($mdDialog) {
        this.dialog = $mdDialog;
    }

    /**
     * Create confirm dialog by given config
     * @param {Object} config - Config for dialog
     * @returns {promise}
     */
    confirm(config) {
        return this.show(this.dialog.confirm(config));
    }

    /**
     * Create prompt dialog by given config
     * @param {Object} config - Config for dialog
     * @returns {promise}
     */
    prompt(config) {
        return this.show(this.dialog.prompt(config));
    }

    /**
     * Create modal dialog by given config
     * @param {Object} config - Config for dialog
     * @returns {promise}
     */
    show(config) {
        return this.dialog.show(config);
    }

    /**
     * Close dialog
     * @param {Object} dialog - Dialog object. If not passed then internal this.dialog will be used
     */
    cancel(dialog) {
        if (dialog && typeof dialog.cancel === 'function') {
            dialog.cancel();
        } else {
            this.dialog.cancel();
        }
    }

    /**
     * Close dialog
     * @param {Object} dialog - Dialog data.
     */
    hide(data) {
        this.dialog.hide(data);
    }
}
