import controller from './thing-details-labels.controller';

const template = require('./thing-details-labels.html');

export default {
    bindings: {
        suffix: '@',
        labels: '<',
        alwaysEditable: '<',
        onLabelAdd: '&',
        onLabelChange: '&',
        onLabelRemove: '&'
    },
    template,
    controller
};
