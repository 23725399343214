import _get from 'lodash/get';

export const parseServerError = response => _get(response, 'data.error', null);

/**
 * ToastService class.
 * This service is made to handle Toasts in application
 * @class ToastService
 */
/* @ngInject */
export default class ToastService {
    constructor($mdToast, TranslationService) {
        this.toast = $mdToast;
        this.defaultText = 'Empty toast';
        this.defaultPosition = 'bottom right';
        this.defaultHideDelay = 3000;
        this.TranslationService = TranslationService;
    }

    /**
     * Show toast by given configuration
     * @param {Object} toast - Toast configuration
     * @returns {promise}
     */
    show(toast) {
        return this.toast.show(toast);
    }

    /**
     * Create simple Toast for futher user (e.g. show later)
     * @param {{text: string, position: string, hideDelay: integer}} config - Toast configuration
     * @returns {Object} Returns $mdToast object
     */
    createSimple(config) {
        return this.toast.build({
            template:
                `<md-toast class="dt-theme dt-animated">
                    <div class="md-toast-content">
                        <div>${config.serverError || config.text}</div>
                        <span flex></span>
                        <md-button class="md-icon-button md-action" ng-click="$ctrl.close()">
                            <md-icon md-svg-icon="clear"></md-icon>
                        </md-button>
                    </div>
                </md-toast>`,
            controller: /* @ngInject */ class ToastController {
                constructor($mdToast) {
                    this.toast = $mdToast;
                }

                close() {
                    this.toast.hide();
                }
            },
            controllerAs: '$ctrl',
            position: config.position || this.defaultPosition,
            hideDelay: config.hideDelay || this.defaultHideDelay,
            bindToController: true
        });
    }

    showSimpleTranslated(textKey, config = {}, vars = {}) {
        const text = this.TranslationService.getLine(textKey, vars);
        return this.showSimpleText(text, config);
    }

    showSimpleText(text, config = {}) {
        config.text = text;
        if (config.serverResponse) {
            config.serverError = parseServerError(config.serverResponse);
        }
        const simpleToast = this.createSimple(config);
        return this.show(simpleToast);
    }
}
