import { parseServerError } from 'services/toast/toast.service';

/* @ngInject */
export default class InviteUrlDialogController {
    constructor(DialogService, IAMService, member) {
        this.DialogService = DialogService;
        this.IAMService = IAMService;
        this.member = member;

        this.onInit();
    }

    onInit() {
        this.inviteUrl = null;
        this.showLoader = true;
        this.IAMService.getInviteUrl(this.member.name)
            .then(({ inviteUrl }) => {
                this.inviteUrl = inviteUrl;
            })
            .catch((e) => {
                this.errorMessage = parseServerError(e);
            })
            .finally(() => {
                this.showLoader = false;
            });
    }

    cancel() {
        this.DialogService.cancel();
    }
}
