
/* @ngInject */
export default class CalibrationInfoController {
    constructor(DialogService, SensorService, ToastService, thing) {
        this.DialogService = DialogService
        this.SensorService = SensorService
        this.ToastService = ToastService
        this.thing = thing
    }

    downloadCertificate() {
        this.SensorService.downloadCertificatePDF(this.thing.id).then(response => {
            // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            const a = document.createElement("a")
            a.style = "display: none"
            document.body.appendChild(a)
            // Create a DOMString representing the blob and point the link element towards it
            const url = window.URL.createObjectURL(response.data)
            a.href = url
            a.download = `DT Calibration Certificate ${this.thing.id}.pdf`
            a.click(); // Programatically click the link to trigger the download
            // Release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url)
        }).catch(error => {
            this.ToastService.showSimpleTranslated('calibration_certificate_wasnt_downloaded')
            console.error(error); // eslint-disable-line no-console
        })
    }

    closeModal() {
        this.DialogService.cancel();
    }
}
