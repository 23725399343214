import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { convertToSearchResults } from 'services/utils';

/* @ngInject */
export default class TransferSensorPopupController {
    constructor(DialogService, ProjectManager, IAMService, ToastService, sensors, transferSensorsToProject, $q, AnalyticsService) {
        this.DialogService = DialogService;
        this.ProjectManager = ProjectManager;
        this.IAMService = IAMService;
        this.ToastService = ToastService;
        this.sensors = sensors;
        this.transferSensorsToProject = transferSensorsToProject;
        this.$q = $q;
        this.AnalyticsService = AnalyticsService;

        this.selectedProject = null;
        this.selectedOrganization = null;
        this.isSingleOrganization = false;

        this.organizations = this.fetchAllOrganizations();
        this.organizations.then((orgs) => {
            if (orgs.length <= 1) {
                this.isSingleOrganization = true;
            }
            // Default to the current project organization.
            const currentOrg = _find(orgs, { name: this.ProjectManager.currentProject.organization });
            if (currentOrg === undefined) {
                // The user does not have permission to move the sensor out of
                // the current organization.
                this.isSingleOrganization = true;
                this.selectedOrganization = {
                    name: this.ProjectManager.currentProject.organization,
                    displayName: this.ProjectManager.currentProject.organizationDisplayName
                };
            } else {
                this.selectedOrganization = currentOrg;
            }
        });
    }

    get transferringToNewOrganization() {
        return this.selectedOrganization?.id !== this.ProjectManager.currentProject.organizationId
    }

    transfer() {
        if (this.selectedProject) {
            this.DialogService.hide(this.transferSensorsToProject(
                this.selectedProject.id,
                this.sensors
            ));
            
            this.AnalyticsService.trackEvent("device.bulk_transfer")
        }
    }

    onSearch(query) {
        return this.IAMService.projects({
            organization: this.selectedOrganization.name,
            query
        })
            .then(convertToSearchResults)
            .catch(() => {
                this.ToastService.showSimpleTranslated('project_search_error');
                return [];
            });
    }

    cancel() {
        this.DialogService.cancel();
    }

    fetchAllOrganizations() {
        const deferred = this.$q.defer();
        this.getOrganizationsPage(null, [])
            .then(orgs => deferred.resolve(orgs))
            .catch(() => {
                this.ToastService.showSimpleTranslated('organization_list_error');
                // Automatically close the dialog if this fails.
                this.cancel();
                deferred.reject();
            });
        return deferred.promise;
    }

    getOrganizationsPage(pageToken, previousData) {
        return this.IAMService
            .organizations({ pageToken })
            .then(({ data, nextPageToken }) => {
                const allData = [...previousData, ...data];
                if (nextPageToken !== '') {
                    return this.getOrganizationsPage(nextPageToken, allData);
                }
                return allData;
            });
    }

    onOrganizationSelect() {
        this.selectedProject = null;
    }

    getOrganizations(searchText) {
        return this.organizations.then((organizations) => {
            if (searchText === '') {
                return organizations;
            }
            const lowerSearchTerm = searchText.toLowerCase();
            return _filter(organizations, o => o.displayName.toLowerCase().search(lowerSearchTerm) !== -1);
        });
    }
}
