
/* @ngInject */
export default class ConfigureSensorsPopupController {
    constructor(DialogService, IAMService, ToastService, sensors) {
        this.DialogService = DialogService;
        this.IAMService = IAMService;
        this.ToastService = ToastService;
        this.sensors = sensors;

        this.devices = sensors.map(sensor => sensor.name);

        this.showConfigurationDropdown = false
        this.configurationTypes = [
            {
                displayName: 'Sampling between heartbeats',
                description: 'Capture additional temperature samples between sensor heartbeats.',
            }
        ]
        this.configurationType = this.configurationTypes[0]
        this.shouldShowSensors = false
        this.currentNumberOfSamples = 1
    }

    setConfigurationType(configuration) {
        this.configurationType = configuration
        this.hideDropdown()
    }
    
    hideDropdown() {
        this.showConfigurationDropdown = false
        
    }

    toggleSensorsVisible() {
        this.shouldShowSensors = !this.shouldShowSensors
    }

    getSampleInterval() {
        switch (this.currentNumberOfSamples) {
            case 1:
                return '15 minutes'
            case 2:
                return '5 minutes'
            case 3:
                return '3 minutes'
            case 4:
                return '1 minute'
            case 5:
                return '30 seconds'
            default:
                return "15 minutes"
        }
    }

    cancel() {
        this.DialogService.cancel();
    }
}
