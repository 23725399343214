import { cloneSensor } from 'services/SensorHelper';
import { States } from '../../app.router';

/* @ngInject */
export default function sensorsAndConnectorsRouter($stateProvider) {
    $stateProvider
        .state(States.SENSORS, {
            url: '/devices?{filter}',
            params: {
                filter: {
                    value: null,
                    dynamic: true
                }
            },
            views: {
                'content@main': {
                    component: 'dtSensorListContainer'
                }
            }
        })
        .state(States.SENSOR_DETAILS, {
            url: '/{sensorId}',
            params: {
                sensor: null,
                ref: null
            },
            resolve: {
                sensor: /* @ngInject */ (SensorService, $stateParams, ToastService) => {
                    if ($stateParams.sensor && $stateParams.sensor.id === $stateParams.sensorId) {
                        return cloneSensor($stateParams.sensor);
                    }

                    return SensorService.getSensor($stateParams.projectId, $stateParams.sensorId).catch((serverResponse) => {
                        ToastService.showSimpleTranslated('sensor_not_found', {
                            serverResponse
                        });
                        return null;
                    });
                }
            },
            onEnter: /* @ngInject */ (sensor, $state, $stateParams) => { // eslint-disable-line consistent-return
                if (!sensor) {
                    return $state.target(States.SENSORS, {
                        projectId: $stateParams.projectId
                    });
                }
            },
            views: {
                rightPanel: {
                    componentProvider: /* @ngInject */ (sensor) => {
                        if (sensor && sensor.type === 'ccon') {
                            return 'dtCloudConnectorDetails';
                        }
                        return 'dtSensorDetails';
                    }
                }
            }
        });
}
