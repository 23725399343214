export class DeferredQueue {
    constructor() {
        this.ready = false;
        this.queue = [];
    }

    setReady() {
        this.ready = true;
        this.flushQueue();
    }

    flushQueue() {
        let cb = this.queue.shift();
        while (cb) {
            cb();
            cb = this.queue.shift();
        }
    }

    enqueue(cb) {
        this.queue.push(cb);
    }

    push(cb) {
        if (this.ready) {
            cb();
        } else {
            this.enqueue(cb);
        }
    }
}
