import { cloneEntity } from 'services/utils';
import { States } from '../../app.router';
import listComponent from './rules.component';
import detailComponent from './rules-detail/rules-detail.component'

/**
 * @param $stateProvider
 * @constructor
 * @ngInject
 */
export default function Routes($stateProvider) {
    $stateProvider
        .state(States.RULES, {
            url: '/rules',
            views: {
                'content@main': {
                    component: listComponent.selector
                }
            }
        })
        .state(States.RULE_DETAIL, {
            url: '/{ruleId}',
            params: {
                rule: null
            },
            resolve: {
                isNew: /* @ngInject */ $stateParams => $stateParams.ruleId === 'new',
                rule: /* @ngInject */ (isNew, $stateParams, $q, RulesService) => {
                    if (isNew) {
                        return {
                            displayName: '',
                            enabled: true,
                            trigger: null,
                            actions: [],
                        };
                    }
                    if ($stateParams.rule?.id === $stateParams.ruleId) {
                        return cloneEntity($stateParams.rule);
                    }
                    return $q((resolve) => {
                        RulesService.getRule($stateParams.ruleId).then(
                            (rule) => resolve(rule)
                        ).catch(() => resolve());
                    });
                }
            },
            onEnter: /* @ngInject */ (rule, $state, $stateParams) => { // eslint-disable-line consistent-return
                if (!rule) {
                    return $state.target(States.RULES, {
                        projectId: $stateParams.projectId
                    });
                }
            },
            component: detailComponent.selector
        });
}
