import options from './loader.component';
import definition from './loader.service';

const LoaderComponent = {
    selector: 'dtLoader',
    options
};

const LoaderService = {
    name: 'Loader',
    definition
};

export {
    LoaderComponent,
    LoaderService
};
