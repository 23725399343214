/* @ngInject */
export default function () {
    function link($scope, $element) {
        function remove() {
            $scope.onHide();

            if (document.getElementById('dt-backdrop')) {
                document.body.removeChild(document.getElementById('dt-backdrop'));
            }

            $scope.$applyAsync();
        }

        function create() {
            const backdrop = document.createElement('div');

            backdrop.id = 'dt-backdrop';
            backdrop.classList.add('dt-backdrop');
            backdrop.addEventListener('click', remove, false);

            document.body.appendChild(backdrop);
        }

        $scope.$watch('dtBackdrop', (value) => {
            if (value) {
                create();
            } else {
                remove();
            }
        });

        $element.on('$destroy', () => {
            if (document.getElementById('dt-backdrop')) {
                document.body.removeChild(document.getElementById('dt-backdrop'));
            }
        });
    }

    return {
        restrict: 'A',
        scope: {
            dtBackdrop: '<',
            onHide: '&'
        },
        link
    };
}
