import moment from 'moment/moment';
import _cloneDeep from 'lodash/cloneDeep';
import { UPDATE_MEMBERSHIP, DELETE_MEMBERSHIP } from 'services/Permissions';
import { scrollContainerToActiveChild, getHoursMinutesFormat } from 'services/utils';
import InviteUrlPopupController from '../popups/invite-url/controller';
import InviteUrlPopupTemplate from '../popups/invite-url/template.html';

/* @ngInject */
export default class DataConnectorController {
    constructor(EventEmitter, RoleManager, AuthService, DialogService, $q, $timeout) {
        this.EventEmitter = EventEmitter;
        this.RoleManager = RoleManager;
        this.AuthService = AuthService;
        this.DialogService = DialogService;
        this.$q = $q;
        this.$timeout = $timeout;
    }

    get canUpdateMember() {
        return this.RoleManager.hasProjectPermissionTo(UPDATE_MEMBERSHIP);
    }

    get canDeleteMember() {
        return this.RoleManager.hasProjectPermissionTo(DELETE_MEMBERSHIP);
    }

    get isCurrentUser() {
        return this.user.id === this.AuthService.getUserId();
    }

    $onInit() {
        this.originalUser = _cloneDeep(this.user);
        this.roles = this.RoleManager.getProjectRoles();
        this.showRoleDropdown = false
        this.userRole = this.user.roles[0];
        this.$timeout(scrollContainerToActiveChild);
    }

    formattedTime(timestamp) {
        return moment(timestamp).format(`MMMM D, yyyy ${getHoursMinutesFormat()}`)
    }

    confirmRoleChange() {
        return this.DialogService.confirm({
            title: 'Confirm role change',
            textContent: 'Do you really want to change role for yourself?',
            ariaLabel: 'change-role',
            ok: 'Change',
            cancel: 'Cancel'
        });
    }

    hideDropdown() {
        this.showRoleDropdown = false
    }

    setNewAccessRole(role) {
        this.userRole = role;
        this.saveRole();
    }

    saveRole() {
        if (this.user.roles[0] !== this.userRole) {
            let readyToSave = true;
            if (this.isCurrentUser) {
                readyToSave = this.confirmRoleChange();
            }
            this.$q.when(readyToSave).then(() => {
                this.user.roles = [this.userRole];
                this.saveUser('roles');
            }, () => {
                this.userRole = this.user.roles[0];
            }).finally(() => {
                this.hideDropdown();
            });

        }
    }

    saveUser(field = null) {
        if (!field || this.originalUser[field] !== this.user[field]) {
            this.onUpdate(
                this.EventEmitter({
                    member: this.user,
                    patch: {
                        [field]: this.user[field]
                    }
                })
            ).then(() => {
                this.originalUser = _cloneDeep(this.user);
            });
        }
    }

    removeAccess() {
        this.onDelete(this.EventEmitter({
            member: this.user,
        }));
    }

    resendInvite() {
        this.onResendInvite(this.EventEmitter({
            member: this.user
        })).then((user) => {
            if (user) {
                this.user = user;
            }
        });
    }

    showInviteUrl() {
        this.DialogService.show({
            controller: InviteUrlPopupController,
            controllerAs: '$ctrl',
            template: InviteUrlPopupTemplate,
            parent: document.body,
            openFrom: 'top',
            closeTo: 'top',
            clickOutsideToClose: true,
            fullscreen: true,
            locals: {
                member: this.user
            }
        });
    }
}
