import controller from './toolbar.controller';

const template = require('./toolbar.html');

export default {
    bindings: {
        backState: '@'
    },
    template,
    controller
};
