import moment from 'moment';
import 'moment-timezone';

import { 
    RANGE_LABEL_DAY, 
    RANGE_LABEL_MONTH, 
    RANGE_LABEL_3_YEARS, 
    SENSOR_GRAPH_RANGES, 
    EXTENDED_SENSOR_GRAPH_RANGES, 
    TEMPERATURE_GRAPH_FILTERS,
    SENSOR_GRAPH_RANGE_STATE_KEY,
} from 'services/charting/constants';
import { getHoursMinutesFormat } from 'services/utils';
import ExtendedStorageInfoController from '../../../../common/extended-storage-info/controller';
import ExtendedStorageInfoTemplate from '../../../../common/extended-storage-info/template.html';

export const SENSOR_GRAPH_FILTER = 'sensor:filter'


/* @ngInject */
export default class SensorChartController {
    constructor(StateService, $rootScope, DialogService, FeatureFlags, AnalyticsService, ProjectManager) {
        this.StateService = StateService;
        this.$rootScope = $rootScope;
        this.DialogService = DialogService;
        this.FeatureFlags = FeatureFlags;
        this.AnalyticsService = AnalyticsService;
        this.ProjectManager = ProjectManager;

        this.featureFlagsLoaded = false;
        this.FeatureFlags.onLoaded().then(() => {
            this.featureFlagsLoaded = true;
        })

        this.chartBucketSizeSeconds = null;
        this.currentExtremes = null;
    }

    get hasLongTermStorageFeatureFlag() {
        return this.FeatureFlags.isActive('sensor_long_term_storage')
    }

    get availableRanges() {
        if (this.hasLongTermStorageFeatureFlag) {
            return SENSOR_GRAPH_RANGES;
        }

        return SENSOR_GRAPH_RANGES.filter(range => !EXTENDED_SENSOR_GRAPH_RANGES.includes(range));
    }

    get unavailableRanges() {
        if (this.hasLongTermStorageFeatureFlag) {
            return [];
        }

        return EXTENDED_SENSOR_GRAPH_RANGES;
    }

    get greatestRangeLabel() {
        return this.hasLongTermStorageFeatureFlag ? RANGE_LABEL_3_YEARS : RANGE_LABEL_MONTH;
    }

    get aggregationInfoTooltip() {
        if (this.chartBucketSizeSeconds === null) {
            return '';
        }

        return `Showing average, min, and max per ${this.chartBucketSizeSeconds / 3600}h`;
    }

    get shouldShowAggregationTooltip() {
        if (this.thing.type === 'motion' || this.thing.type === 'deskOccupancy' || this.thing.type === 'touch' || this.thing.type === 'proximity' || this.thing.type === 'contact') {
            return false
        }

        return this.chartBucketSizeSeconds !== null
    }

    // Handling for zooming and setting durations
    handleChartSelection(event) {
        this.currentExtremes = event?.extremes;

        // Set the range label (eg. "Month", "Day", etc.)
        this.currentRange = null;
    }

    // Handling for using Highchart's navigator to pan the chart
    // Used to connect sensors with multiple independent charts
    handleChartPan(event) {
        this.currentExtremes = event?.extremes;
    }

    setInitialized() {
        this.dataLoaded = true;
        this.$rootScope.$applyAsync()
    }

    setFilter(filter) {
        this.filter = filter
        this.dataLoaded = false
        this.replaceChart = true
        this.StateService.setItem(SENSOR_GRAPH_FILTER, this.filter.value);
        
        setTimeout(() => {
            this.replaceChart = false
            this.hideFilterDropdown()
            this.$rootScope.$applyAsync()
        }, 1);
    }

    setChartMetadata(metadata) {
        this.chartBucketSizeSeconds = metadata?.bucketSizeSeconds;
    }

    hideFilterDropdown() {
        this.showFilterDropdown = false
    }

    setRange(range) {
        this.currentRange = range;
        this.currentExtremes = null;
        this.StateService.setItem(SENSOR_GRAPH_RANGE_STATE_KEY, range);
    }

    hideRangeDropdown() {
        this.showRangeDropdown = false
    }

    $onInit() {
        this.dataLoaded = false;

        this.replaceChart = false
        this.showFilterDropdown = false
        this.showRangeDropdown = false
        
        this.filters = TEMPERATURE_GRAPH_FILTERS
        this.filterValue = this.StateService.getItem(SENSOR_GRAPH_FILTER, 'NONE')
        this.filter = this.filters[this.filterValue]
        
        this.ranges = SENSOR_GRAPH_RANGES;
        this.currentRange = this.StateService.getItem(SENSOR_GRAPH_RANGE_STATE_KEY, RANGE_LABEL_DAY);
            
        this.projectTimeZone = this.ProjectManager.currentProject.location.timeLocation
        this.equalToLocalTimeZone = true

        this.updateLocalTimestamp();
        setInterval(() => {
            this.updateLocalTimestamp();
        }, 1000);
    }

    updateLocalTimestamp() {
        if (this.projectTimeZone !== "") {
            this.localTimestamp = moment().tz(this.projectTimeZone).format(getHoursMinutesFormat());
            this.timezoneDifferenceText = this.formatTimezoneDifference(this.projectTimeZone);
            this.$rootScope.$applyAsync();
        }
    }

    // Function to format the timezone difference
    formatTimezoneDifference(projectTimeZone) {
        const localTimezone = moment.tz.guess();
        const projectTime = moment().tz(projectTimeZone);
        const localTime = moment().tz(localTimezone);

        // Calculate the difference in hours
        const diffHours = projectTime.utcOffset() - localTime.utcOffset();
        const diffInHours = diffHours / 60;  // Convert minutes to hours

        // Determine the text to show based on the time difference
        let timeDiffText = '';
        if (diffInHours > 0) {
            timeDiffText = `${diffInHours} hours ahead`;
            this.equalToLocalTimeZone = false
        } else if (diffInHours < 0) {
            timeDiffText = `${Math.abs(diffInHours)} hours behind`;
            this.equalToLocalTimeZone = false
        } else {
            this.equalToLocalTimeZone = true
        }

        return timeDiffText;
    }

    showExtendedStorageInfo() {
        this.AnalyticsService.trackEvent("device_chart.extended_storage_info_opened")

        this.DialogService.show({
            controller: ExtendedStorageInfoController,
            controllerAs: '$ctrl',
            template: ExtendedStorageInfoTemplate,
            parent: document.body,
            clickOutsideToClose: true,
        });
    }
}
