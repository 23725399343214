import Resource from './Resource';
import { getResourceName, buildApiUrl, extractData } from './helper';

export const EMPTY_METRICS_OBJECT = {
    successCount: '0',
    errorCount: '0',
    latency99p: '0.000s'
};

export const METRIC_KEYS = Object.keys(EMPTY_METRICS_OBJECT);

export const UNAVAILABLE_METRICS_RESPONSE = {
    metrics: {
        ...METRIC_KEYS.reduce((acc, key) => ({ ...acc, [key]: 'N/A' }), {})
    }
};

const injectMetrics = (dataConnector, metrics) => {
    Object.keys(metrics).forEach((key) => {
        Object.defineProperty(dataConnector, key, {
            writable: true,
            value: metrics[key]
        });
    });
    return dataConnector;
};

/* @ngInject */
export default class DataConnectorService {
    constructor($http, ProjectManager, $q) {
        this.$http = $http;
        this.ApiService = new Resource($http);
        this.ProjectManager = ProjectManager;
        this.$q = $q;

        this.getDataConnector = this.ApiService.get;
        this.updateDataConnector = this.ApiService.update;
        this.deleteDataConnector = this.ApiService.remove;
    }

    dataConnectors(params) {
        return this.ApiService.list(getResourceName(
            ['projects', this.ProjectManager.currentProjectId],
            ['project.dataConnectors']
        ), params, 'dataConnectors')
            .then(({ data, ...rest }) => {
                const metricsPromises = data.reduce((acc, dataConnector) => ({
                    ...acc,
                    [dataConnector.name]: this.dataConnectorMetrics(dataConnector.name)
                        .catch(() => UNAVAILABLE_METRICS_RESPONSE)
                }), {});

                return this.$q.all(metricsPromises)
                    .then(metricsResolves => ({
                        data: data.map(dataConnector => injectMetrics(
                            dataConnector,
                            metricsResolves[dataConnector.name].metrics
                        )),
                        ...rest
                    }));
            });
    }

    createDataConnector(data) {
        return this.ApiService.create(getResourceName(
            ['projects', this.ProjectManager.currentProjectId],
            ['project.dataConnectors']
        ), data)
            .then(dataConnector => injectMetrics(dataConnector, EMPTY_METRICS_OBJECT));
    }

    dataConnectorMetrics(dataConnectorName) {
        return this.$http.get(buildApiUrl(
            getResourceName(
                dataConnectorName,
                ['project.dataConnector.metrics']
            )
        ))
            .then(extractData);
    }

    synchronizeDataConnector(dataConnectorName) {
        const url = buildApiUrl(
            getResourceName(
                dataConnectorName,
                ['project.dataConnector.sync']
            )
        );
        return this.$http.post(url).then(extractData);
    }
}
