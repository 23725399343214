import moment from 'moment'
import _uniqBy from 'lodash/uniqBy'

import { parseQuery } from 'services/QueryParser'
import * as DeviceTypes from 'services/config/SensorTypes'

import RangePickerController from '../../../common/range-picker/controller'
import RangePickerTemplate from '../../../common/range-picker/template.html'
import AddDevicesModalController from './add-devices-modal.controller'

import ExtendedStorageInfoController from '../../../common/extended-storage-info/controller';
import ExtendedStorageInfoTemplate from '../../../common/extended-storage-info/template.html';

const devicesModalTemplate = require('./add-devices-modal.html')

/* @ngInject */
export default class ExportController {
    constructor(SensorService, EventEmitter, IAMService, FeatureFlags, DialogService, AnalyticsService, UserPreferencesManager, ProjectManager, $mdDialog, $mdPanel, $q) {
        this.SensorService = SensorService
        this.EventEmitter = EventEmitter
        this.IAMService = IAMService
        this.FeatureFlags = FeatureFlags
        this.DialogService = DialogService
        this.AnalyticsService = AnalyticsService
        this.UserPreferencesManager = UserPreferencesManager
        this.ProjectManager = ProjectManager
        this.userTimezone = null
        this.$q = $q
        this.$mdDialog = $mdDialog
        this.$mdPanel = $mdPanel
        if (typeof Intl !== "undefined") {
            this.userTimezone = Intl.DateTimeFormat?.().resolvedOptions().timeZone
        }
    }

    get formattedStartTime() {
        return moment(this.startTime).format('MMM D')
    }

    get formattedEndTime() {
        return moment(this.endTime).format('MMM D')
    }

    get numberOfActiveDeviceTypes() {
        return Object.keys(this.allDeviceTypes).filter(key => this.allDeviceTypes[key].includeAll).length
    }

    get activeLabels() {
        // Return only the checked/activated label keys
        return Object.keys(this.includedLabels).filter(key => this.includedLabels[key])
    }

    get activeLabelsCSVFormatted() {
        // Replace '-' and ' ' with '_' to match the resulting CSV file
        return this.activeLabels.map(label => label.replaceAll('-', '_').replaceAll(' ', '_'))
    }

    get uniqueActiveAggregations() {
        return _uniqBy(this.activeAggregations, 'displayName')
    }

    get uniqueEventColumns() {
        let columns = []
        this.includedEvents.forEach(event => {
            if (this.exportFormat === 'CSV') {
                columns = [...columns, ...this.eventOptions[event].columnsCSV] 
            } else {
                columns = [...columns, ...this.eventOptions[event].columns] 
            }
        })
        return _uniqBy(columns)
    }

    get deviceTypesInDeviceList() {
        return new Set(this.deviceList.map(device => device.type))
    }

    get showDeviceTypeError() {
        // Show error when a device type that is not within the Project is selected
        if (this.deviceTypeOption === 'ALL' || this.deviceTypeOption === 'CUSTOM') {
            return false
        }
        return this.deviceTypeOption && !this.devicesCount[this.deviceTypeOption]
    }

    get exportButtonDisabled() {
        if (this.exportType === 'EVENTS') {
            return this.includedEvents.length === 0 || this.showDeviceTypeError
        } 
        if (this.exportType === 'AGGREGATE') {
            return this.deviceList.length === 0 || this.activeAggregations.length === 0
        }
        return this.deviceList.length === 0
    }

    get showExcelWarning() {
        return this.exportFormat === 'EXCEL' && (this.exportType === 'EVENTS' || (this.exportType === 'AGGREGATE' && this.deviceListIds.length > 120))
    }

    get hasLongTermStorageFeatureFlag() {
        return this.FeatureFlags.isActive('sensor_long_term_storage')
    }

    get showTemperatureUnit() {
        // Show temperature unit dropdown if there are devices that support temperature
        if (this.exportType === 'EVENTS') {
            return this.includedEvents.includes('temperature') || this.includedEvents.includes('humidity')
        }

        if (this.exportType === 'AGGREGATE') {
            return this.uniqueActiveAggregations.map(agg => agg.displayNameCSV).includes('temperature_average_celsius')
        }
        return false
    }

    hideRangeOptionsDropdown() {
        this.showRangeDropdown = false
    }

    $onInit() {
        this.exportType = 'AGGREGATE' 
        this.startTimeOpen = false
        this.startTime = moment().startOf('day').subtract(7, 'days').startOf('day')
        this.minDate = moment().startOf('day').subtract(30, 'days').toDate()
        this.endTimeOpen = false
        this.endTime = moment()
        this.maxDate = moment().endOf('day').toDate()
        this.showRangeDropdown = false
        this.extendedStorageDurations = ["LAST_3_MONTHS", "LAST_6_MONTHS", "LAST_12_MONTHS", "LAST_3_YEARS"]
        
        this.loadingDevices = false
        this.deviceList = []
        this.deviceListIds = []

        this.deviceExportMethod = 'DEVICE_TYPES'
        this.deviceTypeHelper = DeviceTypes

        this.deviceTypeOption = '' // Either a device type or 'ALL' or 'CUSTOM
        this.showDeviceTypeOptions = false
        this.selectedAllDeviceTypes = false

        this.allDeviceTypes = {
            temperature:      { displayName: 'Temperature',        events: ['temperature'],                                          includeAll: false, disabled: false },    
            humidity:         { displayName: 'Humidity',           events: ['humidity'],                                             includeAll: false, disabled: false },
            co2:              { displayName: 'CO2',                events: ['humidity', 'co2', 'pressure'],                          includeAll: false, disabled: false },
            proximity:        { displayName: 'Proximity',          events: ['objectPresent'],                                        includeAll: false, disabled: false },
            contact:          { displayName: 'Door & Window',      events: ['contact'],                                              includeAll: false, disabled: false },
            deskOccupancy:    { displayName: 'Desk Occupancy',     events: ['deskOccupancy'],                                        includeAll: false, disabled: false },
            motion:           { displayName: 'Motion',             events: ['motion'],                                               includeAll: false, disabled: false },
            waterDetector:    { displayName: 'Water',              events: ['waterPresent'],                                         includeAll: false, disabled: false },
            touch:            { displayName: 'Touch',              events: ['touch'],                                                includeAll: false, disabled: false },
            proximityCounter: { displayName: 'Counting Proximity', events: ['objectPresentCount'],                                   includeAll: false, disabled: false },
            touchCounter:     { displayName: 'Counting Touch',     events: ['touchCount'],                                           includeAll: false, disabled: false },
            ccon:             { displayName: 'Cloud Connector',    events: ['connectionStatus', 'ethernetStatus', 'cellularStatus'], includeAll: false, disabled: false }
        }

        this.eventOptions = {
            temperature:        { active: false, disabled: false, columns: ['Temperature (C)'], columnsCSV: ['temperature_celsius'], },
            touch:              { active: false, disabled: false, columns: [], columnsCSV: [] },
            contact:            { active: false, disabled: false, columns: ['State'], columnsCSV: ['state'] },
            objectPresent:      { active: false, disabled: false, columns: ['State'], columnsCSV: ['state'] },
            objectPresentCount: { active: false, disabled: false, columns: ['Count'], columnsCSV: ['count'] },
            touchCount:         { active: false, disabled: false, columns: ['Count'], columnsCSV: ['count'] },
            humidity:           { active: false, disabled: false, columns: ['Relative Humidity (%RH)', 'Temperature (C)'], columnsCSV: ['relative_humidity_percent', 'temperature_celsius'] },
            waterPresent:       { active: false, disabled: false, columns: ['State'], columnsCSV: ['state'] },
            co2:                { active: false, disabled: false, columns: ['CO2 (ppm)'], columnsCSV: ['co2_ppm'] },
            pressure:           { active: false, disabled: false, columns: ['Pressure (Pa)'], columnsCSV: ['pressure_pascal'] },
            motion:             { active: false, disabled: false, columns: ['State'], columnsCSV: ['state'] },
            deskOccupancy:      { active: false, disabled: false, columns: ['State'], columnsCSV: ['state'] },
            connectionStatus:   { active: false, disabled: false, columns: ['Connection Status'], columnsCSV: ['connection_status'] },
            ethernetStatus:     { active: false, disabled: false, columns: ['MAC Address', 'IP Address'], columnsCSV: ['mac_address', 'ip_address'] },
            cellularStatus:     { active: false, disabled: false, columns: ['Signal Strength (%)'], columnsCSV: ['signal_strength_percent'] },          
            batteryStatus:      { active: false, disabled: false, columns: ['Battery (%)'], columnsCSV: ['battery_percent'] },
            networkStatus:      { active: false, disabled: false, 
                columns: ['Signal Strength (%)', 'RSSI (dB)', 'Transmission Mode', 'Cloud Connector ID'], 
                columnsCSV: ['signal_strength_percent', 'rssi_db', 'transmission_mode', 'cloud_connector_id', ] }
        }

        this.eventOption = 'DEVICES' // 'DEVICES', 'ALL or 'CUSTOM'
        this.showEventOptions = false
        this.eventsBasedOnDevices = []
        this.includedEvents = []

        this.aggregations = {
            temperature: [
                {  fieldName: 'temperature.value',         type: 'AVERAGE',  displayName: 'Temperature Average (C)',           displayNameCSV: 'temperature_average_celsius' },
                {  fieldName: 'temperature.value',         type: 'MAX',      displayName: 'Temperature Max (C)',               displayNameCSV: 'temperature_max_celsius' },
                {  fieldName: 'temperature.value',         type: 'MIN',      displayName: 'Temperature Min (C)',               displayNameCSV: 'temperature_min_celsius' },
            ],
            humidity: [
                {  fieldName: 'humidity.relativeHumidity', type: 'AVERAGE',  displayName: 'Humidity Average (%RH)',            displayNameCSV: 'humidity_average_percent' },
                {  fieldName: 'humidity.relativeHumidity', type: 'MAX',      displayName: 'Humidity Max (%RH)',                displayNameCSV: 'humidity_max_percent' },
                {  fieldName: 'humidity.relativeHumidity', type: 'MIN',      displayName: 'Humidity Min (%RH)',                displayNameCSV: 'humidity_min_percent' },
                {  fieldName: 'humidity.temperature',      type: 'AVERAGE',  displayName: 'Temperature Average (C)',           displayNameCSV: 'temperature_average_celsius' },
                {  fieldName: 'humidity.temperature',      type: 'MAX',      displayName: 'Temperature Max (C)',               displayNameCSV: 'temperature_max_celsius' },
                {  fieldName: 'humidity.temperature',      type: 'MIN',      displayName: 'Temperature Min (C)',               displayNameCSV: 'temperature_min_celsius' },
            ],
            co2: [
                {  fieldName: 'co2.ppm',                   type: 'AVERAGE',  displayName: 'CO2 Average (ppm)',                 displayNameCSV: 'co2_average_ppm' },
                {  fieldName: 'co2.ppm',                   type: 'MAX',      displayName: 'CO2 Max (ppm)',                     displayNameCSV: 'co2_max_ppm' },
                {  fieldName: 'co2.ppm',                   type: 'MIN',      displayName: 'CO2 Min (ppm)',                     displayNameCSV: 'co2_min_ppm' },
                {  fieldName: 'humidity.relativeHumidity', type: 'AVERAGE',  displayName: 'Humidity Average (%RH)',            displayNameCSV: 'humidity_average_percent' },
                {  fieldName: 'humidity.relativeHumidity', type: 'MAX',      displayName: 'Humidity Max (%RH)',                displayNameCSV: 'humidity_max_percent' },
                {  fieldName: 'humidity.relativeHumidity', type: 'MIN',      displayName: 'Humidity Min (%RH)',                displayNameCSV: 'humidity_min_percent' },
                {  fieldName: 'humidity.temperature',      type: 'AVERAGE',  displayName: 'Temperature Average (C)',           displayNameCSV: 'temperature_average_celsius' },
                {  fieldName: 'humidity.temperature',      type: 'MAX',      displayName: 'Temperature Max (C)' ,              displayNameCSV: 'temperature_max_celsius' },
                {  fieldName: 'humidity.temperature',      type: 'MIN',      displayName: 'Temperature Min (C)' ,              displayNameCSV: 'temperature_min_celsius' },
                {  fieldName: 'pressure.pascal',           type: 'AVERAGE',  displayName: 'Pressure Average (Pa)',             displayNameCSV: 'pressure_average_pascal' },
                {  fieldName: 'pressure.pascal',           type: 'MAX',      displayName: 'Pressure Max (Pa)',                 displayNameCSV: 'pressure_max_pascal' },
                {  fieldName: 'pressure.pascal',           type: 'MIN',      displayName: 'Pressure Min (Pa)',                 displayNameCSV: 'pressure_min_pascal' },
            ],
            contact: [
                { fieldName: 'contact.state',              type: 'DURATION', displayName: 'Door Window Open Duration (Minutes)',           displayNameCSV: 'door_window_open_duration_minutes' }
            ],
            proximity: [
                { fieldName: 'objectPresent.state',        type: 'DURATION', displayName: 'Object Present Duration (Minutes)', displayNameCSV: 'object_present_duration_minutes' }
            ],
            deskOccupancy: [
                { fieldName: 'deskOccupancy.state',        type: 'DURATION', displayName: 'Desk Occupancy Duration (Minutes)', displayNameCSV: 'desk_occupancy_duration_minutes' }
            ],
            motion: [
                { fieldName: 'motion.state',               type: 'DURATION', displayName: 'Motion Duration (Minutes)',         displayNameCSV: 'motion_duration_minutes' }
            ],
            waterDetector: [
                { fieldName: 'waterPresent.state',         type: 'DURATION', displayName: 'Water Present Duration (Minutes)',  displayNameCSV: 'water_present_duration_minutes' }
            ],
            touch: [
                { fieldName: 'touch',                      type: 'COUNT',    displayName: 'Touch Count (Total)',               displayNameCSV: 'touch_count_total' }
            ],
            touchCounter:     [],
            proximityCounter: [],
            ccon:             []
        }

        this.activeAggregations = []
        this.aggregationDuration = 'HOUR'
        this.aggregationDurationSeconds = 3600

        this.showIncludedLabels = false
        this.includedLabels = {
            name: true,
            description: false
        }

        this.dateRange = 'LAST_7_DAYS'
        this.exportFormat = 'CSV'

        this.temperatureUnit = this.UserPreferencesManager.useFahrenheit ? 'FAHRENHEIT' : 'CELSIUS'

        this.outputTimezone = 'UTC'
        this.projectTimezone = this.ProjectManager.currentProject.location.timeLocation
        if (this.projectTimezone) {
            this.outputTimezone = this.projectTimezone
        } else {
            this.outputTimezone = 'UTC'
        }

        this.devicesCount = {}
        this.IAMService.getProjectDevicesCount().then(response => {
            this.devicesCount = response.counts

            // Set initial state for the included device types dropdown to a type found within the Project
            for (const deviceType of Object.keys(this.allDeviceTypes)) { // eslint-disable-line no-restricted-syntax
                if (this.devicesCount[deviceType]) {
                    this.deviceTypeOption = deviceType
                    this.activateDeviceType(deviceType, true, false)
                    break
                }
            }
            // Default to temperature if there was no devices
            if (this.deviceTypeOption === '') {
                this.deviceTypeOption = 'temperature'
                this.activateDeviceType('temperature', true, false)
            }
        })
    }

    setExportType(type) { 
        const previousExportType = this.exportType
        this.exportType = type

        switch (this.exportType) {
            case 'EVENTS':
                this.allDeviceTypes.ccon.disabled = false
                this.allDeviceTypes.touchCounter.disabled = false
                this.allDeviceTypes.proximityCounter.disabled = false
                break
            case 'AGGREGATE':
                this.allDeviceTypes.ccon.includeAll = false
                this.allDeviceTypes.ccon.disabled = true

                this.allDeviceTypes.proximityCounter.includeAll = false
                this.allDeviceTypes.proximityCounter.disabled = true

                this.allDeviceTypes.touchCounter.includeAll = false
                this.allDeviceTypes.touchCounter.disabled = true

                if (this.deviceTypeOption === 'ccon' || this.deviceTypeOption === 'proximityCounter' || this.deviceTypeOption === 'touchCounter') {
                    // If the device type does not support aggregations, default to Temperature
                    this.deviceTypeOption = 'temperature'
                }
                this.updateActiveAggregations()

                break
            case 'DEVICES':
                this.allDeviceTypes.ccon.disabled = false
                this.allDeviceTypes.touchCounter.disabled = false
                this.allDeviceTypes.proximityCounter.disabled = false

                break
            default:
                break
        }



        // If we're switching from the EVENTS export type, we'll need to load in the devices to get label suggestions
        if (previousExportType === 'EVENTS' && this.deviceExportMethod === 'DEVICE_TYPES') {
            if (this.deviceTypeOption === 'ALL' || this.deviceTypeOption === 'CUSTOM') {
                for (const deviceType of Object.keys(this.allDeviceTypes)) { // eslint-disable-line no-restricted-syntax
                    if (this.allDeviceTypes[deviceType].includeAll) {        
                        this.loadDevices(deviceType)
                    }
                }
            } else {
                this.loadDevices(this.deviceTypeOption) // Single device type selected
            }
        }

        // Need to reactivate all device types if we're switching from Aggregate and all devices should be selected
        if (previousExportType === 'AGGREGATE' && this.deviceExportMethod === 'DEVICE_TYPES' && this.deviceTypeOption === 'ALL') {
            this.activateAllDeviceTypes(true)
        }
    }

    updateRange(range) {
        this.dateRange = range
        this.hideRangeOptionsDropdown()
        switch (this.dateRange) {
            case 'TODAY':
                // The backend excludes rows that are not finished yet, so it doesn't make sense to do
                // a daily export for today (it's guaranteed to be empty). Instead, we switch the aggregation
                // picker to hourly automatically when "TODAY" is selected as the date range.
                this.aggregationDuration = 'HOUR'
                this.startTime = moment().startOf('day') // Start of today
                this.endTime = moment().add(1, 'day').startOf('day') // Start of tomorrow
                break
            case 'YESTERDAY':
                this.startTime = moment().subtract(1, 'day').startOf('day') // Start of yesterday
                this.endTime = moment().startOf('day') // Start of today
                break
            case 'THIS_WEEK':
                this.startTime = moment().startOf('isoWeek')
                this.endTime = moment()
                break
            case 'LAST_WEEK':
                this.startTime = moment().subtract(1, 'week').startOf('isoWeek')
                this.endTime = moment().subtract(1, 'week').endOf('isoWeek')
                break
            case 'LAST_3_DAYS':
                this.startTime = moment().subtract(3, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_7_DAYS':
                this.startTime = moment().subtract(7, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_30_DAYS':
                this.startTime = moment().subtract(30, 'day').startOf('day')
                this.endTime = moment()
                break
            case 'LAST_3_MONTHS':
                this.startTime = moment().subtract(3, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_6_MONTHS':
                this.startTime = moment().subtract(6, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_12_MONTHS':
                this.startTime = moment().subtract(12, 'month').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            case 'LAST_3_YEARS':
                this.startTime = moment().subtract(3, 'year').startOf('day')
                this.endTime = moment()
                this.aggregationDuration = 'DAY'
                break
            default:
                this.dateRange = 'LAST_7_DAYS'
                this.startTime = moment().subtract(7, 'day').startOf('day')
                this.endTime = moment()
                break
        }
        this.aggregationDurationChanged()
    }

    getFormattedDuration(duration) {
        switch (duration) {
            case 'CUSTOM':
                return 'Custom'
            case 'TODAY':
                return 'Today'
            case 'YESTERDAY':
                return 'Yesterday'
            case 'LAST_3_DAYS':
                return 'Last 3 days'
            case 'LAST_7_DAYS':
                return 'Last 7 days'
            case 'LAST_30_DAYS':
                return 'Last 30 days'
            case 'LAST_3_MONTHS':
                return 'Last 3 months'
            case 'LAST_6_MONTHS':
                return 'Last 6 months'
            case 'LAST_12_MONTHS':
                return 'Last 12 months'
            case 'LAST_3_YEARS':
                return 'Last 3 years'
            case 'THIS_WEEK':
                return 'This week'
            case 'LAST_WEEK':
                return 'Last week'
            default:
                return duration
        }
    }

    showExtendedStorageInfo() {
        this.AnalyticsService.trackEvent("exports.extended_storage_info_opened")

        this.DialogService.show({
            controller: ExtendedStorageInfoController,
            controllerAs: '$ctrl',
            template: ExtendedStorageInfoTemplate,
            parent: document.body,
            clickOutsideToClose: true,
        })
    }

    showRangePicker(ev) {
        this.showingRangePicker = true
        const position = this.$mdPanel.newPanelPosition()
            .relativeTo(ev.target)
            .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW).withOffsetX('54px').withOffsetY('4px')
            
        const panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(ev.target)
            .withAnimation('md-panel--animation')

        const config = {
            attachTo: document.body,
            controller: RangePickerController,
            controllerAs: '$ctrl',
            template: RangePickerTemplate,
            panelClass: 'md-panel-container arrow arrow-gray',
            animation: panelAnimation,
            bindToController: true,
            position,
            openFrom: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 100,
            locals: {
                zoomStartTime: this.startTime,
                zoomEndTime: this.endTime,
                updateDateRange: this.updateDateRange.bind(this),
                minDate: this.hasLongTermStorageFeatureFlag ?  moment().subtract(3, 'year').toDate() : moment().subtract(30, 'day').toDate(),
                maxDate: moment().toDate(),
                saveText: 'Confirm Range'
            },
            onRemoving: () => {
                this.showingRangePicker = false
                this.panelRef.destroy()
            }
        }

        this.$mdPanel.open(config).then(reference => {
            this.panelRef = reference
        })
    }

    updateDateRange(startDate, endDate) {
        this.dateRange = 'CUSTOM'
        this.startTime = startDate
        this.endTime = endDate
        this.panelRef.close()
    }

    aggregationDurationChanged() {
        switch (this.aggregationDuration) {
            case 'HOUR':
                this.aggregationDurationSeconds = 3600
                break
            case 'DAY':
                this.aggregationDurationSeconds = 3600 * 24
                break
            default:
                break
        }
    }

    updatedDeviceExportMethod() {
        if (this.deviceExportMethod === 'DEVICE_IDS') {
            this.deviceList = []
            this.deviceListIds = []
    
            for (const key of Object.keys(this.allDeviceTypes)) { // eslint-disable-line no-restricted-syntax
                this.allDeviceTypes[key].includeAll = false
            }
    
            this.updateLabelsOnRemove()
            this.updateEventsBasedOnDevices()
            this.updateActiveAggregations()
        } else {
            // Reload based on 'deviceTypeOption'
            this.changedDeviceTypeOptionDropdown()
        }
    }

    showAddDeviceDialog(ev) {
        this.$mdDialog.show({
            controller: AddDevicesModalController,
            template: devicesModalTemplate,
            targetEvent: ev,
            clickOutsideToClose: true,
            fullscreen: true,
            controllerAs: '$ctrl',
            locals: {
                deviceTypes: ['touch', 'temperature', 'humidity', 'proximity', 'waterDetector', 'motion', 'deskOccupancy', 'co2', 'contact'], // Providing a list of supported devices
                selectedDevices: this.deviceListIds,
                onAddDevice: this.onAdd.bind(this)
            },
        })
    }

    onAdd(device) { // Callback from select sensor modal
        if (!this.deviceListIds.includes(device.id)) {
            this.deviceListIds.push(device.id)
            this.deviceList.unshift(device)
        }
        this.updateLabelOnAdd()
        this.updateEventsBasedOnDevices()
        this.updateActiveAggregations()
    }

    toggleSelectAllDeviceTypes() {
        this.selectedAllDeviceTypes = !this.selectedAllDeviceTypes
        this.activateAllDeviceTypes(this.selectedAllDeviceTypes)
    }

    activateAllDeviceTypes(activate) {
        for (const deviceType of Object.keys(this.allDeviceTypes)) { // eslint-disable-line no-restricted-syntax
            if (!this.allDeviceTypes[deviceType].disabled) {
                this.allDeviceTypes[deviceType].includeAll = activate
                this.activateDeviceType(deviceType, activate, false)
            }
        }
        this.updateEventsBasedOnDevices()
    }

    activateDeviceType(deviceType, activate, enableCustomOption = true) {

        if (enableCustomOption) { this.deviceTypeOption = 'CUSTOM' }
        const deviceTypeOptions = this.allDeviceTypes[deviceType]
        deviceTypeOptions.includeAll = activate
        if (deviceTypeOptions.includeAll) {
            if (this.exportType === 'AGGREGATE' || this.exportType === 'DEVICES') {
                // Load devices to show label suggestions
                this.loadDevices(deviceType)
            }
        } else { // Remove all of given device type
            this.deviceList = this.deviceList.filter(device => device.type !== deviceType)
            this.deviceListIds = this.deviceList.map(device => device.id)

            this.updateLabelsOnRemove()
            this.updateActiveAggregations()
        }

        this.updateEventsBasedOnDevices()
    }

    changedDeviceTypeOptionDropdown() {
        switch (this.deviceTypeOption) {
            case 'ALL':
                this.activateAllDeviceTypes(true)
                break
            case 'CUSTOM':
                this.showDeviceTypeOptions = true
                break
            default:
                // A single device type was selected
                this.showDeviceTypeOptions = false
                this.activateAllDeviceTypes(false)
                this.activateDeviceType(this.deviceTypeOption, true, false)
                break
        }
    }

    loadDevices(deviceType) {
        const promises = []
        this.loadingDevices = true
        promises.push(this.getAllDevicesOfType(deviceType, null, []).then(devices => {
            devices.forEach(device => {
                if (!this.deviceListIds.includes(device.id)) {
                    this.deviceList.unshift(device)
                }
            })
            this.deviceListIds = this.deviceList.map(device => device.id)
            this.loadingDevices = false
        }))

        this.$q.all(promises).then(() => {
            this.updateLabelOnAdd()
            this.updateActiveAggregations()                
        })
    }

    getAllDevicesOfType(type, pageToken, previousData) { 
        // Recursive function, keep fetching devices of a given type until the nextPageToken is empty
        return this.SensorService.sensors({
            ...parseQuery(`type:${type}`),
            orderBy: 'labels.name',
            pageSize: 100,
            pageToken
        }).then(({ data, nextPageToken}) => {
            const allData = [...previousData, ...data]
            if (nextPageToken !== '') {
                return this.getAllDevicesOfType(type, nextPageToken, allData)
            }
            return allData
        }).catch((serverResponse) => {
            this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
                serverResponse
            })
        })
    }

    removeDevice(device) {
        this.deviceListIds.splice(this.deviceListIds.indexOf(device.id), 1)
        this.deviceList.splice(this.deviceList.indexOf(device), 1)
        
        this.updateLabelsOnRemove()
        this.updateEventsBasedOnDevices()
        this.updateActiveAggregations()
    }

    updateEventsBasedOnDevices() {
        const eventSet = new Set()
        // Get events based on active device checkboxes
        for (const deviceType of Object.keys(this.allDeviceTypes)) { // eslint-disable-line no-restricted-syntax
            if (this.allDeviceTypes[deviceType].includeAll) {
                this.allDeviceTypes[deviceType].events.forEach(event => {
                    eventSet.add(event)
                })
            }
        }
        // Get events based on added single devices 
        this.deviceTypesInDeviceList.forEach(deviceType => {
            this.allDeviceTypes[deviceType].events.forEach(event => {
                eventSet.add(event)
            })
        })

        // Store the uniq event types 
        this.eventsBasedOnDevices = Array.from(eventSet)
        
        // Only set the events as active if events should be based on devices
        if (this.eventOption === 'DEVICES') {
            this.eventsBasedOnDevices.forEach(event => {
                this.eventOptions[event].active = true
            })

            this.includedEvents = this.eventsBasedOnDevices
            for (const event of Object.keys(this.eventOptions)) { // eslint-disable-line no-restricted-syntax
                this.eventOptions[event].active = this.includedEvents.includes(event)
            }
        }
    }

    changedEventOptionDropdown() {
        switch (this.eventOption) {
            case 'DEVICES':
                this.updateEventsBasedOnDevices()
                break
            case 'ALL':
                this.includedEvents = Object.keys(this.eventOptions)
                this.includedEvents.forEach(event => {
                    this.eventOptions[event].active = true
                })
                break
            case 'CUSTOM':
                this.showEventOptions = true
                break
            default:
                break
        }
    }

    changedIncludedEvents() {
        // User toggled an event checkbox
        this.eventOption = 'CUSTOM'
        const activeEvents = Object.keys(this.eventOptions).filter(eventName => this.eventOptions[eventName].active)
        this.includedEvents = activeEvents     
    }

    updateLabelOnAdd() {
        // Add options for all the labels we find for the included devices
        this.deviceList.forEach(device => {
            Object.keys(device.labels).forEach(label => {
                this.includedLabels[label] = this.includedLabels[label] || false
            })
        })
    }

    updateLabelsOnRemove() {
        const deleteKeys = Object.keys(this.includedLabels).filter(key => !this.includedLabels[key]) // We will delete all label options if they are not set to active
        deleteKeys.forEach(key => {
            if (key !== 'name' && key !== 'description') { // Always keep 'name' and 'description' as label options
                delete this.includedLabels[key]
            }
        })

        // Create a set based on all the device labels
        const labels = this.deviceList.map(device => Object.keys(device.labels))
        const labelsSet = new Set(labels.flat())

        labelsSet.forEach(key => {
            if (!this.includedLabels[key]) {
                this.includedLabels[key] = false
            }
        })
    }

    updateActiveAggregations() {
        this.activeAggregations = []
        this.deviceTypesInDeviceList.forEach(deviceType => {    
            this.activeAggregations = [...this.activeAggregations, ...this.aggregations[deviceType]] 
        })
    }

    unitCheck(displayName) {
        // If the user has selected Fahrenheit, we need to change the unit in the display name
        if (this.temperatureUnit === 'FAHRENHEIT') {
            if (displayName.includes('celsius')) {
                return displayName.replace('celsius', 'fahrenheit')
            }
            if (displayName.includes('C')) {
                return displayName.replace('(C)', '(F)')
            }
        }
        return displayName
    }
    
    createExport() {

        const deviceTypes = Object.keys(this.allDeviceTypes).filter(deviceType => this.allDeviceTypes[deviceType].includeAll)
        const deviceIds = this.deviceExportMethod === 'DEVICE_IDS' ? this.deviceListIds : []

        switch (this.exportType) {
            case 'EVENTS':
                this.onCreate(this.EventEmitter({
                    format: this.exportFormat,
                    outputTimezone: this.outputTimezone,
                    events: {
                        startTime: moment(this.startTime).utc().format(),
                        endTime: moment(this.endTime).utc().format(),
                        eventTypes: this.includedEvents,
                        deviceIds,
                        deviceTypes,
                        temperatureUnit: this.temperatureUnit
                    }
                }))
                break
        
            case 'AGGREGATE':
                // Avoid having empty rows by adjusting endTime based on aggregation duration
                if (this.dateRange !== 'YESTERDAY' && this.dateRange !== 'LAST_WEEK') {
                    if (this.aggregationDuration === 'HOUR') {
                        this.endTime = moment(this.endTime).startOf('hour')
                    } else if (this.aggregationDuration === 'DAY' && this.dateRange !== 'TODAY') {
                        this.endTime = moment(this.endTime).startOf('day')
                    }
                }
                this.onCreate(this.EventEmitter({
                    format: this.exportFormat,
                    outputTimezone: this.outputTimezone,
                    aggregates: {
                        startTime: moment(this.startTime).utc().format(),
                        endTime: moment(this.endTime).utc().format(),
                        labelColumns: this.activeLabels,
                        aggregateWindowSeconds: this.aggregationDurationSeconds,
                        aggregations: this.removeDuplicates(this.activeAggregations),
                        deviceIds,
                        deviceTypes,
                        temperatureUnit: this.temperatureUnit
                    }
                }))
                break
        
            case 'DEVICES':
                this.onCreate(this.EventEmitter({
                    format: this.exportFormat,
                    outputTimezone: this.outputTimezone,
                    devices: {
                        labelColumns: this.activeLabels,
                        deviceIds,
                        deviceTypes
                    }
                }))
                break
        
            default:
                break
        }
    }


    // Returns a new array with duplicates removed based on fieldName and type
    removeDuplicates(array) {
        const seen = new Set();
    
        return array.filter(item => {
            const key = `${item.fieldName}-${item.type}`;
            if (!seen.has(key)) {
                seen.add(key);
                return true;
            }
            return false;
        });
    }
}
