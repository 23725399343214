import _merge from 'lodash/merge';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { memoizeWith, identity, identical, filter, last } from 'ramda';
import ConfigPresets from 'services/charting/presets';
import { DeferredQueue } from 'services/DeferredQueue';
import {
    EVENT_TYPE_NETWORK_STATUS,
    EVENT_TYPE_OBJECT_PRESENT
} from 'constants/device';
import {
    HEARTBEAT_MAX_GAP,
    OPPOSITE_PROXIMITY_STATES,
    PROXIMITY_STATES
} from 'services/charting/data-converter';
import { DataConverter } from 'services/charting';
import { reflowCharts } from 'services/utils';

const REDRAW_DEBOUNCE_WINDOW = 150;

const MESSAGE_LOADING = 'Loading data from server...';

const isStateEvent = identical(EVENT_TYPE_OBJECT_PRESENT);
const onlyStateEvents = memoizeWith(
    identity,
    filter(({ eventType }) => isStateEvent(eventType))
);

const isNetworkEvent = identical(EVENT_TYPE_NETWORK_STATUS);
const onlyNetworkEvents = memoizeWith(
    identity,
    filter(({ eventType }) => isNetworkEvent(eventType))
);

const networkEventTimeGetter = ({ data }) =>
    +new Date(data[EVENT_TYPE_NETWORK_STATUS].updateTime);

const proximityEventTimeGetter = ({ data }) =>
    +new Date(data[EVENT_TYPE_OBJECT_PRESENT].updateTime);

const proximityEventStateGetter = ({ data }) =>
    data[EVENT_TYPE_OBJECT_PRESENT].state;

const getEventsWindowByExtremes = (events, extremes, eventTimeGetter) => {
    const [min, max] = extremes;

    let closestBefore = null;
    let closestAfter = null;
    const eventsInside = [];

    events.every(ev => {
        const updateTime = eventTimeGetter(ev);
        if (updateTime < min) {
            closestBefore = ev;
        } else if (updateTime >= min && updateTime <= max) {
            eventsInside.push(ev);
        } else if (!closestAfter) {
            closestAfter = ev;
        } else {
            return false;
        }
        return true;
    });

    return {
        closestBefore,
        closestAfter,
        eventsInside
    };
};

const convertToChartData = (eventsWindow, extremes) => {
    const [min, max] = extremes;
    const { closestBefore, closestAfter, eventsInside } = eventsWindow;

    const result = [];

    if (eventsInside.length) {
        let prevTime = min;
        let startedAt = closestBefore
            ? proximityEventTimeGetter(closestBefore)
            : null;
        eventsInside.forEach(ev => {
            const updateTime = proximityEventTimeGetter(ev);
            result.push({
                x: prevTime,
                x2: updateTime,
                y: OPPOSITE_PROXIMITY_STATES[proximityEventStateGetter(ev)],
                startedAt,
                lastedFor: startedAt ? updateTime - startedAt : null
            });
            startedAt = updateTime;
            prevTime = updateTime;
        });
        result.push({
            x: prevTime,
            x2: max,
            y: PROXIMITY_STATES[proximityEventStateGetter(last(eventsInside))],
            startedAt,
            lastedFor: closestAfter
                ? proximityEventTimeGetter(closestAfter) - startedAt
                : +new Date() - startedAt
        });
        return result;
    }

    if (closestBefore) {
        const startedAt = proximityEventTimeGetter(closestBefore);
        result.push({
            x: min,
            x2: max,
            y: PROXIMITY_STATES[proximityEventStateGetter(closestBefore)],
            startedAt,
            lastedFor: closestAfter
                ? proximityEventTimeGetter(closestAfter) - startedAt
                : +new Date() - startedAt
        });
        return result;
    }

    if (closestAfter) {
        result.push({
            x: min,
            x2: max,
            y:
                OPPOSITE_PROXIMITY_STATES[
                    proximityEventStateGetter(closestAfter)
                ]
        });
        return result;
    }

    return result;
};

const GRAPH_MAX_POINTS = 3000;

const isTooMuchData = stateEvents => stateEvents.length > GRAPH_MAX_POINTS;

/**
 * @class AuxiliaryChartController
 *
 * @property {Object[]} events
 * @property {number[]} extremes
 * @property {Function} onChartSelection
 * @property {boolean} loading
 * @ngInject
 */
export default class AuxiliaryChartController {
    constructor(EventEmitter, $scope) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;

        this.changesQueue = new DeferredQueue();
        this.redrawData = _debounce(
            this.redrawData.bind(this),
            REDRAW_DEBOUNCE_WINDOW
        );
        this.handleChartSelection = this.handleChartSelection.bind(this);
    }

    get dataSeries() {
        return this.chartRef.series[0];
    }

    get xAxis() {
        return this.chartRef.xAxis[0];
    }

    onChartLoaded({ chart }) {
        this.chartRef = chart;
        this.changesQueue.setReady();
    }

    handleChartSelection({ xAxis }) {
        if (!xAxis || !xAxis.length) {
            return;
        }
        const { min, max } = xAxis[0];
        this.onChartSelection(
            this.EventEmitter({
                extremes: [min, max]
            })
        );
    }

    redrawData() {
        const networkEvents = onlyNetworkEvents(this.events);
        const stateEvents = onlyStateEvents(this.events);

        /**
         * In case when no proximity events happened in the selected range
         * most likely the current sensor state is still valid.
         * That's why we add it as an event to the stateEvents array.
         */
        if (!stateEvents.length) {
            const currentState = _get(
                this.thing,
                `reported.${EVENT_TYPE_OBJECT_PRESENT}`
            );
            if (currentState) {
                stateEvents.push({
                    data: {
                        [EVENT_TYPE_OBJECT_PRESENT]: currentState
                    }
                });
            }
        }

        const networkWindow = getEventsWindowByExtremes(
            networkEvents,
            this.extremes,
            networkEventTimeGetter
        );

        const proximityWindow = getEventsWindowByExtremes(
            stateEvents,
            this.extremes,
            proximityEventTimeGetter
        );

        const tooMuchData = isTooMuchData(proximityWindow.eventsInside);
        const shouldAppear = !tooMuchData && this.tooMuchData;
        this.tooMuchData = tooMuchData;
        this.$scope.$root.$applyAsync();
        if (this.tooMuchData) {
            return;
        }

        const networkEventsTimestamps = DataConverter.getTimestampsFromNetworkStatusEvents(networkEvents)
        const plotBands = DataConverter.createOfflineBands(
            networkEventsTimestamps,
            /**
             * Here we check if events window is close to the beginning of the loaded range.
             * We simulate the offline band that started some time before.
             */
            networkWindow.closestBefore
                ? networkEventTimeGetter(networkWindow.closestBefore)
                : this.extremes[0] - 2 * HEARTBEAT_MAX_GAP,
            /**
             * Here we check if events window is close to the end of the loaded range,
             * which is basically the current moment of time.
             */
            networkWindow.closestAfter
                ? networkEventTimeGetter(networkWindow.closestAfter)
                : +new Date()
        );

        const chartData = DataConverter.syncOfflineProximity(
            convertToChartData(proximityWindow, this.extremes),
            plotBands
        );

        this.dataSeries.setData(chartData);
        this.xAxis.setExtremes(...this.extremes);

        /**
         * Highcharts sometimes fails to redraw the chart correctly
         * when plot bands are added and removed in the loops like here.
         * That's why the setTimeout is used to stack things for later execution.
         * @todo: improve this
         */
        this.xAxis.plotLinesAndBands.forEach(({ id }) => {
            setTimeout(() => this.xAxis.removePlotBand(id), 0);
        });

        plotBands.forEach(plotBand => {
            setTimeout(() => this.xAxis.addPlotBand(plotBand), 0);
        });

        if (shouldAppear) {
            setTimeout(() => reflowCharts(), 0);
        }
    }

    $onChanges(changes) {
        if (changes.events) {
            this.changesQueue.push(this.redrawData);
        }
        if (changes.extremes) {
            this.changesQueue.push(this.redrawData);
        }
        if (changes.loading && this.chartRef) {
            if(this.loading) {
                this.chartRef.showLoading(MESSAGE_LOADING);
            } else {
                this.chartRef.hideLoading();
            }
        }
    }

    $onInit() {
        this.chartRef = null;
        this.chartConfig = _merge(
            {},
            ConfigPresets.Base,
            ConfigPresets.Proximity,
            {
                chart: {
                    height: 226,
                    marginBottom: 40,
                    events: {
                        selection: this.handleChartSelection
                    }
                },
                xAxis: {
                    visible: false
                },
                scrollbar: {
                    enabled: false
                },
                series: [
                    {
                        data: []
                    }
                ]
            }
        );
        this.tooMuchData = false;
    }
}
