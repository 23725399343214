import moment from 'moment';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import { getHistoryChartType, getHistoryEventType } from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { AbstractChartController } from '../chart-abstract-base';

/* @ngInject */
export default class ChartMotionAggregatedController extends AbstractChartController {
    constructor(SensorEventsLoader, ToastService, $scope,EventEmitter) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);

        super.$onInit();
    }

    $onChanges(changes) {
        // If we have new chart extremes, update the x-axis
        if (changes.extremes && 
            changes.extremes.currentValue && 
            changes.extremes.currentValue !== changes.extremes.previousValue) 
        {
            this.xAxis.setExtremes(...changes.extremes.currentValue);
        }

        super.$onChanges(changes)
    }

    // Overrides AbstractChartController
    getEventTypes() {
        return [this.eventType];
    }

    // Overrides AbstractChartController
    dataToLoad(days) { // eslint-disable-line no-unused-vars
        // Always showing aggregated data
        return "aggregated";
    }

    // Overrides AbstractChartController
    getAggregationFields() {
        return [
            {"fieldName": "objectPresent.state", "type": "COUNT"},
            {"fieldName": "networkStatus.signalStrength", "type": "COUNT"}, // For offline detection
        ];
    }
    
    // Overrides AbstractChartController
    getConfigPreset() {
        return _merge(
            ConfigPresets.ProximityAggregated,
            {
                chart: {
                    events: {
                        selection: this.handleChartSelection
                    }
                }
            }
        );
    }

    handleChartSelection({ xAxis }) {
        if (!xAxis || !xAxis.length) {
            return;
        }
        const { min, max } = xAxis[0];
        this.onChartSelection(
            this.EventEmitter({
                extremes: [min, max]
            })
        );
    }

    // Overrides AbstractChartController
    convertAggregated(aggregatedData) {
        if (aggregatedData.results.length === 0) {
            return {
                data: [],
                plotBands: [],
            }
        }
        this.chartData = aggregatedData.results[0].values.map(aggWindow => {
            return [
                moment(aggWindow.timeWindow).valueOf(),
                aggWindow.values["objectPresent.state_COUNT"],
            ]
        });

        this.plotBands = DataConverter.createAggregatedOfflineBands(
            aggregatedData.results[0].values,
            "networkStatus.signalStrength_COUNT",
        );
        
        return {
            data: this.chartData,
            plotBands: this.plotBands,
        }
    }

    // Overrides AbstractChartController
    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.proximitySeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax,
            {
                dataGrouping: {
                    enabled: true,
                    units: [
                        ['day', [1]],
                        ['week', [1]],
                        ['month', [1]]]
                }
            }
        )
    }

    // Overrides AbstractChartController
    convertEvents(events) {
        const { state } = DataConverter.splitByStateAndNetworkStatus(events);
        this.sanitizeStateEvents(state);
        this.plotBands = [];
        this.chartData = state.map(({ data }) => [
            +new Date(data.objectPresent.updateTime),
            1
        ]);
        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    sanitizeStateEvents(stateEvents) {
        if (!stateEvents.length) {
            const state = _get(
                this.thing,
                'reported.objectPresent.state',
                null
            );
            if (state) {
                const updateTime = moment(
                    _get(this.thing, 'reported.objectPresent.updateTime')
                );
                stateEvents.push({
                    data: {
                        motion: {
                            state,
                            updateTime:
                                updateTime < this.chartMin
                                    ? this.chartMin
                                    : updateTime
                        }
                    }
                });
            }
        }
    }

    syncOfflinePlotBand() {}

    // Overrides AbstractChartController
    onStateEventReceived(eventData) { // eslint-disable-line no-unused-vars
        // TODO
    }
}
