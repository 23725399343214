import { parseQuery } from 'services/QueryParser';
import { DEFAULT_TOKEN } from 'services/PaginationHelper';
import { getProductDescription } from 'services/SensorHelper';

/**
 * @ngInject
 */
export default class AddDeviceDialogController {
    constructor(SensorService, $mdDialog, selectedDevices, deviceTypes, onAddDevice, ToastService) {
        this.SensorService = SensorService;
        this.$mdDialog = $mdDialog;
        this.deviceTypes = deviceTypes;
        this.selectedDevices = selectedDevices
        this.onAddDevice = onAddDevice;
        this.ToastService = ToastService;
    }

    productDescription(device) { // eslint-disable-line class-methods-use-this
        return getProductDescription(device)
    }

    $onInit() {
        this.progress = null;
        this.orderBy = 'labels.name';
        this.query = '';
        this.previousQuery = ''; // Allows us to detect when the user has changed the query
        this.currentPageSize = 100;
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;
        this.devices = [];
        this.loadList();
        this.onReorderCallback = this.onReorderCallback.bind(this);
    }

    loadList() {
        // Reset orderBy when the user has changed the text in the search box.
        // This will let the backend decide the order based on a match score.
        if (this.previousQuery !== this.query) {
            if (this.query.length > 0) {
                // Let the backend decide when the user has entered a search query
                this.orderBy = '';
            } else if (this.orderBy === '') {
                // Default to labels.name if the user has removed the search query
                this.orderBy = 'labels.name';
            }
        }
        this.previousQuery = this.query;

        this.progress = this.SensorService.sensors({
            ...parseQuery(this.query),
            deviceTypes: this.deviceTypes,
            orderBy: this.orderBy,
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        }).then(({ data, nextPageToken}) => {
            this.devices = data;
            this.nextPageToken = nextPageToken;
        }).catch((serverResponse) => {
            this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
                serverResponse
            });
        });
    }

    search({ query }) {
        this.query = query;
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }

    clearSearchResult() {
        this.query = '';
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }

    onReorderCallback(order) {
        this.orderBy = order;
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }

    setPageSize({ pageSize }) {
        this.currentPageToken = DEFAULT_TOKEN;
        this.currentPageSize = pageSize;
        this.loadList();
    }

    setPageToken({ pageToken }) {
        this.currentPageToken = pageToken;
        this.loadList();
    }

    close() {
        this.$mdDialog.hide();
    }
}
