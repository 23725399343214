import moment from 'moment';
import {
    getHistoryChartType,
    getHistoryEventType
} from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { AbstractChartController } from '../chart-abstract-base';

/* @ngInject */
export default class ChartCO2Controller extends AbstractChartController {
    constructor(
        SensorEventsLoader,
        ToastService,
        $scope,
        EventEmitter,
    ) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    getEventTypes() {
        return [this.eventType, 'humidity', 'pressure'];
    }

    // Overrides AbstractChartController
    getAggregationFields() {
        return [
            {"fieldName": "co2.ppm",                   "type": "AVERAGE"},
            {"fieldName": "co2.ppm",                   "type": "MIN"},
            {"fieldName": "co2.ppm",                   "type": "MAX"},
            {"fieldName": "humidity.temperature",      "type": "AVERAGE"},
            {"fieldName": "humidity.temperature",      "type": "MIN"},
            {"fieldName": "humidity.temperature",      "type": "MAX"},
            {"fieldName": "humidity.relativeHumidity", "type": "AVERAGE"},
            {"fieldName": "humidity.relativeHumidity", "type": "MIN"},
            {"fieldName": "humidity.relativeHumidity", "type": "MAX"},
            {"fieldName": "pressure.pascal",           "type": "AVERAGE"},
            {"fieldName": "pressure.pascal",           "type": "MIN"},
            {"fieldName": "pressure.pascal",           "type": "MAX"},
            // For offline detection
            {"fieldName": "co2.ppm",                   "type": "COUNT"},
        ];
    }

    getConfigPreset() {
        return ConfigPresets.CO2;
    }

    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.CO2Series(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax
        );
    }

    convertEvents(events) {

        const timestamps = [];
        events.forEach((event) => {
            if (event.eventType === 'co2') {
                timestamps.push(moment(event.data.co2?.updateTime).valueOf());
            }
        });

        this.plotBands = DataConverter.createOfflineBands(
            timestamps,
            this.chartMin,
            this.chartMax
        );
        this.chartData = DataConverter.co2(events);
        
        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    convertToHectoPascal(pascal) {
        return pascal !== null ? pascal / 100 : null; 
    }

    convertAggregated(aggregatedData) {
        this.chartData = aggregatedData.results[0].values.map(aggWindow => {
            return [
                moment(aggWindow.timeWindow).valueOf(),                                         // Index 0
                aggWindow.values["co2.ppm_AVERAGE"],                                            // Index 1
                DataConverter.celsiusToUserPreference(aggWindow.values["humidity.temperature_AVERAGE"]), // Index 2
                aggWindow.values["humidity.relativeHumidity_AVERAGE"],                          // Index 3
                this.convertToHectoPascal(aggWindow.values["pressure.pascal_AVERAGE"]),         // Index 4

                aggWindow.values["co2.ppm_MIN"],                                                // Index 5
                DataConverter.celsiusToUserPreference(aggWindow.values["humidity.temperature_MIN"]),     // Index 6
                aggWindow.values["humidity.relativeHumidity_MIN"],                              // Index 7
                this.convertToHectoPascal(aggWindow.values["pressure.pascal_MIN"]),             // Index 8

                aggWindow.values["co2.ppm_MAX"],                                                // Index 9
                DataConverter.celsiusToUserPreference(aggWindow.values["humidity.temperature_MAX"]),     // Index 10
                aggWindow.values["humidity.relativeHumidity_MAX"],                              // Index 11
                this.convertToHectoPascal(aggWindow.values["pressure.pascal_MAX"])              // Index 12
            ]
        })

        this.plotBands = DataConverter.createAggregatedOfflineBands(
            aggregatedData.results[0].values,
            "co2.ppm_COUNT",
        );
        
        return {
            data: this.chartData,
            plotBands: this.plotBands,
        }
    }

    syncAdditionalDataSeries() {
        // The underlying AbstractChart has two series: dataSeries, spacerSeries
        // The CO2 chart adds three additional series for temperature, relativeHumidity & pressure (each with min/max)
        // These need to be synced on xAxis changes

        // The index are based on the order from 'convertAggregated' above
        this.chart.series[2].setData(this.chartData.map(item => [item[0], item[5], item[9]]))   // CO2 Min & Max

        this.chart.series[3].setData(this.chartData.map(item => [item[0], item[2]]))           // Temperature    
        this.chart.series[4].setData(this.chartData.map(item => [item[0], item[6], item[10]])) // Temperature Min & Max

        this.chart.series[5].setData(this.chartData.map(item => [item[0], item[3]]))           // Relative humidity
        this.chart.series[6].setData(this.chartData.map(item => [item[0], item[7], item[11]])) // Relative humidity Min & Max

        this.chart.series[7].setData(this.chartData.map(item => [item[0], item[4]]))           // Pressure
        this.chart.series[8].setData(this.chartData.map(item => [item[0], item[8], item[12]])) // Pressure Min & Max
    }

    onStateEventReceived(eventData) {// eslint-disable-line no-unused-vars
        // TODO
    }

    adjustMinMaxValues(value) {
        if (value < this.spacerMin) {
            this.spacerMin = value;
        } else if (value > this.spacerMax) {
            this.spacerMax = value;
        }
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);
        super.$onInit();
    }
}
