import controller from './list.controller';

const template = require('./list.html');

export default {
    bindings: {
        project: '<'
    },
    template,
    controller
};
