import controller from './dashboard-chart.controller';

const template = require('./dashboard-chart.html');

export default {
    bindings: {
        duration: '<',
        card: '<',
        showLoading: '<',
        zoomStartTime: '<',
        zoomEndTime: '<'
    },
    template,
    controller
};
