import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _round from 'lodash/round';
import { celsiusToFahrenheit, fahrenheitToCelsius, getTemperatureUnitSuffix } from 'services/utils';
import { MEASUREMENT_SYSTEM_CHANGED_EVENT } from 'services/StudioEvents';

/* @ngInject */
export default class EmulatorHumidityController {
    constructor(
        EventEmitter,
        $scope,
        $rootScope,
        $state,
        $timeout,
        UserPreferencesManager
    ) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.UserPreferencesManager = UserPreferencesManager;
    }

    $onInit() {
        this.determineValues()

        this.$rootScope.$on(MEASUREMENT_SYSTEM_CHANGED_EVENT, () => {
            this.determineValues()
        })
    }

    determineValues() {
        this.event = {
            humidity: {
                temperature: this.convertedTemperature(_get(this.device, 'reported.humidity.temperature', 0)),
                relativeHumidity: _get(this.device, 'reported.humidity.relativeHumidity', 0)
            }
        }
        
        this.min = this.convertedTemperature(-40)
        this.max = this.convertedTemperature(85)
        this.unitSuffix = getTemperatureUnitSuffix()
    }

    convertedTemperature(temperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? _round(celsiusToFahrenheit(temperature), 2)
            : temperature;
    }

    ensureCelsius(temperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? _round(fahrenheitToCelsius(temperature), 2)
            : temperature;
    }

    sendHumidityEvent() {
        // Send value as Celsius, keep local state if Fahrenheit
        
        const sendEvent = _cloneDeep(this.event)
        sendEvent.humidity.temperature = this.ensureCelsius(sendEvent.humidity.temperature)
        
        this.onPublishEvent(
            this.EventEmitter({
                event: sendEvent
            })
        )
    }
}