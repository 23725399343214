import moment from 'moment';
import { getTemperatureUnitSuffix, celsiusToFahrenheit, isMobileLayout, getHoursMinutesFormat } from 'services/utils';
import { getStateValue, getHistoryEventType } from 'services/SensorHelper';
import { DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED, DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN, DASHBOARD_APPEARANCE_MOTION_HISTOGRAM, DASHBOARD_APPEARANCE_MOTION_HEATMAP, DASHBOARD_APPEARANCE_CO2_HEATMAP } from 'constants/device';
import { States } from '../../../../app.router';

/* @ngInject */
export default class SingleDeviceController {
    constructor(EventEmitter, $scope, $state, UserPreferencesManager, IAMService, ProjectManager) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$state = $state;
        this.UserPreferencesManager = UserPreferencesManager;
        this.IAMService = IAMService;
        this.ProjectManager = ProjectManager;
    }

    $onInit() {
        this.hoverText = "";
        this.editingCardName = "";
        this.secondaryLatestValue = "";
        this.shouldShowOptions = false;
        this.doneLoading = false;
        this.isMobile = isMobileLayout();
        this.DASHBOARD_APPEARANCE_CO2_HEATMAP = DASHBOARD_APPEARANCE_CO2_HEATMAP // Needed in html
        this.DASHBOARD_APPEARANCE_MOTION_HISTOGRAM = DASHBOARD_APPEARANCE_MOTION_HISTOGRAM // Needed in html

        this.$scope.$on('dashboardTooltipHover',  (event, data) => {
            const cardType = this.card.content.devices[0].type;
            const showYearInTooltip = moment(data.target.x).year() !== moment().year()
            let momentFormat = `${getHoursMinutesFormat()} ddd, MMM D${showYearInTooltip ? ', YYYY' : ''}`
            // Special formatting for histograms
            if (this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HISTOGRAM || this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
                momentFormat = `ddd, MMM D${showYearInTooltip ? ', YYYY' : ''}`
                // Change format if hours are being shown instead of days
                if (data.target.series.currentDataGrouping.count === 1) {
                    momentFormat = `ddd HH:00-HH:59, MMM D${showYearInTooltip ? ', YYYY' : ''}`
                }
            }
            const timeStamp = moment(data.target.x).format(momentFormat);
            if (cardType === 'temperature') {
                this.hoverText = `${data.target.y.toFixed(2)}${getTemperatureUnitSuffix()} ${timeStamp}`;
            } else if (cardType === 'proximity' || cardType === 'contact' || cardType === 'waterDetector' || cardType === 'motion' || cardType === 'deskOccupancy') {
                if (this.card.appearance === DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN) {
                    this.hoverText = `${data.target.y} open events ${timeStamp}`;
                } else if (this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HISTOGRAM) {
                    const hours = Math.floor(data.target.y)
                    const minutes = Math.round((data.target.y - hours) * 60)
                    if (hours === 0) {
                        this.hoverText = `${minutes} min of motion on ${timeStamp}`;
                    } else {
                        this.hoverText = `${hours} hour${hours === 1 ? '':'s'}, ${minutes} min of motion on ${timeStamp}`
                    }
                } else if (this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP) {
                    // Uses a tooltip to show hover data
                } else if (this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
                    const hours = Math.floor(data.target.y)
                    const minutes = Math.round((data.target.y - hours) * 60)
                    if (hours === 0) {
                        this.hoverText = `${minutes} min occupied on ${timeStamp}`;
                    } else {
                        this.hoverText = `${hours} hour${hours === 1 ? '':'s'}, ${minutes} min occupied on ${timeStamp}`
                    }
                } else {
                    const options = data.target.options
                    const now = new Date().valueOf()
                    if (options.x2 > now) { // There is a few minutes padding into to future on graphs, remove this before calculating duration
                        options.x2 = now
                    }
                    const lastedFor = options.lastedFor || options.x2 - options.x;
                    const duration = moment.duration(lastedFor).humanize()
                    const state = data.target.series.yAxis.categories[data.target.y]
                    this.hoverText = `${state} ${timeStamp} for ${duration}` 
                }
            } else if (cardType === 'touch') {
                this.hoverText = `${data.target.y} touches ${timeStamp}`;
            } else if (cardType === 'touchCounter') {
                this.hoverText = `${data.target.y} touches ${timeStamp}`; 
            } else if (cardType === 'proximityCounter') {
                this.hoverText = `${data.target.y} state changes ${timeStamp}`;
            } else if (cardType === 'co2') {
                if (this.card.appearance === '') { // Time series
                    this.hoverText = `${data.target.y} PPM ${timeStamp}`
                }
            }
            this.$scope.$applyAsync();
        });
        
        this.$scope.$on('dashboardTooltipHoverEnd', () => {
            this.hoverText = "";
            this.$scope.$applyAsync();
        });

        this.$scope.$on('dashboardSaveCardEdit', (event, card) => {
            if (this.card === card) {
                this.card.displayName = this.editingCardName;
            }
        });

        // Missing device label placeholders
        this.movedToProjectName = 'Loading...';
        this.movedToOrganizationName = '';

        // Detect changes to missingDevices and find the project for the missing device
        this.$scope.$watch('$ctrl.card.missingDevices', (missingDevices) => {
            if (missingDevices) {
                const projectId = missingDevices[0]?.name?.split('/')[1];
                if (projectId) {
                    this.IAMService.getProject(projectId).then(project => {
                        this.movedToProjectName = project.displayName;
                        const currentProject = this.ProjectManager.currentProject;
                        if (currentProject.organization !== project.organization) {
                            this.movedToOrganizationName = project.organizationDisplayName;
                        }
                        this.movedToProject = project;
                    })
                }
            }
        });
    }

    goToSensorPage(projectId, sensorId) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId,
            sensorId
        });
    }

    get device() {
        if (!this.card.content || this.card.content.devices.length === 0) { return undefined; }
        return this.card.content.devices[0];
    }

    get cardName() {
        if (this.card.displayName) { return this.card.displayName; }
        if (this.card.content)     { return this.deviceName; }
        return '';
    }

    get deviceName() {
        if (this.device) {
            return this.device.labels.name || this.device.name.split('/').slice(-1)[0];
        }
        return ''
    }

    get subheadingText() {
        if (!this.card.doneLoading) {
            return 'Loading...'
        }
        
        if (this.hoverText) {
            return this.hoverText;
        }

        if (this.card.missingDevices) {
            return 'Not able to display card';
        }

        if (this.device?.offline) {
            return `${this.device.typeName} (Offline)`
        }

        if (!this.hoverText && this.device) {
            return this.device.typeName;
        }

        return '';
    }

    get showChangeAppearanceDialog() {
        if ((this.device?.type === 'proximity' || this.device?.type === 'motion' || this.device?.type === 'deskOccupancy') && this.card.appearance === '') {
            if (moment(this.zoomEndTime).diff(moment(this.zoomStartTime), 'months', true) > 4) {
                this.card.doneLoading = true
                return true
            }
        }
        return false
    }

    get latestValue() {
        if (this.device.type === 'proximity' && this.card.appearance === DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN) {    
            // Show number of open events within the current zoom range
            const events = this.card.content.events[this.device.name]
            if (events !== undefined && events.length > 0) {                
                let openEvents = 0

                events.forEach(item => {
                    if (item.data && item.data.objectPresent) {
                        if (item.data.objectPresent.openCount) {
                            openEvents += item.data.objectPresent.openCount;
                        } else if (item.data.objectPresent.state === "NOT_PRESENT") {
                            openEvents += 1;
                        }
                    }
                })

                return `${openEvents} open event${openEvents > 1 ? 's' : ''}`
            }
            return 'No events'
            
        }

        if (this.device.type === 'co2') {
            const stateValue = getStateValue(this.device);

            const values = stateValue.split('|')
            this.secondaryLatestValue = `${values[1]} ${values[2]}` // Humidity & temperature
            return values[0] // CO2
        }

        if (this.device.type === 'proximityCounter' || this.device.type === 'touchCounter') {
            const events = this.card.content.events[this.device.name]

            // Get the state changes or touches within the dashboard time frame
            // Get the diff between last and first event
            const eventType = getHistoryEventType(this.device)
            if (events?.length > 1) {
                const totalStateChanges = events[events.length - 1].data[eventType].total - events[0].data[eventType].total
                return `${totalStateChanges} ${this.device.type === 'proximityCounter' ? 'state changes' : 'touches'}`
            }
            return "Too few events"
        }

        return getStateValue(this.device);
    }

    get updateTime() {
        const reportedValue = this.device.reported[getHistoryEventType(this.device)];
        if (reportedValue) {
            return moment(reportedValue.updateTime).fromNow();
        }

        return 'No events';
    }

    get temperatureStats() {

        // Check if device has stats
        const stats = this.card.content.stats[this.device.name]["temperature.value"];
        if(stats !== undefined) {
            return {
                min: this.convertedTemperature(stats.MIN).toFixed(1),
                max: this.convertedTemperature(stats.MAX).toFixed(1),
                avg: this.convertedTemperature(stats.AVERAGE).toFixed(1)
            };
        }

        return {
            min: '-',
            max: '-',
            avg: '-'
        };
    }

    get humidityStats() {

        // Check if device has stats
        const temperatureStats = this.card.content.stats[this.device.name]["humidity.temperature"];
        const humidityStats = this.card.content.stats[this.device.name]["humidity.relativeHumidity"];
        if(temperatureStats !== undefined) {
            return {
                minH: `${humidityStats.MIN.toFixed(1)}%`,
                minT: `${this.convertedTemperature(temperatureStats.MIN).toFixed(1)}${getTemperatureUnitSuffix()}`,
                maxH: `${humidityStats.MAX.toFixed(1)}%`,
                maxT: `${this.convertedTemperature(temperatureStats.MAX).toFixed(1)}${getTemperatureUnitSuffix()}`,
                avgH: `${humidityStats.AVERAGE.toFixed(1)}%`,
                avgT: `${this.convertedTemperature(temperatureStats.AVERAGE).toFixed(1)}${getTemperatureUnitSuffix()}`
            };
        }

        return {
            min: '-',
            max: '-',
            avg: '-'
        };
    }

    convertedTemperature(rawTemperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? celsiusToFahrenheit(rawTemperature)
            : rawTemperature;
    }

    showOptions() {
        this.shouldShowOptions = true;
    }

    
    viewSensorDetails() {
        const sensorId = this.card.content.devices[0].id;
        this.$state.go(States.SENSOR_DETAILS, {
            sensorId
        });
    }

    toggleProximityToHistogram() {
        this.shouldShowOptions = false
        if (this.card.appearance === DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN) {
            this.card.appearance = ''
        } else {
            this.card.appearance = DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN
        }
        this.onUpdateAppearance(this.EventEmitter({
            card: this.card
        }));
    }


    toggleDeskOccupancyToHistogram() {
        this.shouldShowOptions = false
        if (this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
            this.card.appearance = ''
        } else {
            this.card.appearance = DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED
        }
        this.onUpdateAppearance(this.EventEmitter({
            card: this.card
        }))
    }

    setMotionAppearance(appearance) {
        this.shouldShowOptions = false
        this.card.appearance = appearance
        this.onUpdateAppearance(this.EventEmitter({
            card: this.card
        }));
    }

    setHistogramAppearance() {
        this.shouldShowOptions = false
        if (this.device.type === 'motion') {
            this.card.appearance = DASHBOARD_APPEARANCE_MOTION_HISTOGRAM
        } else if (this.device.type === 'deskOccupancy') {
            this.card.appearance = DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED
        } else if (this.device.type === 'proximity') {
            this.card.appearance = DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN
        }
        this.onUpdateAppearance(this.EventEmitter({
            card: this.card
        }));
    }

    toggleCO2ToHeatmap() {
        this.shouldShowOptions = false
        if (this.card.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP) {
            this.card.appearance = '' // Defaults to time series 
        } else {
            this.card.appearance = DASHBOARD_APPEARANCE_CO2_HEATMAP
        }
        this.onUpdateAppearance(this.EventEmitter({
            card: this.card
        }));
    }

    toggleLegend() {
        this.shouldShowOptions = false;
        this.onToggleLegend(this.EventEmitter({
            card: this.card
        }))
    }
    
    mobileHideOptions() {
        // Workaround to detect touch outside div on mobile
        if(isMobileLayout()) {
            setTimeout(() => {
                this.shouldShowOptions = false;
                this.$scope.$applyAsync();
            }, 200);
        }
    }

    editCard() {
        this.editingCardName = this.card.displayName; // ng-model for name input field 
        this.shouldShowOptions = false;
        setTimeout(() => {
            this.onEditCard(this.EventEmitter({
                card: this.card
            }));
            this.$scope.$applyAsync();
        }, 300);
    }

    remove() {
        this.onRemove(this.EventEmitter({
            card: this.card
        }));
    }
}
