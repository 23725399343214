import _cloneDeep from 'lodash/cloneDeep';

/* @ngInject */
export default class ProjectFilterController {
    constructor($scope, filters, onClose) {
        this.$scope = $scope
        this.filters = filters
        this.onClose = onClose;
    }

    $onInit() {
        this.setupFilters()
    }

    close() {
        this.onClose()
    }

    updateCount() {
        let count = 0
        Object.keys(this.filters).forEach(key => {
            if (this.filters[key].active) {
                count += 1
            }
        });
        this.onUpdateFilterCount(count)
    }

    update() {
        this.updateCount()
        // Check if any filter optionValues are null, if so, don't update
        // This is caused by the <select> element being reset when the element is removed from the DOM
        if (this.filters.filter(filter => filter.optionValue === null).length === 0) {
            this.onUpdateFilters(_cloneDeep(this.filters))
        }
    }

    clearAllOptions(filter) {
        filter.options.forEach(option => {
            option.active = false;
        });
        this.update();
    }

    setupFilters() {
        
        if (this.filters.length === 0) {
            this.filters.push(
                {
                    displayName: "Alert Type",
                    active: false,
                    options: [
                        {
                            displayName: "Sensor Offline",
                            value: "SENSOR_OFFLINE",
                            active: true
                        },
                        {
                            displayName: "Cloud Connector Offline",
                            value: "CLOUD_CONNECTOR_OFFLINE",
                            active: true
                        },
                        {
                            displayName: "Temperature",
                            value: "temperature",
                            active: true
                        },
                        {
                            displayName: "Humidity",
                            value: "relativeHumidity",
                            active: true
                        },
                        {
                            displayName: "CO2",
                            value: "co2",
                            active: true
                        },
                        {
                            displayName: "Door & Window",
                            value: "contact",
                            active: true
                        },
                        {
                            displayName: "Proximity",
                            value: "objectPresent",
                            active: true
                        },
                        {
                            displayName: "Water Detected",
                            value: "waterPresent",
                            active: true
                        },
                        {
                            displayName: "Touch",
                            value: "touch",
                            active: true
                        }
                    ],
                },
                {
                    displayName: "Alert Status",
                    active: false,
                    options: [
                        {
                            displayName: "Open",
                            value: "TRIGGERED",
                            active: true
                        },
                        {
                            displayName: "Acknowledged",
                            value: "ACKNOWLEDGED",
                            active: true
                        },
                        {
                            displayName: "Monitoring",
                            value: "MONITORING",
                            active: true
                        },
                        {
                            displayName: "Resolved",
                            value: "RESOLVED",
                            active: true
                        },
                        {
                            displayName: "Archived",
                            value: "ARCHIVED",
                            active: true
                        }
                    ],
                },
                {
                    displayName: "Duration",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: 'Less than 30 minutes',
                            value: 'LESS_THAN_30_MINUTES',
                            maxDuration: '1800s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'Less than 1 hour',
                            value: 'LESS_THAN_1_HOUR',
                            maxDuration: '3600s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'Less than 6 hours',
                            value: 'LESS_THAN_6_HOURS',
                            maxDuration: '21600s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'Less than 12 hours',
                            value: 'LESS_THAN_12_HOURS',
                            maxDuration: '43200s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'Less than 24 hours',
                            value: 'LESS_THAN_24_HOURS',
                            maxDuration: '86400s',
                            minDuration: '0s'
                        },
                        {
                            displayValue: 'More than 24 hours',
                            value: 'MORE_THAN_24_HOURS',
                            maxDuration: null,
                            minDuration: '86400s'
                        },
                        {
                            displayValue: 'More than 48 hours',
                            value: 'MORE_THAN_48_HOURS',
                            maxDuration: null,
                            minDuration: '172800s'
                        },
                        {
                            displayValue: 'More than 72 hours',
                            value: 'MORE_THAN_72_HOURS',
                            maxDuration: null,
                            minDuration: '259200s'
                        },
                        {
                            displayValue: 'More than 1 week',
                            value: 'MORE_THAN_1_WEEK',
                            maxDuration: null,
                            minDuration: '604800s'
                        },
                        {
                            displayValue: 'More than 1 month',
                            value: 'MORE_THAN_1_MONTH',
                            maxDuration: null,
                            minDuration: '2592000s'
                        }

                    ],
                    optionValue: "MORE_THAN_24_HOURS"
                },
                {
                    displayName: "Acknowledged",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "Has been acknowledged",
                            value: "HAS_ACKNOWLEDGEMENT"
                        },
                        {
                            displayValue: "Has not been acknowledged",
                            value: "NO_ACKNOWLEDGEMENT"
                        }
                    ],
                    optionValue: "HAS_ACKNOWLEDGEMENT"
                },
                {
                    displayName: "Corrective Action",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "Has corrective action",
                            value: "HAS_CORRECTIVE_ACTION"
                        },
                        {
                            displayValue: "No corrective action",
                            value: "NO_CORRECTIVE_ACTION"
                        }
                    ],
                    optionValue: "HAS_CORRECTIVE_ACTION",
                },
                {
                    displayName: "Device Labels",
                    active: false,
                    dropdown: false,
                    options: [],
                    optionValue: "",
                    labels: [
                        ["", ""]
                    ]
                }
            )
        }
    }

    addFilterLabel(filter) {
        filter.labels.push(["", ""])
    }

    removeFilterLabel(filter, index) {
        filter.labels.splice(index, 1)
    }
}
