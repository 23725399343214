import controller from './emulator-details.controller';

const template = require('./emulator-details.html');

export default {
    bindings: {
        device: '<',
        isNew: '<',
        onDelete: '&',
        onCreate: '&',
        onPublishEvent: '&'
    },
    controller,
    template
};
