import controller from './sensor-chart.controller';

const template = require('./sensor-chart.html');

export default {
    bindings: {
        thing: '<',
        eventsObservable: '<'
    },
    template,
    controller
};
