
/* @ngInject */
export default class ApplicationHelpController {
    constructor(onShowFeedbackForm, ) {
        this.onShowFeedbackForm = onShowFeedbackForm;
    }

    showFeedbackForm() {
        this.onShowFeedbackForm();
    }
}
