import _get from 'lodash/get';
/* @ngInject */
export default class EmulatorCellularStatusController {
    constructor(
        EventEmitter,
    ) {
        this.EventEmitter = EventEmitter;
    }


    $onInit() {
        this.event = {
            cellularStatus: {
                signalStrength: _get(this.device, "reported.cellularStatus.signalStrength", 100),
            }
        }
    }

    sendEvent() {
        this.onPublishEvent(
            this.EventEmitter({
                event: this.event
            })
        );
    }
}