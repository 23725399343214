import controller from './thing-name.controller';

const template = require('./thing-name.html');

export default {
    bindings: {
        name: '<',
        onUpdate: '&',
        labelPrefix: '@'
    },
    template,
    controller
};
