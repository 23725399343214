import controller from './autocomplete.controller';

const template = require('./autocomplete.html');

export default {
    bindings: {
        isFocused: '<',
        onSearch: '&',
        onChange: '&',
        onSelected: '&',
        notFoundText: '@',
        textKey: '@',
        placeholder: '@'
    },
    controller,
    template
};
