import { MEASUREMENT_SYSTEM_METRIC, MEASUREMENT_SYSTEM_IMPERIAL } from 'services/UserPreferencesManager';

/* @ngInject */
export default class ProfileController {
    constructor(AuthService, UserPreferencesManager, $rootScope) {
        this.AuthService = AuthService;
        this.UserPreferencesManager = UserPreferencesManager;
        this.$rootScope = $rootScope;
    }

    $onInit() {
        const authData = this.AuthService.getAuthData();

        if (authData) {
            this.userProfile = authData.profile;
        }

        // Use the locally cached measurement system
        this.measurementSystem = this.UserPreferencesManager.getMeasurementSystem();
        this.metricValue = MEASUREMENT_SYSTEM_METRIC;
        this.imperialValue = MEASUREMENT_SYSTEM_IMPERIAL

        // Refresh the local cache, and update if it changed
        this.UserPreferencesManager.refresh()
            .then(() => {
                this.measurementSystem = this.UserPreferencesManager.getMeasurementSystem();
            })
    }

    onMeasurementSystemChange() {
        this.UserPreferencesManager.setMeasurementSystem(this.measurementSystem)
    }

    signOut() {
        this.AuthService.signOut();
    }
}


