/* @ngInject */
export default class Loader {
    constructor($q) {
        this.$q = $q;
        this.showLoader = false;

        this.hide = this.hide.bind(this);
    }

    show() {
        this.showLoader = true;
    }

    hide() {
        this.showLoader = false;
    }

    toggle() {
        this.showLoader = !this.showLoader;
    }

    isShowLoader() {
        return this.showLoader;
    }

    set promise(promise) {
        this.show();
        this.$q.when(promise).finally(this.hide);
    }

    set promises(promises) {
        this.show()
        this.$q.all(promises).then(this.hide)
    }
}
