import Config from './projects.router';
import options from './projects.component';

const Component = {
    selector: 'dtProjectsPage',
    options
};

export {
    Config,
    Component
};
