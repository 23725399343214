import controller from './installation-alert-box.controller';

const template = require('./installation-alert-box.html');

export default {
    selector: 'dtInstallationAlertBox',
    options: {
        bindings: {
            alert: '<',
            mutedAlertType: '<',
            onMuteChanged: '&'
        },
        template,
        controller
    }
};
