// TILT-SUPPORT
export default {
    chart: {
        type: 'line',
        marginRight: 58
    },
    yAxis: [
        {
            allowDecimals: true,
            opposite: true,
            min: -2,
            max: 2,
            softMin: -2,
            softMax: 2.0,
            startOnTick: true,
            endOnTick: true,
            tickInterval: 1,
            tickAmount: 5,
            // minTickInterval: 1,
            // tickPixelInterval: 32,
            // minPadding: 0,
            labels: {
                format: '{value} G'
            },
        },
        {
            visible: false,
            // max: 2
        },
        {
            visible: false,
            // max: 2
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
    },
    plotOptions: {
        line: {
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                enabledThreshold: 4
            }
        }
    },
    tooltip: {
        shared: true,
        enabled: true
    },
    legend: {
        enabled: true,
        floating: true,
        verticalAlign: "top",
        itemDistance: 12
    }
};
