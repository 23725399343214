import controller from './autocomplete.controller';

const template = require('./autocomplete.html');

export default {
    bindings: {
        labels: '<',
        editable: '<',
        requireMatch: '<',
        placeholder: '@',
        secondaryPlaceholder: '@',
        onChange: '&',
        onAdd: '&',
        onRemove: '&'
    },
    controller,
    template
};
