import controller from './autocomplete.controller';

const template = require('./autocomplete.html');

export default {
    bindings: {
        entities: '<',
        editable: '<',
        requireMatch: '<',
        onSearch: '&',
        onChange: '&',
        onAdd: '&',
        onRemove: '&',
        textKey: '@',
        placeholder: '@',
        secondaryPlaceholder: '@'

    },
    controller,
    template
};
