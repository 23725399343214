import { States } from '../../app.router';

/* @ngInject */
export default function projectsRouter($stateProvider) {
    $stateProvider
        .state(States.NEW_USER_FLOW, {
            url: '/create-organization',
            views: {
                content: {
                    component: 'dtNewUserFlow'
                }
            },
            resolve: {
                projects: /* @ngInject */ (IAMService, $exceptionHandler) => {
                    return new Promise(resolve => {
                        IAMService.projects().then(({ data }) => {
                            resolve(data)
                        }).catch((e) => {
                            $exceptionHandler(e)
                            resolve(null)
                        })
                    })
                }
            },
            onEnter: /* @ngInject */ (ProjectManager) => { // eslint-disable-line consistent-return
                ProjectManager.setCurrentProject({});    
            }
        })
}
