import moment from 'moment';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import {
    getHistoryChartType,
    getHistoryEventType
} from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { OCCUPANCY_STATES, PLOT_BAND_OFFLINE_PREFIX, PLOT_BAND_INCOMPLETE_DATA_PREFIX } from 'services/charting/data-converter';
import { AbstractChartController } from '../chart-abstract-base';

/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartDeskOccupancyController extends AbstractChartController {
    constructor(SensorEventsLoader, ToastService, $scope,EventEmitter) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);

        super.$onInit();
    }

    $onChanges(changes) {
        // If we have new chart extremes, update the x-axis
        if (changes.extremes && 
            changes.extremes.currentValue && 
            changes.extremes.currentValue !== changes.extremes.previousValue) 
        {
            // Remove all plot bands before setting new extremes
            const allPlotBandsIds = [] 
            this.xAxis.plotLinesAndBands.forEach(band => {
                if (band.id.startsWith(PLOT_BAND_OFFLINE_PREFIX) || band.id.startsWith(PLOT_BAND_INCOMPLETE_DATA_PREFIX)) {
                    allPlotBandsIds.push(band.id)
                }
            })

            // Clear them all before redraw
            allPlotBandsIds.forEach(bandId => { 
                this.xAxis.removePlotBand(bandId)
            })

            this.xAxis.setExtremes(...changes.extremes.currentValue)
        }

        super.$onChanges(changes)
    }

    getEventTypes() {
        return [this.eventType, 'networkStatus'];
    }

    // Overrides AbstractChartController
    dataToLoad(days) {
        if (days > 31) {
            this.tooLongDuration = true;
            return "";
        } 
        
        this.tooLongDuration = false;
        return "events";
    }

    getConfigPreset() {
        return _merge(
            ConfigPresets.DeskOccupancy,
            {
                chart: {
                    events: {
                        selection: this.handleChartSelection
                    }
                }
            }
        );
    }

    handleChartSelection({ xAxis }) {
        if (!xAxis || !xAxis.length) {
            return;
        }
        const { min, max } = xAxis[0];
        this.onChartSelection(
            this.EventEmitter({
                extremes: [min, max]
            })
        );
    }

    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.proximitySeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax,
        );
    }

    setCurrentExtremes(extremes) {
        super.setCurrentExtremes(extremes)
        const remarkHoverInfo = document.getElementById('remarks-warning') 
        remarkHoverInfo.classList.remove('visible')
    }

    convertEvents(events) {
        const {
            state,
            networkStatus
        } = DataConverter.splitByStateAndNetworkStatus(events);
        this.sanitizeStateEvents(state);
        const networkEventsTimestamps = DataConverter.getTimestampsFromNetworkStatusEvents(networkStatus)
        this.plotBands = DataConverter.createOfflineBands(
            networkEventsTimestamps,
            this.chartMin,
            this.chartMax
        );
        let remarksPlotBands = DataConverter.createRemarkBands(state, this.chartMax)
        this.chartData = DataConverter.syncOfflineProximity(
            DataConverter.deskOccupancy(state, this.chartMin, this.chartMax),
            this.plotBands
        );
        remarksPlotBands = DataConverter.syncOfflineRemarks(this.plotBands, remarksPlotBands)
        this.plotBands = [...this.plotBands, ...remarksPlotBands]
        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    sanitizeStateEvents(stateEvents) {
        if (!stateEvents.length) {
            const state = _get(
                this.thing,
                'reported.deskOccupancy.state',
                null
            );
            if (state) {
                const updateTime = moment(
                    _get(this.thing, 'reported.deskOccupancy.updateTime')
                );
                stateEvents.push({
                    data: {
                        deskOccupancy: {
                            state,
                            updateTime:
                                updateTime < this.chartMin
                                    ? this.chartMin
                                    : updateTime
                        }
                    }
                });
            }
        }
    }

    addNetworkEventPoint() {
        const lastRangeIndex = this.chartData.length - 1;

        const lastRange = this.chartData[lastRangeIndex];
        const lastBand = this.plotBands[this.plotBands.length - 1];

        // Check if the remark plotBand should be extended
        if (lastBand?.className?.includes('incomplete-data')) {
            this.updateRemarkPlotBand(lastBand)
        }

        if (lastBand && lastRange && lastBand.to > lastRange.x2) {
            return;
        }

        const range = {
            x: lastRange.x,
            x2: this.chartMax,
            y: lastRange.y
        };

        this.chartData[lastRangeIndex] = range;
        const pointToUpdate = this.dataSeries.points.find(
            point => point.x === range.x
        );
        if (pointToUpdate) {
            pointToUpdate.update(range);
        }

        // Update extremes to now without loading new data
        this.extremesUpdatedThroughTicker = true
        this.xAxis.setExtremes(this.currentExtremes[0], this.chartMax);
        this.extremesUpdatedThroughTicker = false
    }

    updateRemarkPlotBand(lastBand) {
        if (this.thing.reported.deskOccupancy.remarks.includes('INCOMPLETE_DATA')) {
            // The latest event had a remark, extend the plotBand to the current moment
            const from = lastBand.from
            this.xAxis.removePlotBand(lastBand.id);
            this.xAxis.addPlotBand(DataConverter.plotBandForIncompleteData(from, this.chartMax));
        }
    }

    onStateEventReceived(eventData) {
        const timestamp = moment(eventData.updateTime).valueOf();
        const state = OCCUPANCY_STATES[eventData.state];

        const fakeFutureTimestamp = timestamp + 50; // just to make it visible

        const lastRangeIndex = this.chartData.length - 1;
        const lastBandIndex = this.plotBands.length - 1;

        const lastRange = this.chartData[lastRangeIndex];
        const lastBand = this.plotBands[lastBandIndex];

        const wasOffline = lastBand && lastBand.to > lastRange.x2;
        const stateChanged = lastRange.y !== state;

        let range;

        if (wasOffline) {
            range = {
                x: timestamp,
                x2: fakeFutureTimestamp,
                y: state
            };
            this.chartData.push(range);
            this.dataSeries.addPoint(range);
        } else if (!stateChanged) {
            range = {
                x: lastRange.x,
                x2: timestamp,
                y: state
            };
            this.chartData[lastRangeIndex] = range;
            const pointToUpdate = this.dataSeries.points.find(
                point => point.x === range.x
            );
            if (pointToUpdate) {
                pointToUpdate.update(range);
            }
        } else {
            range = {
                x: lastRange.x,
                x2: timestamp,
                y: lastRange.y
            };
            this.chartData[lastRangeIndex] = range;
            const pointToUpdate = this.dataSeries.points.find(
                point => point.x === range.x
            );
            if (pointToUpdate) {
                pointToUpdate.update(range, false);
            }

            range = {
                x: timestamp,
                x2: fakeFutureTimestamp,
                y: state
            };
            this.chartData.push(range);
            this.dataSeries.addPoint(range);
        }
    }
}
