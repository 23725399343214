import Config from './exports.router';
import options from './exports.component';
import ExportComponent from './export';

const Component = {
    selector: 'dtExportsPage',
    options
};

export {
    Config,
    Component,
    ExportComponent
};
