import moment from 'moment';
import _get from 'lodash/get';
import {
    getHistoryChartType,
    getHistoryEventType
} from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { EVENT_TYPE_OBJECT_PRESENT } from 'constants/device';
import { AbstractChartController } from '../chart-abstract-base';

/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartProximityAdvancedController extends AbstractChartController {
    constructor(SensorEventsLoader, ToastService, $scope,EventEmitter) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    getEventTypes() {
        return [this.eventType, 'networkStatus'];
    }

    // Overrides AbstractChartController
    dataToLoad(days) {
        if (days > 31) {
            return "";
        } 

        return "events";
    }

    getConfigPreset() {
        return ConfigPresets.ProximityAdvanced;
    }

    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.proximitySeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax,
            {
                dataGrouping: {
                    type: 'column',
                    forced: true,
                    groupAll: true,
                    units: [
                        ['second', [1, 10, 30]],
                        ['minute', [1, 5, 15, 30]],
                        ['hour', [1, 2, 4]],
                        ['day', [1, 2, 4]]
                    ]
                }
            }
        );
    }

    convertEvents(events) {
        const { state } = DataConverter.splitByStateAndNetworkStatus(events);
        this.sanitizeStateEvents(state);
        this.plotBands = [];
        this.chartData = state.map(({ data }) => [
            +new Date(data[EVENT_TYPE_OBJECT_PRESENT].updateTime),
            1
        ]);
        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    sanitizeStateEvents(stateEvents) {
        if (!stateEvents.length) {
            const state = _get(
                this.thing,
                'reported.objectPresent.state',
                null
            );
            if (state) {
                const updateTime = moment(
                    _get(this.thing, 'reported.objectPresent.updateTime')
                );
                stateEvents.push({
                    data: {
                        objectPresent: {
                            state,
                            updateTime:
                                updateTime < this.chartMin
                                    ? this.chartMin
                                    : updateTime
                        }
                    }
                });
            }
        }
    }

    syncOfflinePlotBand() {}

    onStateEventReceived(eventData) {
        const timestamp = +new Date(eventData.updateTime);
        const point = [timestamp, 1];

        this.dataSeries.addPoint(point);
    }

    handleAuxChartSelection({ extremes }) {
        this.xAxis.setExtremes(...extremes);
        this.trackZoomChange('custom');
        this.onChartSelection();
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);

        super.$onInit();
    }
}