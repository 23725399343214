import controller from './user-popover.controller';

const template = require('./user-popover.html');

export default {
    bindings: {
        onClose: '&'
    },
    controller,
    template
};
