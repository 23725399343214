import Utils from 'services/utils';
import enLang, {
    DOCUMENT_TITLE_TRANSLATION_PREFIX,
    TRACKING_TITLE_TRANSLATION_PREFIX
} from './en.lang';

export const getDocumentTitleByState = state => `${DOCUMENT_TITLE_TRANSLATION_PREFIX}${state}`;
export const getTrackingTitleByState = state => `${TRACKING_TITLE_TRANSLATION_PREFIX}${state}`;

export default class TranslationService {
    constructor() {
        this.init();
    }

    init() {
        this.currentLang = 'en';

        this.lines = {
            en: enLang
        };
    }

    getLine(key, vars = {}) {
        const line = this.lines[this.currentLang][key] || '';
        return Utils.formatString(vars, line);
    }

    getCurrentLanguage() {
        return this.currentLang;
    }

    updateDocumentTitle(translationKey, project = null) {
        const vars = {};
        if (project) {
            vars.projectName = project.displayName;
            vars.organizationName = project.organizationDisplayName;
        }
        document.title = this.getLine(translationKey, vars);
    }
}
