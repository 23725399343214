import definition from './dialog.service';

/**
 * DialogService settings
 * @type {{name: string, definition: DialogService}}
 */
export default {
    name: 'DialogService',
    definition
};
