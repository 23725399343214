export const EVENT_TYPE_OBJECT_PRESENT = 'objectPresent';
export const EVENT_TYPE_MOTION = 'motion';
export const EVENT_TYPE_NETWORK_STATUS = 'networkStatus';
export const DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN = 'proximity-histogram-open'
export const DASHBOARD_APPEARANCE_CO2_HEATMAP = 'co2-heatmap'
export const DASHBOARD_APPEARANCE_MOTION_HISTOGRAM = 'motion-histogram'
export const DASHBOARD_APPEARANCE_MOTION_HEATMAP = 'motion-heatmap'
export const DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED = 'desk-occupancy-aggregated'
export const DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE = 'multiple-temperature'
export const DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY = 'multiple-humidity'
export const DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD = 120 // Days