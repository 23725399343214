import moment from 'moment';
import _get from 'lodash/get';
import {
    getHistoryChartType,
    getHistoryEventType
} from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { AbstractChartController } from '../chart-abstract-base';

/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartCountingController extends AbstractChartController {
    constructor(SensorEventsLoader, ToastService, $scope,EventEmitter) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    getEventTypes() {
        return [this.eventType, 'networkStatus'];
    }

    // Overrides AbstractChartController
    dataToLoad(days) { // eslint-disable-line no-unused-vars
        // TODO: Implement aggregated data for counting
        return "events";
    }

    getConfigPreset() {
        return ConfigPresets.Counting;
    }

    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.proximitySeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax,
            {
                dataGrouping: {
                    enabled: true,
                    type: 'column',
                    forced: true,
                    groupAll: true,
                    units: [
                        ['second', [1, 10, 30]],
                        ['minute', [1, 5, 15, 30]],
                        ['hour', [1, 2, 4]],
                        ['day', [1, 2, 4]]
                    ]
                }
            }
        );
    }

    convertEvents(events) {
        const { state } = DataConverter.splitByStateAndNetworkStatus(events);
        let delta = 0;

        this.sanitizeStateEvents(state);
        this.plotBands = [];
        this.chartData = state.map(({ data }, index) => {
            const { updateTime, total } = data[this.eventType];

            if (index === 0) {
                delta = total;
            }

            const value = total - delta;

            delta = total;

            return [
                +new Date(updateTime),
                value
            ];
        });

        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    sanitizeStateEvents(stateEvents) {
        if (!stateEvents.length) {
            const total = _get(
                this.thing,
                `reported[${this.eventType}]['total']`,
                null
            );
            if (total) {
                const updateTime = moment(
                    _get(this.thing, `reported[${this.eventType}]['total']`)
                );
                stateEvents.push({
                    data: {
                        [this.eventType]: {
                            total,
                            updateTime:
                                updateTime < this.chartMin
                                    ? this.chartMin
                                    : updateTime
                        }
                    }
                });
            }
        }
    }

    syncOfflinePlotBand() { }

    onStateEventReceived(eventData) {
        const timestamp = +new Date(eventData.updateTime);
        const point = [timestamp, 1];

        this.dataSeries.addPoint(point);
    }

    handleAuxChartSelection({ extremes }) {
        this.xAxis.setExtremes(...extremes);
        this.trackZoomChange('custom');
        this.onChartSelection();
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);

        super.$onInit();
    }
}
