import controller from './controller';

const template = require('./cellularstatus.html');

export default {
    bindings: {
        device: '<',
        onPublishEvent: '&'
    },
    controller,
    template
};
