import * as SensorTypes from 'services/config/SensorTypes';

import { hasOwnProperty } from 'services/utils';
import dialogTemplate from './label-search-dialog.tmpl.html';
import LabelDialogController from './label-dialog.controller';

/* @ngInject */
export default class SearchBoxController {
    constructor(EventEmitter, DialogService, $timeout, AnalyticsService, IAMService) {
        this.eventEmitter = EventEmitter;
        this.DialogService = DialogService;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.IAMService = IAMService
    }

    $onInit() {
        this.devices = SensorTypes;

        this.variants = ['']
        this.selectionAreaVisible = false

        // Track which item is currently expanded
        this.expandedItem = null
        this.expandedIndex = -1

        // Used to calculate floating selection area highlight behind list
        this.itemHeight = 48
        this.variantHeight = 41
        this.selectionAreaHeight = `${this.itemHeight}px`
        this.selectionAreaOffset = ''
        this.devicesCount = null // Using `null` so it can be used in a conditional ng-if
        this.IAMService.getProjectDevicesCount().then(response => {
            this.devicesCount = response.counts

            // Add counting variants to standard count as this is the way they are displayed in the UI
            if (hasOwnProperty(this.devicesCount, 'proximityCounter')) {
                this.devicesCount.proximity = (this.devicesCount.proximity || 0) + this.devicesCount.proximityCounter;
            }
            
            if (hasOwnProperty(this.devicesCount, 'touchCounter')) {
                this.devicesCount.touch = (this.devicesCount.touch || 0) + this.devicesCount.touchCounter;
            }
        })
    }

    toggleDropdownPanel() {
        this.dropdownTouched = true;
        this.dropdownVisible = !this.dropdownVisible;
    }

    showDropdownPanel() {
        if (!this.dropdownDisabled) {
            this.dropdownTouched = true;
            this.dropdownVisible = true;
        }
    }

    hideDropdownPanel() {
        this.dropdownTouched = true;
        this.dropdownVisible = false;

        // Reset expanded and selection area highlight
        this.expandedItem = null
        this.expandedIndex = -1
        this.selectionAreaVisible = false
        this.selectionAreaHeight = `${this.itemHeight}px`
    }

    focused() {
        this.dropdownTouched = true;
        if (!this.query) {
            this.showDropdownPanel();
        }
    }

    blurred() {
        this.dropdownTouched = false;
        this.$timeout(() => {
            if (!this.dropdownTouched) {
                this.hideDropdownPanel();
                this.emitSearchEvent(this.query);
            }
            this.dropdownTouched = false;
        }, 200);
    }

    queryChanged() {
        if (this.query) {
            this.hideDropdownPanel();
        } else {
            this.showDropdownPanel();
            this.search() // Update search when clearing input
        }
        this.onChange(
            this.eventEmitter({
                query: this.query
            })
        );
    }

    search() {
        this.emitSearchEvent(this.query);
    }

    emitSearchEvent(query) {
        this.onSearch(
            this.eventEmitter({
                query
            })
        );
        if (query) {
            this.hideDropdownPanel();
        }
    }

    filterOnMainType(type) {

        // Handling for filtering on both counting & standard variants
        let updatedType = type
        if (updatedType === SensorTypes.touch.filter) {
            updatedType += ` type:${SensorTypes.touchCounter.filter}`
        }

        if (updatedType === SensorTypes.proximity.filter) {
            updatedType += ` type:${SensorTypes.proximityCounter.filter}`
        }
        this.searchByType(updatedType)
    }

    filterOnVariant(variant) {
        // This will be updated to use product number in the future
        this.searchByType(variant)
    }

    searchByType(type) {
        this.AnalyticsService.trackEvent(`device_list.search_by_type.${type}`)
        const query = `type:${type}`;
        this.emitSearchEvent(query);
    }

    setexpandedItem(device, index) {

        this.dropdownTouched = true
        this.expandedIndex = index
        this.variants = Object.keys(device.variants)
        if (this.expandedItem === device) { // Close current expanded item
            this.expandedIndex = -1
            this.expandedItem = null
            this.selectionAreaHeight = `${this.itemHeight}px`
        } else { // Expand selected item
            this.expandedItem = device
            this.selectionAreaHeight = `${this.itemHeight + this.variants.length * this.variantHeight}px`
        }
    }

    hoverOnItem(device, index) {
        
        this.selectionAreaVisible = true
        // Calculate height of selection area behind items
        if (this.expandedIndex > -1) { // Check if any item is expanded
            if (this.expandedItem?.filter === device?.filter) {
                this.variants = Object.keys(device.variants)
                this.selectionAreaHeight = `${this.itemHeight + this.variants.length * this.variantHeight}px`
            } else {
                this.variants = Object.keys(this.expandedItem?.variants)
                this.selectionAreaHeight = `${this.itemHeight}px`
            }
        } 

        // Calculate Y offset of selection area behind items
        // Dynamically based what items are expanded
        const separatorOffset = (device === null) ? 25 : 0
        if (this.expandedIndex < index && this.expandedItem?.filter) {
            this.selectionAreaOffset = `${index * this.itemHeight + 8 + this.variants.length * this.variantHeight + separatorOffset}px`
        } else {
            this.selectionAreaOffset = `${index * this.itemHeight + 8 + separatorOffset}px`
        }
    }

    hoverOnVariant(variant, index) {
        // Calculate Y offset and height of selection area 
        this.variants = [variant]
        const yOffset = this.expandedIndex * this.itemHeight + this.itemHeight
        this.selectionAreaHeight = `${this.variantHeight}px`
        this.selectionAreaOffset = `${yOffset + index * this.variantHeight + 8}px`
    }

    hasMultipleVariants(device) { // eslint-disable-line class-methods-use-this
        return Object.keys(device.variants).length > 1
    }

    kitSearchDialog() {
        this.DialogService.prompt({
            title: 'Search by Kit ID',
            textContent: 'The Kit ID is located on the back of the device packaging.',
            required: true,
            clickOutsideToClose: true,
            ok: 'OK',
            cancel: 'Cancel',
            placeholder: 'Example: abc-00-abc'
        })
            .then(result => {
                this.AnalyticsService.trackEvent("device_list.search_by_kit_label")
                this.emitSearchEvent(`label:kit=${result}`)
            })
            .catch(() => {});
        this.hideDropdownPanel();
    }

    labelSearchDialog(configInput) {
        const defaultConfig = {
            label: '',
            template: dialogTemplate,
            controller: LabelDialogController,
            clickOutsideToClose: true
        };
        const config = { ...defaultConfig, ...configInput };

        this.DialogService.show(config)
            .then(({ name, value }) => {
                if (!name) {
                    return;
                }
                this.AnalyticsService.trackEvent(`device_list.search_by_label.${value ? "key_and_value" : "key_only"}`)
                const labelSearch = value ? `label:${name}=${value}` : `label:${name}`;
                this.emitSearchEvent(labelSearch);
            });
        this.hideDropdownPanel();
    }

    mouseLeaveDropdown() {
        this.dropdownTouched = false;
        this.$timeout(() => {
            if (!this.dropdownTouched) {
                this.hideDropdownPanel();
            }
            this.dropdownTouched = false;
        }, 200);
    }

    searchStarred() {
        const query = 'starred:true';
        this.emitSearchEvent(query);
    }

    clear() {
        this.query = '';
        this.onClear();
    }
}
