import options from './dashboard-card.component';
import DashboardChart from './dashboard-chart';
import SingleDevice from './single-device';
import MultipleDevices from './multiple-devices';

export default {
    selector: 'dtDashboardCard',
    options,
    DashboardChart,
    SingleDevice,
    MultipleDevices
};
