const BLANK_ICON_SENSOR = 'sensor-blank';

export default class IconController {
    $onInit() {
        if (!this.iconClass) {
            this.iconClass = BLANK_ICON_SENSOR;
        }
    }

    getAriaLabel() {
        return (this.iconClass.charAt(0).toUpperCase() + this.iconClass.slice(1)).split('-').join(' ');
    }
}
