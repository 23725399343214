import _get from 'lodash/get';
/* @ngInject */
export default class EmulatorBatteryStatusController {
    constructor(
        EventEmitter,
    ) {
        this.EventEmitter = EventEmitter;
    }


    $onInit() {
        this.event = {
            batteryStatus: {
                percentage: _get(this.device, "reported.batteryStatus.percentage", 100)
            }
        }
    }

    sendEvent() {
        this.onPublishEvent(
            this.EventEmitter({
                event: this.event
            })
        );
    }
}