
/* @ngInject */
export default class FeedbackPopoverController {
    constructor(AuthService, FeedbackApi, $state, ToastService) {
        this.AuthService = AuthService;
        this.FeedbackApi = FeedbackApi;
        this.$state = $state;
        this.ToastService = ToastService;
    }

    $onInit() {
        
        setTimeout(() => {
            document.getElementById('feedback').focus()
        }, 400);

        this.feedbackText = '';
        this.includeEmail = true;
        this.getAuthData();
    }

    getAuthData() {
        const authData = this.AuthService.getAuthData();

        if (authData) {
            this.userProfile = authData.profile;
        }
    }

    cancel() {
        this.onClose()
    }

    send() {
        this.inProgress = true;

        const feedback = {
            request: {
                requester: {}, 
                subject: `Studio feedback on URL: ${this.$state.current.url}`,
                comment: {
                    body: this.feedbackText,
                }
            }
        };

        // Let the user decide if they want to be anonymous
        if (this.includeEmail) {
            feedback.request.requester.name = this.userProfile.name;
            feedback.request.requester.email = this.userProfile.email;
        } else {
            feedback.request.requester.name = 'Anonymous Studio User';
        }

        this.FeedbackApi
            .create(feedback)
            .then(() => {
                this.ToastService.showSimpleTranslated('feedback_sent');
                this.cancel();
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('feedback_wasnt_sent', {
                    serverResponse
                });
            })
            .finally(() => {
                this.inProgress = false;
            });
    }

}
