import controller from './create-member-form.controller';

const template = require('./create-member-form.html');

export default {
    selector: 'dtCreateMemberForm',
    options: {
        bindings: {
            onCreateMember: '&',
            roles: '<',
            simplifiedAdminType: '<'
        },
        controller,
        template
    }
};
