import { repeatRequest } from './helper';

const RATE_LIMIT_QUOTA_EXCEEDED_STATUS_CODE = 429;
const RETRY_AFTER_HEADER = 'Retry-After';
const DEFAULT_RETRY_DELAY = 400; // 0.4s

/* @ngInject */
export default function RateLimitingInterceptor($httpProvider) {
    $httpProvider.interceptors.push(/* @ngInject */ ($q, $injector) => {
        function responseError(rejection) {
            if (rejection.status === RATE_LIMIT_QUOTA_EXCEEDED_STATUS_CODE) {
                const retryAfter = rejection.headers(RETRY_AFTER_HEADER);
                let delay = DEFAULT_RETRY_DELAY;
                if (retryAfter !== null) {
                    delay += parseInt(retryAfter, 10) * 1000;
                }
                return repeatRequest($injector, rejection.config, delay);
            }
            return $q.reject(rejection);
        }

        return {
            responseError
        };
    });
}
