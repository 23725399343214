export default function () {
    function link($scope, $element) {
        const el = $element[0];
        if ($scope.$ctrl.showAriaLabel) {
            const label = $scope.$ctrl.getAriaLabel();

            el.setAttribute('aria-label', label);
            el.removeAttribute('aria-hidden');
        } else {
            el.setAttribute('aria-hidden', 'true');
        }
    }

    return {
        restrict: 'A',
        link
    };
}
