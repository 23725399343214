import { States } from '../../../../../app.router';

/* @ngInject */
export default class LiveMotionCardController {
    constructor(EventEmitter, $scope, $state) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$state = $state;
    }

    $onInit() {
        this.showDeviceList = false
        this.totalDevices = this.card.content.devices.length
        this.totalMotion = 0
        this.totalNoMotion = 0
        this.totalOffline = 0
        this.updateCardStatus()

        // GraphLoading is set to true by dashboard controller when new events are received
        this.$scope.$watch("$ctrl.card.graphLoading", () => {
            this.updateCardStatus()
            this.$scope.$applyAsync()        
        });
    }

    updateCardStatus() {
        let newTotalOffline = 0
        let newTotalMotion = 0
        let newTotalNoMotion = 0
        this.card.content.devices.forEach(device => {
            if (device.offline) {
                newTotalOffline += 1
                return
            }
            if (device.reported.motion?.state === 'MOTION_DETECTED') {
                newTotalMotion += 1
            }
            if (device.reported.motion?.state === 'NO_MOTION_DETECTED') {
                newTotalNoMotion += 1
            }
        });
        this.totalOffline = newTotalOffline
        this.totalMotion = newTotalMotion
        this.totalNoMotion = newTotalNoMotion
    }

    hoverStarted() {
        this.hoveringOnCard = true
        setTimeout(() => {
            if (this.hoveringOnCard) {
                this.showDeviceList = true
                this.$scope.$applyAsync()
            }
        }, 250);
    }

    hoverStopped() {
        this.hoveringOnCard = false
        this.showDeviceList = false
    }

    getStatusForDevice(device) { // eslint-disable-line class-methods-use-this
        if (device.offline) {
            return 'Offline'
        }
        if (device.reported.motion?.state === 'MOTION_DETECTED') {
            return 'Occupied'
        }
        if (device.reported.motion?.state === 'NO_MOTION_DETECTED') {
            return 'Not Occupied'
        }
        return ''
    }

    goToSensorPage(device) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: device.name.split('/')[1],
            sensorId: device.name.split('/')[3],
        });
    }
}
