import controller from './export.controller';

const template = require('./export.html');

export default {
    bindings: {
        onCreate: '&'
    },
    controller,
    template
};
