export default {
    chart: {
        type: 'line',
        marginLeft: 58
    },
    yAxis: [
        {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
                format: '{value}%'
            }
        },
        {
            visible: false
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
    },
    tooltip: {
        enabled: true,
        shared: true
    },
    plotOptions: {
        line: {
            getExtremesFromAll: false,
            marker: {
                enabledThreshold: 4
            }
        },
        series: {
            turboThreshold: 0
        }
    }
};
