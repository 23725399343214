import controller from './http-headers.controller';

const template = require('./http-headers.html');

export default {
    bindings: {
        editable: '<',
        headers: '<'
    },
    template,
    controller
};
