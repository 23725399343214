import controller from './member-details.controller';

const template = require('./member-details.html');

export default {
    bindings: {
        user: '<',
        onUpdate: '&',
        onDelete: '&',
        onResendInvite: '&'
    },
    controller,
    template
};
