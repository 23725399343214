import { States } from '../../app.router';

/* @ngInject */
export default function orgDetailsRouter($stateProvider) {
    $stateProvider
        .state(States.ORGANIZATION_DETAILS, {
            url: '/organization-details',
            views: {
                'content@main': {
                    component: 'dtOrganizationDetailsPage'
                }
            }
        })

}
