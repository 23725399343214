import controller from './search-box.controller';

const template = require('./search-box.html');

export default {
    bindings: {
        placeholder: '@',
        dropdownDisabled: '<',
        query: '<',
        onSearch: '&',
        onClear: '&',
        onChange: '&'
    },
    template,
    controller
};
