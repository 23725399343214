import controller from './multiple-devices.controller';

const template = require('./multiple-devices.html');

export default {
    bindings: {
        card: '<',
        canUpdate: '<',
        duration: '<',
        zoomStartTime: '<',
        zoomEndTime: '<',
        onEditCard: '&',
        onEditDevices: '&',
        onRemoveMissingDevices: '&',
        onToggleLegend: '&',
        onUpdateAppearance: '&',
        onRemove: '&'
    },
    template,
    controller
};
