import InterceptorConfig from './auth.interceptor';
import definition from './auth.service';

const AuthService = {
    name: 'AuthService',
    definition
};

export {
    AuthService,
    InterceptorConfig
};
