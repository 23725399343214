import { CREATE_MEMBERSHIP } from 'services/Permissions';

/* @ngInject */
export default class CreateMemberFormController {
    constructor(EventEmitter, RoleManager, ProjectManager, $scope) {
        this.EventEmitter = EventEmitter;
        this.RoleManager = RoleManager;
        this.ProjectManager = ProjectManager;
        this.$scope = $scope;
    }

    $onInit() {
        this.role = this.roles.length ? this.roles[0] : null;
        this.showRoleDropdown = false;
    }

    get canCreateMember() {
        return this.RoleManager.hasProjectPermissionTo(CREATE_MEMBERSHIP);
    }

    setRole(role){
        this.role = role;
        this.showRoleDropdown = false;
    }

    hideDropdown() {
        setTimeout(() => {
            this.showRoleDropdown = false;
            this.$scope.$applyAsync();
        }, 200);
    }

    createMember() {
        if (this.form.$invalid) {
            return;
        }

        this.onCreateMember(
            this.EventEmitter({
                member: {
                    email: this.email,
                    roles: [this.role.name]
                }
            })
        ).then(() => {
            this.email = '';

            this.form.$setPristine();
            this.form.$setUntouched();
        });
    }
}
