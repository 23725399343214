import controller from './organization-member-details.controller';

const template = require('./organization-member-details.html');

export default {
    bindings: {
        user: '<',
    },
    controller,
    template
};
