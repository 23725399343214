import moment from 'moment';

/**
 * Convert a duration string into a moment duration object.
 * A duration string might look like `300.00s` or `1s`.
 *
 * @private
 * @param {string} duration
 * @return {moment.duration}
 */
function parseDuration(duration) {
    if (!duration) return moment.duration(0);
    if (duration.slice(-1) !== 's')
        throw new Error("invalid duration: suffix 's' required");
    const seconds = parseFloat(duration.slice(0, -1));
    return moment.duration(seconds, 'seconds');
}

// Humanize the duration string (e.g 300s) into a more human readable version.
// E.g, "1 hour, 2 minutes and 30 seconds"
function humanize(durationString) {
    const pluralize = (v, plural) => {
        if (v > 1) return `${plural}s`;
        return plural
    }
    const d = parseDuration(durationString);
    const parts = [];
    const seconds = d.seconds();
    const minutes = d.minutes();
    const hours = d.hours() + (d.days() * 24);
    if (hours > 0) parts.push(`${hours} ${pluralize(hours, "hour")}`);
    if (minutes > 0) parts.push(`${minutes} ${pluralize(minutes, "minute")}`);
    if (seconds > 0) parts.push(`${seconds} ${pluralize(seconds, "second")}`);
    if (parts.length === 0) {
        return "";
    }
    if (parts.length === 1) {
        return parts[0];
    }
    const last = parts.pop();
    return `${parts.join(", ")} and ${last}`;
}

export class Duration {
    constructor(seconds = 0, minutes = 0, hours = 0) {
        this.seconds = seconds;
        this.minutes = minutes;
        this.hours = hours;
    }

    get momentDuration() {
        return moment.duration({
            seconds: this.seconds,
            minutes: this.minutes,
            hours: this.hours
        });
    }

    toString() {
        return `${this.momentDuration.asSeconds()}s`;
    }

    static fromMomentDuration(d) {
        return new Duration(d.seconds(), d.minutes(), d.hours() + (d.days() * 24));
    }

    static fromString(durationString) {
        const d = parseDuration(durationString);
        return this.fromMomentDuration(d);
    }

    static humanizedFromString(durationString) {
        return humanize(durationString);
    }
}

/* @ngInject */
export default class DurationController {
    /**
     *
     * @param $state
     * @param $scope
     */
    constructor($state, $scope) {
        this.$state = $state;
        this.$scope = $scope;
        this.humanize = humanize;
        /**
         * @type {Duration}
         */
        this.duration = undefined;
        /**
         * @type {string}
         */
        this.max = undefined;
        /**
         * @type {string}
         */
        this.min = undefined;
    }

    $onInit() {
        this.ngModelCtrl.$overrideModelOptions({allowInvalid: true});
        if (this.min) {
            this.ngModelCtrl.$validators.min = (modelValue, viewValue) => {
                const minSeconds = parseDuration(this.min).asSeconds();
                const d = parseDuration(modelValue || viewValue);
                return d.asSeconds() >= minSeconds;
            };
        }
        if (this.max) {
            this.ngModelCtrl.$validators.max = (modelValue, viewValue) => {
                const maxSeconds = parseDuration(this.max).asSeconds();
                const d = parseDuration(modelValue || viewValue);
                return d.asSeconds() <= maxSeconds;
            };
        }
        this.ngModelCtrl.$render = () => {
            this.duration = Duration.fromString(this.ngModelCtrl.$viewValue);
        };
    }

    durationChanged($event) {
        this.ngModelCtrl.$setDirty();
        this.ngModelCtrl.$setViewValue(this.duration.toString(), $event);
        this.ngModelCtrl.$processModelValue();
    }
}
