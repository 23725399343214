import { getResourceName } from 'services/api/helper';
import { cloneEntity } from 'services/utils';
import { States } from '../../app.router';

/* @ngInject */
export default function projectsRouter($stateProvider) {
    $stateProvider
        .state(States.PROJECT_SETTINGS, {
            url: '/project-settings',
            views: {
                'content@main': {
                    component: 'dtProjectSettingsPage'
                }
            }
        })
        .state(States.PROJECT_SETTINGS_MEMBER, {
            url: '/{memberId}',
            params: {
                member: null
            },
            resolve: {
                user: /* @ngInject */ (IAMService, $stateParams, ToastService) => {
                    if ($stateParams.member && $stateParams.member.id === $stateParams.memberId) {
                        return cloneEntity($stateParams.member);
                    }

                    return IAMService.getMember(
                        getResourceName(
                            ['projects', $stateParams.projectId],
                            ['project.members', $stateParams.memberId]
                        )
                    ).catch((serverResponse) => {
                        ToastService.showSimpleTranslated('member_not_found', {
                            serverResponse
                        });
                        return null;
                    });
                }
            },
            onEnter: /* @ngInject */ (user, $state, $stateParams) => { // eslint-disable-line consistent-return
                if (!user) {
                    return $state.target(States.PROJECT_SETTINGS, {
                        projectId: $stateParams.projectId
                    });
                }
            },
            component: 'dtProjectMemberDetailsPage'
        });
}
