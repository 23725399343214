/* @ngInject */
export default class HTTPHeadersController {

    get canUpdate() {
        return this.editable;
    }

    get hasHeaders() {
        return this.headers && Object.keys(this.headers).length > 0;
    }

    get isEmpty() {
        return this.headerName === '';
    }

    $onInit() {
        this.headerName = '';
        this.headerValue = '';
        this.headerNamePattern = /^[\^_`a-zA-Z\-0-9!#$%&'*+.|~]+$/;
    }

    createHeader() {
        this.headers[this.headerName.toLocaleLowerCase()] = this.headerValue;
        this.headerName = '';
        this.headerValue = '';
        this.addForm.$setPristine();
        this.addForm.$setUntouched();
    }

    saveValue(headerName, value) {
        this.headers[headerName] = value;
    }

    remove(labelKey) {
        delete this.headers[labelKey];
    }
}
