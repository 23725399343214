import {
    APP_CONTENT_RESIZE_EVENT,
    STATE_TRANSITION_START_EVENT,
    SIMULATE_FULLSCREEN,
    STOP_SIMULATE_FULLSCREEN,
    NEW_USER_FLOW,
    NEW_USER_FLOW_FINISHED,
    SHOW_MISSING_BILLING_INFO_BANNER,
    REMOVE_MISSING_BILLING_INFO_BANNER,
    SHOW_OVERDUE_INVOICE_BANNER,
    REMOVE_OVERDUE_INVOICE_BANNER,
    SHOW_ORGANIZATION_ALERTS,
    HIDE_ORGANIZATION_ALERTS
} from 'services/StudioEvents';
import {
    TOP_LEVEL_NETWORK_ERROR_STATE_KEY,
    TOP_LEVEL_NETWORK_ERROR_VISIBLE_STATE_KEY
} from 'services/api/ResourceStream';
import { CREATE_MEMBERSHIP } from 'services/Permissions';
import { reflowCharts } from 'services/utils';
import { States } from '../../app.router';


/* @ngInject */
export default class LayoutController {
    constructor($timeout, $scope, $state, StateService, AppMenu, $rootScope, RoleManager, BillingCheckHelper) {
        this.$timeout = $timeout;
        this.appMenu = AppMenu;
        this.navCollapsed = false;
        this.$scope = $scope;
        this.$state = $state
        this.$rootScope = $rootScope;
        this.RoleManager = RoleManager;
        this.StateService = StateService;
        this.BillingCheckHelper = BillingCheckHelper
    }

    $onInit() {
        this.isMobileNav = null;
        this.removeEventListener = this.$scope.$root.$on(
            STATE_TRANSITION_START_EVENT,
            () => {
                this.hideMobileNav();
            }
        );
        this.removeResizeListener = this.$scope.$root.$on(
            APP_CONTENT_RESIZE_EVENT,
            () => {
                reflowCharts();
            }
        );
        this.removeSimulateFullscreenListener = this.$scope.$root.$on(
            SIMULATE_FULLSCREEN, () => {
                this.navCollapsed = true
            }
        )
        this.removeStopSimulateFullscreenListener = this.$scope.$root.$on(
            STOP_SIMULATE_FULLSCREEN, () => {
                this.navCollapsed = false
            }
        )
        this.newUserFlow = false
        this.$rootScope.$on(NEW_USER_FLOW, () => {
            this.newUserFlow = true
        })

        this.$rootScope.$on(NEW_USER_FLOW_FINISHED, () => {
            this.newUserFlow = false
        })

        this.$rootScope.$on(SHOW_ORGANIZATION_ALERTS, () => {
            this.showingOrganizationAlerts = true
        })

        this.$rootScope.$on(HIDE_ORGANIZATION_ALERTS, () => {
            this.showingOrganizationAlerts = false
        })

        setTimeout(() => {
            this.BillingCheckHelper.check()
        }, 1000); // No need to rush this

        this.missingBillingInfo = false
        this.overdueInvoice = false

        this.$rootScope.$on(SHOW_MISSING_BILLING_INFO_BANNER, () => {
            this.missingBillingInfo = true
            this.$rootScope.$applyAsync()
        })

        this.$rootScope.$on(REMOVE_MISSING_BILLING_INFO_BANNER, () => {
            this.missingBillingInfo = false
            this.$rootScope.$applyAsync()
        })

        this.$rootScope.$on(SHOW_OVERDUE_INVOICE_BANNER, () => {
            this.overdueInvoice = true
            this.$rootScope.$applyAsync()
        })

        this.$rootScope.$on(REMOVE_OVERDUE_INVOICE_BANNER, () => {
            this.overdueInvoice = false
            this.$rootScope.$applyAsync()
        })
    }

    $onDestroy() {
        this.removeEventListener();
        this.removeResizeListener();
        this.removeSimulateFullscreenListener();
        this.removeStopSimulateFullscreenListener();
    }

    get isOrganizationAdmin() {
        return this.RoleManager.hasOrganizationPermissionTo(CREATE_MEMBERSHIP)
    }

    get hasNetworkError() {
        return (
            this.StateService.getItem(
                TOP_LEVEL_NETWORK_ERROR_VISIBLE_STATE_KEY,
                false
            ) &&
            this.StateService.getItem(TOP_LEVEL_NETWORK_ERROR_STATE_KEY, false)
        );
    }

    // Avoid showing billing banners when viewing the billing page
    get notShowingBilling() {
        return this.$state.current.name !== States.ORGANIZATION_BILLING
    }

    // Temporarily hide the billing warning (until Studio is refreshed)
    hideWarning() {
        this.missingBillingInfo = false
        this.overdueInvoice = false
    }

    openBilling() {
        this.$state.go(States.ORGANIZATION_BILLING);
    }

    showMobileNav() {
        this.isMobileNav = true;
    }

    hideMobileNav() {
        this.isMobileNav = false;
    }
}
