
/* @ngInject */
export default class ExtendedStorageController {
    constructor(DialogService, $rootScope, StateService, AuthService, FeedbackApi, ToastService, ProjectManager, AnalyticsService) {
        this.DialogService = DialogService
        this.$rootScope = $rootScope
        this.StateService = StateService
        this.AuthService = AuthService
        this.FeedbackApi = FeedbackApi
        this.ToastService = ToastService
        this.ProjectManager = ProjectManager
        this.AnalyticsService = AnalyticsService
        this.requestedMoreInfo = false
        this.sentRequest = false
    }

    $onInit() {
        if (this.StateService.getItem('EXTENDED_DATA_STORAGE_INFO_REQUESTED', false)) {
            this.requestedMoreInfo = true
            this.sentRequest = true
            this.AnalyticsService.trackEvent('extended_storage_info.request_already_sent')
        }
        this.getAuthData()
    }

    $onDestroy() {
        if (!this.sentRequest) {
            this.AnalyticsService.trackEvent('extended_storage_info.user_aborted_request')
        }
    }
    
    getAuthData() {
        const authData = this.AuthService.getAuthData();
        
        if (authData) {
            this.userProfile = authData.profile;
        }
    }
    
    requestMoreInfo() {
        this.requestedMoreInfo = true
        this.$rootScope.$applyAsync()
        
        const name = (this.userProfile.name && this.userProfile.name.length >= 2) ? this.userProfile.name : 'Missing Studio Name'
        const infoRequest = {
            request: {
                requester: {
                    name
                }, 
                subject: 'Extended Data Storage – Activation Request',
                comment: {
                    body: `User: ${name} (${this.userProfile.email}) is requesting activation. Organization: ${this.ProjectManager.currentProject.organizationDisplayName} (Org ID: ${this.ProjectManager.currentProject.organizationId}).`,
                }
            }
        }
        
        this.AnalyticsService.trackEvent('extended_storage_info.request_sent');

        this.FeedbackApi.create(infoRequest).then(() => {    
            this.StateService.setItem('EXTENDED_DATA_STORAGE_INFO_REQUESTED', true, {
                persistenceDriver: 'localStorage'
            })
            this.sentRequest = true
        }).catch((serverResponse) => {
            this.AnalyticsService.trackEvent('extended_storage_info.failed_to_send_request');
            
            this.ToastService.showSimpleTranslated('feedback_wasnt_sent', { // Generic send error message
                serverResponse
            })
            this.requestedMoreInfo = false
        }).finally(() => {
            this.$rootScope.$applyAsync()
        })
    }

    closeModal() {
        this.DialogService.cancel()
    }
}
