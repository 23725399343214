import _get from 'lodash/get';
/* @ngInject */
export default class EmulatorCounterController {
    constructor(
        EventEmitter,
    ) {
        this.EventEmitter = EventEmitter;
    }


    get reportedCount() {
        let count = 0;
        count = _get(this.device, 'reported.touchCount.total', count)
        count = _get(this.device, 'reported.objectPresentCount.total', count)
        return count

    }

    $onInit() {
        this.count = 0;
        this.count = _get(this.device, 'reported.touchCount.total', this.count);
        this.count = _get(this.device, 'reported.objectPresentCount.total', this.count);
    }

    sendCountEvent() {
        let event;
        if (this.device.type === "touchCounter") {
            event = {
                touchCount: {
                    total: this.count
                }
            }
        } else if (this.device.type === "proximityCounter") {
            event = {
                objectPresentCount: {
                    total: this.count
                }
            }
        } else {
            return
        }
        this.onPublishEvent(
            this.EventEmitter({
                event
            })
        );
    }
}