
/* @ngInject */
export default class RemoveMemberDialogController {
    constructor(DialogService, IAMService, member, RoleManager, ProjectManager, ToastService, Loader, goBackToOrganizationMembers) {
        this.DialogService = DialogService;
        this.IAMService = IAMService;
        this.RoleManager = RoleManager;
        this.member = member;
        this.ProjectManager = ProjectManager;
        this.ToastService = ToastService;
        this.Loader = Loader;
        this.goBackToOrganizationMembers = goBackToOrganizationMembers;
    }

    removeUserFromOrganization() {
        if (this.member.isOrgAdmin || this.member.isOrgViewer) {
            this.IAMService.deleteMember(this.member.name).then(() => {
                this.removeFromAllProjects()
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('member_wasnt_removed', {
                    serverResponse
                });
            });
        } else {
            this.removeFromAllProjects()
        }
    }

    removeFromAllProjects() {
        const memberNames = []
        this.member.memberships.forEach(membership => {
            memberNames.push(membership.name)
        })
        return this.IAMService.batchDeleteMembersFromProjects(memberNames).then(() => {
            this.ToastService.showSimpleTranslated('member_was_removed');
            this.cancel();
            this.goBackToOrganizationMembers();
        }).catch((serverResponse) => {
            this.ToastService.showSimpleTranslated('member_access_wasnt_updated', {
                serverResponse
            });
        })
    }

    cancel() {
        this.DialogService.cancel();
    }
}
