import _cloneDeep from 'lodash/cloneDeep';

const LoaderType = {
    LINE: 'progress-linear'
};

/* @ngInject */
export default class LoaderController {
    constructor(Loader) {
        this.Loader = Loader;
    }

    $onInit() {
        if (!this.loaderType) {
            this.loaderType = LoaderType.LINE;
        }
        this.showLoader = false;
    }

    $onChanges(changes) {
        if (changes.loaderType) {
            this.loaderType = _cloneDeep(changes.loaderType.currentValue);
        }
    }

    isLoading() {
        return this.Loader.isShowLoader();
    }
}
