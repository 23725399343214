import moment from 'moment';
// TILT-SUPPORT
import {
    getHistoryChartType,
    getHistoryEventType
} from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { accelerationFieldsToGravity } from 'services/utils';
import { AbstractChartController } from '../chart-abstract-base';

/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartTiltController extends AbstractChartController {
    constructor(
        SensorEventsLoader,
        ToastService,
        $scope,
        EventEmitter,
    ) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);

        super.$onInit();
    }

    get dataSeries() {
        return {
            addPoint: (date, accX, accY, accZ) => {
                this.chart.series[0].addPoint([date, accX], false);
                this.chart.series[2].addPoint([date, accY], false);
                this.chart.series[4].addPoint([date, accZ], false);
            },
            setData: (data) => {
                this.chart.series[0].setData(data.map(item => [item[0], item[1]])); // X
                this.chart.series[2].setData(data.map(item => [item[0], item[2]])); // Y
                this.chart.series[4].setData(data.map(item => [item[0], item[3]])); // Z
            }
        }
    }

    getEventTypes() {
        return [this.eventType, 'networkStatus'];
    }

    // Overrides AbstractChartController
    dataToLoad(days) {
        if (days > 31) {
            return "";
        } 

        return "events";
    }

    getConfigPreset() {
        return ConfigPresets.Tilt;
    }

    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.tiltSeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax
        );
    }

    convertEvents(events) {
        const {
            state,
            networkStatus
        } = DataConverter.splitByStateAndNetworkStatus(events);
        const networkEventsTimestamps = DataConverter.getTimestampsFromNetworkStatusEvents(networkStatus)
        this.plotBands = DataConverter.createOfflineBands(
            networkEventsTimestamps,
            this.chartMin,
            this.chartMax
        );
        this.chartData = DataConverter.syncOfflineTemperature(
            DataConverter.tilt(state),
            this.plotBands
        );
        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    onStateEventReceived(eventData) {
        if (eventData.type !== "tilt") {
            return;
        }
        const { x, y, z } = accelerationFieldsToGravity(eventData.fields);
        this.dataSeries.addPoint(
            moment(eventData.updateTime).valueOf(), 
            x, y, z
        );
    }

    adjustMinMaxValues(value) {
        if (value < this.spacerMin) {
            this.spacerMin = value;
        } else if (value > this.spacerMax) {
            this.spacerMax = value;
        }
    }
}
