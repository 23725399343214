import _get from 'lodash/get';
/* @ngInject */
export default class EmulatorNetworkStatusController {
    constructor(
        EventEmitter,
    ) {
        this.EventEmitter = EventEmitter;
    }


    $onInit() {
        this.event = {
            networkStatus: {
                signalStrength: _get(this.device, "reported.networkStatus.signalStrength", 100),
                transmissionMode: _get(this.device, "reported.networkStatus.transmissionMode", "LOW_POWER_STANDARD_MODE")
            }
        }
    }

    sendEvent() {
        this.onPublishEvent(
            this.EventEmitter({
                event: this.event
            })
        );
    }
}