import moment from 'moment'
import { ISO3166Countries, ISO3166States, dateFromXID } from 'services/utils'
import { FETCHED_BILLING_INFO } from 'services/StudioEvents';

import BillingInformationController from './information-modal/controller';
import BillingInformationTemplate from './information-modal/template.html';

import SubscriptionController from './subscription-modal/controller';
import SubscriptionTemplate from './subscription-modal/template.html';

/* @ngInject */
export default class BillingController {
    
    constructor(ProjectManager, Loader, $state, IAMService, DialogService, $http, FeatureFlags, AnalyticsService, StorageService, $rootScope) {
        this.ProjectManager = ProjectManager;
        this.$state = $state;
        this.IAMService = IAMService;
        this.DialogService = DialogService;
        this.$http = $http;
        this.Loader = Loader;
        this.FeatureFlags = FeatureFlags;
        this.AnalyticsService = AnalyticsService;
        this.StorageService = StorageService;
        this.$rootScope = $rootScope;

        this.onSubscriptionReorder = this.onSubscriptionReorder.bind(this)
        this.onInvoiceReorder = this.onInvoiceReorder.bind(this)
    }

    get invoicesEmptyState() {
        return !this.Loader.isShowLoader() && this.invoices.length === 0;
    }

    get subscriptionsEmptyState() {
        return !this.Loader.isShowLoader() && this.subscriptions.length === 0;
    }

    get hasInvoiceEmail() {
        return Boolean(this.billingInfo.invoiceContact?.email)
    }

    get hasSubscriptionContact() {
        return Boolean(this.billingInfo.subscriptionContact?.email) && Boolean(this.billingInfo.subscriptionContact?.firstName) && Boolean(this.billingInfo.subscriptionContact?.lastName)
    }

    get hasBillingAddress() {
        return Boolean(this.billingAddress)
    }

    get missingBillingInfo() {
        return !this.hasInvoiceEmail || !this.hasSubscriptionContact || !this.hasBillingAddress;
    }

    get hasWriteAccess() {
        return Boolean(this.billingInfo.accessLevel?.writeAccess)
    }

    get hasReadAccess() {
        return Boolean(this.billingInfo.accessLevel?.readAccess)
    }

    get unclaimedSensors() {
        if (this.salesOrders.length > 0) {
            return this.salesOrders.map(salesOrder => salesOrder.unclaimedSensorCount).reduce((a, b) => a + b)
        }
        return 0
    }

    get unclaimedCloudConnectors() {
        if (this.salesOrders.length > 0) {
            return this.salesOrders.map(salesOrder => salesOrder.unclaimedCloudConnectorCount).reduce((a, b) => a + b)
        }
        return 0
    }

    get billingAddress() {
        const address = this.billingInfo.billingAddress

        function hasValue(string) {
            return string.length > 0
        }

        // Check if any required values are missing
        if (Object.keys(address).length > 0 && 
            hasValue(address.companyName) && hasValue(address.country) && 
            hasValue(address.addr1) && hasValue(address.zip) && hasValue(address.city)) {
            const country = ISO3166Countries()[address.country]
            /* eslint-disable prefer-template */ 
            return `${address.companyName}, 
                    ${address.addr1} ${address.addr2}, 
                    ${address.zip} ${address.city}${address.country === "US" ? (' ' + ISO3166States()[address.state]) : ''},
                    ${country}`;
            /* eslint-enable prefer-template */ 
        }
        return ""
    }

    $onInit() {

        this.orgName = this.ProjectManager.currentProject.organizationDisplayName;
        this.stateTitle = `${this.orgName} | Billing`;

        this.invoiceOrder = ['-issueDate','-invoiceNumber'];
        this.subscriptionOrder = ['nextRenewalTime']
        
        this.overdueInvoice = false

        this.activeTab = 'SUBSCRIPTIONS'
        this.expandedSalesOrderRows = []
        this.expandedKits = []
        this.invoices = [];
        this.salesOrders = []
        this.downloadingInvoices = []; // List of invoices waiting to be downloaded
        this.subscriptions = [];
        
        this.billingInfoAvailable = false;
        this.loadedBillingInfo = false;
        this.billingInfo = {}

        this.Loader.promises = [this.loadBillingInformation(), this.loadInvoices(), this.loadSubscriptions(), this.loadSalesOrders()]

        const orgId = this.ProjectManager.currentProject.organization.split("/")[1]
        if (orgId) {
            this.orgCreatedDate = dateFromXID(orgId)
        }
    }

    getClaimedDeviceCountForSalesOrder(salesOrder) {
        if (!salesOrder) {
            return 0
        }
        return salesOrder.sensorCount + salesOrder.cloudConnectorCount - salesOrder.unclaimedSensorCount - salesOrder.unclaimedCloudConnectorCount
    }

    getDeviceCountForSalesOrder(salesOrder) {
        if (!salesOrder) {
            return 0
        }
        return salesOrder.sensorCount + salesOrder.cloudConnectorCount
    }

    getUnclaimedDevicesCountForKit(kit) {
        if (!kit || !kit.devices) {
            return 0
        }
        return kit.devices.filter(device => !device.isClaimed).length
    }

    expandSalesOrder(salesOrder, index) {

        if (this.expandedSalesOrderRows.includes(index)) {
            this.expandedSalesOrderRows.splice(this.expandedSalesOrderRows.indexOf(index), 1);
        } else {
            this.expandedSalesOrderRows.push(index);
        }
        
        if (!salesOrder.kits) {
            this.IAMService.getKitsFromSalesOrder(salesOrder.salesOrderId).then(data => {
                // Sort the kits based on the number of unclaimed devices in descending order
                data.kits.sort((a, b) => {
                    const unclaimedA = a.devices.filter(device => !device.isClaimed).length;
                    const unclaimedB = b.devices.filter(device => !device.isClaimed).length;
                    return unclaimedB - unclaimedA; // For descending order
                })
                salesOrder.kits = data.kits
                this.$rootScope.$applyAsync()
            })
        }
    }

    formatCurrency(currency, amount) { // eslint-disable-line class-methods-use-this
        const formatter = new Intl.NumberFormat(undefined, { // Uses user locale for formatting when set to 'undefined'
            style: 'currency',
            currency
        })
        return formatter.format(amount)
    }

    daysLeft(date) { // eslint-disable-line class-methods-use-this
        return moment(date).diff(moment(), 'days')
    }

    subscriptionPeriod(period) { // eslint-disable-line class-methods-use-this
        switch (period) {
            case 'YEARS_1':
                return '1 Year'
            case 'YEARS_2':
                return '2 Years'
            case 'YEARS_3':
                return '3 Years'
            case 'YEARS_4':
                return '4 Years'
            case 'YEARS_5':
                return '5 Years'
            default:
                return "Unspecified"
        }
    }

    onInvoiceReorder(invoiceOrder) { 
        if (invoiceOrder === '') {
            this.order = ['-issueDate','-invoiceNumber'];
        }
        this.AnalyticsService.trackEvent(`billing.invoice.sort.${invoiceOrder}`)
    }
    
    onSubscriptionReorder(subscriptionOrder) {
        if (subscriptionOrder === '') {
            this.order = 'nextRenewalTime'
        }

        this.AnalyticsService.trackEvent(`billing.subscription.sort.${subscriptionOrder}`)
    }

    loadBillingInformation() {
        return this.IAMService.getBillingInformation().then(response => {
            this.billingInfo = response.billingInfo
            this.billingInfoAvailable = true
            this.loadedBillingInfo = true
            this.updateBillingBadgeCheck()
        }).catch(() => {
            this.billingInfoAvailable = false
            this.loadedBillingInfo = true
        })
    }
    
    loadInvoices() {
        return this.IAMService.listInvoices().then(invoices => {
            this.invoices = invoices.data.filter(invoice => invoice.amount !== "0");
            if (this.invoices.length > 0) {
                this.updateBillingBadgeCheck()
            }
        })
    }

    loadSalesOrders() {
        this.loadingSalesOrders = true
        return this.IAMService.getSalesOrders().then(data => {
            this.salesOrders = data.salesOrders
        }).finally(() => {
            this.loadingSalesOrders = false
        })
    }

    loadSubscriptions() {
        return this.IAMService.listSubscriptions().then(subscriptions => {
            this.subscriptions = subscriptions.data
        })
    }

    updateBillingBadgeCheck() {
        if (this.loadedBillingInfo) {
            this.$rootScope.$broadcast(FETCHED_BILLING_INFO)
        }
    }
    
    showBillingInformationModal(ev) {

        this.DialogService.show({
            class: 'billing-information-modal',
            controller: BillingInformationController,
            controllerAs: '$ctrl',
            template: BillingInformationTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
                billingInfo: this.billingInfo
            }
        });
    }

    showSubscriptionModal(ev, subscription) {

        this.DialogService.show({
            controller: SubscriptionController,
            controllerAs: '$ctrl',
            template: SubscriptionTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
            locals: {
                subscription
            }
        });
    }

    downloadPDF(invoiceNumber) {
        this.downloadingInvoices.push(invoiceNumber)
        this.IAMService.generateInvoicePDF(invoiceNumber).then(response => {
            
            // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            const a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            // Create a DOMString representing the blob and point the link element towards it
            const url = window.URL.createObjectURL(response.data);
            a.href = url;
            a.download = `${invoiceNumber}.pdf`;
            a.click(); // Programatically click the link to trigger the download
            // Release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
            this.downloadingInvoices.splice(this.downloadingInvoices.indexOf(invoiceNumber), 1);
        })

        this.AnalyticsService.trackEvent("billing.invoice.download")
    }
}
