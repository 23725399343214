import * as Components from './components';
import ListContainer from './list';

import Routes from './sensors.router';
import options from './sensors.component';

const Component = {
    selector: 'dtSensorsAndConnectors',
    options
};

export {
    Component,
    Components,
    Routes,
    ListContainer
};
