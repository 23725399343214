import angular from 'angular';

/* @ngInject */
export default function mdCustomColumnDirectiveController($mdUtil, $compile) {
    function postLink($scope, $element, $attrs, $ctrls) {
        const headCtrl = $ctrls.shift();
        const tableCtrl = $ctrls.shift();
        $scope.orderCounter = {};

        function attachSortIcon() {
            const icons = ['table-sortable', 'table-sorted'];

            icons.forEach((icon) => {
                const sortIcon = angular.element(
                    `<md-icon class="md-custom-column-arrow ${icon}" md-svg-icon="${icon}">`
                );

                $compile(sortIcon.addClass('md-sort-icon').attr('ng-class', 'getDirection()'))($scope);

                if ($element.hasClass('md-numeric')) {
                    $element.prepend(sortIcon);
                } else {
                    $element.append(sortIcon);
                }
            });
        }

        function detachSortIcon() {
            Array.prototype.some.call($element.find('md-icon'), icon => (
                icon.classList.contains('md-sort-icon') && $element[0].removeChild(icon)
            ));
        }

        function getIndex() {
            return Array.prototype.indexOf.call($element.parent().children(), $element[0]);
        }

        function isActive() {
            return $scope.orderBy && (headCtrl.order === $scope.orderBy || headCtrl.order === `-${$scope.orderBy}`);
        }

        function isNumeric() {
            return $attrs.mdNumeric === '' || $scope.numeric;
        }

        function setOrder() {
            if (isNaN($scope.orderCounter[$scope.orderBy])) {
                $scope.orderCounter[$scope.orderBy] = 0;
            }

            $scope.orderCounter[$scope.orderBy] += 1;

            if ($scope.orderCounter[$scope.orderBy] % 3 === 0) {
                headCtrl.order = '';
            } else if (isActive()) {
                headCtrl.order = $scope.getDirection() === 'md-asc' ? `-${$scope.orderBy}` : $scope.orderBy;
            } else {
                headCtrl.order = $scope.getDirection() === 'md-asc' ? $scope.orderBy : `-${$scope.orderBy}`;
            }

            if (angular.isFunction(headCtrl.onReorder)) {
                $mdUtil.nextTick(() => {
                    headCtrl.onReorder(headCtrl.order);
                });
            }

            $scope.$applyAsync();
        }

        function updateColumn(index, column) {
            tableCtrl.$$columns[index] = column;

            if (column.numeric) {
                $element.addClass('md-numeric');
            } else {
                $element.removeClass('md-numeric');
            }
        }

        function disableSorting() {
            detachSortIcon();
            $element.removeClass('md-sort');
            $element.off('click', setOrder);
        }

        function enableSorting() {
            attachSortIcon();
            $element.addClass('md-sort');
            $element.on('click', setOrder);
        }

        $scope.getDirection = function () {
            if (isActive()) {
                return headCtrl.order.charAt(0) === '-' ? 'md-desc' : 'md-asc';
            }

            return $attrs.mdDesc === '' || $scope.$eval($attrs.mdDesc) ? 'md-desc' : 'md-asc';
        };

        $scope.$watch(isActive, (active) => {
            if (active) {
                $element.addClass('md-active');
            } else {
                $element.removeClass('md-active');
            }
        });

        $scope.$watch(getIndex, (index) => {
            updateColumn(index, { 'numeric': isNumeric() });
        });

        $scope.$watch(isNumeric, (numeric) => {
            updateColumn(getIndex(), { 'numeric': numeric });
        });

        $scope.$watch('orderBy', (newValue) => {
            if (newValue) {
                if (!$element.hasClass('md-sort')) {
                    enableSorting();
                }
            } else if ($element.hasClass('md-sort')) {
                disableSorting();
            }
        });
    }

    function compile(tElement) {
        tElement.addClass('md-column');
        return postLink;
    }

    return {
        compile,
        require: ['^^mdHead', '^^mdTable'],
        restrict: 'A',
        scope: {
            numeric: '=?mdNumeric',
            orderBy: '@?mdOrderBy'
        }
    };
}
