import controller from './message-box.controller';

const template = require('./message-box.html');

export default {
    selector: 'dtMessageBox',
    options: {
        bindings: {
            modifier: '@'
        },
        transclude: true,
        template,
        controller
    }
};
