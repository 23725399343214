import angular from 'angular';

import ApplicationHelpController from './popups/application-help/controller';
import ApplicationHelpTemplate from './popups/application-help/template.html';
import FeedbackFormController from './popups/feedback-form/controller';
import FeedbackFormTemplate from './popups/feedback-form/template.html';

/* @ngInject */
export default class ContentHeaderController {
    constructor($mdPanel, ProjectManager) {
        this.$mdPanel = $mdPanel;
        this.ProjectManager = ProjectManager;

        this.showFeedbackForm = this.showFeedbackForm.bind(this);
    }

    $onInit() {
        if (!this.stateTitle) {
            this.stateTitle = 'Specify State Title';
        }
    }

    showHelpMenu(ev) {
        this.isActive = true;
        this.triggeredBy = ev;

        const panelPosition = this.$mdPanel.newPanelPosition()
            .relativeTo(document.querySelector('#app-menu-trigger'))
            .addPanelPosition(this.$mdPanel.xPosition.ALIGN_END, this.$mdPanel.yPosition.BELOW);

        const config = {
            attachTo: angular.element(document.querySelector('.application-container')),
            controller: ApplicationHelpController,
            controllerAs: '$ctrl',
            template: ApplicationHelpTemplate,
            targetEvent: this.triggeredBy,
            panelClass: 'md-panel--arrow-top',
            position: panelPosition,
            openFrom: this.triggeredBy,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            locals: {
                onShowFeedbackForm: this.showFeedbackForm
            },
            onRemoving: () => {
                this.isActive = false;
            }
        };

        this.$mdPanel.open(config);
    }

    showFeedbackForm(mdPanelRef) {
        mdPanelRef.close().then(() => {
            this.isActive = true;

            const panelPosition = this.$mdPanel.newPanelPosition()
                .relativeTo(document.querySelector('#app-menu-trigger'))
                .addPanelPosition(this.$mdPanel.xPosition.ALIGN_END, this.$mdPanel.yPosition.BELOW);

            const config = {
                attachTo: angular.element(document.querySelector('.application-container')),
                controller: FeedbackFormController,
                controllerAs: '$ctrl',
                template: FeedbackFormTemplate,
                targetEvent: this.triggeredBy,
                panelClass: 'md-panel--arrow-top dt-feedback-popup',
                position: panelPosition,
                openFrom: this.triggeredBy,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                onRemoving: () => {
                    this.isActive = false;
                }
            };

            this.$mdPanel.open(config);
        });
    }
}
