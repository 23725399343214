import { getResourceName } from 'services/api/helper';
import { cloneEntity } from 'services/utils';
import { States } from '../../app.router';

/* @ngInject */
export default function projectsRouter($stateProvider) {
    $stateProvider
        .state(States.SERVICE_ACCOUNTS, {
            url: '/serviceaccounts',
            views: {
                'content@main': {
                    component: 'dtServiceAccountsPage'
                }
            }
        })
        .state(States.SERVICE_ACCOUNT_DETAILS, {
            url: '/{serviceAccountId}',
            params: {
                serviceAccount: null
            },
            resolve: {
                serviceAccount: /* @ngInject */ (ServiceAccountService, $stateParams, ToastService) => {
                    if ($stateParams.serviceAccount && $stateParams.serviceAccount.id === $stateParams.serviceAccountId) { // eslint-disable-line max-len
                        return cloneEntity($stateParams.serviceAccount);
                    }

                    return ServiceAccountService.getServiceAccount(
                        getResourceName(
                            ['projects', $stateParams.projectId],
                            ['project.serviceAccounts', $stateParams.serviceAccountId]
                        )
                    ).catch((serverResponse) => {
                        ToastService.showSimpleTranslated('serviceaccount_not_found', {
                            serverResponse
                        });
                        return null;
                    });
                }
            },
            onEnter: /* @ngInject */ (serviceAccount, $state, $stateParams) => { // eslint-disable-line consistent-return,max-len
                if (!serviceAccount) {
                    return $state.target(States.SERVICE_ACCOUNTS, {
                        projectId: $stateParams.projectId
                    });
                }
            },
            component: 'dtServiceAccountDetailsPage'
        });
}
