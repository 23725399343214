import Config from './data-connectors.router';
import options from './data-connectors.component';
import DataConnectorComponent from './data-connector';
import HTTPHeadersComponent from './http-headers';

const Component = {
    selector: 'dtDataConnectorsPage',
    options
};

export {
    Config,
    Component,
    HTTPHeadersComponent,
    DataConnectorComponent
};
