/* @ngInject */
export default class SearchAutocompleteController {
    constructor(EventEmitter) {
        this.eventEmitter = EventEmitter;
    }

    getText(item) {
        if (this.textKey) {
            return item[this.textKey];
        }
        return item;
    }

    itemSelected() {
        this.onSelected(
            this.eventEmitter({
                item: this.selectedItem
            })
        );
    }

    queryChanged() {
        this.onChange(
            this.eventEmitter({
                query: this.query
            })
        );
    }

    search() {
        return this.onSearch(
            this.eventEmitter({
                query: this.query
            })
        );
    }
}
