import controller from './humidity.controller';

const template = require('./humidity.html');

export default {
    bindings: {
        device: '<',
        onPublishEvent: '&'
    },
    controller,
    template
};
