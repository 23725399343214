/* @ngInject */
import { 
    PROJECT_CHANGED,
    FLAGS_CHANGED
} from 'services/StudioEvents';

// The custom FeatureFlagDirective doesn't work when there
// are spaces in the feature flag name. This normalized the
// name by removing all ":", replacing spaces with "_", and
// finally lowercasing.
//
// As an example "Billing: Subscription management" becomes
// "billing_subscription_management"
function normalizeFlagName(flag) {
    return flag.
        replaceAll(":", "").
        replaceAll(" ", "_").
        toLowerCase()
}

/**
 * @constructor
 * @param {FeatureFlagsApiService} FeatureFlagsApiService
 * @param {$rootScope} $rootScope
 * @param {ProjectManager} ProjectManager
 * @ngInject
 */
function featureFlagsFactory(FeatureFlagsApiService, $rootScope, ProjectManager) {

    const currentFlagsSet = new Set();
    let hasLoadedFlags = false;

    // Used to prevent loading the same flags repeatedly
    let currentProjectId;

    /**
     * @param {Flag[]} flags
     */
    function updateFlags(flags) {
        // Clear the old flags
        currentFlagsSet.clear();

        // Set the new flags, but normalize them first
        flags.
            map(f => normalizeFlagName(f.name)).
            forEach(flag => currentFlagsSet.add(flag))

        hasLoadedFlags = true;
        $rootScope.$broadcast(FLAGS_CHANGED);
    }

    function loadFlags() {
        const project = ProjectManager.currentProject;
        if (project?.id === currentProjectId) {
            return;
        }
        currentProjectId = project.id;
        FeatureFlagsApiService.flags({
            project: project.name,
            organization: project.organization
        })
            .then(response => {
                updateFlags(response.activeFlags);
            });
    }

    loadFlags();

    $rootScope.$on(PROJECT_CHANGED, loadFlags);


    return {
        isActive(feature) {
            if (!feature) {
                return true;
            }
            return currentFlagsSet.has(feature);
        },
        onLoaded() {
            return new Promise((resolve) => {
                if (hasLoadedFlags) {
                    resolve();
                    return;
                }
                const deregister = $rootScope.$on(FLAGS_CHANGED, () => {
                    deregister();
                    resolve();
                });
            });
        }
    };
}

export default function featureFlagsProvider() {
    this.$get = featureFlagsFactory;
}
