import { STATE_TRANSITION_START_EVENT } from 'services/StudioEvents';

/* @ngInject */
export default class ApplicationHelpController {
    constructor(onShowFeedbackForm, mdPanelRef, $scope) {
        this.onShowFeedbackForm = onShowFeedbackForm;
        this.mdPanelRef = mdPanelRef;
        this.docsUrl = 'https://support.disruptive-technologies.com/';

        this.unbindEventListener = $scope.$root.$on(STATE_TRANSITION_START_EVENT, () => {
            this.mdPanelRef.close();
            this.unbindEventListener();
        });
    }

    showFeedbackForm() {
        this.onShowFeedbackForm(this.mdPanelRef);
    }
}
