import Config from './organization-billing.router';
import options from './organization-billing.component';

const Component = {
    selector: 'dtBillingPage',
    options
};

export {
    Config,
    Component
};
