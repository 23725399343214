import Config from './new-user-flow.router';
import options from './new-user-flow.component';

const Component = {
    selector: 'dtNewUserFlow',
    options
};

export {
    Config,
    Component
};
