
import { UPDATE_DEVICE } from 'services/Permissions';
import { RANGE_LABEL_WEEK, SENSOR_GRAPH_RANGES } from 'services/charting/constants';
import { fields } from '../rules/rules-detail/rules-detail.controller';

export const CONNECTIVITY_GRAPH_RANGE_STATE_KEY = 'sensor:connectivityGraphRange';
export const CCON_GRAPH_RANGE_STATE_KEY = 'ccon:cconGraphRange';
/* @ngInject */
export default class ActiveAlertsController {
    
    constructor($scope, AlertsAndRulesService, SensorService, FeatureFlags, UserPreferencesManager, RoleManager, RulesService, StateService) {
        this.$scope = $scope;
        this.AlertsAndRulesService = AlertsAndRulesService;
        this.SensorService = SensorService;
        this.FeatureFlags = FeatureFlags;
        this.UserPreferencesManager = UserPreferencesManager;
        this.RoleManager = RoleManager;
        this.RulesService = RulesService;
        this.StateService = StateService;
    }
    
    $onInit() {
        this.fields = fields;
        this.ranges = SENSOR_GRAPH_RANGES
        this.loadedAlerts = false

        this.StateService.setItem(CONNECTIVITY_GRAPH_RANGE_STATE_KEY, 'Week');
        this.StateService.setItem(CCON_GRAPH_RANGE_STATE_KEY, 'Week');

        const listActiveAlertsPromise = this.AlertsAndRulesService.listActiveAlerts().then(response => {
            const alerts = response.data;
            alerts.forEach(alert => {
                alert.showRangeDropdown = false;
                alert.currentRange = RANGE_LABEL_WEEK;
                alert.events.sort((a, b) => {
                    return new Date(b.createTime) - new Date(a.createTime);
                });
            });

            const deviceIds = alerts.map(alert => alert.device.split('/')[3]);

            // Return the inner promise so it can be chained
            return this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
                alerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.device = device;
                });
                alerts.sort((a, b) => {
                    return new Date(b.createTime) - new Date(a.createTime);
                });
                this.alerts = alerts;    
            });
        });

        Promise.all([listActiveAlertsPromise]).then(() => {
            this.loadedAlerts = true;
            this.$scope.$applyAsync();
        }).catch(error => {
            console.error('An error occurred:', error); // eslint-disable-line no-console
        });

    }

    get canCreate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }
 
    $onDestroy() {}

}
