import _cloneDeep from 'lodash/cloneDeep';
import { RESERVED_LABEL_KEYS } from 'services/SensorHelper';
import { hasOwnProperty } from 'services/utils';

const ROW_GREEN_CSS_CLASS = 'row--success';
const ROW_RED_CSS_CLASS = 'row--warning';
const CELL_GREEN_CSS_CLASS = 'cell--success';
const CELL_RED_CSS_CLASS = 'cell--warning';

/* @ngInject */
export default class EditLabelsPopupController {
    constructor(DialogService, ProjectManager, IAMService, ToastService, sensors, batchUpdateLabels, AnalyticsService) {
        this.DialogService = DialogService;
        this.ProjectManager = ProjectManager;
        this.IAMService = IAMService;
        this.ToastService = ToastService;
        this.sensors = sensors;
        this.batchUpdateLabels = batchUpdateLabels;
        this.AnalyticsService = AnalyticsService;

        this.labelKey = '';
        this.labelValue = '';

        this.devices = sensors.map(sensor => sensor.name);
        this.addLabels = {};
        this.removeLabels = [];

        this.updating = false;

        this.labels = sensors.reduce((acc, sensor) => {
            Object.keys(sensor.labels)
                .filter(key => !RESERVED_LABEL_KEYS.includes(key))
                .forEach((key) => {
                    const value = sensor.labels[key];
                    if (acc[key]) {
                        if (!acc[key].multiple && acc[key].value !== value) {
                            acc[key].value = '';
                            acc[key].multiple = true;
                        }
                    } else {
                        acc[key] = {
                            value,
                            added: false,
                            multiple: false,
                            deleted: false
                        };
                    }
                });
            return acc;
        }, {});

        this.originalLabels = _cloneDeep(this.labels);
    }

    get hasLabels() {
        return Object.keys(this.labels).length;
    }

    get hasUpdates() {
        return !this.updating && (Object.keys(this.addLabels).length || this.removeLabels.length);
    }

    addLabel() {
        if (this.removeLabels.includes(this.labelKey)) {
            this.removeLabels = this.removeLabels.filter(key => key !== this.labelKey);
        }
        let added = true;
        if (hasOwnProperty(this.labels, this.labelKey) || hasOwnProperty(this.originalLabels, this.labelKey)) {
            added = false;
        }
        this.labels[this.labelKey] = {
            value: this.labelValue,
            multiple: false,
            added,
            deleted: false
        };
        this.addLabels[this.labelKey] = this.labelValue;
        this.labelKey = '';
        this.labelValue = '';
    }

    updateLabel(key, value) {
        this.addLabels[key] = value;
    }

    removeLabel(key) {
        if (!this.labels[key].deleted) {
            if (hasOwnProperty(this.addLabels, key)) {
                delete this.addLabels[key];
                if (!this.labels[key].added) {
                    this.removeLabels.push(key);
                    this.labels[key] = this.originalLabels[key];
                    this.labels[key].deleted = true;
                } else {
                    delete this.labels[key];
                }
            } else {
                this.removeLabels.push(key);
                this.labels[key].deleted = true;
            }
        } else {
            this.removeLabels = this.removeLabels.filter(item => item !== key);
            this.labels[key].deleted = false;
        }
    }

    update() {
        const hasAdded = Object.keys(this.addLabels).some(key => this.labels[key].added);
        const hasEdited = Object.keys(this.addLabels).some(key => !this.labels[key].added);
        const hasRemoved = !!this.removeLabels.length;
        if (hasAdded) {
            this.AnalyticsService.trackEvent("device.label.bulk_added");
        }
        if (hasEdited) {
            this.AnalyticsService.trackEvent("device.label.bulk_updated");
        }
        if (hasRemoved) {
            this.AnalyticsService.trackEvent("device.label.bulk_deleted");
        }
        
        this.updating = true;
        this.batchUpdateLabels({
            devices: this.devices,
            addLabels: this.addLabels,
            removeLabels: this.removeLabels
        })
            .then(() => {
                this.DialogService.hide();
            })
            .finally(() => {
                this.updating = false;
            });
    }

    getLabelRowClasses(key) {
        return {
            [ROW_GREEN_CSS_CLASS]: this.labels[key].added,
            [ROW_RED_CSS_CLASS]: this.labels[key].deleted
        };
    }

    getLabelValueCellClasses(key) {
        return {
            [CELL_GREEN_CSS_CLASS]: !this.labels[key].added && this.addLabels[key],
            [CELL_RED_CSS_CLASS]: !this.labels[key].added && this.addLabels[key] === ''
        };
    }

    cancel() {
        this.DialogService.cancel();
    }
}
