import activityTimerImage from '../../../../../assets/images/motion-activity-timer-example.svg';

/* @ngInject */
export default class ActivityTimerInfoController {
    constructor(DialogService) {
        this.DialogService = DialogService;
        this.onInit();
    }

    onInit() {
        this.activityTimerImage = activityTimerImage;
    }

    closeModal() {
        this.DialogService.cancel();
    }
}
