import controller from './copyable-input.controller';

const template = require('./copyable-input.html');

export default {
    bindings: {
        label: '@',
        value: '@',
        showLabel: '<',
        supportLink: '@'
    },
    template,
    controller
};
