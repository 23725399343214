import { getTemperatureUnitSuffix, getHoursMinutesSecondsFormat } from 'services/utils';

export const temperature = {
    title: 'Temperature Sensor',
    titlePlural: 'Temperature Sensors',
    filter: 'temperature',
    icon: 'temperature',
    prop: 'temperature.value',
    type: 'number',
    get format() {
        return `%s${getTemperatureUnitSuffix()}`;
    },
    history: {
        eventType: 'temperature'
    },
    variants: {
        // standard: 'Standard',
        // secondGeneration: 'Standard (2nd Gen)',
        // en12830: 'EN12830',
        // industrial: 'Industrial',
    }
};

export const proximity = {
    title: 'Proximity Sensor',
    titlePlural: 'Proximity Sensors',
    filter: 'proximity',
    icon: 'proximity',
    prop: 'objectPresent.state',
    type: 'enum',
    format: {
        UNKNOWN: 'Unknown',
        PRESENT: 'Closed',
        NOT_PRESENT: 'Open'
    },
    history: {
        eventType: 'objectPresent'
    },
    variants: {
        proximity: 'Standard',
        proximityCounter: 'Counting'
    }
};

export const touch = {
    title: 'Touch Sensor',
    titlePlural: 'Touch Sensors',
    filter: 'touch',
    icon: 'touch',
    prop: 'touch.updateTime',
    type: 'timestamp',
    get format() {
        return `${getHoursMinutesSecondsFormat()}`;
    },
    history: {
        eventType: 'touch'
    },
    variants: {
        touch: 'Standard',
        touchCounter: 'Counting'
    }
};

export const humidity = {
    title: 'Humidity Sensor',
    titlePlural: 'Humidity Sensors',
    filter: 'humidity',
    icon: 'humidity',
    prop: 'humidity.relativeHumidity|humidity.temperature',
    type: 'humidity',
    get format() {
        return `%h%RH %t${getTemperatureUnitSuffix()}`;
    },
    history: {
        eventType: 'humidity'
    },
    variants: {
        // standard: 'Standard',
    }
};

export const waterDetector = {
    title: 'Water Detector Sensor',
    titlePlural: 'Water Detector Sensors',
    filter: 'waterDetector',
    icon: 'waterDetector',
    prop: 'waterPresent.state',
    type: 'enum',
    format: {
        PRESENT: 'Water detected',
        NOT_PRESENT: 'No water detected'
    },
    history: {
        eventType: 'waterPresent'
    },
    variants: {
        // standard: 'Standard',
    }
}

export const motion = {
    title: 'Motion Sensor',
    titlePlural: 'Motion Sensors',
    filter: 'motion',
    icon: 'motion',
    prop: 'motion.state',
    type: 'enum',
    format: {
        MOTION_DETECTED: 'Motion Detected',
        NO_MOTION_DETECTED: 'No Motion Detected'
    },
    history: {
        eventType: 'motion'
    },
    variants: {}
};

export const co2 = {
    title: 'CO2 Sensor',
    titlePlural: 'CO2 Sensors',
    filter: 'co2',
    icon: 'co2',
    prop: 'co2.ppm|humidity.relativeHumidity|humidity.temperature|pressure.pascal',
    type: 'co2',
    get format() {
        return `%d PPM | %h%RH | %t${getTemperatureUnitSuffix()} | %p hPa`;
    },
    history: {
        eventType: 'co2'
    },
    variants: {}
};

export const deskOccupancy = {
    title: 'Desk Occupancy Sensor',
    titlePlural: 'Desk Occupancy Sensors',
    filter: 'deskOccupancy',
    icon: 'deskOccupancy',
    prop: 'deskOccupancy.state',
    type: 'enum',
    format: {
        OCCUPANCY_UNKNOWN: 'Unknown',
        OCCUPIED: 'Occupied',
        NOT_OCCUPIED: 'Not occupied'
    },
    history: {
        eventType: 'deskOccupancy'
    },
    variants: {}
};

export const contact = {
    title: 'Door & Window Sensor',
    titlePlural: 'Door & Window Sensors',
    filter: 'contact',
    icon: 'contact',
    prop: 'contact.state',
    type: 'enum',
    format: {
        UNKNOWN: 'Unknown',
        OPEN: 'Open',
        CLOSED: 'Closed'
    },
    history: {
        eventType: 'contact'
    },
    variants: {}
};

export const ccon = {
    title: 'Cloud Connector',
    titlePlural: 'Cloud Connectors',
    filter: 'ccon',
    icon: 'cloud-connector',
    prop: 'connectionStatus.connection',
    type: 'enum',
    format: {
        OFFLINE: 'OFFLINE',
        ETHERNET: 'ETHERNET',
        CELLULAR: 'CELLULAR'
    },
    variants: {}
};

export const proximityCounter = {
    title: 'Proximity Counter Sensor',
    titlePlural: 'Proximity Counting Sensors',
    filter: 'proximityCounter',
    icon: 'proximityCounter',
    prop: 'objectPresentCount.total',
    type: 'integer',
    get format() {
        return `%s state changes`;
    },
    history: {
        eventType: 'objectPresentCount'
    }
}

export const touchCounter = {
    title: 'Touch Counter Sensor',
    titlePlural: 'Touch Counting Sensors',
    filter: 'touchCounter',
    icon: 'touchCounter',
    prop: 'touchCount.total',
    type: 'integer',
    get format() {
        return `%s touches`;
    },
    history: {
        eventType: 'touchCount'
    }
}

// TILT-SUPPORT
export const tilt = {
    title: 'Tilt Sensor',
    titlePlural: 'Tilt Sensors',
    filter: 'tilt',
    icon: 'tilt',
    prop: 'prototypeData.fields',
    type: 'tilt',
    get format() {
        return ``;
    },
    history: {
        eventType: 'prototypeData'
    }
};

export const analog = {
    title: 'Analog Sensor',
    titlePlural: 'Analog Sensors',
    filter: 'analog',
    icon: 'analog',
    prop: 'voltage.channel1|voltage.channel2',
    type: 'analog',
    get format() {
        return `%t${getTemperatureUnitSuffix()} | %p PSI`;
    },
    history: {
        eventType: 'voltage'
    }
};
