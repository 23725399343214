import controller from './proximity.controller';

const template = require('./proximity.html');

export default {
    bindings: {
        device: '<',
        onPublishEvent: '&'
    },
    controller,
    template
};
