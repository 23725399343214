/* @ngInject */
export default class ShowSecretPopupController {
    constructor(DialogService, $timeout, keyId, secret) {
        this.DialogService = DialogService;
        this.keyId = keyId;
        this.secret = secret;
    }

    cancel() {
        this.DialogService.cancel();
    }
}
