import moment from 'moment';
import { getHistoryChartType, getHistoryEventType } from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { AbstractChartController } from '../chart-abstract-base';

/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartAnalogController extends AbstractChartController {
    constructor(
        SensorEventsLoader,
        ToastService,
        $scope,
        UserPreferencesManager,
        EventEmitter,
    ) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
        this.UserPreferencesManager = UserPreferencesManager;
    }

    // Overrides AbstractChartController
    getEventTypes() {
        return [this.eventType];
    }

    // Overrides AbstractChartController
    getAggregationFields() {
        return [
            {"fieldName": "voltage.channel1", "type": "AVERAGE"},
            {"fieldName": "voltage.channel1", "type": "MIN"},
            {"fieldName": "voltage.channel1", "type": "MAX"},
            {"fieldName": "voltage.channel2", "type": "AVERAGE"},
            {"fieldName": "voltage.channel2", "type": "MIN"},
            {"fieldName": "voltage.channel2", "type": "MAX"},
            // For offline detection
            {"fieldName": "voltage.channel1", "type": "COUNT"},
        ];
    }

    // Overrides AbstractChartController
    getConfigPreset() {
        return ConfigPresets.Analog;
    }

    // Overrides AbstractChartController
    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.analogSeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax
        );
    }

    // Overrides AbstractChartController
    convertEvents(events) {
        const eventTimestamps = events.map(e => moment(e.data.voltage.updateTime).valueOf())

        this.plotBands = DataConverter.createOfflineBands(
            eventTimestamps,
            this.chartMin,
            this.chartMax
        );
        this.chartData = DataConverter.analog(events);

        return {
            data: this.chartData,
            plotBands: this.plotBands
        }
    }

    convertAggregated(aggregatedData) {
        const convertTemp = (voltage) => {
            if (voltage === null) {
                return null;
            }
            return DataConverter.celsiusToUserPreference(DataConverter.voltageToCelsius(voltage));
        }
        const convertPressure = (voltage) => {
            if (voltage === null) {
                return null;
            }
            return DataConverter.voltageToPSI(voltage);
        }

        this.chartData = aggregatedData.results[0].values.map(aggWindow => {
            return [
                moment(aggWindow.timeWindow).valueOf(),

                convertTemp(aggWindow.values["voltage.channel2_AVERAGE"]),
                convertPressure(aggWindow.values["voltage.channel1_AVERAGE"]),

                convertTemp(aggWindow.values["voltage.channel2_MIN"]),
                convertPressure(aggWindow.values["voltage.channel1_MIN"]),

                convertTemp(aggWindow.values["voltage.channel2_MAX"]),
                convertPressure(aggWindow.values["voltage.channel1_MAX"]),
            ]
        })

        this.plotBands = DataConverter.createAggregatedOfflineBands(
            aggregatedData.results[0].values,
            "voltage.channel1_COUNT",
        );
        
        return {
            data: this.chartData,
            plotBands: this.plotBands,
        }
    }

    // Overrides AbstractChartController
    syncAdditionalDataSeries() {
        // The underlying AbstractChart has two series: dataSeries, spacerSeries
        // The Analog chart adds an additional series for pressure
        // This need to be synced on xAxis changes
        this.chart.series[2].setData(this.chartData.map(item => [item[0], item[2]])) // Pressure

        // Temperature min/max
        this.chart.series[3].setData(this.chartData.map(item => [item[0], item[3], item[5]]))
        
        // Pressure min/max
        this.chart.series[4].setData(this.chartData.map(item => [item[0], item[4], item[6]]))
    }

    // Overrides AbstractChartController
    onStateEventReceived(eventData) { // eslint-disable-line no-unused-vars
        // TODO
    }

    adjustMinMaxValues(value) {
        if (value < this.spacerMin) {
            this.spacerMin = value;
        } else if (value > this.spacerMax) {
            this.spacerMax = value;
        }
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);
        super.$onInit();
    }
}
