import Resource from './Resource';

// A placeholder org id to use if the user has not yet selected a project,
// or the current project of the user has not yet resolved.
const PLACEHOLDER_ORG_ID = "-";

/* @ngInject */
export default class AnalyticsService {
    constructor($http, ProjectManager) {
        this.$http = $http;
        this.ApiService = new Resource($http);
        this.ProjectManager = ProjectManager;

        // Key is org id, and the value is an object where the key is the 
        // event name and the value is the event count. The main intention
        // with this structure is to ensure we send the event count batch
        // for the correct organization (since the user can switch orgs).
        //
        // Eg: {"orgA": {"event1": 2, "event2": 4}}
        this.events = {};

        // Start flushing events every 3 seconds.
        this.flush = this.flush.bind(this);
        setInterval(this.flush, 3000);
    }

    // Public.
    // Increment the counter for the given event name.
    trackEvent(eventName, count = 1) {
        // The org ID will not be set when Studio is initially loaded. It takes a little while
        // to resolve the default project, and perhaps the user has never selected a default
        // project. In this case, a placeholder is used. We'll try again when flushing.
        const orgId = this.getOrgId();
        
        // Ensure there's an entry for the org
        if (!this.events[orgId]) {
            this.events[orgId] = {};
        }
    
        // Increment the count of the event name for this org.
        this.events[orgId][eventName] = (this.events[orgId][eventName] || 0) + count;
    }
    
    // Private.
    // Flushes the stored events to the backend, and clears it for the next batch.
    flush() {

        // Bail early if there's nothing to flush.
        if (Object.keys(this.events).length === 0) {
            return;
        }

        // Flush one batch per org. If the org ID of a batch is a placeholder, it might have been
        // because the project had not yet resolved. Try to fetch it again if that's the case.
        Object.entries(this.events).forEach(([orgId, eventCounts]) => {
            const payload = {
                orgId: orgId === PLACEHOLDER_ORG_ID ? this.getOrgId() : orgId,
                eventCounts
            };

            this.ApiService.create(`analytics`, payload);
        })

        // Prepare for the next batch
        this.events = {};
    }

    // Private.
    // A helper function to get the id of the organization the user is in.
    // Will return a placeholder if the current organization has not yet been resolved 
    // (eg. user hasn't selected a project yet).
    getOrgId() {
        return this.ProjectManager.currentProject?.organization?.split("/")[1] ?? PLACEHOLDER_ORG_ID;
    }
}