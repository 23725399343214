/* @ngInject */
export default function onEnterDirectiveConfig() {
    function link($scope, $element, $attrs) {
        $element.on('keydown', ($ev) => {
            if ($ev.keyCode === 13) {
                if ($scope.$eval($attrs.considerSpecialKeys)) {
                    if (!$ev.shiftKey) {
                        $scope.$eval($attrs.onEnter);
                    }
                } else {
                    $scope.$eval($attrs.onEnter);
                }
            }
        });
    }

    return {
        restrict: 'A',
        link
    };
}
