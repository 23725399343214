const LAST_SEEN_ANNOUNCEMENT_KEY = 'last-seen-announcement'

// Increment this number to indicate a new announcement
const LATEST_ANNOUNCEMENT_NUMBER = 36;

/* @ngInject */
export default class AnnouncementManager {
    constructor(StudioProfileService) {
        this.StudioProfileService = StudioProfileService;
    }

    getLastSeenAnnouncementNumber() {
        const lastSeenAnnouncementNumber = this.StudioProfileService.get(LAST_SEEN_ANNOUNCEMENT_KEY);
        if (lastSeenAnnouncementNumber) {
            return lastSeenAnnouncementNumber
        }
        
        this.setUpToDate() // Set up to date for new users. Announcements will open automatically on next new announcement.
        
        return LATEST_ANNOUNCEMENT_NUMBER 
    }

    setUpToDate() {
        this.StudioProfileService.set(LAST_SEEN_ANNOUNCEMENT_KEY, LATEST_ANNOUNCEMENT_NUMBER)
    }

    get hasUnseenAnnouncements() {
        return this.getLastSeenAnnouncementNumber() < LATEST_ANNOUNCEMENT_NUMBER
    }
}
