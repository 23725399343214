import { UPDATE_DEVICE } from 'services/Permissions';

/* @ngInject */
export default class ThingNameController {
    constructor(
        EventEmitter,
        RoleManager
    ) {
        this.eventEmitter = EventEmitter;
        this.RoleManager = RoleManager;
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    $onChanges({ name }) {
        this.originalName = name.currentValue;
    }

    save() {
        if (this.name !== this.originalName) {
            this.onUpdate(
                this.eventEmitter({
                    patch: {
                        name: this.name
                    }
                })
            );
        }
    }
}
