import { getHighchartTimeFormat} from 'services/utils';

export default {
    chart: {
        animation: false,
        zoomType: 'x',
        resetZoomButton: {
            position: {
                x: 0,
                y: -40
            }
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: 0,
        marginTop: 40,
        marginBottom: 16,
        marginRight: 0,
        marginLeft: 40,
        height: 250,
    },
    title: {
        text: null
    },
    boost: {
        enabled: false,
    },
    plotOptions: {
        series: {
            boostThreshold: 50000
        }
    },
    yAxis: [
        {
            title: {
                text: null
            },
            alternateGridColor: '#E8ECF0'
        }
    ],
    xAxis: {
        ordinal: false,
        endOnTick: false,
        type: 'datetime',
        tickLength: 8,
        overscroll: 0,
        crosshair: true,
        minRange: 1000 * 60, // 1 minute max zoom
        maxPadding: 0,
        labels: {
            formatter: function() {
                const format = {
                    minute: `%b %e, ${getHighchartTimeFormat()}`, // "Jan 1, 00:00 or 12:00 PM"
                    hour: `%b %e, ${getHighchartTimeFormat()}`,   // "Jan 1, 00:00 or 12:00 PM"
                    day: '%a, %b %e',       // "Mon, Jan 1"
                    week: '%a, %b %e',      // "Mon, Jan 1"
                    month: '%b %Y',         // "Jan 2023"
                    year: '%b %Y'           // "Jan 2023"
                }
                return window.Highcharts.dateFormat(format[this.axis.tickPositions.info.unitName], this.value)
            }
        }
    },
    scrollbar: {
        enabled: true,
        height: 24,
    },
    tooltip: {
        enabled: false,
    },
    credits: {
        enabled: false
    },
    legend: {
        enabled: false
    }
};
