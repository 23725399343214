import moment from 'moment'
import {
    ORGANIZATION_CHANGED, 
    FETCHED_BILLING_INFO,
    SHOW_MISSING_BILLING_INFO_BADGE,
    REMOVE_MISSING_BILLING_INFO_BADGE,
    SHOW_OVERDUE_INVOICE_BADGE,
    REMOVE_OVERDUE_INVOICE_BADGE,
    SHOW_MISSING_BILLING_INFO_BANNER,
    REMOVE_MISSING_BILLING_INFO_BANNER,
    SHOW_OVERDUE_INVOICE_BANNER,
    REMOVE_OVERDUE_INVOICE_BANNER
} from 'services/StudioEvents';
import { CREATE_MEMBERSHIP } from 'services/Permissions';

/* @ngInject */
export default class BillingCheckHelper {
    constructor(IAMService, RoleManager, $rootScope, ProjectManager, StorageService) {
        this.IAMService = IAMService
        this.RoleManager = RoleManager
        this.$rootScope = $rootScope
        this.ProjectManager = ProjectManager
        this.StorageService = StorageService

        this.missingBillingInfo = false
        this.overdueInvoice = false
        this.hasActivatedSubscription = false

        this.$rootScope.$on(ORGANIZATION_CHANGED, () => { 
            // Reset badges and banners
            this.missingBillingInfo = false
            this.overdueInvoice = false
            this.hasActivatedSubscription = false
            this.broadcast()

            // Recheck for this organization
            this.check()
        })
    
        this.$rootScope.$on(FETCHED_BILLING_INFO, () => {
            // Billing page was loaded and info fetched, check if anything should be updated    
            this.check()
        })
    }

    get isOrganizationAdmin() {
        return this.RoleManager.hasOrganizationPermissionTo(CREATE_MEMBERSHIP);
    }

    // Do a check for missing billing info and overdue invoices
    // Billing info is prioritized, won't check overdue invoices if billing info is missing
    check() {
        if (this.ProjectManager.currentProject.organizationId === undefined) {
            return // No organization selected, skipping billing check
        }
        
        this.IAMService.getCustomerStatus().then((customerStatus) => {
            // For organization admins, check if the organization has billing information
            if (this.isOrganizationAdmin) {
                if (!customerStatus.hasBillingInformation) {
                    this.missingBillingInfo = true // Show "MISSING BILLING INFO" badge
                    // Check subscriptions to determine if full warning banner should be shown
                    this.checkSubscriptions()
                }
            }

            // Regardless of role, check if there are any overdue invoices
            switch (customerStatus.paymentStatus) {
                case "PAYMENT_OVERDUE":
                case "FEATURES_RESTRICTED":
                case "DEVICE_DEACTIVATION":
                case "CUSTOMER_ON_HOLD":
                    this.overdueInvoice = true // Show "OVERDUE INVOICE" banner for everybody (+ billing badge for admins)
                    break
                default:
                    this.overdueInvoice = false
                    break
            }
            this.broadcast()
        }).catch(error => {
            console.error(error) // eslint-disable-line no-console
        })
    }

    checkSubscriptions() {
        this.IAMService.listSubscriptions().then(response => {
            const subscriptions = response.data
            if (subscriptions.length > 0) {
                // Get the earliest subscription
                const earliestSubscription = subscriptions.reduce((a, b) => new Date(a.activatedTime) < new Date(b.activatedTime) ? a : b)
                // Avoid showing banner right after claiming, give a 2 day leeway before the full warning banner is shown
                if (moment().diff(moment(earliestSubscription.activatedTime), 'days') > 2) {
                    this.hasActivatedSubscription = true
                }
            }
        }).catch(error => {
            console.error(error) // eslint-disable-line no-console
        }).finally(() => {
            this.broadcast()
        })
    }

    // Determine what badges and banners that are shown to the user
    broadcast() {
        // Missing info is prioritized over overdue invoice 
        if (this.missingBillingInfo) {
            this.$rootScope.$broadcast(SHOW_MISSING_BILLING_INFO_BADGE)
            // Warning banner is only shown when the organization has a subscription
            if (this.hasActivatedSubscription) {
                this.$rootScope.$broadcast(SHOW_MISSING_BILLING_INFO_BANNER)
            }
        } else {
            this.$rootScope.$broadcast(REMOVE_MISSING_BILLING_INFO_BANNER)
            this.$rootScope.$broadcast(REMOVE_MISSING_BILLING_INFO_BADGE)
            
            if (this.overdueInvoice) {
                this.$rootScope.$broadcast(SHOW_OVERDUE_INVOICE_BANNER) // Shown for all users
                this.$rootScope.$broadcast(SHOW_OVERDUE_INVOICE_BADGE) // Only visible to organization admins
            } else {
                this.$rootScope.$broadcast(REMOVE_OVERDUE_INVOICE_BANNER)
                this.$rootScope.$broadcast(REMOVE_OVERDUE_INVOICE_BADGE)
            }
        }
    }

    // Try updating billing info 10 times with 3 second delay between each attempt
    setBillingInfo(info, attempt = 0) {
        this.IAMService.updateBillingInformation(info).then(() => {}).catch(error => {
            setTimeout(() => {
                if (attempt >= 10) {
                    return
                }
                this.setBillingInfo(info, attempt + 1)
            }, 3000);
            console.error(error) // eslint-disable-line no-console
        })
    }
}
