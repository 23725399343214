/**
 * DialogService class.
 * Use this service to create dialogs.
 */

import Resource from "services/api/Resource";
import Config from "../../services/config/local";

import {
    injectTypeIcon,
} from '../../services/SensorHelper';
import { applyInjectors } from "../../services/utils";

const injectComputedProps = entity => applyInjectors(entity, [
    injectTypeIcon,
]);


/* @ngInject */
export default class DeviceEmulator {
    constructor($http, ProjectManager, SensorService) {
        this.$http = $http;
        this.ProjectManager = ProjectManager;
        this.EmulatorService = new Resource($http, Config.emulator.version, Config.emulator.host);
        this.SensorService = SensorService;
    }



    deleteDevice(name) {
        return this.EmulatorService.remove(name);
    }

    /**
     * Fetch device from sensor api
     * @param {string} projectId
     * @param {string} deviceId
     */
    getDevice(projectId, deviceId) {
        return this.SensorService.getSensor(projectId, deviceId);
    }

    createDevice(data) {
        return this.EmulatorService.create(`projects/${this.ProjectManager.currentProjectId}/devices`, data)
            .then(injectComputedProps);
    }

    /**
     * @param  {} params
     * @returns {Promise}
     */
    listDevices(params) {
        return this.EmulatorService.list(`projects/${this.ProjectManager.currentProjectId}/devices`, params, 'devices')
    }

    /**
     * @param  {Device} sensor
     * @param  {TouchEvent | TemperatureEvent | ObjectPresentEvent} event
     * @returns {Promise}
     */
    publishEvent(device, event) {
        return this.EmulatorService.create(`${device.name}:publish`, event);
    }
}