import { DEFAULT_TOKEN, getPageSize, persistPageSize } from 'services/PaginationHelper';
import { CREATE_SERVICEACCOUNT, DELETE_SERVICEACCOUNT } from 'services/Permissions';
import { scrollContainerToActiveChild } from 'services/utils';
import { States } from '../../app.router';

const PAGE_SIZE_SUFFIX = 'serviceaccounts';

/* @ngInject */
export default class ServiceAccountsController {
    constructor(
        IAMService,
        ServiceAccountService,
        Loader,
        DialogService,
        ToastService,
        $q,
        $state,
        RoleManager,
        $timeout
    ) {
        this.IAMService = IAMService;
        this.ServiceAccountService = ServiceAccountService;
        this.Loader = Loader;
        this.DialogService = DialogService;
        this.toastService = ToastService;
        this.$q = $q;
        this.$state = $state;
        this.RoleManager = RoleManager;
        this.$timeout = $timeout;
    }

    get canCreateNew() {
        return this.RoleManager.can(CREATE_SERVICEACCOUNT);
    }

    get canDelete() {
        return this.RoleManager.can(DELETE_SERVICEACCOUNT);
    }

    get isDetailView() {
        return this.$state.is(States.SERVICE_ACCOUNT_DETAILS);
    }

    get isEmptyState() {
        return !this.Loader.isShowLoader() && this.serviceAccounts.length === 0;
    }

    get reachedQuotaLimit() {
        return this.serviceAccounts.length >= 10
    }

    isActive(id) {
        return this.$state.params.serviceAccountId === id;
    }

    $onInit() {
        this.query = '';
        this.labelsLoaded = false;
        this.addFormVisible = false;
        this.pageState = States.SERVICE_ACCOUNTS;

        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;

        this.requestParams = {
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        };

        this.serviceAccounts = [];

        this.Loader.promise = this.loadServiceAccounts()
            .then(() => this.$timeout(scrollContainerToActiveChild));
    }

    setPageToken({ pageToken }) {
        this.requestParams.pageToken = pageToken;
        this.Loader.promise = this.loadServiceAccounts();
    }

    setPageSize({ pageSize }) {
        persistPageSize(pageSize, PAGE_SIZE_SUFFIX);
        this.requestParams.pageToken = DEFAULT_TOKEN;
        this.requestParams.pageSize = pageSize;
        this.Loader.promise = this.loadServiceAccounts();
    }

    updateUser({ serviceAccount, patch }) {
        const promise = this.ServiceAccountService.updateServiceAccount(serviceAccount.name, patch)
            .then((updatedAccount) => {
                const accountRef = this.serviceAccounts.find(item => item.name === updatedAccount.name);
                Object.assign(accountRef, updatedAccount);

                this.toastService.showSimpleTranslated('service_account_was_updated');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('service_account_wasnt_updated', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    createServiceAccount() {
        this.processing = true;

        const promise = this.ServiceAccountService.createServiceAccount({
            displayName: this.name
        })
            .then((serviceAccount) => {
                this.serviceAccounts.unshift(serviceAccount);

                this.$state.go(States.SERVICE_ACCOUNT_DETAILS, {
                    serviceAccountId: serviceAccount.id,
                    serviceAccount
                });

                this.toastService.showSimpleTranslated('service_account_was_created');

                this.cancelForm();
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('service_account_wasnt_created', {
                    serverResponse
                });
            }).finally(() => {
                this.processing = false;
            });
        this.Loader.promise = promise;
        return promise;
    }

    showDeleteConfirmation({ serviceAccount }) {
        this.DialogService.confirm({
            title: 'Remove Service Account?',
            textContent: `Do you really want to remove "${serviceAccount.displayName}"?`
                + ' Any app using this Service Account will lose access.',
            ariaLabel: 'remove-service-account',
            ok: 'Remove',
            cancel: 'Cancel'
        }).then(() => {
            this.deleteServiceAccount(serviceAccount);
        }).catch(() => {});
    }

    deleteServiceAccount(serviceAccount) {
        this.Loader.promise = this.ServiceAccountService.deleteServiceAccount(serviceAccount.name)
            .then(() => {
                this.serviceAccounts = this.serviceAccounts.filter(user => user.name !== serviceAccount.name);

                this.toastService.showSimpleTranslated('service_account_was_removed');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('service_account_wasnt_removed', {
                    serverResponse
                });
            });
        this.$state.go(States.SERVICE_ACCOUNTS);
    }

    loadServiceAccounts() {
        return this.ServiceAccountService
            .serviceAccounts(this.requestParams)
            .then(({ data, nextPageToken }) => {
                this.currentPageSize = this.requestParams.pageSize;
                this.currentPageToken = this.requestParams.pageToken;
                this.nextPageToken = nextPageToken;
                this.serviceAccounts = data;
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('service_accounts_wasnt_fetched', {
                    serverResponse
                });
            });
    }

    showForm() {
        this.addFormVisible = true;
    }

    cancelForm() {
        this.name = null;

        this.addServiceAccountForm.$setPristine();
        this.addServiceAccountForm.$setUntouched();

        this.addFormVisible = false;
    }
}
