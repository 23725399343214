import moment from 'moment';

/* @ngInject */
export default class SensorEventsLoader {
    constructor(SensorService, $q, ProjectManager) {
        this.SensorService = SensorService;
        this.$q = $q;
        this.ProjectManager = ProjectManager;
    }

    async loadSince(deviceName, eventTypes, startTime, endTime = moment()) {
        const params = {
            startTime: startTime.format(),
            endTime: endTime.format(),
            eventTypes,
            pageToken: "",
            pageSize: 10000,
        };

        let events = [];

        do {
            const resp = await this.SensorService.events(deviceName, params) // eslint-disable-line no-await-in-loop
    
            events = events.concat(resp.data);
            params.pageToken = resp.nextPageToken;
        } while (params.pageToken !== "")

        // Reverse to get the events in graph order (oldest at index 0)
        return events.reverse();
    }

    /**
     * Returns a reasonable bucket size in seconds for value aggregations (eg. temperature, co2, etc).
     * 
     * @param {Moment} start The start time to load data from
     * @param {Moment} end The end time to load data to
     */
    aggregationBucketSizeSeconds(start, end) { // eslint-disable-line class-methods-use-this
        const days = end.diff(start, 'days');

        if (days <= 40)  { return 3600 * 1 }
        if (days <= 50)  { return 3600 * 2 }
        if (days <= 70)  { return 3600 * 4 }
        if (days <= 120) { return 3600 * 6 }
        if (days <= 250) { return 3600 * 8 }
        if (days <= 500) { return 3600 * 12 }
        if (days <= 730) { return 3600 * 24 }
        return 3600 * 48
    }

    async loadAggregatedData(deviceName, aggregationFields, startTime, endTime, windowSizeSeconds) {
        
        // Check if there is a project location, will return '' if not
        let timeLocation = this.ProjectManager.currentProjectTimeLocation

        // Use the browser's time location if there is no project location
        if (timeLocation === '' && typeof Intl !== "undefined") {
            timeLocation = Intl.DateTimeFormat?.().resolvedOptions().timeZone;
        }

        return this.SensorService.aggregatedEvents({
            devices: [deviceName],
            startTime: startTime.format(),
            endTime: endTime.format(),
            timeWindow: windowSizeSeconds,
            fields: aggregationFields,
            timeLocation,
        })
    }
}
