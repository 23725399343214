
import { NEW_USER_FLOW_FINISHED } from 'services/StudioEvents';
import { States } from '../../app.router';

/* @ngInject */
export default class ProjectsController {
    //  Project logic is moved into 'projects-modal'
    constructor(ProjectManager, $state, $rootScope) {
        this.ProjectManager = ProjectManager;
        this.$state = $state;
        this.$rootScope = $rootScope
    }

    $onInit() {
        this.$rootScope.$broadcast(NEW_USER_FLOW_FINISHED) // Handle user canceling the create organization flow by clicking the Studio logo
        // This page should only be served if no project is selected
        const currentProject = this.ProjectManager.currentProject.id;
        if (currentProject) {
            this.$state.go(States.SENSORS, {
                projectId: currentProject
            })
        }
    }
}
