import moment from 'moment';
import { getHoursMinutesSecondsFormat} from 'services/utils';

export default {
    chart: {
        type: 'xrange'
    },
    yAxis: [
        {
            min: 0,
            max: 1,
            categories: ['Detected', 'Not Detected'],
            labels: {
                rotation: -90
            }
        }
    ],
    tooltip: {
        enabled: true,
        shared: true,
        formatter() {
            return false;
        }
    },
    plotOptions: {
        xrange: {
            pointPadding: 0,
            minPointLength: 1,
            borderRadius: 2
        },
        series: {
            turboThreshold: 0,
            point: {
                events: {
                    mouseOver() {
                        const chart = this.series.chart;
                        const momentFormat = `MMM D, ${getHoursMinutesSecondsFormat()}`;
                        const startedAt =
                            this.options.startedAt || this.options.x;
                        const lastedFor =
                            this.options.lastedFor ||
                            this.options.x2 - this.options.x;
                        const fromStamp = `<span class="dt-highcharts-number">${moment(
                            startedAt
                        ).format(momentFormat)}</span>`; // eslint-disable-line max-len
                        const duration = `<span class="dt-highcharts-number">${moment
                            .duration(lastedFor)
                            .humanize()}</span>`; // eslint-disable-line max-len
                        if (!chart.lbl) {
                            chart.lbl = chart.renderer
                                .text('', 40, 20, true)
                                .addClass('highchart-custom-tooltip')
                                .add();
                        }
                        const currentCat = parseInt(this.options.y, 10);
                        const catName = this.series.yAxis.categories[currentCat];
                        const catNameHTML = `<span class="dt-highcharts-number">${catName}</span>`;
                        chart.lbl.show().attr({
                            text: `${catNameHTML} on ${fromStamp}, for ${duration}`
                        });
                    }
                }
            },
            events: {
                mouseOut() {
                    const chart = this.chart;
                    if (chart.lbl) {
                        chart.lbl.hide();
                    }
                }
            }
        }
    }
};
