import { cloneEntity } from 'services/utils';
import { States } from '../../app.router';

/* @ngInject */
export default function orgSettingsRouter($stateProvider) {
    $stateProvider
        .state(States.ORGANIZATION_SETTINGS, {
            url: '/organization-settings',
            views: {
                'content@main': {
                    component: 'dtOrganizationSettingsPage'
                }
            }
        })
        .state(States.ORGANIZATION_SETTINGS_MEMBER, {
            url: '/{memberId}',
            params: {
                member: null
            },
            resolve: {
                user: /* @ngInject */ (IAMService, $stateParams, ToastService) => {
                    return IAMService.allMembershipsForMember($stateParams.memberId).then((data) => {
                        const memberWithMemberships = cloneEntity($stateParams.member);
                        memberWithMemberships.memberships = data.members;
                        return memberWithMemberships
                    }).catch((serverResponse) => {
                        if ($stateParams.member) { // Prevent showing error message if user goes directly to the URL
                            ToastService.showSimpleTranslated('member_not_found', {
                                serverResponse
                            });
                        }
                        return null;
                    });
                }
            },
            onEnter: /* @ngInject */ (user, $state, $stateParams) => { // eslint-disable-line consistent-return
                if (!user) {
                    return $state.target(States.ORGANIZATION_SETTINGS, {
                        projectId: $stateParams.projectId
                    });
                }
            },
            component: 'dtOrganizationMemberDetailsPage'
        });

}
