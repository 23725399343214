import options from './multiple-devices.component';
import DashboardChart from '../dashboard-chart';
import LiveProximityCard from './live-proximity-card';
import LiveDeskOccupancyCard from './live-desk-occupancy-card';
import LiveMotionCard from './live-motion-card';

export default {
    selector: 'dtMultipleDevicesCard',
    options,
    DashboardChart,
    LiveProximityCard,
    LiveDeskOccupancyCard,
    LiveMotionCard
};
