import Resource from './Resource';
import { getResourceName } from './helper';

/* @ngInject */
export default class ServiceAccountService {
    constructor($http, ProjectManager) {
        this.$http = $http;
        this.ApiService = new Resource($http);
        this.ProjectManager = ProjectManager;

        this.getServiceAccount = this.ApiService.get;
        this.updateServiceAccount = this.ApiService.update;
        this.deleteServiceAccount = this.ApiService.remove;

        this.getKey = this.ApiService.get;
        this.deleteKey = this.ApiService.remove;
    }

    serviceAccounts(params) {
        return this.ApiService.list(getResourceName(
            ['projects', this.ProjectManager.currentProjectId],
            ['project.serviceAccounts']
        ), params, 'serviceAccounts');
    }

    createServiceAccount(data) {
        return this.ApiService.create(getResourceName(
            ['projects', this.ProjectManager.currentProjectId],
            ['project.serviceAccounts']
        ), data);
    }

    keys(serviceAccountName, params) {
        return this.ApiService.list(getResourceName(
            serviceAccountName,
            ['project.serviceAccount.keys']
        ), params, 'keys');
    }

    createKey(serviceAccountName, data = {}) {
        return this.ApiService.create(getResourceName(
            serviceAccountName,
            ['project.serviceAccount.keys']
        ), data);
    }
}
