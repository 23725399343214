import controller from './single-device.controller';

const template = require('./single-device.html');

export default {
    bindings: {
        card: '<',
        canUpdate: '<',
        duration: '<',
        zoomStartTime: '<',
        zoomEndTime: '<',
        onEditCard: '&',
        onToggleLegend: '&',
        onUpdateAppearance: '&',
        onRemove: '&'
    },
    template,
    controller
};
