import _isString from 'lodash/isString';
import _isPlainObject from 'lodash/isPlainObject';
import _snakeCase from 'lodash/snakeCase';
import Config from '../config/local';
import { getPropByKey } from '../utils';

export const extractData = ({ data }) => data;

export const nullOnError = () => null;

export const emptyArrayOnError = () => [];

export const getResourceUrl = configKey => getPropByKey(Config.api.resources, configKey);

export const getResourceName = (...pairs) => {
    const urlParts = pairs.map((pair) => {
        if (_isString(pair)) {
            return pair;
        }

        const [configKey, resourceId] = pair;
        const urlPart = [getResourceUrl(configKey)];
        if (resourceId) {
            urlPart.push(resourceId);
        }
        return urlPart;
    });
    return [].concat(...urlParts).join('/').replace('/:', ':');
};

export const buildApiUrl = (resourceUrl, version = Config.api.version, host = Config.api.host) => `${host}${version}/${resourceUrl}`;
export const buildApiUrlWithoutResource = (path, version = Config.api.version, host = Config.api.host) => `${host}${version}${path}`;


export const filterEmptyParams = (params = {}) => {
    const result = {};

    Object.keys(params)
        .filter(key => params[key])
        .forEach((key) => {
            result[key] = params[key];
        });

    return result;
};

export const buildUpdateMask = (obj, prefix = '') => Object.keys(obj)
    .map((key) => {
        const convertedKey = `${prefix}${_snakeCase(key)}`;
        if (_isPlainObject(obj[key])) {
            return buildUpdateMask(obj[key], `${convertedKey}.`);
        }
        return convertedKey;
    })
    .join(',');

// eslint-disable-next-line no-restricted-properties
export const createAttemptDelayer = (delay, increaseFactor) => attempt => delay * Math.pow(increaseFactor, attempt - 1);
