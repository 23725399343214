/* @ngInject */
export default class EntitiesAutocompleteController {
    constructor(EventEmitter, $element, $mdConstant) {
        this.eventEmitter = EventEmitter;
        this.componentNode = $element[0];
        this.$mdConstant = $mdConstant;

        this.onMdChipsClick = this.onMdChipsClick.bind(this);
        this.added = this.added.bind(this);
    }

    $onInit() {
        if (!this.entities) {
            this.entities = [];
        }

        this.separatorValues = [', ', ',', ' '];

        this.separatorKeys = [
            this.$mdConstant.KEY_CODE.ENTER,
            this.$mdConstant.KEY_CODE.COMMA,
            this.$mdConstant.KEY_CODE.SPACE
        ];

        this.mdChipsNode = this.componentNode.querySelector('.md-chips');

        if (this.mdChipsNode) {
            this.mdChipsNode.addEventListener('click', this.onMdChipsClick);
        }
    }

    $onDestroy() {
        if (this.mdChipsNode) {
            this.mdChipsNode.removeEventListener('click', this.onMdChipsClick);
        }
    }

    onMdChipsClick(e) {
        if (e.target === this.mdChipsNode) {
            const input = this.mdChipsNode.querySelector('input');
            if (input) {
                input.focus();
            }
        }
    }

    processMultipleChips(chip) {
        const added = this.separatorValues.some((separator) => {
            const split = chip.split(separator);
            if (split.length > 1) {
                split.forEach(this.added);
                return true;
            }
            return false;
        });

        if (!added) {
            this.added(chip);
        }

        return null;
    }

    getText(item) {
        if (this.textKey) {
            return item[this.textKey] || item;
        }
        return item;
    }

    added(entity) {
        if (!this.entities.includes(entity)) {
            this.entities.push(entity);
            this.onAdd(
                this.eventEmitter({
                    entity
                })
            );
            this.changed();
        }
    }

    removed(entity) {
        this.onRemove(
            this.eventEmitter({
                entity
            })
        );
        this.changed();
    }

    changed() {
        this.onChange(
            this.eventEmitter({
                entities: this.entities
            })
        );
    }

    search() {
        return this.onSearch(
            this.eventEmitter({
                query: this.query
            })
        );
    }
}
