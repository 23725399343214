import moment from 'moment';

/**
 * Application configuration class
 * @constructor
 */
/* @ngInject */
function AppConfig($mdThemingProvider, $mdDateLocaleProvider, $compileProvider, $mdGestureProvider) {
    if (NODE_ENV !== 'development') {
        $compileProvider.debugInfoEnabled(false);
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);
    }

    $mdGestureProvider.skipClickHijack();

    // Display date using local format. 'll' gives Oct 19, 2020 in Norway
    $mdDateLocaleProvider.formatDate = function (date) {
        return moment(date).format('ll') 
    };

    // Required for the component to know how to convert a string into a date instance
    $mdDateLocaleProvider.parseDate = function(dateString) {
        const date = moment(dateString, 'll', true);
        return date.isValid() ? date.toDate() : undefined;
    };

    // Week should start on Monday, not Sunday
    $mdDateLocaleProvider.firstDayOfWeek = 1;

    const customLightBlue = $mdThemingProvider.extendPalette('blue-grey', {
        '50': '#ECEFF1',
        '100': '#CFD8DC',
        '200': '#89bad6', // custom
        '300': '#90A4AE',
        '400': '#78909C',
        '500': '#207da9', // custom
        '600': '#2d79a5', // custom
        '700': '#455A64',
        '800': '#37474F',
        '900': '#263238',
        'contrastDefaultColor': 'light',
        'contrastLightColors': '400 500 600 700 800 900',
        'contrastStrongLightColors': '400 600 700 800 900'
    });

    const customRed = $mdThemingProvider.extendPalette('red', {
        '50': '#ffebee',
        '100': '#ffcdd2',
        '200': '#ef9a9a',
        '300': '#e57373',
        '400': '#E11F30', // custom
        '500': '#f44336',
        '600': '#e53935',
        '700': '#d32f2f',
        '800': '#c62828',
        '900': '#b71c1c',
        'A100': '#ff8a80',
        'A200': '#ff5252',
        'A400': '#ff1744',
        'A700': '#ff0008', // custom
        'contrastDefaultColor': 'light',
        'contrastDarkColors': '50 100 200 300 A100',
        'contrastStrongLightColors': '400 500 600 700 A200 A400 A700'
    });

    const customDark = $mdThemingProvider.extendPalette('teal', {
        '100': '#8fd5c0',
        '200': '#8fd5c0',
        '300': '#8fd5c0',
        '400': '#8fd5c0',
        '500': '#fff',
        '600': '#fff',
        '700': '#fff',
        '800': '#fff',
        '900': '#fff',
        'contrastDefaultColor': 'light'
    });

    $mdThemingProvider.definePalette('customLightBlue', customLightBlue);

    $mdThemingProvider.definePalette('customRed', customRed);

    $mdThemingProvider.definePalette('customDark', customDark);

    $mdThemingProvider.theme('default')
        .primaryPalette('customLightBlue')
        .accentPalette('customLightBlue')
        .warnPalette('customRed');

    $mdThemingProvider.theme('dark')
        .primaryPalette('customDark')
        .dark()
        .foregroundPalette['4'] = 'rgba(221, 221, 221, 1)';

    // $animateProvider.classNameFilter(/dt-animated/);
}

/**
 * EventEmitter is wrapper and used to ease migration in future to angular 2
 * @type {Object}
 */
const EventEmitter = {
    name: 'EventEmitter',
    definition: payload => ({ $event: payload })
};

export {
    AppConfig,
    EventEmitter
};
