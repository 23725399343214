import { DEFAULT_TOKEN, getPageSize, persistPageSize } from 'services/PaginationHelper';
import { noop, scrollContainerToActiveChild } from 'services/utils';
import { animateSensorIcon } from 'services/SensorHelper'
import { CREATE_EMULATOR, READ_EMULATOR, DELETE_EMULATOR } from 'services/Permissions';
import { INTEGRATIONS_DROPDOWN_OPEN_EVENT } from 'services/StudioEvents';

import { States } from '../../app.router';

const PAGE_SIZE_SUFFIX = 'dataconnectors';
const STATE_ANIMATION_DURATION = 3000;

const animateStateChange = (thingId) => {
    const sensorStateNode = document.querySelector(`.cell-${thingId}-state`);
    if (sensorStateNode) {
        setTimeout(() => {
            if (sensorStateNode.classList.contains('text-state--updated')) {
                return;
            }
            sensorStateNode.classList.add('text-state--updated');
            setTimeout(() => {
                sensorStateNode.classList.remove('text-state--updated');
            }, STATE_ANIMATION_DURATION);
        }, 0);
    }
};

const rippleOnSensor = (thingId) => {
    const sensorIconNode = document.querySelector(`.cell-${thingId}-icon`);
    if (sensorIconNode) {
        animateSensorIcon(sensorIconNode);
    }
};

/* @ngInject */
export default class EmulatorController {
    constructor(
        DeviceEmulator,
        SensorService,
        Loader,
        DialogService,
        ToastService,
        $q,
        $state,
        $timeout,
        $scope,
        $rootScope,
        AnalyticsService,
        RoleManager
    ) {
        this.EmulatorService = DeviceEmulator;
        this.SensorService = SensorService;
        this.Loader = Loader;
        this.DialogService = DialogService;
        this.toastService = ToastService;
        this.$q = $q;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.RoleManager = RoleManager;
        this.AnalyticsService = AnalyticsService;
        this.eventSubscription = null;
    }


    get isNewDevice() {
        return this.$state.params.deviceId === 'new';
    }

    get isEmptyState() {
        if (this.isNewDevice) {
            return false;
        }
        return this.loaded ? this.devices.length === 0 : false;
    }

    get isDetailView() {
        return this.$state.is(States.EMULATOR_DETAILS);
    }

    get canReadEmulator() {
        return this.RoleManager.can(READ_EMULATOR);
    }

    get canCreateEmulator() {
        return this.RoleManager.can(CREATE_EMULATOR);
    }

    get canDeleteEmulator() {
        return this.RoleManager.can(DELETE_EMULATOR);
    }

    get reachedQuotaLimit() {
        return this.devices.length >= 100
    }

    isActive(id) {
        return this.$state.params.deviceId === id;
    }

    $onInit() {
        this.loaded = false;

        // Ensure emulator is visible in dropdown menu
        this.$rootScope.$broadcast(INTEGRATIONS_DROPDOWN_OPEN_EVENT);

        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;

        this.requestParams = {
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        };

        this.devices = [];

        this.noop = noop;

        this.loadDevices()
            .then(() => this.$timeout(scrollContainerToActiveChild))
            .then(() => {
                this.eventSubscription = this.SensorService
                    .createObservableFromAllUpdates(this.devices.map(d => d.id))
                    .subscribe(event => this.onNewEvents(event));
            });
    }

    $onDestroy() {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    }

    // Handles API events
    onNewEvents(event) {
        const device = this.devices.find(d => d.name === event.targetName);
        if (device) {
            device.reported = {
                ...device.reported,
                ...event.data
            }
            rippleOnSensor(device.id);
            animateStateChange(device.id);
            this.$scope.$applyAsync();
        }
    }

    publishEvent({ device, event }) {
        const eventType = Object.keys(event)[0]
        this.AnalyticsService.trackEvent(`emulator.published_event.${eventType}`)

        return this.EmulatorService.publishEvent(device, event)
            .then(() => {
                this.toastService.showSimpleTranslated('emulator_event_was_published');
            })
            .catch(() => {
                this.toastService.showSimpleTranslated('emulator_event_wasnt_published');
            });
    }

    setPageToken({ pageToken }) {
        this.requestParams.pageToken = pageToken;
        return this.loadDevices();
    }

    setPageSize({ pageSize }) {
        persistPageSize(pageSize, PAGE_SIZE_SUFFIX);
        this.requestParams.pageToken = DEFAULT_TOKEN;
        this.requestParams.pageSize = pageSize;
        return this.loadDevices();
    }

    createDevice({ device }) {
        const promise = this.EmulatorService.createDevice(device)
            .then((newDevice) => {
                this.devices.unshift(newDevice);
                this.sortDevicesByName();
                this.$state.go(States.EMULATOR_DETAILS, {
                    deviceId: newDevice.id,
                    device: newDevice
                });
                this.toastService.showSimpleTranslated('emulated_device_was_created');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('emulated_device_wasnt_created', {
                    serverResponse
                });
            });
        
        this.AnalyticsService.trackEvent(`emulator.created.${device.type}`)

        this.Loader.promise = promise;
        return promise;
    }

    sortDevicesByName() {
        this.devices.sort((a, b) => a.labels.name < b.labels.name ? -1 : 1)
    }

    updateLabel({ device, key, value }) {
        return this.SensorService.updateLabel(device.name, key, value);
    }

    showDeleteConfirmation({ device }) {
        return this.DialogService.confirm({
            title: 'Delete Emulated Device?',
            textContent: `Do you really want to delete emulated device "${device.labels.name}"?`,
            ariaLabel: 'delete-device',
            ok: 'Delete',
            cancel: 'Cancel'
        }).then(() => {
            this.deleteDevice(device);
        });
    }


    /**
     * @param  {Device} device
     */
    deleteDevice(device) {
        this.AnalyticsService.trackEvent("emulator.deleted")

        this.Loader.promise = this.EmulatorService.deleteDevice(device.name)
            .then(() => {
                this.devices = this.devices.filter(d => d.id !== device.id);
                this.$state.go(States.EMULATOR);
                this.toastService.showSimpleTranslated('emulated_device_was_removed');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('emulated_device_wasnt_removed', {
                    serverResponse
                });
            });
    }

    loadDevices() {
        const promise = this.EmulatorService
            .listDevices(this.requestParams)
            .then(({ data, nextPageToken }) => {
                this.currentPageSize = this.requestParams.pageSize;
                this.currentPageToken = this.requestParams.pageToken;
                this.nextPageToken = nextPageToken;
                if (data.length > 0) {
                    this.loadDeviceMetaData(data)
                } else {
                    this.loaded = true
                }
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('emulated_devices_wasnt_loaded', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    loadDeviceMetaData(devices) {
        // Emulator API does not give us enough details about the device (missing reported), use device IDs to do a call to SensorService
        this.SensorService.sensors({ deviceIds: devices.map(device => device.id) }).then(({ data }) => {
            this.devices = data
            this.sortDevicesByName()
            this.loaded = true
        })
    }
}
