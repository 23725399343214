import { PROJECT_ADMINISTRATOR_PERMISSIONS } from 'services/Permissions';
import { cloneProject } from 'services/ProjectHelper';
import { States } from '../../app.router';

/* @ngInject */
export default function projectsRouter($stateProvider) {
    $stateProvider
        .state(States.PROJECTS, {
            url: '/projects?{query}',
            params: {
                query: {
                    value: null,
                    dynamic: true
                }
            },
            views: {
                content: {
                    component: 'dtProjectsPage'
                }
            },
            resolve: {
                projects: /* @ngInject */ (IAMService, $exceptionHandler) => {
                    return new Promise(resolve => {
                        IAMService.projects().then(({ data }) => {
                            resolve(data)
                        }).catch((e) => {
                            $exceptionHandler(e)
                            resolve(null)
                        })
                    })
                }
            },
            onEnter: /* @ngInject */ (projects, $state) => { // eslint-disable-line consistent-return
                // Any users that do not have access to any projects should be presented
                // with the option to create a new organization.
                if (projects.length === 0) {
                    return $state.target(States.NEW_USER_FLOW);
                } 
            }
        })
        .state(States.PROJECT_DETAILS, {
            url: '/{projectId}',
            params: {
                project: null,
                justCreated: false
            },
            resolve: {
                project: /* @ngInject */ (IAMService, $stateParams, ToastService) => {
                    if ($stateParams.project && $stateParams.project.id === $stateParams.projectId) {
                        return cloneProject($stateParams.project);
                    }

                    return IAMService.getProject($stateParams.projectId).catch((serverResponse) => {
                        ToastService.showSimpleTranslated('project_not_found', {
                            serverResponse
                        });
                        return null;
                    });
                }
            },
            onEnter: /* @ngInject */ (project, $state, $stateParams, ProjectManager, RoleManager) => { // eslint-disable-line consistent-return,max-len
                if (!project) {
                    return $state.target(States.PROJECTS);
                }
                ProjectManager.setCurrentProject(cloneProject(project));
                RoleManager.loadPermissions($stateParams.justCreated ? PROJECT_ADMINISTRATOR_PERMISSIONS : null);
            },
            component: 'dtProjectDetailsPage'
        });
}
