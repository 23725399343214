import angular from 'angular';

import IAMService from './IAMService';
import DashboardService from './DashboardService';
import SensorService from './SensorService';
import DataConnectorService from './DataConnectorService';
import ServiceAccountService from './ServiceAccountService';
import FeedbackService from './FeedbackService';
import ExportService from './ExportService';
import RulesApiService from './RulesApiService';
import FeatureFlagsApiService from './FeatureFlagsApiService';
import AnalyticsService from './AnalyticsService';
import * as Interceptors from './interceptors';


const dtApi = 'dt.api';
export default dtApi;

angular.module(dtApi, [])
    .config(Interceptors.RateLimitingInterceptor)
    .config(Interceptors.ServerErrorInterceptor)
    .service('FeedbackApi', FeedbackService)
    .service('IAMService', IAMService)
    .service('DashboardService', DashboardService)
    .service('SensorService', SensorService)
    .service('DataConnectorService', DataConnectorService)
    .service('ExportService', ExportService)
    .service('ServiceAccountService', ServiceAccountService)
    .service('RulesApiService', RulesApiService)
    .service('FeatureFlagsApiService', FeatureFlagsApiService)
    .service('AnalyticsService', AnalyticsService);
