import StackdriverErrorReporter from 'stackdriver-errors-js';
import Config from 'services/config/local';

/**
 * @constructor
 * @ngInject
 */
export default function StackdriverExceptionHandler($log) {
    if (Config.localDev) {
        // Ignore stackdriver logging while developing locally
        return () => {};
    }

    const reporter = new StackdriverErrorReporter();
    if (Config.stackdriverErrors.enabled) {
        reporter.start({
            service: "Studio",
            key: Config.stackdriverErrors.apiKey,
            projectId: Config.stackdriverErrors.projectId,
        });
    }
    return (exception, cause) => {
        if (Config.stackdriverErrors.enabled) {
            $log.warn(exception, cause);
            reporter.report(exception);
        } else {
            $log.error(exception, cause);
        }
    };
}
