import { hasOwnProperty } from 'services/utils';
import { createAttemptDelayer } from 'services/api/helper';
import { repeatRequest } from './helper';

const RETRY_COUNT_PROPERTY_NAME = '__RETRY_COUNT__';
const MAX_TRIES = 3;
const RETRY_DELAY = 1000; // 1s
const INCREASE_FACTOR = 2;

const getTryDelay = createAttemptDelayer(RETRY_DELAY, INCREASE_FACTOR);

/* @ngInject */
export default function ServerErrorInterceptor($httpProvider) {
    $httpProvider.interceptors.push(/* @ngInject */ ($q, $injector) => {
        function responseError(rejection) {
            if (rejection.status >= 500 && rejection.status < 600) {
                if (!hasOwnProperty(rejection.config, RETRY_COUNT_PROPERTY_NAME)) {
                    rejection.config[RETRY_COUNT_PROPERTY_NAME] = 0;
                }
                rejection.config[RETRY_COUNT_PROPERTY_NAME]++;
                if (rejection.config[RETRY_COUNT_PROPERTY_NAME] <= MAX_TRIES) {
                    return repeatRequest(
                        $injector,
                        rejection.config,
                        getTryDelay(rejection.config[RETRY_COUNT_PROPERTY_NAME])
                    );
                }
            }
            return $q.reject(rejection);
        }

        return {
            responseError
        };
    });
}
