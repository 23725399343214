export const CREATE_MEMBERSHIP = 'membership.create';
export const READ_MEMBERSHIP = 'membership.read';
export const UPDATE_MEMBERSHIP = 'membership.update';
export const DELETE_MEMBERSHIP = 'membership.delete';

export const READ_SENSOR = 'sensor.read';
export const UPDATE_SENSOR = 'sensor.update';
export const TRANSFER_SENSOR = 'sensor.transfer';

export const READ_DEVICE = 'device.read';
export const UPDATE_DEVICE = 'device.update';
export const TRANSFER_DEVICE = 'device.transfer';

export const CREATE_PROJECT = 'project.create';
export const READ_PROJECT = 'project.read';
export const UPDATE_PROJECT = 'project.update';
export const DELETE_PROJECT = 'project.delete';

export const READ_ORGANIZATION = 'organization.read';
export const UPDATE_ORGANIZATION = 'organization.update';

export const CREATE_DATACONNECTOR = 'dataconnector.create';
export const READ_DATACONNECTOR = 'dataconnector.read';
export const UPDATE_DATACONNECTOR = 'dataconnector.update';
export const DELETE_DATACONNECTOR = 'dataconnector.delete';

export const CREATE_SERVICEACCOUNT = 'serviceaccount.create';
export const READ_SERVICEACCOUNT = 'serviceaccount.read';
export const UPDATE_SERVICEACCOUNT = 'serviceaccount.update';
export const DELETE_SERVICEACCOUNT = 'serviceaccount.delete';

export const CREATE_SERVICEACCOUNT_KEY = 'serviceaccount.key.create';
export const READ_SERVICEACCOUNT_KEY = 'serviceaccount.key.read';
export const DELETE_SERVICEACCOUNT_KEY = 'serviceaccount.key.delete';

export const CREATE_EMULATOR = 'emulator.create';
export const READ_EMULATOR = 'emulator.read';
export const UPDATE_EMULATOR = 'emulator.update';
export const DELETE_EMULATOR = 'emulator.delete';

export const PROJECT_ADMINISTRATOR_PERMISSIONS = [
    CREATE_MEMBERSHIP,
    READ_MEMBERSHIP,
    UPDATE_MEMBERSHIP,
    DELETE_MEMBERSHIP,

    READ_SENSOR,
    UPDATE_SENSOR,

    READ_DEVICE,
    UPDATE_DEVICE,

    READ_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,

    CREATE_DATACONNECTOR,
    READ_DATACONNECTOR,
    UPDATE_DATACONNECTOR,
    DELETE_DATACONNECTOR,

    CREATE_SERVICEACCOUNT,
    READ_SERVICEACCOUNT,
    UPDATE_SERVICEACCOUNT,
    DELETE_SERVICEACCOUNT,

    CREATE_SERVICEACCOUNT_KEY,
    READ_SERVICEACCOUNT_KEY,
    DELETE_SERVICEACCOUNT_KEY,

    CREATE_EMULATOR, 
    READ_EMULATOR,
    UPDATE_EMULATOR,
    DELETE_EMULATOR
];
