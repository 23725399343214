import controller from './chart-range-selector.controller';

const template = require('./chart-range-selector.html');

export default {
    bindings: {
        processing: '<',
        ranges: '<',
        currentRange: '<',
        onSetRange: '&'
    },
    template,
    controller
};
