import { States } from '../../app.router';

/* @ngInject */
export default function billingRouter($stateProvider) {
    $stateProvider
        .state(States.ORGANIZATION_BILLING, {
            url: '/organization-billing',
            views: {
                'content@main': {
                    component: 'dtBillingPage'
                }
            }
        })

}
