import controller from './claim-devices.controller';

const template = require('./claim-devices.html');

export default {
    bindings: {
        onClose: '&'
    },
    controller,
    template
};
