import controller from './content-header.controller';

const template = require('./content-header.html');

export default {
    bindings: {
        stateTitle: '<'
    },
    template,
    controller,
    transclude: true
};
