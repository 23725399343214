import { truncateString } from 'services/utils';
import { SIMULATE_FULLSCREEN, STOP_SIMULATE_FULLSCREEN } from 'services/StudioEvents';
import imgLogo from 'file-loader!../../sass/dt-design-system/src/scss/assets/dt-logo--light.svg'; //eslint-disable-line
import ProjectDropDownController from './projects-modal/controller';
import ProjectDropDownTemplate from './projects-modal/template.html';
import QRModalController from './qr-modal/controller';
import QRModalTemplate from './qr-modal/template.html';
import LinksTemplate from './links-popover/template.html';
import FeedbackController from './feedback-popover/controller';
import FeedbackTemplate from './feedback-popover/template.html';
import { States } from '../../app.router';

/* @ngInject */
export default class HeaderController {
    constructor($state, $scope, ProjectManager, $mdPanel, $document, DialogService, AnnouncementManager) {
        this.$state = $state;
        this.$scope = $scope;
        this.ProjectManager = ProjectManager;
        this.$mdPanel = $mdPanel;
        this.$document = $document;
        this.DialogService = DialogService;
        this.AnnouncementManager = AnnouncementManager;
    }

    $onInit() {
        this.isMobileNav = false;
        this.imgLogo = imgLogo;

        this.$document.find('body').addClass('with-header');

        // Display the modal picker if user loads '/projects'
        if (this.$state.current.name === States.PROJECTS) {
            this.showProjectsModal(null);
        }

        this.panelRef = null;

        // Check if a new announcement badge should be visible
        this.newAnnouncementIndicator = this.AnnouncementManager.hasUnseenAnnouncements

        this.removeSimulateFullscreenListener = this.$scope.$root.$on(
            SIMULATE_FULLSCREEN, () => {
                this.$document.find('body').removeClass('with-header');
            }
        )
        this.removeStopSimulateFullscreenListener = this.$scope.$root.$on(
            STOP_SIMULATE_FULLSCREEN, () => {
                this.$document.find('body').addClass('with-header');
            }
        )
    }

    $onDestroy() {
        this.$document.find('body').removeClass('with-header');
        this.removeSimulateFullscreenListener()
        this.removeStopSimulateFullscreenListener()
    }

    get hasCurrentProject() {
        return this.ProjectManager.currentProjectId;
    }

    get viewingOrganizationPage() {
        return [States.ORGANIZATION_DETAILS, States.ORGANIZATION_SETTINGS, States.ORGANIZATION_BILLING].includes(this.$state.current.name);
    }

    showProjectsModal(ev) {

        this.DialogService.show({
            controller: ProjectDropDownController,
            controllerAs: '$ctrl',
            template: ProjectDropDownTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
        });
    }

    truncateProjectName(num) {
        const projectName = this.ProjectManager.currentProjectName;
        if (this.ProjectManager.currentProjectId) {
            return projectName ? truncateString(projectName, num) : "(Missing Project name)"
        } 
        return "Select Project"
    }

    truncateOrganizationName(num) {
        const organizationName = this.ProjectManager.currentProject.organizationDisplayName;
        return organizationName ? truncateString(organizationName, num) : "";
    }

    showQRModal(ev) {

        this.DialogService.show({
            controller: QRModalController,
            controllerAs: '$ctrl',
            template: QRModalTemplate,
            parent: document.body,
            targetEvent: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true,
        });
    }

    showUserInfo(ev) {
        const position = this.$mdPanel.newPanelPosition()
            .relativeTo(ev.target)
            .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW).withOffsetX('20px');
            
        const panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(ev.target)
            .withAnimation('md-panel--animation');

        const config = {
            attachTo: document.body,
            controller: () => {},
            controllerAs: '$ctrl',
            template: '<dt-user-popover class="popover" on-close="$ctrl.closeUserInfo()"></dt-user-popover>',
            panelClass: 'md-panel-container arrow',
            animation: panelAnimation,
            position,
            openFrom: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 100,
            locals: {
                closeUserInfo: this.closeUserInfo
            }
        };

        this.$mdPanel.open(config);
    }
    
    showLinksDialog(ev) {
        const position = this.$mdPanel.newPanelPosition()
            .relativeTo(ev.target)
            .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW).withOffsetX('20px');
            
        const panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(ev.target)
            .withAnimation('md-panel--animation');

        const config = {
            attachTo: document.body,
            controller: () => {},
            template: LinksTemplate,
            panelClass: 'md-panel-container arrow',
            animation: panelAnimation,
            position,
            openFrom: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 100,
        };

        this.$mdPanel.open(config);
    }

    closeUserInfo() {
        this.DialogService.cancel();
    }

    showFeedbackDialog(ev) {
        const position = this.$mdPanel.newPanelPosition()
            .relativeTo(ev.target)
            .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW).withOffsetX('20px');;
            
        const panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(ev.target)
            .withAnimation('md-panel--animation');

        const config = {
            attachTo: document.body,
            controller: FeedbackController,
            controllerAs: '$ctrl',
            template: FeedbackTemplate,
            panelClass: 'md-panel-container arrow',
            animation: panelAnimation,
            position,
            openFrom: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 100,
            locals: {
                onClose: this.closePanel.bind(this)
            }
        };

        this.$mdPanel.open(config).then(reference => {
            this.panelRef = reference;
        });
    }

    closePanel() {
        this.panelRef.close()
    }
}
