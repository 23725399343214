import AppComponent from './app.settings';

export const States = {
    AUTH: 'auth',
    AUTH_PROCESS: 'auth-process',
    MAIN: 'main',
    NEW_USER_FLOW: 'main.new-user-flow',
    PROJECTS: 'main.projects',
    DEFAULT_PROJECT: 'main.default-project',
    PROJECT_DETAILS: 'main.projects.project',
    DASHBOARD: 'main.projects.project.dashboard',
    DASHBOARD_SPECIFIED: 'main.projects.project.dashboard.specified',
    SENSORS: 'main.projects.project.sensors',
    SENSOR_DETAILS: 'main.projects.project.sensors.sensor',
    SERVICE_ACCOUNTS: 'main.projects.project.serviceaccounts',
    SERVICE_ACCOUNT_DETAILS: 'main.projects.project.serviceaccounts.serviceaccount',
    PROJECT_SETTINGS: 'main.projects.project.project-settings',
    PROJECT_SETTINGS_MEMBER: 'main.projects.project.project-settings.member',
    DATA_CONNECTORS: 'main.projects.project.dataconnectors',
    DATA_CONNECTOR_DETAILS: 'main.projects.project.dataconnectors.dataconnector',
    EXPORTS: 'main.projects.project.exports',
    EXPORT: 'main.projects.project.exports.export',
    RULES: 'main.projects.project.rules',
    RULE_DETAIL: 'main.projects.project.rules.rule',
    ALERTS: 'main.projects.project.alerts',
    ALERTS_LOG: 'main.projects.project.alerts.log',
    ALERTS_RULES: 'main.projects.project.alerts.rules',
    ALERTS_RULES_DETAIL: 'main.projects.project.alerts.rules.rule',
    EMULATOR: 'main.projects.project.emulator',
    EMULATOR_DETAILS: 'main.projects.project.emulator.device',
    ORGANIZATION_ALERTS: 'main.projects.project.organization-alerts',
    ORGANIZATION_DETAILS: 'main.projects.project.organization-details',
    ORGANIZATION_SETTINGS: 'main.projects.project.organization-settings',
    ORGANIZATION_SETTINGS_MEMBER: 'main.projects.project.organization-settings.member',
    ORGANIZATION_BILLING: 'main.projects.project.organization-billing'

};


/**
 * App specific routes and state configuration
 * @param {StateProvider} $stateProvider ui-router state provider
 * @param {UrlService} $urlServiceProvider ui-router provider
 * @param {$locationProvider} $locationProvider location provider
 * @constructor
 * @ngInject
 */
export default function AppRoutes($stateProvider, $urlServiceProvider, $locationProvider) {
    $locationProvider.html5Mode(true);

    const mainState = {
        abstract: true,
        component: AppComponent.selector,
        resolve: {
            storedUser: /* @ngInject */ (AuthService, $exceptionHandler) => AuthService.getUser().catch($exceptionHandler),
            authorizedUser: /* @ngInject */ (storedUser, AuthService, $exceptionHandler) => {
                if (storedUser && !storedUser.expired) {
                    return storedUser;
                }

                return AuthService.silentLogin().catch($exceptionHandler);
            }
        },
        onEnter: /* @ngInject */ (authorizedUser, $state, RoleManager, AuthService) => { // eslint-disable-line consistent-return,max-len
            if (!authorizedUser) {
                AuthService.storeCurrentState();
                return $state.target(States.AUTH);
            }
            RoleManager.loadRoles();
        }
    };

    $stateProvider
        .state(States.AUTH, {
            url: '/auth',
            onEnter: /* @ngInject */ (AuthService) => {
                AuthService.login();
            }
        });

    $stateProvider
        .state(States.AUTH_PROCESS, {
            url: '/auth/process',
            resolve: {
                authorizedUser: /* @ngInject */ (AuthService, $exceptionHandler) => {
                    return new Promise(resolve => {
                        AuthService.authorize()
                            .then((data) => {
                                resolve(data)
                            })
                            .catch((e) => {
                                $exceptionHandler(e)
                                
                                // Waiting 1000 ms before completing the Promise to
                                // allow the exception handler to send the error report.
                                // Resolving instead of rejecting in this case to 
                                // trigger another re-authentication. We don't know
                                // yet if it's possible to end up in this state "naturally".
                                setTimeout(() => {
                                    resolve(null)
                                }, 1000)
                            })
                    })
                }
            },
            onEnter: /* @ngInject */ (authorizedUser, $state, AuthService) => { // eslint-disable-line consistent-return
                if (authorizedUser) {
                    const storedState = AuthService.getStoredState();
                    if (storedState) {
                        return $state.target(storedState.name, storedState.params);
                    }
                    return $state.target(States.PROJECTS);
                }

                AuthService.clearStorage();
                window.location.href = '/default-project';
            }
        });

    $stateProvider
        .state(States.MAIN, mainState);

    $stateProvider
        .state(States.DEFAULT_PROJECT, {
            url: '/default-project',
            resolve: {
                projects: /* @ngInject */ (IAMService, $exceptionHandler) => {
                    return new Promise(resolve => {
                        IAMService.projects().then(({ data }) => {
                            resolve(data)
                        }).catch((e) => {
                            $exceptionHandler(e)
                            resolve(null)
                        })
                    })
                }
            },
            onEnter: /* @ngInject */ (projects, ProjectManager, $state) => {
                const projectId = ProjectManager.currentProjectId;
                if (projectId) {
                    return $state.target(States.SENSORS, {
                        projectId
                    });
                }
                
                // Any users that do not have access to any projects should be presented
                // with the option to create a new organization.
                if (projects.length === 0) {
                    return $state.target(States.NEW_USER_FLOW);
                } 
                return $state.target(States.PROJECTS);
            }
        });

    $urlServiceProvider.rules.otherwise('/default-project')
}
