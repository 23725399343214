import controller from './chart-motion.controller';
import { bindings } from '../chart-abstract-base';
import template from './template.html';

export default {
    bindings: {
        ...bindings,
        onChartSelection: '&',
        extremes: '<',
    },
    template,
    controller
};
