import html2canvas from 'html2canvas/dist/html2canvas';

import angular from 'angular';

window.html2canvas = html2canvas;

/* @ngInject */
export default function screenshotDirectiveConfig($compile, Loader) {
    function link($scope, $element) {
        const previewEl = $element[0].querySelector('.dtHelp-screenshot__preview');
        $scope.isPreviewRender = false;

        function prepareContent() {
            $scope.canvas = null;
            $scope.flagDraw = null;
            $scope.rectangles = [];
            $scope.currentRect = null;
            $scope.selectingMode = 'highlight';
            $scope.canvasCtx = null;

            $scope.contentEl = angular.element(document.getElementById('help-screenshot-content'));
            $scope.contentEl.addClass('is-load');
            $scope.contentHightlightsEl = angular.element(document.getElementById('screenshot-content-hightlights'));
            $scope.contentHidesEl = angular.element(document.getElementById('help-screenshot-content-hides'));

            document.addEventListener('keydown', $scope.destruct);
            $scope.contentEl[0].ondragstart = () => false;
        }

        $scope.destruct = function (e) {
            if (e.keyCode === 27) {
                e.preventDefault();
                e.stopPropagation();

                $scope.contentEl.remove();

                document.querySelector('.md-panel-outer-wrapper').style.display = 'block';
                document.removeEventListener('keydown', $scope.destruct);
            }
        };

        /**
         * Start Navbar
         */
        $scope.setHighlightMode = function () {
            $scope.selectingMode = 'highlight';
        };

        $scope.setHideMode = function () {
            $scope.selectingMode = 'hide';
        };

        function createNavBar() {
            const navbar = $compile(`<div class="help-screenshot-navbar" dt-drag>
                <a href="#" class="md-secondary help-screenshot-navbar-button"
                    ng-class="{'is-active': selectingMode === 'highlight'}"
                    ng-click="setHighlightMode(); $event.preventDefault();">
                    
                    <md-icon class="material-icons" md-svg-icon="remove_red_eye"></md-icon>
                    Highlight
                    <md-tooltip
                        class="dt-theme" md-z-index="1000" md-direction="top">Click on relevant item</md-tooltip>
                </a>
                
                <a href="#" class="md-secondary help-screenshot-navbar-button"
                        ng-class="{'is-active': selectingMode === 'hide'}"
                        ng-click="setHideMode(); $event.preventDefault();">
                        
                    <md-icon class="material-icons" md-svg-icon="block"></md-icon>
                    Hide
                    <md-tooltip
                        class="dt-theme" md-z-index="1000" md-direction="top">Click on relevant item</md-tooltip>
                </a>
                
                <span flex></span>
                
                <a href="#" class="md-primary help-screenshot-navbar-button"
                    ng-click="onDone(); $event.preventDefault();">Done</a>
                
                <div class="drag-handler" dt-drag-handle>
                    <md-icon class="material-icons material-icons--s" md-svg-icon="open_with"></md-icon>
                </div>
                `)($scope);

            $scope.contentEl.append(navbar);
        }
        /**
         * End NavBar
         */
        function renderRect(rectId) {
            const currentRect = angular.element($scope.currentRect);
            const top = currentRect.css('top');
            const left = currentRect.css('left');
            const height = currentRect.css('height');
            const width = currentRect.css('width');

            const rect = $compile(`<div class="help-screenshot-rect" id="${rectId}"
                    style="top: ${top}; left: ${left}; height: ${height}; width: ${width};">
                <md-button class="md-icon-button md-primary" ng-click="removeRect('${rectId}');">
                        <md-icon md-svg-icon="add_circle"></md-icon>
                </md-button>
            </div>`)($scope);

            if ($scope.selectingMode === 'highlight') {
                $scope.contentHightlightsEl.append(rect);
            } else {
                $scope.contentHidesEl.append(rect);
            }

            angular.element($scope.currentRect).remove();
        }

        function clean() {
            $scope.startX = null;
            $scope.startY = null;
            $scope.coord_x = null;
            $scope.coord_y = null;
            $scope.rectWidth = null;
            $scope.rectHeight = null;
            $scope.currentRect = null;
        }

        function start(ev) {
            if (ev.which !== 1 || ev.target.getAttribute('data-rect-id')
                || ev.target.classList.contains('help-screenshot-shape')) return;

            // update flag
            $scope.flagDraw = true;
            // calc coordinates
            $scope.startY = ev.clientY - $scope.canvas.offsetTop;
            $scope.coord_y = $scope.startY;

            $scope.startX = ev.clientX - $scope.canvas.offsetLeft;
            $scope.coord_x = $scope.startX;
            // create rectangle
            $scope.currentRect = document.createElement('div');
            $scope.currentRect.style.top = `${$scope.coord_y}px`;
            $scope.currentRect.style.left = `${$scope.coord_x}px`;

            $scope.currentRect.id = 'help-screenshot-current-rect';
            $scope.currentRect.className = 'help-screenshot-current-rect';
            $scope.contentEl.append($scope.currentRect);
        }

        function move(ev) {
            if (!$scope.flagDraw) return;

            const endX = ev.clientX - $scope.canvas.offsetLeft;
            const endY = ev.clientY - $scope.canvas.offsetTop;

            $scope.rectWidth = endX - $scope.startX;
            $scope.rectHeight = endY - $scope.startY;

            if ($scope.rectWidth < 0) {
                $scope.rectWidth = Math.abs($scope.rectWidth);

                $scope.currentRect.style.left = `${endX}px`;
                $scope.coord_x = endX;
            }

            if ($scope.rectHeight < 0) {
                $scope.rectHeight = Math.abs($scope.rectHeight);

                $scope.currentRect.style.top = `${endY}px`;
                $scope.coord_y = endY;
            }

            $scope.currentRect.style.width = `${$scope.rectWidth}px`;
            $scope.currentRect.style.height = `${$scope.rectHeight}px`;
        }

        function stop() {
            if (!$scope.flagDraw) return;

            $scope.flagDraw = false;

            if ($scope.rectWidth < 15 || $scope.rectHeight < 15) {
                angular.element($scope.currentRect).remove();
                return;
            }

            const rectId = `rect-${+new Date()}`;

            $scope.currentRect.id = rectId;

            $scope.rectangles.push({
                x: $scope.coord_x,
                y: $scope.coord_y,
                width: $scope.rectWidth,
                height: $scope.rectHeight,
                type: $scope.selectingMode,
                id: rectId
            });

            $scope.canvasCtx.clearRect($scope.coord_x, $scope.coord_y, $scope.rectWidth, $scope.rectHeight);

            if ($scope.selectingMode === 'highlight') {
                // redraw hides
                $scope.rectangles
                    .filter(item => item.type === 'hide')
                    .forEach((item) => {
                        const {
                            x, y, height, width
                        } = item;

                        $scope.canvasCtx.clearRect(x, y, width, height);
                        $scope.canvasCtx.fillStyle = 'rgba(0,0,0, .8)';
                        $scope.canvasCtx.fillRect(x, y, width, height);
                    });
            } else {
                $scope.canvasCtx.fillStyle = 'rgba(0,0,0, .8)';
                $scope.canvasCtx.fillRect($scope.coord_x, $scope.coord_y, $scope.rectWidth, $scope.rectHeight);
            }

            renderRect(rectId);

            clean();
        }

        function prepareCanvas(canvas) {
            const width = document.documentElement.clientWidth;
            const height = document.documentElement.clientHeight;

            const contentCanvas = $compile(
                `<div class="help-screenshot-content-canvas" id="help-screenshot-content-canvas"
                        style="width:${width}px; height:${height}px">
                    <canvas id="help-screenshot-canvas" width="${width}" height="${height}"></canvas>
                </div>`
            )($scope);

            $scope.contentEl.prepend(contentCanvas);
            angular.element(document.getElementById('help-screenshot-content-canvas')).prepend(canvas);

            $scope.canvas = document.getElementById('help-screenshot-canvas');

            $scope.canvasCtx = $scope.canvas.getContext('2d');
            $scope.canvasCtx.fillStyle = 'rgba(0,0,0, .4)';
            $scope.canvasCtx.fillRect(0, 0, $scope.canvas.offsetWidth, $scope.canvas.offsetHeight);

            $scope.contentEl[0].addEventListener('mousedown', start);
            $scope.contentEl[0].addEventListener('mousemove', move);
            $scope.contentEl[0].addEventListener('mouseup', stop);
            $scope.contentEl[0].addEventListener('mouseleave', stop);
        }

        /**
         * Start canvas
         */
        function redrawRects() {
            $scope.rectangles
                .filter(item => item.type === 'highlight')
                .forEach((item) => {
                    const {
                        x, y, height, width
                    } = item;

                    $scope.canvasCtx.clearRect(x, y, width, height);
                });

            $scope.rectangles
                .filter(item => item.type === 'hide')
                .forEach((item) => {
                    const {
                        x, y, height, width
                    } = item;

                    $scope.canvasCtx.clearRect(x, y, width, height);
                    $scope.canvasCtx.fillStyle = 'rgba(0,0,0, .8)';
                    $scope.canvasCtx.fillRect(x, y, width, height);
                });
        }

        $scope.removeRect = function (rectId) {
            const [rect] = $scope.rectangles.filter(item => item.id === rectId);
            const index = $scope.rectangles.indexOf(rect);

            const {
                x, y, width, height
            } = rect;

            angular.element(document.getElementById(rect.id).remove());

            $scope.canvasCtx.clearRect(x, y, width, height);
            $scope.canvasCtx.fillStyle = 'rgba(0,0,0, .4)';
            $scope.canvasCtx.fillRect(x, y, width, height);

            $scope.rectangles.splice(index, 1);

            redrawRects();
        };
        /**
         * End canvas
         */

        function onScreenshot() {
            // hide unnecessary content
            document.querySelector('.md-panel-outer-wrapper').style.display = 'none';

            // need global loader
            Loader.show();
            $scope.$applyAsync();

            html2canvas(document.querySelector('.application-container'), {
                onrendered: (canvas) => {
                    prepareContent();

                    createNavBar();

                    prepareCanvas(canvas);

                    Loader.hide();
                }
            });

            const content = $compile(
                `<div class="help-screenshot-content" id="help-screenshot-content">
                    <div class="help-screenshot-content-hightlights" id="screenshot-content-hightlights"></div>
                    <div class="help-screenshot-content-hides" id="help-screenshot-content-hides"></div>
                </div>`
            )($scope);

            angular.element(document.body).append(content);
        }

        function renderPreview() {
            // hide unnecessary content
            document.querySelector('.md-panel-outer-wrapper').style.visibility = 'hidden';

            html2canvas(document.body, {
                onrendered: (canvas) => {
                    $scope.isPreviewRender = true;
                    $scope.currentCanvas = canvas;
                    canvas.style.width = '100%';
                    canvas.style.height = 'auto';
                    previewEl.appendChild(canvas);
                    previewEl.addEventListener('click', onScreenshot);
                    $scope.$applyAsync();
                }
            });

            // show unnecessary content
            document.querySelector('.md-panel-outer-wrapper').style.visibility = 'visible';
        }

        $scope.onDone = function () {
            $scope.rectangles = [];

            // hide unnecessary content
            angular.element(document.querySelectorAll('.help-screenshot-rect .md-icon-button')).css('display', 'none');
            angular.element(document.querySelectorAll('.help-screenshot-navbar')).css('display', 'none');

            html2canvas(document.body, {
                onrendered: (canvas) => {
                    $scope.isPreviewRender = true;
                    canvas.style.width = '100%';
                    canvas.style.height = 'auto';
                    previewEl.appendChild(canvas);
                    previewEl.addEventListener('click', onScreenshot);
                    $scope.$applyAsync();
                }
            });

            angular.element(document.querySelector('#help-screenshot-content')).remove();

            // show unnecessary content
            document.querySelector('.md-panel-outer-wrapper').style.display = 'block';
            // update preview
            angular.element(previewEl).empty();
            $scope.isPreviewRender = false;
        };

        $scope.$watch('updateScreenshot', (value) => {
            if (value) {
                renderPreview();
            }
        });
    }

    return {
        restrict: 'E',
        template: '<div class="dtHelp-screenshot__preview" dt-block-loader="!isPreviewRender"></div>',
        scope: {
            updateScreenshot: '<'
        },
        link
    };
}
