import controller from './ccon-details.controller';

const template = require('./ccon-details.html');

export default {
    bindings: {
        thing: '<sensor',
        thingUpdate: '<',
        onThingUpdated: '&',
        onMoveSensor: '&'
    },
    template,
    controller
};
