import controller from './desk-occupancy.controller';

const template = require('./desk-occupancy.html');

export default {
    bindings: {
        device: '<',
        onPublishEvent: '&'
    },
    controller,
    template
};
