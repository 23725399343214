import { STATE_TRANSITION_START_EVENT } from 'services/StudioEvents';
import { isMobileLayout } from 'services/utils';

/* @ngInject */
export default class FeedbackFormController {
    constructor(FeedbackApi, ToastService, AuthService, onCancelDialog, $scope) {
        this.FeedbackApi = FeedbackApi;
        this.ToastService = ToastService;
        this.AuthService = AuthService;
        this.onCancelDialog = onCancelDialog

        this.unbindEventListener = $scope.$root.$on(STATE_TRANSITION_START_EVENT, () => {
            this.cancel()
            this.unbindEventListener();
        });
    }

    $onInit() {
        const isMobile = isMobileLayout();
        this.isScreenshot = false;
        this.descriptionRows = isMobile ? 2 : 4;
        this.getAuthData();
        this.feedbackFormData = {
            email: this.userProfile.email,
            name: this.userProfile.name.trim()
        };
        this.isRendered = true;
    }

    getAuthData() {
        const authData = this.AuthService.getAuthData();

        if (authData) {
            this.userProfile = authData.profile;
        }
    }

    /**
     * Convert image to base64
     * @param el - image element in DOM
     */
    toBase64() { // eslint-disable-line class-methods-use-this
        const canvas = document.querySelector('.dtHelp-screenshot__preview canvas');
        if (!canvas) return '';

        return canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '');
    }

    send() {
        this.inProgress = true;

        const image = this.toBase64();
        const feedback = {
            name: this.feedbackFormData.name,
            email: this.feedbackFormData.email,
            subject: this.feedbackFormData.subject,
            body: this.feedbackFormData.body,
            image_base64: image
        };

        this.FeedbackApi
            .create(feedback)
            .then(() => {
                this.ToastService.showSimpleTranslated('ticket_was_submitted');
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('ticket_wasnt_submitted', {
                    serverResponse
                });
            })
            .finally(() => {
                this.inProgress = false;
                this.cancel();
            });
    }

    cancel() {
        this.onCancelDialog()
    }
}
