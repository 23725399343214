import controller from './dashboard-card.controller';

const template = require('./dashboard-card.html');

export default {
    bindings: {
        card: '<',
        canUpdate: '<',
        duration: '<',
        zoomStartTime: '<',
        zoomEndTime: '<',
        onEditCard: '&',
        onEditDevices: '&',
        onRemoveMissingDevices: '&',
        onToggleLegend: '&',
        onUpdateAppearance: '&',
        onRemove: '&'
    },
    template,
    controller
};
