import _get from 'lodash/get'
import {waterDetector} from 'services/config/SensorTypes';

/* @ngInject */
export default class EmulatorWaterDetectorController {
    constructor(
        EventEmitter,
        $scope,
        $state,
        $timeout,
    ) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.event = {
            waterPresent: {
                state: _get(this.device, 'reported.waterPresent.state', 'NOT_PRESENT')
            }
        }
    }

    get prettyState() {
        const state = waterDetector.format[this.event.waterPresent.state]
        return state || "";
    }

    sendWaterPresentEvent() {
        this.onPublishEvent(
            this.EventEmitter({
                event: this.event
            })
        )
    }
}