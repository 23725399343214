import angular from 'angular';
import routes from './rules.router';
import RulesListComponent from './rules.component';
import RuleDetailComponent from './rules-detail';
import RulesServiceFactory from './rules.service';
import { RulesDeviceListComponent } from './rules-detail/device-list.component';
import ChipPatternDirective from './rules-detail/chip-pattern.directive';
import DurationComponent from './rules-detail/duration';
import dtCommon from '../../common';

const studioRules = 'studioRules';
export default studioRules;

angular.module(studioRules, [dtCommon])
    .component(RulesListComponent.selector, RulesListComponent.options)
    .component(RuleDetailComponent.selector, RuleDetailComponent.options)
    .component(RulesDeviceListComponent.selector, RulesDeviceListComponent.options)
    .component(DurationComponent.selector, DurationComponent.options)
    .directive('dtChipPattern', ChipPatternDirective)
    .factory('RulesService', RulesServiceFactory)
    .config(routes);
