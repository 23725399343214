import moment from 'moment'
import { isMobileLayout } from 'services/utils'
import { DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED, DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE, DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY } from 'constants/device'

/* @ngInject */
export default class MultipleDevicesController {
    constructor(EventEmitter, $scope, $state) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$state = $state;
    }

    $onInit() {
        this.hoverText = "";
        this.editingCardName = "";
        this.shouldShowOptions = false;
        this.doneLoading = false;

        this.$scope.$on('dashboardTooltipHover',  (event, data) => {
            const cardType = this.card.content.devices[0].type
            this.hoverText = ''
            if (cardType === 'deskOccupancy') {
                let momentFormat = 'ddd, MMM D'
                // Change format if hours are being shown instead of days
                if (data.target.series.currentDataGrouping.count === 1) {
                    momentFormat = 'ddd HH:00-HH:59, MMM D'
                }
                const timeStamp = moment(data.target.x).format(momentFormat)
                const prefixWithDesk = this.card.cols > 1 ? `Desk${data.target.y === 1 ? '' : 's'} ` : ''
                this.hoverText = `${data.target.y} ${prefixWithDesk} Occupied ${timeStamp}`
            }
            this.$scope.$applyAsync()
        })
        
        this.$scope.$on('dashboardTooltipHoverEnd', () => {
            this.hoverText = "";
            this.$scope.$applyAsync();
        });

        this.$scope.$on('dashboardSaveCardEdit', (event, card) => {
            if (this.card === card) {
                this.card.displayName = this.editingCardName;
            }
        });
        
        this.$scope.$watch("$ctrl.card.doneLoading", () => {
            if (this.card.doneLoading) {
                this.updateDesksOccupied()
                this.$scope.$applyAsync()        
            }
        });
    }

    get cardIcon() {
        if (this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE) {
            return 'temperature'
        }
        if (this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY) {
            return 'humidity'
        }
        return this.card.content?.devices[0]?.type
    }

    get subheadingText() {
        if (!this.card.doneLoading) {
            return 'Loading...'
        }
        
        if (this.hoverText) {
            return this.hoverText;
        }

        if (this.card.missingDevices) {
            return 'Not able to display card';
        }

        return `${this.card.deviceConfig.deviceIds.length} Sensors`;
    }

    get multipleTemperature() {
        if (!this.card.content) return false
        return this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE || this.card.content.devices.every(device => device.type === "temperature" || device.type === "co2" || device.type === "humidity")
    }

    get multipleHumidity() {
        if (!this.card.content) return false
        return this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY
    }

    get onlyProximityAndContactSensors() {
        if (!this.card.content) return false
        return this.card.content.devices.every(device => device.type === "proximity" || device.type === "contact")
    }

    get onlyDeskOccupancySensors() {
        if (!this.card.content) return false
        return this.card.content.devices.every(device => device.type === "deskOccupancy")
    }

    get onlyMotionSensors() {
        if (!this.card.content) return false
        return this.card.content.devices.every(device => device.type === "motion")
    }

    get tooManyDeskOccupancySensorsForTimeFrame() {

        const deviceCount = this.card.content.devices.length
        const timeFrameDays = (this.zoomEndTime - this.zoomStartTime) / (1000 * 60 * 60 * 24);

        // Simple check to avoid hitting the payload limit when fetching aggregated desk occupancy data
        // ~70k data points should be below the limit with some margin
        return (deviceCount * timeFrameDays) > 70000
    }

    showOptions() {
        this.shouldShowOptions = true;
    }

    mobileHideOptions() {
        // Workaround to detect touch outside div on mobile
        if(isMobileLayout()) {
            setTimeout(() => {
                this.shouldShowOptions = false;
                this.$scope.$applyAsync();
            }, 200);
        }
    }

    editCard() {
        this.editingCardName = this.card.displayName; // ng-model for name input field 
        this.shouldShowOptions = false;
        setTimeout(() => {
            this.onEditCard(this.EventEmitter({
                card: this.card
            }));
            this.$scope.$applyAsync();
        }, 300);
    }

    editDevices() {
        this.onEditDevices(this.EventEmitter({
            card: this.card
        }));
    }

    toggleLegend() {
        this.shouldShowOptions = false;
        this.onToggleLegend(this.EventEmitter({
            card: this.card
        }));
    }

    removeMissingDevices() {
        this.onRemoveMissingDevices(this.EventEmitter({
            card: this.card
        }))
    }

    setDeskOccupancyAppearance(appearance) {
        this.shouldShowOptions = false
        this.card.appearance = appearance
        this.onUpdateAppearance(this.EventEmitter({
            card: this.card
        }))
    }

    updateDesksOccupied() {
        if (this.onlyDeskOccupancySensors && this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
            let newTotalOffline = 0
            let newTotalOccupied = 0
            this.card.content.devices.forEach(device => {
                if (device.offline) {
                    newTotalOffline += 1
                    return
                }
                if (device.reported?.deskOccupancy?.state === 'OCCUPIED') {
                    newTotalOccupied += 1
                }
            })
            this.numberOfDesksOffline = newTotalOffline
            this.numberOfDesksOccupied = newTotalOccupied
        }
    }

    remove() {
        this.onRemove(this.EventEmitter({
            card: this.card
        }));
    }
}
