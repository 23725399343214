import moment from 'moment/moment';
import { DEFAULT_TOKEN, getPageSize } from 'services/PaginationHelper';
import { getHoursMinutesFormat } from 'services/utils';
import { States } from '../../../app.router';

import { UPDATE_MEMBERSHIP, DELETE_MEMBERSHIP } from 'services/Permissions';

import RemoveMemberPopupController from '../remove-member/controller';
import RemoveMemberPopupTemplate from '../remove-member/template.html';

import InviteUrlPopupController from '../invite-url/controller';
import InviteUrlPopupTemplate from '../invite-url/template.html';

const PAGE_SIZE_SUFFIX = 'projects';

/* @ngInject */
export default class OrganizationMemberDetailsController {
    constructor(Loader, EventEmitter, RoleManager, AuthService, DialogService, $q, $timeout, IAMService, ToastService, ProjectManager, $scope, $state) {
        this.Loader = Loader;
        this.EventEmitter = EventEmitter;
        this.RoleManager = RoleManager;
        this.AuthService = AuthService;
        this.DialogService = DialogService;
        this.$q = $q;
        this.$timeout = $timeout;
        this.IAMService = IAMService;
        this.ToastService = ToastService;
        this.ProjectManager = ProjectManager;
        this.$scope = $scope;
        this.$state = $state;
    }

    get canUpdateMember() {
        return this.RoleManager.hasProjectPermissionTo(UPDATE_MEMBERSHIP);
    }

    get canDeleteMember() {
        return this.RoleManager.hasProjectPermissionTo(DELETE_MEMBERSHIP);
    }

    get numberOfProjectsToInvite() {
        return Object.keys(this.inviteProjectAccess).length;
    }

    get isOrgAdmin() {
        return this.user.isOrgAdmin
    }

    get isOrgViewer() {
        return this.user.isOrgViewer
    }

    get accessRole() {
        if (this.isOrgAdmin) {
            return this.organizationRoles.find(role => role.name === 'roles/organization.admin');
        }
        if (this.isOrgViewer) {
            return this.organizationRoles.find(role => role.name === 'roles/organization.viewer');
        }
        return this.organizationRoles.find(role => role.name === 'roles/projects'); // Imaginary role
    }

    formattedTime(timestamp) {
        return moment(timestamp).format(`MMMM D, yyyy ${getHoursMinutesFormat()}`)
    }

    $onInit() {
        this.isFetching = true;
        this.projects = [];
        this.query = '';

        this.selected = [];

        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;

        this.requestParams = {
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken,
            organization: `organizations/${this.ProjectManager.currentProject.organizationId}`,
            query: this.query
        };

        this.roles = this.RoleManager.getProjectRoles();
        this.organizationRoles = this.RoleManager.getOrganizationRoles(true)

        this.newAccessRole = this.accessRole
        this.projectAccessDropdownId = ''

        this.projectAccess = {}; // What access the user has per project
        
        if (this.user.memberships) {
            this.user.memberships.forEach(membership => {
                const projectId = membership.name.split('/')[1]
                // Get role from this.roles based on membership.role
                this.projectAccess[projectId] = this.roles.find(role => role.name === membership.roles[0]);
            });
        }

        this.Loader.promise = this.fetchAllProjects().then(projects => {
            this.projects = projects;
            this.filteredProjects = projects;          
        });

        this.inviteProjectAccess = {}; // What access the user should be invited to per project
        this.selectedMultipleAccessOption = this.roles[0];
    }

    fetchAllProjects() {
        const deferred = this.$q.defer();
        this.getProjectsPage(null, [])
            .then(projects => deferred.resolve(projects))
            .catch(() => {
                this.ToastService.showSimpleTranslated('projects_wasnt_fetched');
                deferred.reject();
            });
        return deferred.promise;
    }

    getProjectsPage(pageToken, previousData) {
        const requestParams = {
            organization: `organizations/${this.ProjectManager.currentProject.organization.split('/')[1]}`,
            pageToken
        }
        return this.IAMService
            .projects(requestParams)
            .then(({ data, nextPageToken }) => {
                const allData = [...previousData, ...data];
                if (nextPageToken !== '') {
                    return this.getProjectsPage(nextPageToken, allData);
                }
                
                // Sort projects based on projectAccess
                // Order should be -> "roles/project.admin", "roles/project.developer", "roles/project.user" and then alphabetical
                return allData.sort((a, b) => {
                    const aXID = a.name.split('/')[1];
                    const bXID = b.name.split('/')[1];

                    const aRole = this.projectAccess[aXID]?.name;
                    const bRole = this.projectAccess[bXID]?.name;

                    if (aRole === bRole) {
                        return a.displayName.localeCompare(b.displayName);
                    }

                    const roleOrder = {
                        'roles/project.admin': 0,
                        'roles/project.developer': 1,
                        'roles/project.user': 2
                    };

                    if (aRole in roleOrder && bRole in roleOrder) {
                        return roleOrder[aRole] - roleOrder[bRole];
                    }

                    if (aRole in roleOrder) {
                        return -1;
                    }

                    if (bRole in roleOrder) {
                        return 1;
                    }

                    return a.displayName.localeCompare(b.displayName);
                });
            });
    }

    clearSelected() {
        this.selected = [];
    }

    queryChanged() {
        this.filteredProjects = this.projects.filter(project => project.displayName.toLowerCase().includes(this.query.toLowerCase()));
    }

    clear() {
        this.query = '';
        this.filteredProjects = this.projects;
    }

    hideDropdown() {
        this.projectAccessDropdownId = ''
        this.showRoleDropdown = false
    }

    setNewAccessRole(role) {
        this.newAccessRole = role;
    }

    updateAccessRole() {

        const member = {
            email: this.user.email,
            roles: [this.newAccessRole.name]
        }

        if ((this.newAccessRole.name === 'roles/organization.viewer' && this.isOrgAdmin) ||
            (this.newAccessRole.name === 'roles/organization.admin' && this.isOrgViewer)) {

            // Need to delete member before we can add them with a new role
            this.IAMService.deleteMember(this.user.name).then(() => {
                this.createOrganizationMember(member)
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('member_wasnt_removed', {
                    serverResponse
                });
            })
        } else if (this.newAccessRole.name === 'roles/projects') {
            // Member is orgViewer or orgAdmin, so we need to remove them from the organization
            this.IAMService.deleteMember(this.user.name).then(() => {
                this.user.isOrgAdmin = false
                this.user.isOrgViewer = false
                this.ToastService.showSimpleTranslated('member_access_updated');
                this.$scope.$emit('updateMembersList')
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('member_access_wasnt_updated', {
                    serverResponse
                });
            })
        } else {
            // Giving orgViewer or orgAdmin access
            this.createOrganizationMember(member)
        }
    }

    createOrganizationMember(member) {
        this.IAMService.createOrganizationMember(member).then((createdMember) => {
            this.user.name = createdMember.name
            this.user.memberId = createdMember.id
            if (this.newAccessRole.name === 'roles/organization.admin') {
                this.user.isOrgAdmin = true
                this.user.isOrgViewer = false
            }
            if (this.newAccessRole.name === 'roles/organization.viewer') {
                this.user.isOrgViewer = true
                this.user.isOrgAdmin = false
            }
            this.user.roles = [this.newAccessRole.name]
            this.ToastService.showSimpleTranslated('member_access_updated');
            this.$scope.$emit('updateMembersList')
            this.$scope.$applyAsync();
        }).catch((serverResponse) => {
            this.ToastService.showSimpleTranslated('member_access_wasnt_updated', {
                serverResponse
            });
        });
    }

    setMultipleRole(role) {
        if (role !== null) { // Null means no access
            this.selectedMultipleAccessOption = role;
        } else {
            this.selectedMultipleAccessOption = {
                name: null,
                displayName: 'No Access'
            }
        }      
    }

    setRole(projectId, role) {
        if (this.user.memberId === 'TO_BE_INVITED') {
            // We are building a list of projects to invite the user to
            if (role === null) { // Null means "No Access"
                delete this.inviteProjectAccess[projectId] 
            } else {
                this.inviteProjectAccess[projectId] = role;
            }
        } else if (role === null) { // Null means "No Access", so we remove the user from the project
            const memberNames = [`projects/${projectId}/members/${this.user.memberId}`]
            this.IAMService.batchDeleteMembersFromProjects(memberNames).then(() => {
                delete this.projectAccess[projectId]    
                this.selected = []
                this.ToastService.showSimpleTranslated('member_access_updated');
                this.updateMemberships()
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('member_access_wasnt_updated', {
                    serverResponse
                });
            })
        } else {
            const memberships = []
            memberships.push({
                project: `projects/${projectId}`,
                email: this.user.email,
                roles: [role.name]
            });
            this.IAMService.batchInviteMembersToProjects(memberships).then(() => {
                this.projectAccess[projectId] = role;
                
                this.ToastService.showSimpleTranslated('member_access_updated');
                this.updateMemberships()
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('member_access_wasnt_updated', {
                    serverResponse
                });
            })
        }
        
    }

    batchUpdateProjectAccess() {

        if (this.user.memberId === 'TO_BE_INVITED') { 
            this.selected.forEach(projectId => {
                if (this.selectedMultipleAccessOption.name !== null) {
                    this.inviteProjectAccess[projectId] = this.selectedMultipleAccessOption;
                } else {
                    delete this.inviteProjectAccess[projectId]
                }
            })
            this.selected = []
        } else if (this.selectedMultipleAccessOption.name === null) { // Null means "No Access", so we remove the user from the project
            const memberNames = []
            this.selected.forEach(projectId => {
                memberNames.push( `projects/${projectId}/members/${this.user.memberId}`)
            })
            this.IAMService.batchDeleteMembersFromProjects(memberNames).then(() => {
                this.selected.forEach(projectId => {
                    delete this.projectAccess[projectId]
                })
                this.selected = []
                this.ToastService.showSimpleTranslated('member_access_updated');
                this.updateMemberships()
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('member_access_wasnt_updated', {
                    serverResponse
                });
            })
        } else { // Updating access based on dropdown selection
            const memberships = []
            this.selected.forEach(projectId => {
                memberships.push({
                    project: `projects/${projectId}`,
                    email: this.user.email,
                    roles: [this.selectedMultipleAccessOption.name]
                });
            })
            this.IAMService.batchInviteMembersToProjects(memberships).then(() => {
                this.selected.forEach(projectId => {
                    this.projectAccess[projectId] = this.selectedMultipleAccessOption;
                })
                this.selected = []
                this.ToastService.showSimpleTranslated('member_access_updated');
                this.updateMemberships()
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('member_access_wasnt_updated', {
                    serverResponse
                });
            })
        }
    }

    updateMemberships() {
        this.IAMService.allMembershipsForMember(this.user.memberId).then((data) => {
            this.user.memberships = data.members;
        })
    }

    roleForProject(projectId) {
        if (this.user.memberId === 'TO_BE_INVITED') {
            return this.inviteProjectAccess[projectId] ? this.inviteProjectAccess[projectId].displayName : 'No Access';
        }
        if (this.isOrgAdmin) {
            return 'Project Administrator';
        }
        if (this.isOrgViewer) {
            return 'Project User';
        }
        return this.projectAccess[projectId] ? this.projectAccess[projectId].displayName : 'No Access';
    }

    showRemoveMemberPopup() {
        this.DialogService.show({
            controller: RemoveMemberPopupController,
            controllerAs: '$ctrl',
            template: RemoveMemberPopupTemplate,
            parent: document.body,
            openFrom: 'top',
            closeTo: 'top',
            clickOutsideToClose: true,
            fullscreen: true,
            locals: {
                member: this.user,
                goBackToOrganizationMembers: this.goBackToOrganizationMembers.bind(this)
            }
        });
    }

    inviteMemberToProjects() {
        const memberships = []
        Object.keys(this.inviteProjectAccess).forEach(projectId => {
            memberships.push({
                project: `projects/${projectId}`,
                email: this.user.email,
                roles: [this.inviteProjectAccess[projectId].name]
            });
        })
        this.IAMService.batchInviteMembersToProjects(memberships).then(response => {
            this.user.memberId = response.members[0].name.split('/')[3] // Get the memberId from the response
            this.ToastService.showSimpleTranslated('member_was_created')
            this.updateMemberships()
            this.projectAccess = this.inviteProjectAccess
        }).catch((serverResponse) => {
            this.ToastService.showSimpleTranslated('member_wasnt_created', {
                serverResponse
            });
        })
    }


    showInviteUrl() {
        if (!this.user.isOrgAdmin && !this.user.isOrgViewer) {
            this.user.name = this.user.memberships[0].name
        }
        this.DialogService.show({
            controller: InviteUrlPopupController,
            controllerAs: '$ctrl',
            template: InviteUrlPopupTemplate,
            parent: document.body,
            openFrom: 'top',
            closeTo: 'top',
            fullscreen: false,
            multiple: true,
            clickOutsideToClose: true,
            hasBackdrop: true,
            locals: { member: this.user }
        });
    }

    resendInvite() {
        if (this.user.isOrgAdmin || this.user.isOrgViewer) {
            const promise = this.IAMService
                .deleteMember(this.user.name)
                .then(() => this.IAMService.createOrganizationMember({
                    email: this.user.email,
                    roles: this.user.roles
                }))
                .then((data) => {
                    this.ToastService.showSimpleTranslated('member_invite_was_sent');
                    this.user.createTime = data.createTime || moment().format()
                })
                .catch((serverResponse) => {
                    this.ToastService.showSimpleTranslated('member_invite_wasnt_sent', {
                        serverResponse
                    });
                });
            this.Loader.promise = promise;
        } else {
            // For a member that has access to specific projects, 
            // we need to delete them from all projects and re-invite them
            const memberNames = []
            this.user.memberships.forEach(membership => {
                memberNames.push(membership.name)
            })

            const promise = this.IAMService.batchDeleteMembersFromProjects(memberNames).then(() => {
                const memberships = []
                this.user.memberships.forEach(membership => {
                    memberships.push({
                        project: `projects/${membership.name.split('/')[1]}`,
                        email: this.user.email,
                        roles: [membership.roles[0]]
                    });
                })

                this.IAMService.batchInviteMembersToProjects(memberships).then(response => {
                    this.ToastService.showSimpleTranslated('member_invite_was_sent');
                    this.user.createTime = response.createTime || moment().format()
                }).catch((serverResponse) => {
                    this.ToastService.showSimpleTranslated('member_invite_wasnt_sent', {
                        serverResponse
                    });
                })
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('member_invite_wasnt_sent', {
                    serverResponse
                });
            })
            this.Loader.promise = promise;
        }
    }

    goBackToOrganizationMembers() {
        this.$state.go(States.ORGANIZATION_SETTINGS);
    }

    cancelInvite() {
        this.goBackToOrganizationMembers();
    }
}
