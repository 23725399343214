import moment from 'moment';
import { getHoursMinutesSecondsFormat } from 'services/utils';

export default {
    navigator: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    chart: {
        type: 'column'
    },
    plotOptions: {
        column: {
            borderWidth: 0,
            borderRadius: 2,
            getExtremesFromAll: true
        },
        series: {
            events: {
                mouseOut() {
                    const chart = this.chart;
                    if (chart.lbl) {
                        chart.lbl.hide();
                    }
                }
            }
        }
    },
    yAxis: [
        {
            title: {
                text: 'Number of events',
                margin: 0
            },
            min: 0,
            tickInterval: 1,
            allowDecimals: false,
            opposite: false
        }
    ],
    tooltip: {
        enabled: true,
        shared: true,
        shadow: false,
        pointFormatter() {
            const chart = this.series.chart;
            const currentPoint = this;
            const momentFormat = `dddd, MMM D, ${getHoursMinutesSecondsFormat()}`;
            const timeStamp = moment(currentPoint.x).format(momentFormat);
            const label = `<span class="dt-highcharts-number">${
                currentPoint.y
            }</span> event${
                currentPoint.y !== 1 ? 's' : ''
            } on <span class="dt-highcharts-number">${timeStamp}</span>`;

            if (!chart.lbl) {
                chart.lbl = chart.renderer
                    .text('', 40, 20, true)
                    .addClass('highchart-custom-tooltip')
                    .add();
            }

            if (currentPoint.y > 0) {
                chart.lbl.show().attr({
                    text: label
                });
            } else {
                chart.lbl.hide();
            }
            return `${currentPoint.y} event${currentPoint.y !== 1 ? 's' : ''}`;
        }
    }
};
