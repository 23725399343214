import controller from './radio-wide.controller';

const template = require('./radio-wide.html');

export default {
    bindings: {
        radio: '<'
    },
    template,
    controller
};
