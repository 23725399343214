import { applyInjectors, cloneEntity } from 'services/utils';

export const injectDeviceCount = (project) => {
    Object.defineProperty(project, 'deviceCount', {
        value: project.sensorCount + project.cloudConnectorCount
    });
    return project;
};

export const injectOrganizationIdProp = (project) => {
    if (project.organization) {
        Object.defineProperty(project, 'organizationId', {
            value: project.organization.split('/').pop()
        });
    }
    return project;
};

const INJECTORS_PIPE = [
    injectDeviceCount,
    injectOrganizationIdProp
];

export const applyProjectInjectors = project => applyInjectors(project, INJECTORS_PIPE);

export const cloneProject = project => applyProjectInjectors(cloneEntity(project));
