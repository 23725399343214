import Config from './organization-settings.router';
import options from './organization-settings.component';
import OrganizationMemberDetailsComponent from './member-details';

const Component = {
    selector: 'dtOrganizationSettingsPage',
    options
};

export {
    Config,
    Component,
    OrganizationMemberDetailsComponent
};
