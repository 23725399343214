import * as SensorTypes from 'services/config/SensorTypes';
import { dateFromXID } from './utils';

// Object with the key being a lowercased device type, and the value
// being the canonical form (eg. "deskOccupancy")
const deviceTypesMap = Object.fromEntries(
    [
        SensorTypes.temperature,
        SensorTypes.proximity,
        SensorTypes.touch,
        SensorTypes.humidity,
        SensorTypes.waterDetector,
        SensorTypes.motion,
        SensorTypes.co2,
        SensorTypes.deskOccupancy,
        SensorTypes.ccon,
        SensorTypes.proximityCounter,
        SensorTypes.touchCounter,
    ].map(t => [t.filter.toLowerCase(), t.filter])
)

// This regexp extracts and removes the label:..... clauses in the query string
const REGEX_LABEL = /label:([a-zA-Z0-9\-_]*)((=[^"][^ ]*)|(="[^"]*"))?/g;

// This regexp extracts and removes the type:.... clause
const REGEX_DEVICE_TYPE = /type:([a-zA-Z0-9-]*)/g;

const REGEX_ALERT_FILTER = /alerts:([a-zA-Z]+)/g;

// From a single query input line, we want to extract labelExpressions
// type filter and the remaining query
export const parseQuery = (query) => {

    // The data to extract
    const labelFilters = [];
    const deviceTypes = [];
    const alertFilters = [];

    const parts = query.split(/[\s,]+/) // Split on " " and ","

    // If the query contains _only_ valid XIDs, we want to pass just the list
    // of XIDs in the search request. We're discarding all other search parameters
    // in this case to allow the search to be more efficient. Only consider XIDs as 
    // valid if they were created between 2016 and now.
    let queryIsOnlyDeviceIDs = true
    parts.forEach(part => {
        let id = part
        if (id.length === 23 && id.startsWith("emu")) {
            id = id.substring(3)
        }
        const date = dateFromXID(id)
        if (!date) {
            queryIsOnlyDeviceIDs = false
            return
        }
        if (date < new Date(2016) || date > new Date()) {
            queryIsOnlyDeviceIDs = false
        }
    })
    if (queryIsOnlyDeviceIDs) {
        return { 
            query: null,
            deviceTypes: null,
            labelFilters: null,
            alertFilters: null,
            deviceIds: parts 
        }
    }
    
    // Look for any device type matches in the query, and add them to the list
    // of the device types to search for. Usually, the query will be exactly one
    // device type, but sometimes also multiple device types.
    parts.forEach(part => {
        if (deviceTypesMap[part.toLowerCase()]) {
            deviceTypes.push(deviceTypesMap[part.toLowerCase()])
        }
    })

    // First we extract the label-specific parts:
    let m;
    while ((m = REGEX_LABEL.exec(query)) !== null) { // eslint-disable-line no-cond-assign
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === REGEX_LABEL.lastIndex) {
            REGEX_LABEL.lastIndex++;
        }
        if (m && m.length > 2) {
            const key = m[1];
            let value = m[2];
            if (value) { // Remove the trailing = sign from the value
                value = value.slice(1);
                if (value.startsWith('"') && value.endsWith('"')) {
                    // remove quotes from the quoted string:
                    value = value.slice(1, -1);
                }
            }
            // Label expressions are either key=value, or just key
            labelFilters.push(key + (value ? `=${value}` : ''));
        }
    }
    // Remove all the label expressions from the original query
    let restOfQuery = query.replace(REGEX_LABEL, '').trim();

    // Then pick out a type:device_type clause.  If there are multiple, we only
    // care about the first
    while ((m = REGEX_DEVICE_TYPE.exec(restOfQuery)) !== null) { // eslint-disable-line no-cond-assign
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === REGEX_LABEL.lastIndex) {
            REGEX_LABEL.lastIndex++;
        }
        if (m && m.length > 1) {
            deviceTypes.push(m[1]);
        }
    }
    // Remove type filter from the original query
    restOfQuery = restOfQuery.replace(REGEX_DEVICE_TYPE, '').trim();

    // Then pick out alerts:<state> clauses.
    while ((m = REGEX_ALERT_FILTER.exec(restOfQuery)) !== null) { // eslint-disable-line no-cond-assign
        alertFilters.push(m[1]);
    }
    // Remove alerts clauses from the original query
    restOfQuery = restOfQuery.replace(REGEX_ALERT_FILTER, '').trim();

    return {
        query: restOfQuery.length ? restOfQuery : null,
        deviceTypes: deviceTypes.length ? deviceTypes : null,
        labelFilters: labelFilters.length ? labelFilters : null,
        alertFilters: alertFilters.length ? alertFilters : null,
        deviceIds: null
    };
};
