import Config from './organization-details.router';
import options from './organization-details.component';

const Component = {
    selector: 'dtOrganizationDetailsPage',
    options
};

export {
    Config,
    Component
};
