import { convertTimePropsToLocal, noop } from 'services/utils';
import { fixTimestampJitter } from 'services/SensorHelper';
import { createAttemptDelayer } from 'services/api/helper';

const EVENT_TIME_PROPERTIES = [
    'timestamp',
    'data.networkStatus.updateTime',
    'data.batteryStatus.updateTime',
    'data.temperature.updateTime',
    'data.touch.updateTime',
    'data.objectPresent.updateTime',
    'data.objectPresent.updateTime',
    'data.cellularStatus.updateTime',
    'data.connectionLatency.updateTime',
    'data.connectionStatus.updateTime',
    'data.ethernetStatus.updateTime',
    'data.prototypeData.updateTime' // TILT-SUPPORT
];

const RECONNECTION_INTERVAL = 1000;
const RECONNECTION_INCREASE_FACTOR = 2;
const RECONNECTION_MAX_ATTEMPTS = 4;

const INITIALIZING_TIMEOUT = 5000; // in milliseconds
const PING_INTERVAL_JITTER = 2000; // in milliseconds
const PING_INTERVAL_SECONDS = 10;

const EVENT_TYPE_PING = 'ping';

export const TOP_LEVEL_NETWORK_ERROR_STATE_KEY = 'root:network-error';
export const TOP_LEVEL_NETWORK_ERROR_VISIBLE_STATE_KEY = 'root:network-error-visible';

export const STATUS_INITIALIZING = 'initializing';
export const STATUS_LIVE = 'live';
export const STATUS_STALE = 'stale';
export const STATUS_NOT_INITIALIZED = 'not_initialized';
export const STATUS_RECONNECTING = 'reconnecting';
export const STATUS_FAILED = 'failed';
export const STATUS_CLOSED = 'closed';

export default class ResourceStream {
    constructor(url, onMessageCallback, config = {}) {
        this.url = url;
        this.onMessageCallback = onMessageCallback;

        this.initializationTimeout = config.initializationTimeout || INITIALIZING_TIMEOUT;
        this.pingIntervalJitter = config.pingIntervalJitter || PING_INTERVAL_JITTER;
        this.pingIntervalInSeconds = config.pingIntervalInSeconds || PING_INTERVAL_SECONDS;
        this.maxReconnectionAttempts = config.maxReconnectionAttempts || RECONNECTION_MAX_ATTEMPTS;
        this.getAttemptDelay = createAttemptDelayer(
            config.reconnectionInterval || RECONNECTION_INTERVAL,
            config.reconnectionIncreaseFactor || RECONNECTION_INCREASE_FACTOR
        );
        this.onStatusChange = config.onStatusChange || noop;

        this.pingInterval = this.pingIntervalInSeconds * 1000;
        this.pingIntervalTimeout = this.pingIntervalJitter + this.pingInterval;
        this.pingIntervalQueryParam = `&pingInterval=${(this.pingIntervalInSeconds)}s`;

        this.restartTimerId = null;

        this.start = this.start.bind(this);

        this.onError = this.onError.bind(this);
        this.onMessage = this.onMessage.bind(this);

        this.connectionAttempt = 0;
        this.pingEverReceived = false;

        this.setStatus(STATUS_INITIALIZING);
        this.start();
    }

    setStatus(status) {
        if (this.status !== status) {
            this.onStatusChange(status);
        }
        this.status = status;
    }

    start() {
        this.firstPingReceived = false;
        this.lastPingReceivedAt = null;
        this.lastReceivedEvent = null;

        this.connectionAttempt++;

        this.evtSource = new EventSource(`${this.url()}${this.pingIntervalQueryParam}`);

        this.evtSource.onerror = this.onError;
        this.evtSource.onmessage = this.onMessage;

        this.scheduleInitializedCheck();
    }

    stop(status = STATUS_CLOSED) {
        this.evtSource.close();
        this.setStatus(status);

        this.cancelPingCheck();
        this.cancelInitializedCheck();
        this.cancelStart();
    }

    schedulePingCheck() {
        this.cancelPingCheck();
        this.pingTimerId = setTimeout(() => {
            this.stop(STATUS_STALE);
        }, this.pingIntervalTimeout);
    }

    cancelPingCheck() {
        clearTimeout(this.pingTimerId);
    }

    scheduleInitializedCheck() {
        this.cancelInitializedCheck();
        this.initializedTimerId = setTimeout(this.onError, this.initializationTimeout);
    }

    cancelInitializedCheck() {
        clearTimeout(this.initializedTimerId);
    }

    scheduleStart() {
        this.cancelStart();
        this.restartTimerId = setTimeout(this.start, this.getAttemptDelay(this.connectionAttempt));
    }

    cancelStart() {
        clearTimeout(this.restartTimerId);
    }

    onError() {
        if (this.connectionAttempt <= this.maxReconnectionAttempts) {
            this.stop(this.pingEverReceived ? STATUS_RECONNECTING : STATUS_INITIALIZING);
            this.scheduleStart();
        } else {
            this.stop(this.pingEverReceived ? STATUS_FAILED : STATUS_NOT_INITIALIZED);
        }
    }

    handlePing() {
        const now = Date.now();
        if (!this.firstPingReceived) {
            this.firstPingReceived = true;
            this.pingEverReceived = true;
            this.lastPingReceivedAt = now;
            this.connectionAttempt = 1;
            this.setStatus(STATUS_LIVE);
            this.cancelInitializedCheck();
        } else if ((now - this.lastPingReceivedAt - this.pingInterval) > this.pingIntervalJitter) {
            this.stop(STATUS_STALE);
        } else {
            this.lastPingReceivedAt = now;
            this.schedulePingCheck();
        }
    }

    onMessage(ev) {
        const data = JSON.parse(ev.data);
        if (data && data.result) {
            const { event: rawEvent } = data.result;
            if (rawEvent.eventType === EVENT_TYPE_PING) {
                this.handlePing(rawEvent);
            } else {
                this.lastReceivedEvent = rawEvent;
                const event = convertTimePropsToLocal(rawEvent, EVENT_TIME_PROPERTIES);
                event.timestamp = fixTimestampJitter(event.timestamp);
                this.onMessageCallback(event);
            }
        }
    }
}
