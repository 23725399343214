import _get from 'lodash/get'

/* @ngInject */
export default class EmulatorMotionController {
    constructor(
        EventEmitter,
        $scope,
        $state,
        $timeout,
    ) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.event = {
            deskOccupancy: {
                state: _get(this.device, 'reported.deskOccupancy.state', 'NOT_OCCUPIED')
            }
        }
    }

    get prettyState() {
        if (this.event.motion.state === "NOT_OCCUPIED") {
            return "Not Occupied"
        }
        if (this.event.motion.state === "OCCUPIED") {
            return "Occupied"
        }
        return ""
    }

    sendDeskOccupancyEvent() {
        this.onPublishEvent(
            this.EventEmitter({
                event: this.event
            })
        )
    }
}