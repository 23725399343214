/* @ngInject */
export default function blockLoaderDirectiveConfig($compile) {
    function link($scope, $element) {
        let isActive = false;
        const diameter = $scope.diameter || 50;

        function checkPosition(el) {
            if (window.getComputedStyle(el[0], null).getPropertyValue('position') === 'static') {
                el.css('position', 'relative');
            }
        }

        function hide() {
            const blockLoaderNode = $element[0].querySelector('.block-loader');

            if (blockLoaderNode) {
                blockLoaderNode.parentNode.removeChild(blockLoaderNode);
            }

            isActive = false;
        }

        function show() {
            if (isActive) return;

            checkPosition($element);

            const blockLoader = $compile(`<div class="block-loader">
                        <md-progress-circular
                            class="md-hue-2"
                            md-mode="indeterminate"
                            md-diameter="${diameter}px">
                        </md-progress-circular>
                    </div>`)($scope);

            $element.append(blockLoader);
            isActive = true;
        }

        $scope.$watch('dtBlockLoader', (value) => {
            if (value) {
                show();
            } else {
                hide();
            }
        });
    }

    return {
        restrict: 'A',
        scope: {
            dtBlockLoader: '<',
            diameter: '<'
        },
        link
    };
}
