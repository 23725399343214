import { from } from 'rxjs';
// import Highcharts from 'highcharts/js/highstock'; // eslint-disable-line Used through "window.Highcharts"
import moment from 'moment';
import 'moment-timezone'

/* @ngInject */
export default class HighchartsController {
    constructor($timeout, EventEmitter, $element, ProjectManager) {
        this.$timeout = $timeout;
        this.EventEmitter = EventEmitter;
        this.elementNode = $element[0];
        this.ProjectManager = ProjectManager;
    }

    $onChanges(changes) {
        if (changes.config && !changes.config.isFirstChange()) {
            // TODO: introduce check of the whole config not just updating the data
            const { series } = changes.config.currentValue;
            series.forEach(({ data }, index) => {
                this.chart?.series[index]?.setData(data); // eslint-disable-line no-unused-expressions
            });
        }
    }

    $onInit() {
        this.subscription = from(import('./chart.engine'))
            .subscribe(({ Highcharts }) => {
                const timeLocation = this.ProjectManager.currentProject.location.timeLocation;
                if (timeLocation !== '') {
                    // Set default timezone for Moment.js
                    moment.tz.setDefault(timeLocation)
        
                    Highcharts.setOptions({
                        time: {
                            useUTC: true,
                            timezone: timeLocation
                        }
                    })
                }

                this.chart = Highcharts[this.useStockChart ? 'StockChart' : 'chart'](this.elementNode, this.config);

                this.onChartLoaded(
                    this.EventEmitter({
                        chart: this.chart
                    })
                );

                this.$timeout(() => {
                    this.chart.reflow();
                }, 100);

            });
    }

    $onDestroy() {
        this.subscription.unsubscribe();
    }
}
