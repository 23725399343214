import IconComponentOptions from './icon.component';
import IconDirectiveOptions from './icon.directive';
import IconConfig from './icon.config';
import IconBootstrap from './icon.bootstrap';

const IconComponent = {
    selector: 'dtIcon',
    options: IconComponentOptions
};

const IconDirective = {
    selector: 'dtIconAttributes',
    options: IconDirectiveOptions
};

/**
 * Example how to use:
 * <dt-icon icon-class="'window'" color-class="'warning'" has-exclamation="true"></dt-icon>
 * <dt-icon icon-class="'door'" color-class="'accent'" has-exclamation="false" icon-size="'xl'"></dt-icon>
 * <dt-icon icon-class="'touch'" has-exclamation="true" icon-size="'l'" show-aria-label="true"></dt-icon>
 */
export {
    IconComponent, IconDirective, IconConfig, IconBootstrap
};
