const UNAUTHENTICATED_ERR_CODE = 401;

/* @ngInject */
export default function AuthInterceptor($httpProvider) {
    $httpProvider.interceptors.push(/* @ngInject */ (AuthService, $q) => {
        function request(config) {
            const accessToken = AuthService.getAccessToken();

            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            return config;
        }

        function responseError(rejection) {
            const { data, status } = rejection;
            if ((status === UNAUTHENTICATED_ERR_CODE) || (data && data.code === UNAUTHENTICATED_ERR_CODE)) {
                return AuthService.login();
            }
            return $q.reject(rejection);
        }

        return {
            request,
            responseError
        };
    });
}
