const MODIFIER_INFO = 'info';
const MODIFIER_WARNING = 'warning';
const MODIFIER_ERROR = 'error';

/* @ngInject */
export default class InstallationAlertBoxController {
    $onInit() {
        if (this.mutedAlertType) {
            this.alert = {
                "alertType": this.mutedAlertType.alertType,
                "title": this.mutedAlertType.title,
                "muted": true
            };
        }
        switch (this.alert.severity) {
            case 'WARNING':
                this.modifier = MODIFIER_WARNING;
                break;
            case 'ERROR':
                this.modifier = MODIFIER_ERROR;
                break;
            default:
                this.modifier = MODIFIER_INFO;
                break;
        }
        this.expanded = !this.alert.muted;
    }

    get icon() {
        if (this.modifier === MODIFIER_ERROR) {
            return 'report';
        }
        return this.modifier;
    }

    get showTroubleshootLink() {
        return this.alert.troubleshootLink && this.alert.troubleshootLink.length > 0;
    }

    get showExpansionToggle() {
        return this.alert.muted;
    }

    get iconSize() {
        if (!this.expanded) {
            return 'm';
        }
        return 'xl';
    }

    get boxClass() {
        if (!this.expanded) {
            return 'contracted';
        }
        return '';
    }

    onMuteToggled() {
        this.onMuteChanged({type: this.alert.alertType, muted: this.alert.muted});
    }

    toggleExpansion() {
        this.expanded = !this.expanded;
    }
}
