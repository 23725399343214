import options from './ccon-details.component';

const Component = {
    selector: 'dtCloudConnectorDetails',
    options
};

export {
    Component
};
