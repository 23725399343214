import moment from 'moment';
import { getTemperatureUnitSuffix, getHoursMinutesFormat } from 'services/utils';

export default {
    chart: {
        type: 'line',
        marginLeft: 70,
        marginRight: 10,
        height: 680,
        className: 'multi-series-chart'
    },
    scollbar: {
        margin: 40
    },
    yAxis: [
        {
            tickAmount: 4,
            title: {
                align: 'high',
                textAlign: 'left',
                useHTML: true,
                offset: 0,
                text: '<span style="white-space: nowrap;"><b>CO2</b> <span style="opacity: 0.6; font-size: 0.95em;">(ppm)</span></span>',
                rotation: 0,
                y: -8
            },
            labels: {
                get format() {
                    return `{value} ppm`
                }
            },
            minPadding: 0,
            maxPadding: 0,
            endOnTick: false,
            height: '19%',
        },
        {
            visible: true,
            top: '19%',
            height: '8%',
            min: 0,
            max: 1,
            className: 'spacer-series',
            plotBands: [{
                className: 'highcharts-white-plot-band',
                from: 0,
                to: 1
            }]
        },
        {
            top: '27%',
            height: '19%',
            tickAmount: 4,
            offset: 0,
            title: {
                align: 'high',
                textAlign: 'left',
                useHTML: true,
                offset: 0,
                text: '<span style="white-space: nowrap;"><b>Temperature</b> <span style="opacity: 0.6; font-size: 0.95em;">(°C / °F)</span></span>',
                rotation: 0,
                y: -8
            },
            labels: {
                get format() {
                    return `{value}${getTemperatureUnitSuffix()}`;
                }
            }
        },
        {
            visible: true,
            top: '46%',
            height: '8%',
            min: 0,
            max: 1,
            className: 'spacer-series',
            plotBands: [{
                className: 'highcharts-white-plot-band',
                from: 0,
                to: 1
            }]
        },
        {
            top: '54%',
            height: '19%',
            tickAmount: 4,
            offset: 0,
            title: {
                align: 'high',
                textAlign: 'left',
                useHTML: true,
                offset: 0,
                text: '<span style="white-space: nowrap;"><b>Relative humidity</b> <span style="opacity: 0.6; font-size: 0.95em;">(%RH)</span></span>',
                rotation: 0,
                y: -8
            },
            labels: {
                format: '{value}%'
            }
        },
        {
            visible: true,
            top: '73%',
            height: '8%',
            min: 0,
            max: 1,
            className: 'spacer-series',
            plotBands: [{
                className: 'highcharts-white-plot-band',
                from: 0,
                to: 1
            }]
        },
        {
            top: '81%',
            height: '19%',
            tickAmount: 4,
            offset: 0,
            title: {
                align: 'high',
                textAlign: 'left',
                useHTML: true,
                offset: 0,
                text: '<span style="white-space: nowrap;"><b>Barometric pressure</b> <span style="opacity: 0.6; font-size: 0.95em;">(hPa)</span></span>',
                rotation: 0,
                y: -8
            },
            labels: {
                get format() {
                    return `{value} hPa`;
                }
            }
        },
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds    
    },
    legend: {
        enabled: false,
        floating: true,
        verticalAlign: "top",
        itemDistance: 12
    },
    tooltip: {
        enabled: true,
        followPointer: true,
        outside: true,
        shadow: false,
        animation: false,
        hideDelay: 100,
        borderRadius: 12,
        padding: 10,
        shared: true,
        useHTML: true,
        formatter() {
            const momentFormat = `${getHoursMinutesFormat()} ddd, MMM D`;
            const timestamp = moment(this.x).format(momentFormat);
            let tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${timestamp}</p>`;

            let co2AvgOrRaw = null // Average if min/max is present, raw if not
            let tempAvgOrRaw = null
            let humidityAvgOrRaw = null
            let pressureAvgOrRaw = null

            let co2Min = null
            let tempMin = null
            let humidityMin = null
            let pressureMin = null
            
            let co2Max = null
            let tempMax = null
            let humidityMax = null
            let pressureMax = null

            this.points.forEach(function(entry) {
                if (entry.series.name === 'CO2') {
                    co2AvgOrRaw = entry.y.toFixed(0)
                }
                if (entry.series.name === 'Temperature') {
                    tempAvgOrRaw = entry.y.toFixed(2)
                }
                if (entry.series.name === 'Relative humidity') {
                    humidityAvgOrRaw = entry.y.toFixed(2)
                }
                if (entry.series.name === 'Pressure') {
                    pressureAvgOrRaw = entry.y.toFixed(0)
                }
                if (entry.series.name === 'CO2-Min/Max') {
                    co2Min = entry.point.low.toFixed(0)
                    co2Max = entry.point.high.toFixed(0)
                }
                if (entry.series.name === 'Temperature-Min/Max') {
                    tempMin = entry.point.low.toFixed(2)
                    tempMax = entry.point.high.toFixed(2)
                }
                if (entry.series.name === 'Humidity-Min/Max') {
                    humidityMin = entry.point.low.toFixed(2)
                    humidityMax = entry.point.high.toFixed(2)
                }
                if (entry.series.name === 'Pressure-Min/Max') {
                    pressureMin = entry.point.low.toFixed(0)
                    pressureMax = entry.point.high.toFixed(0)
                }
            })

            tooltipHtml += "<table>"
            if (co2Min !== null) {
                tooltipHtml += `
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        <br>CO2 (ppm)
                    </td>
                    <td style="text-align: right; color: white; min-width: 60px;">
                        Max<br><b style="padding-top: 2px;">${co2Max}</b>
                    </td>
                    <td style="text-align: right; color: white; min-width: 60px;">
                        Avg<br><b style="padding-top: 2px;">${co2AvgOrRaw}</b>
                    </td>
                    <td style="text-align: right; color: white; min-width: 60px;">
                        Min<br><b style="padding-top: 2px;">${co2Min}</b>
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 12px; padding-top: 2px;">
                        Temperature (${getTemperatureUnitSuffix()})
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${tempMax}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${tempAvgOrRaw}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${tempMin}
                    </td>
                </tr>
                <tr style="margin-bottom: 10px;">
                    <td style="color: white; padding-right: 12px; padding-top: 2px;">
                        Relative humidity (%)
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${humidityMax}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${humidityAvgOrRaw}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${humidityMin}
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 12px; padding-top: 2px;">
                        Pressure (hPa)
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${pressureMax}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${pressureAvgOrRaw}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${pressureMin}
                    </td>
                </tr>`
            } else {
                tooltipHtml += `
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        CO2
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${co2AvgOrRaw} ppm
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        Temperature
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${tempAvgOrRaw}${getTemperatureUnitSuffix()}
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        Relative humidity
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${humidityAvgOrRaw}%
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        Pressure
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${pressureAvgOrRaw} hPa
                    </td>
                </tr>`
            }
            
            tooltipHtml += "</table>"
            return tooltipHtml
        }
    },
    plotOptions: {
        line: {
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            },
        },
    }
};
