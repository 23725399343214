/* @ngInject */
export default function getWidthDirectiveConfig($timeout) {
    function link($scope, $element) {
        $scope.element = $element[0];

        $timeout(() => {
            $scope.getWidth = $element[0].clientWidth;
        }, 1);

        $scope.$watch('element.clientWidth', (newValue, oldValue) => {
            $timeout(() => {
                if (newValue === oldValue || !newValue) return;

                $scope.getWidth = $element[0].clientWidth;
            }, 40);
        });
    }

    return {
        restrict: 'A',
        scope: {
            getWidth: '='
        },
        link
    };
}
