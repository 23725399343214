export const DEFAULT_TOKEN = '';

export const PAGE_SIZE_OPTIONS = [25, 50, 75, 100];

export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];

export const PAGE_SIZE_STORAGE_KEY = 'dt-paginator-page-size';

export const persistPageSize = (pageSize, suffix) => {
    localStorage.setItem(`${PAGE_SIZE_STORAGE_KEY}-${suffix}`, pageSize);
};

export const getPageSize = (suffix) => {
    const pageSize = localStorage.getItem(`${PAGE_SIZE_STORAGE_KEY}-${suffix}`);
    return pageSize ? parseInt(pageSize, 10) : DEFAULT_PAGE_SIZE;
};
