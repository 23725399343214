const requestURL = 'https://disruptive.zendesk.com/api/v2/requests.json'

/* @ngInject */
export default class FeedbackService {
    constructor($http) {
        this.$http = $http;
    }

    create(feedback) { // eslint-disable-line class-methods-use-this
        
        // Using Zendesk's API directly
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', requestURL, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve();
                } else {
                    reject(this.statusText);
                }
            };
            xhr.onerror = function () {
                reject(this.statusText);
            };
            xhr.send(JSON.stringify(feedback));
        });
    }
}
