import _cloneDeep from 'lodash/cloneDeep';
import { isMobileLayout } from 'services/utils';
import { UPDATE_DEVICE } from 'services/Permissions';

const INPUT_FOR_KEY_ID = 'add-label-input-for-key';

/* @ngInject */
export default class ThingDetailLabelsController {
    constructor(EventEmitter, DialogService, Loader, $timeout, AnalyticsService, RoleManager) {
        this.EventEmitter = EventEmitter;
        this.DialogService = DialogService;
        this.Loader = Loader;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.RoleManager = RoleManager;
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get hasLabels() {
        return this.labels ? Object.keys(this.labels).length : false;
    }

    $onChanges(changes) {
        if (changes.labels) {
            this.originalLabels = _cloneDeep(changes.labels.currentValue);
        }
    }

    $onInit() {
        this.labelKey = '';
        this.labelValue = '';
        this.inputForKeyId = INPUT_FOR_KEY_ID;
        this.editMode = false
        this.isMobile = isMobileLayout();
        // Set default value for alwaysEditable if not provided
        if (this.alwaysEditable === null) {
            this.alwaysEditable = false;
        }

        if (this.alwaysEditable) {
            this.editMode = true;
        }
    }

    addLabel() {
        this.labelInProgress = !this.labelInProgress;
    }

    enterEditMode() {
        this.editMode = !this.editMode
    }

    createLabel() {
        this.AnalyticsService.trackEvent("device.label.added");

        this.Loader.promise = this.onLabelAdd(this.EventEmitter({
            label: {
                key: this.labelKey,
                value: this.labelValue
            }
        })).then(() => {
            this.labelInProgress = false;

            this.labelKey = '';
            this.labelValue = '';

            this.$timeout(() => {
                const input = document.getElementById(INPUT_FOR_KEY_ID);
                if (input) {
                    input.focus();
                }
            }, 0);
        });
    }

    saveValue(labelKey, value) {
        if (value !== this.originalLabels[labelKey]) {
            this.originalLabels[labelKey] = value
            this.AnalyticsService.trackEvent("device.label.updated");
            this.labelsPromise = this.onLabelChange(this.EventEmitter({
                labelKey,
                value
            }));
        }
    }

    showDeleteConfirmation(key) {
        this.DialogService.confirm({
            title: 'Remove label?',
            textContent: `Do you really want to remove label "${key}"?`,
            ariaLabel: 'remove-label',
            ok: 'Remove',
            cancel: 'Cancel'
        }).then(() => {
            this.remove(key);
        }).catch(() => {});
    }

    remove(labelKey) {
        this.AnalyticsService.trackEvent("device.label.removed");
        this.labelsPromise = this.onLabelRemove(this.EventEmitter({
            labelKey
        }));
    }
}
