import { DEFAULT_TOKEN, PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE } from 'services/PaginationHelper';

/* @ngInject */
export default class PaginatorController {
    constructor(EventEmitter) {
        this.EventEmitter = EventEmitter;
    }

    get isHidden() {
        return this.pageSize === DEFAULT_PAGE_SIZE
            && !this.nextPageToken
            && this.currentPageToken === this.prevPageToken;
    }

    $onInit() {
        this.pageSizeOptions = PAGE_SIZE_OPTIONS;
        this.resetTokenHistory();
    }

    $onChanges(changes) {
        if (changes.currentPageToken && changes.currentPageToken.currentValue === DEFAULT_TOKEN) {
            this.resetTokenHistory();
        }
    }

    resetTokenHistory() {
        this.tokenHistory = [];
        this.prevPageToken = DEFAULT_TOKEN;
    }

    navigateBack() {
        const token = this.tokenHistory.pop();
        this.prevPageToken = this.tokenHistory[this.tokenHistory.length - 1] || DEFAULT_TOKEN;
        this.navigate(token);
    }

    navigateForth() {
        this.tokenHistory.push(this.currentPageToken);
        this.prevPageToken = this.currentPageToken;
        this.navigate(this.nextPageToken);
    }

    navigate(pageToken) {
        this.onNavigate(
            this.EventEmitter({
                pageToken
            })
        );
    }

    changePageSize(pageSize) {
        this.onPageSizeChanged(
            this.EventEmitter({
                pageSize
            })
        );
    }
}
