import moment from 'moment';
import { getHoursMinutesSecondsFormat} from 'services/utils';

export const Proximity = {
    chart: {
        height: 226,
        marginBottom: 40,
        type: 'xrange'
    },
    yAxis: [
        {
            min: 0,
            max: 1,
            categories: ['Closed', 'Open'],
            labels: {
                rotation: -90
            }
        }
    ],
    xAxis: {
        visible: false
    },
    tooltip: {
        enabled: true,
        shared: true,
        formatter() {
            return false;
        }
    },
    scrollbar: {
        enabled: false
    },
    plotOptions: {
        xrange: {
            pointPadding: 0,
            minPointLength: 1,
            borderRadius: 2
        },
        series: {
            turboThreshold: 0,
            pointWidth: 30,
            point: {
                events: {
                    mouseOver() {
                        const chart = this.series.chart;
                        const momentFormat = `dddd, MMM D, ${getHoursMinutesSecondsFormat()}`;
                        const startedAt =
                            this.options.startedAt || this.options.x;
                        const lastedFor =
                            this.options.lastedFor ||
                            this.options.x2 - this.options.x;
                        const fromStamp = `<span class="dt-highcharts-number">${moment(
                            startedAt
                        ).format(momentFormat)}</span>`; // eslint-disable-line max-len
                        const duration = `<span class="dt-highcharts-number">${moment
                            .duration(lastedFor)
                            .humanize()}</span>`; // eslint-disable-line max-len
                        if (!chart.lbl) {
                            chart.lbl = chart.renderer
                                .text('', 40, 20, true)
                                .addClass('highchart-custom-tooltip')
                                .add();
                        }
                        const currentCat = parseInt(this.options.y, 10);
                        const catName = this.series.yAxis.categories[
                            currentCat
                        ];
                        const catNameHTML = `<span class="dt-highcharts-number">${catName}</span>`;
                        chart.lbl.show().attr({
                            text: `${catNameHTML} on ${fromStamp}, for ${duration}`
                        });
                    }
                }
            },
            events: {
                mouseOut() {
                    const chart = this.chart;
                    if (chart.lbl) {
                        chart.lbl.hide();
                    }
                }
            }
        }
    }
};

export const ProximityAggregated = {
    navigator: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    chart: {
        type: 'column'
    },
    plotOptions: {
        column: {
            borderWidth: 0,
            borderRadius: 2,
            getExtremesFromAll: true
        },
        series: {
            events: {
                mouseOut() {
                    const chart = this.chart;
                    if (chart.lbl) {
                        chart.lbl.hide();
                    }
                }
            },
            colorIndex: 0
        }
    },
    yAxis: [
        {
            title: {
                text: 'Number of events',
                margin: 0
            },
            min: 0,
            tickInterval: 1,
            allowDecimals: false,
            opposite: false
        }
    ],
    tooltip: {
        enabled: true,
        shared: true,
        shadow: false,
        pointFormatter() {
            const chart = this.series.chart;
            const label = `<span class="dt-highcharts-number">${this.y}</span> event${this.y !== 1 ? 's' : ''} <span class="dt-highcharts-number">${this.key}</span>`
            if (!chart.lbl) {
                chart.lbl = chart.renderer
                    .text('', 40, 20, true)
                    .addClass('highchart-custom-tooltip')
                    .add();
            }

            if (this.y > 0) {
                chart.lbl.show().attr({
                    text: label
                });
            } else {
                chart.lbl.hide();
            }
            return false;
        }
    }
};

export const ProximityAdvanced = {
    navigator: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    chart: {
        type: 'column'
    },
    plotOptions: {
        column: {
            borderWidth: 0,
            borderRadius: 2,
            getExtremesFromAll: true
        },
        series: {
            events: {
                mouseOut() {
                    const chart = this.chart;
                    if (chart.lbl) {
                        chart.lbl.hide();
                    }
                }
            },
            colorIndex: 0
        }
    },
    yAxis: [
        {
            title: {
                text: 'Number of events',
                margin: 0
            },
            min: 0,
            tickInterval: 1,
            allowDecimals: false,
            opposite: false
        }
    ],
    tooltip: {
        enabled: true,
        shared: true,
        shadow: false,
        pointFormatter() {
            const chart = this.series.chart;
            const currentPoint = this;
            const momentFormat = `dddd, MMM D, ${getHoursMinutesSecondsFormat()}`;
            const timeStamp = moment(currentPoint.x).format(momentFormat);
            const label = `<span class="dt-highcharts-number">${
                currentPoint.y
            }</span> event${
                currentPoint.y !== 1 ? 's' : ''
            } on <span class="dt-highcharts-number">${timeStamp}</span>`;

            if (!chart.lbl) {
                chart.lbl = chart.renderer
                    .text('', 40, 20, true)
                    .addClass('highchart-custom-tooltip')
                    .add();
            }

            if (currentPoint.y > 0) {
                chart.lbl.show().attr({
                    text: label
                });
            } else {
                chart.lbl.hide();
            }
            return false;
        }
    }
};
