import options from './sensor-details.component';

const Component = {
    selector: 'dtSensorDetails',
    options
};

export {
    Component
};
