import { isMobileLayout } from 'services/utils';
import { getProductDescription, GOOD_CELLULAR_CONNECTION_THRESHOLD } from 'services/SensorHelper'
import { TRANSFER_DEVICE, UPDATE_DEVICE } from 'services/Permissions';
import { States } from '../../../../app.router';

function getThingColorClass(thing) {
    let colorClass = '';
    if (thing.alertsStatus) {
        for (let i = 0; i < thing.alertsStatus.firingAlerts.length; i++) {
            const alert = thing.alertsStatus.firingAlerts[i];
            if (!alert.muted) {
                if (alert.severity === 'ERROR') {
                    return 'alert';
                }
                colorClass = 'warning';
            }
        }
    }
    return colorClass;
}

/* @ngInject */
export default class ThingsListController {
    constructor(EventEmitter, DialogService, SensorService, $state, $scope, RoleManager, ToastService) {
        this.eventEmitter = EventEmitter;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$scope = $scope;
        this.RoleManager = RoleManager;
        this.SensorService = SensorService;
        this.ToastService = ToastService;

        this.onReorder = this.onReorder.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getThingColorClass = getThingColorClass;
        this.selectionHasCCON = false;
    }

    get canTransferSensors() {
        return this.RoleManager.can(TRANSFER_DEVICE);
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get goodCellularConnectionThreshold() { // eslint-disable-line class-methods-use-this
        return GOOD_CELLULAR_CONNECTION_THRESHOLD;
    }

    get isRowSelectible() {
        return this.$state.is(States.SENSORS) && !isMobileLayout();
    }

    productDescription(device) { // eslint-disable-line class-methods-use-this
        return getProductDescription(device)
    }

    degradedEstimate(device) { // eslint-disable-line class-methods-use-this
        return device.reported.deskOccupancy?.remarks?.includes('INCOMPLETE_DATA')
    }

    isActive(id) {
        return this.$state.params.sensorId === id;
    }

    $onInit() {
        this.allSelected = false;
    }

    onReorder(order) {
        this.onListReorder({
            order
        });
    }

    onChange() {
        
        // There is a limit of 100 devices on batch opeartions (setting labels or moving devices)
        if (this.selected.length > 100) {
            this.selected.splice(100)
            this.$scope.$applyAsync()
            this.ToastService.showSimpleTranslated('sensor_batch_max_limit');
            return
        }

        this.onSelectionChange(
            this.eventEmitter({
                selected: this.selected
            })
        );

        // Check if selection has CCON, used to disable 'Add to Dashboard' button
        // Device will always be in cache
        this.SensorService.getFromCache(this.selected).then(({devices}) => {
            this.selectionHasCCON = !!devices.find(device => device.type === 'ccon')
        })
    }

    transferSensors(event) {
        this.onTransferSensors(
            this.eventEmitter({
                event,
                sensors: this.selected
            })
        );
    }

    addSensorsToDashboard(event) {
        this.onAddSensorsToDashboard(
            this.eventEmitter({
                event,
                sensors: this.selected
            })
        );
    }

    configureSensors(event) {
        this.onConfigureSensors(
            this.eventEmitter({
                event,
                sensors: this.selected
            })
        );
    }
    
    editLabels(event) {
        this.onEditLabels(
            this.eventEmitter({
                event,
                sensors: this.selected
            })
        );
    }

    clearSelection() {
        this.selected = [];
    }
}
