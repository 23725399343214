import controller from './left.controller';

const template = require('./left.html');

export default {
    bindings: {
        nav: '<',
        hideMobileNav: '&',
        onToggle: '&'
    },
    controller,
    template
};
