import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';
import { getResourceName } from 'services/api/helper';
import {
    CREATE_MEMBERSHIP, READ_MEMBERSHIP, UPDATE_MEMBERSHIP, DELETE_MEMBERSHIP,
    CREATE_SERVICEACCOUNT_KEY, DELETE_SERVICEACCOUNT_KEY, UPDATE_SERVICEACCOUNT
} from 'services/Permissions';
import { scrollContainerToActiveChild, getHoursMinutesSecondsFormat } from 'services/utils';

import ShowSecretPopupController from './popups/show-secret/controller';
import ShowSecretPopupTemplate from './popups/show-secret/template.html';

const ROLE_NONE = null;

const highlightNewKey = () => {
    const keysTableNode = document.getElementById('keys-table');
    if (keysTableNode) {
        const highlighted = keysTableNode.querySelector('.row--success');
        if (highlighted) {
            highlighted.classList.remove('row--success');
        }
        const firstRow = keysTableNode.querySelector('tbody tr:first-child');
        if (firstRow) {
            firstRow.classList.add('row--success');
        }
    }
};

/* @ngInject */
export default class DataConnectorController {
    constructor(
        EventEmitter,
        ProjectManager,
        RoleManager,
        ToastService,
        DialogService,
        IAMService,
        ServiceAccountService,
        Loader,
        $timeout,
        $scope
    ) {
        this.EventEmitter = EventEmitter;
        this.ProjectManager = ProjectManager;
        this.RoleManager = RoleManager;
        this.DialogService = DialogService;
        this.toastService = ToastService;
        this.IAMService = IAMService;
        this.ServiceAccountService = ServiceAccountService;
        this.Loader = Loader;
        this.$timeout = $timeout;
        this.$scope = $scope;

        this.onRoleChangeSuccess = this.onRoleChangeSuccess.bind(this);
        this.onRoleChangeFailure = this.onRoleChangeFailure.bind(this);
    }

    get canManipulateRole() {
        return this.RoleManager.hasProjectPermissionTo(CREATE_MEMBERSHIP)
            && this.RoleManager.hasProjectPermissionTo(READ_MEMBERSHIP)
            && this.RoleManager.hasProjectPermissionTo(UPDATE_MEMBERSHIP)
            && this.RoleManager.hasProjectPermissionTo(DELETE_MEMBERSHIP);
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_SERVICEACCOUNT);
    }

    get canCreateKey() {
        return this.RoleManager.can(CREATE_SERVICEACCOUNT_KEY);
    }

    get canDeleteKey() {
        return this.RoleManager.can(DELETE_SERVICEACCOUNT_KEY);
    }
    
    get canDelete() {
        return this.RoleManager.can(DELETE_MEMBERSHIP);
    }

    $onInit() {
        this.originalServiceAccount = _cloneDeep(this.serviceAccount);
        this.roles = this.RoleManager.getProjectRoles();
        this.showRoleDropdown = false;
        this.loadMemberInfo();
        this.Loader.promise = this.loadKeys();
        this.$timeout(scrollContainerToActiveChild);
    }

    loadKeys() {
        return this.ServiceAccountService.keys(this.serviceAccount.name)
            .then(({ data }) => {
                this.keys = data;
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('service_account_keys_wasnt_fetched', {
                    serverResponse
                });
            });
    }

    hideDropdown() {
        setTimeout(() => {
            this.showRoleDropdown = false;
            this.$scope.$applyAsync();
        }, 200);
    }

    formattedTime(timestamp) {
        return moment(timestamp).format(`MMM D, yyyy ${getHoursMinutesSecondsFormat()}`)
    }

    showSecretPopup(keyId, secret) {
        this.DialogService.show({
            controller: ShowSecretPopupController,
            controllerAs: '$ctrl',
            template: ShowSecretPopupTemplate,
            parent: document.body,
            openFrom: 'top',
            closeTo: 'top',
            fullscreen: true,
            locals: {
                keyId,
                secret
            }
        });
    }

    createKey() {
        this.Loader.promise = this.ServiceAccountService.createKey(this.serviceAccount.name)
            .then((key) => {
                this.keys.unshift(key.key);
                this.showSecretPopup(key.key.id, key.secret);

                this.$timeout(highlightNewKey);

                this.toastService.showSimpleTranslated('service_account_key_was_created');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('service_account_key_wasnt_created', {
                    serverResponse
                });
            });
    }

    showDeleteConfirmation(key) {
        this.DialogService.confirm({
            title: 'Remove key?',
            textContent: `Do you really want to remove key "${key.id}"? Any app using this key will lose access.`,
            ariaLabel: 'remove-key',
            ok: 'Remove',
            cancel: 'Cancel'
        }).then(() => {
            this.deleteKey(key);
        }).catch(() => {});
    }

    deleteKey(key) {
        this.keysPromise = this.ServiceAccountService.deleteKey(key.name)
            .then(() => {
                this.keys = this.keys.filter(item => item.name !== key.name);

                this.toastService.showSimpleTranslated('service_account_key_was_removed');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('service_account_key_wasnt_removed', {
                    serverResponse
                });
            });
    }

    loadMemberInfo() {
        return this.IAMService
            .getMember(getResourceName(
                ['projects', this.ProjectManager.currentProjectId],
                ['project.members', this.serviceAccount.id]
            ))
            .then((member) => {
                this.member = member;
                this.accountRole = member.roles[0];
                this.originalAccountRole = this.accountRole;
            }).catch(() => {
                this.accountRole = ROLE_NONE;
                this.originalAccountRole = this.accountRole;
            });
    }

    onRoleChangeSuccess(member) {
        this.member = member;
        this.originalAccountRole = this.accountRole;
        this.toastService.showSimpleTranslated('service_account_role_was_updated');
    }

    onRoleChangeFailure(serverResponse) {
        this.accountRole = this.originalAccountRole;
        if (serverResponse.status === 404) {
            // 404 is likely caused by attempting to edit role of Service Account with higher org access
            // Special handling added as a quick fix to provide a better error message
            this.toastService.showSimpleTranslated('service_account_role_wasnt_updated_org_access', { hideDelay: 5000 });
        } else {
            this.toastService.showSimpleTranslated('service_account_role_wasnt_updated', {
                serverResponse
            });
        }
    }

    saveRole(roleName) {
        this.accountRole = roleName;
        if (this.accountRole !== this.originalAccountRole) {
            let promise;
            if (this.accountRole === ROLE_NONE) {
                promise = this.IAMService
                    .deleteMember(this.member.name)
                    .then(this.onRoleChangeSuccess)
                    .catch(this.onRoleChangeFailure);
            } else {
                if (this.originalAccountRole === ROLE_NONE) {
                    promise = this.IAMService
                        .createProjectMember({
                            email: this.serviceAccount.email,
                            roles: [this.accountRole]
                        })
                        .then(this.onRoleChangeSuccess)
                        .catch(this.onRoleChangeFailure);
                } else {
                    promise = this.IAMService
                        .updateMember(this.member.name, {
                            roles: [this.accountRole]
                        })
                        .then(this.onRoleChangeSuccess)
                        .catch(this.onRoleChangeFailure);
                }
                this.Loader.promise = promise;
            }
        }
    }

    saveAccount(field = null) {
        if (!field || this.originalServiceAccount[field] !== this.serviceAccount[field]) {
            this.onUpdate(
                this.EventEmitter({
                    serviceAccount: this.serviceAccount,
                    patch: {
                        [field]: this.serviceAccount[field]
                    }
                })
            ).then(() => {
                this.originalServiceAccount = _cloneDeep(this.serviceAccount);
            });
        }
    }

    removeAccess() {
        this.onDelete(this.EventEmitter({
            serviceAccount: this.serviceAccount,
        }));
    }
}
