import controller from './alert.controller';

const template = require('./alert.html');

export default {
    selector: 'alert',
    options: {
        bindings: {
            alert: '=',
            showBigFormat: '<?' || false,
            onClose: '&',
            isModal: '<?' || false
        },
        controller,
        template
    }
};
