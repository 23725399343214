import controller from './touch.controller';

const template = require('./touch.html');

export default {
    bindings: {
        device: '<',
        onPublishEvent: '&'
    },
    controller,
    template
};
