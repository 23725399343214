import controller from './things-list.controller';

const template = require('./things-list.html');

export default {
    bindings: {
        order: '=',
        selected: '=',
        onSelectThing: '&',
        onListReorder: '&',
        onTransferSensors: '&',
        onAddSensorsToDashboard: '&',
        onConfigureSensors: '&',
        onEditLabels: '&',
        onSelectionChange: '&',
        things: '<',
    },
    template,
    controller
};
