import controller from './sensor-configurations.controller';

const template = require('./sensor-configurations.html');

export default {
    bindings: {
        thing: '<',
    },
    template,
    controller
};
