import { DEFAULT_TOKEN, getPageSize } from 'services/PaginationHelper';
import { FLAGS_CHANGED } from 'services/StudioEvents';
import { getTemperatureUnitSuffix, celsiusToFahrenheit } from 'services/utils';
import { UPDATE_DEVICE } from 'services/Permissions';
import { States } from '../../app.router';
import { fields } from './rules/rules-detail/rules-detail.controller';
import { Duration } from './rules/rules-detail/duration/duration.controller';

import AlertGuideController from './alert-guide-modal/controller';
import AlertGuideTemplate from './alert-guide-modal/template.html';


const PAGE_SIZE_SUFFIX = 'rules';

/* @ngInject */
export default class RulesController {
    /**
     * @param $state
     * @param $scope
     * @param RoleManager
     * @param {RulesService} RulesService
     * @param {DialogService} DialogService
     * @param {ToastService} ToastService
     * @param {SensorService} SensorService
     */
    constructor($state, $scope, $rootScope, Loader, RoleManager, RulesService, DialogService, ToastService, SensorService, AnalyticsService, UserPreferencesManager) {
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.Loader = Loader;
        this.RoleManager = RoleManager;
        this.RulesService = RulesService;
        this.DialogService = DialogService;
        this.ToastService = ToastService;
        this.SensorService = SensorService;
        this.AnalyticsService = AnalyticsService;
        this.UserPreferencesManager = UserPreferencesManager;
    }

    $onInit() {
        this.rules = [];
        this.Loader.promise = this.RulesService.listRules()
            .then(response => {
                this.rules = response.data;
                this.rules.forEach(rule => {
                    this.countFieldSwitcheroo(rule) // Change field name for count based notifications
                });
            });
        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;
        this.requestParams = {
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        };

        this.streamSubscription = this.SensorService.subscribeToAllUpdates({}, (event) => {
            this.$scope.$broadcast('deviceUpdate', event);
        });
        this.fields = fields;

        this.states = {
            ACTIVE_ALERTS: States.ALERTS,
            LOG: States.ALERTS_LOG,
            RULES: States.ALERTS_RULES,
            RULE_DETAIL: States.ALERTS_RULES_DETAIL
        };
        
        this.alerts = [] // Binding to the active alerts component to show the number of active alerts

        // Temporary hack to get the active alerts count to change in the left menu when opening the alerts page
        this.$rootScope.$broadcast(FLAGS_CHANGED, {});
    }

    setState(state) {
        this.activeState = state;
        this.$state.go(state);
    }

    $onDestroy() {
        if (this.streamSubscription) {
            this.streamSubscription.stop();
        }
    }

    get isDetailView() {
        return this.$state.is(States.RULE_DETAIL);
    }

    get canCreate() {
        // TODO: Replace 'UPDATE_DEVICE' with a rules specific permission.
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get canDelete() {
        // TODO: Replace 'UPDATE_DEVICE' with a rules specific permission.
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    triggerDescription(rule) {
        // Returns a clear and explicit trigger description
        // E.g. 'Humidity above 15%' or 'No water present for 5 minutes'
        const trigger = rule.trigger;
        const measurement = this.fields[trigger.field].displayName;

        const unit = trigger.field === "temperature" ? getTemperatureUnitSuffix() : this.fields[trigger.field].unit

        const humanizedDelay = Duration.humanizedFromString(rule.triggerDelay);
        const delayString = humanizedDelay ? ` for ${humanizedDelay}` : '';

        // If the upper/lower range exists, the field is temperature, and the user wants fahrenheit,
        // convert the value to fahrenheit. Otherwise leave it as-is.
        let lowerRange = trigger.range?.lower;
        let upperRange = trigger.range?.upper;
        if (lowerRange !== null && trigger.field === "temperature" && this.UserPreferencesManager.useFahrenheit) {
            lowerRange = celsiusToFahrenheit(lowerRange).toFixed(1);
        }
        if (upperRange !== null && trigger.field === "temperature" && this.UserPreferencesManager.useFahrenheit) {
            upperRange = celsiusToFahrenheit(upperRange).toFixed(1);
        }
        
        switch (trigger.field) {
            case 'touch':
                return `${this.fields[trigger.field].displayName}${delayString}`;
            case 'temperature':
            case 'co2':
            case 'relativeHumidity':
                switch (trigger.range.type) {
                    case 'OUTSIDE':
                        if (trigger.range.lower !== null && trigger.range.upper !== null) {
                            return `${measurement} outside range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower !== null && trigger.range.upper === null) {
                            return `${measurement} below ${lowerRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower === null && trigger.range.upper !== null) {
                            return `${measurement} above ${upperRange}${unit}${delayString}`;
                        }
                        return 'No trigger';
                    case 'WITHIN':
                        if (trigger.range.lower !== null && trigger.range.upper !== null) {
                            return `${measurement} within range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower !== null && trigger.range.upper === null) {
                            return `${measurement} above ${lowerRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower === null && trigger.range.upper !== null) {
                            return `${measurement} below ${upperRange}${unit}${delayString}`;
                        }
                        return 'No trigger';
                    default: 
                        return 'No trigger';
                }
            case 'objectPresent':
            case 'waterPresent':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.presence]}${delayString}`;
            case 'contact':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.contact]}${delayString}`;
            case 'motion':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.motion]}${delayString}`;
            case 'touchCount':
                return `Touch count reaches ${trigger.triggerCount}`
            case 'proximityCount':
                return `Proximity count reaches ${trigger.triggerCount}`
            case 'connectionStatus':
                switch (trigger.connection) {
                    case 'CLOUD_CONNECTOR_OFFLINE':
                        return 'Cloud Connector offline';
                    case 'SENSOR_OFFLINE':
                        return 'Sensor offline';
                    default:
                        return 'No trigger';
                }
            default:
                return measurement;
        }
    }

    isActive(id) {
        return this.$state.params.ruleId === id;
    }


    // Convert field name for count based notifications (locally in Studio)
    // Converted back to the normal field names when communicating with the backend
    countFieldSwitcheroo(rule) { // eslint-disable-line class-methods-use-this
        if (rule.trigger.field === 'objectPresent' && rule.trigger.triggerCount) {
            rule.trigger.field = 'proximityCount'
        }
        if (rule.trigger.field === 'touch' && rule.trigger.triggerCount) {
            rule.trigger.field = 'touchCount'
        }
    }

    showAlertsGuide() {
        this.DialogService.show({
            controller: AlertGuideController,
            template: AlertGuideTemplate,
            controllerAs: '$ctrl',
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true
        })
    }
}
