import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';
import { getHistoryChartType, getHistoryEventType } from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { AbstractChartController } from '../chart-abstract-base';

/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartTemperatureController extends AbstractChartController {
    constructor(
        SensorEventsLoader,
        ToastService,
        $scope,
        EventEmitter,
    ) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    // Overrides AbstractChartController
    get dataSeries() {
        return {
            addPoint: (data) => {
                this.chart.series[0].addPoint(data, true)
            },
            setData: (data) => {
                this.chart.series[0].setData(data.map(item => [item[0], item[1]])); // Temperature
                if (this.filter?.value === 'GLYCOL') { 
                    this.chart.series[2].setData(data.map(item => [item[0], item[2]])); // Filtered glycol data
                } else {
                    this.chart.series[2].setData(this.chartData.map(item => [item[0], item[2], item[3]])) // Min Max range
                }
            }
        }
    }

    // Overrides AbstractChartController
    getEventTypes() {
        return [this.eventType]
    }

    // Overrides AbstractChartController
    getAggregationFields() {
        return [
            {"fieldName": "temperature.value", "type": "AVERAGE"},
            {"fieldName": "temperature.value", "type": "MIN"},
            {"fieldName": "temperature.value", "type": "MAX"},
            // For offline detection
            {"fieldName": "temperature.value", "type": "COUNT"},
        ];
    }

    // Overrides AbstractChartController
    getConfigPreset() {
        if (this.filter?.value === 'GLYCOL') {
            return ConfigPresets.TemperatureFiltered;

        }
        return ConfigPresets.Temperature;
    }

    // Overrides AbstractChartController
    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        if (this.filter?.value === 'GLYCOL') { 
            return ConfigHelper.temperatureFilterSeries(
                data,
                spacerStart,
                spacerEnd,
                spacerMin,
                spacerMax
            );
        }
        return ConfigHelper.temperatureSeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax
        );
    }

    // Overrides AbstractChartController
    convertEvents(events) {
        this.sampleTimestamps = [];

        if (this.thing.type === 'temperature') {
            events.forEach(event => {
                // Samples are API order (newest first)
                // SampleTimestamps should be in sorted order from oldest → newest
                const samples = event.data.temperature.samples;
                samples.forEach(sample => {
                    this.sampleTimestamps.push(moment(sample.sampleTime).valueOf())
                });
            })
    
            this.sampleTimestamps.sort(); // Sorting in case samples are overlapping
        } else { // Humidity events, we need to convert to temperature format
            this.sampleTimestamps = events.map(event => moment(event.timestamp).valueOf());
            events = events.map(event => { // eslint-disable-line no-param-reassign
                return {
                    timestamp: event.timestamp,
                    data: {
                        temperature: {
                            samples: [
                                {
                                    sampleTime: event.timestamp,
                                    value: event.data.humidity.temperature
                                }
                            ],
                            isBackfilled: false,
                            updateTime: event.timestamp,
                            value: event.data.humidity.temperature
                        },
                        timestamp: event.timestamp,
                        targetName: event.data.targetName,
                        eventId: event.eventId
                    }
                }
            })
        }


        this.plotBands = DataConverter.createOfflineBands(
            this.sampleTimestamps,
            this.chartMin,
            this.chartMax,
            this.thing.productNumber
        );
        this.chartData = DataConverter.syncOfflineTemperature(
            DataConverter.temperature(events),
            this.plotBands
        );
        if (this.filter?.value === 'GLYCOL') {
            this.chartData = this.calculateGlycolFilter(this.chartData);
        }
        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    // Overrides AbstractChartController
    convertAggregated(aggregatedData) {
        this.chartData = aggregatedData.results[0].values.map(aggWindow => {
            return [
                moment(aggWindow.timeWindow).valueOf(),
                DataConverter.celsiusToUserPreference(aggWindow.values["temperature.value_AVERAGE"]),
                DataConverter.celsiusToUserPreference(aggWindow.values["temperature.value_MIN"]),
                DataConverter.celsiusToUserPreference(aggWindow.values["temperature.value_MAX"]),
            ]
        })

        this.plotBands = DataConverter.createAggregatedOfflineBands(
            aggregatedData.results[0].values,
            "temperature.value_COUNT",
        );
        
        return {
            data: this.chartData,
            plotBands: this.plotBands,
        }
    }

    // Overrides AbstractChartController
    convertSamples(events) {
        if (!Array.isArray(events) || events.some(event => !event.data.temperature?.samples)) {
            return []
        }

        const state = DataConverter.splitByStateAndNetworkStatus(events).state
        let samples = DataConverter.getTemperatureSamples(state)
        if (this.filter?.value === 'GLYCOL') {
            samples = this.calculateGlycolFilter(samples)
        }
        return samples
    }

    calculateGlycolFilter(data) {
        if (data.length < 2) { return [] }
        
        // Use a work buffer to delay the start of filter being applied by 3 hours
        const workBuffer = _cloneDeep(data)
        workBuffer[0][2] = data[0][1] // Set initial value
        const startTime = data[0][0]
        const threeHours = 3 * 60 * 60 * 1000

        // Calculate glycol equivalent temperature based on formula from Johannes 🧙‍♂️
        for (let index = 1; index < data.length; index++) {
            const deltaTime = ((data[index][0] - data[index - 1][0]) / 1000)
            const filteredValue = (workBuffer[index - 1][2] - data[index][1]) * Math.exp(-0.000326446*deltaTime) + data[index][1]
            workBuffer[index][2] = filteredValue
            if ((data[index][0] - startTime) > threeHours) {
                data[index][2] = filteredValue
            }
        }
        return data
    }

    // Overrides AbstractChartController
    onStateEventReceived(eventData) {
        // Add each new sample to the chart
        eventData.samples.forEach(sample => {
            const timestamp = +new Date(sample.sampleTime)
            this.sampleTimestamps.push(timestamp)

            const temperature = DataConverter.celsiusToUserPreference(sample.value);
            const point = [timestamp, temperature];
    
            this.adjustMinMaxValues(temperature);
            this.dataSeries.addPoint(point);
        });
        this.sampleTimestamps.sort() // Sort newly appended sample timestamps
        this.redrawPlotBands()
    }

    redrawPlotBands() {
        // Ensure we have all plotBands from the actual chart
        const allPlotBandsIds = [] 
        this.xAxis.plotLinesAndBands.forEach(band => {
            if (band.id.startsWith('offline-since')) {
                allPlotBandsIds.push(band.id)
            }
        })
        // Clear them all before redraw
        allPlotBandsIds.forEach(bandId => { 
            this.xAxis.removePlotBand(bandId)
        })

        // Generate new bands with updated `sampleTimestamps`
        this.plotBands = DataConverter.createOfflineBands(
            this.sampleTimestamps,
            this.chartMin,
            this.chartMax,
            this.thing.productNumber
        );

        this.plotBands.forEach(band => { 
            this.xAxis.addPlotBand(band);
        });

    }

    adjustMinMaxValues(value) {
        if (value < this.spacerMin) {
            this.spacerMin = value;
        } else if (value > this.spacerMax) {
            this.spacerMax = value;
        }
    }

    $onInit() {
        if (this.thing.type === 'co2') {
            this.chartType = 'temperature';
            this.eventType = 'humidity';
        } else {
            this.chartType = getHistoryChartType(this.thing);
            this.eventType = getHistoryEventType(this.thing);
        }

        super.$onInit();
    }
}
