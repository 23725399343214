import controller from './chart-proximity-agg.controller';
import { bindings } from '../chart-abstract-base';
import template from './template.html';

export default {
    bindings: {
        ...bindings,
        onChartSelection: '&',
        onChartPan: '&',
        extremes: '<',
    },
    template,
    controller
};
