/* @ngInject */
export default class CopyableInputController {
    constructor($element, ClipboardService, $window) {
        this.elementNode = $element[0];
        this.ClipboardService = ClipboardService;
        this.$window = $window
    }

    $onInit() {
        // Set the default value for showLabel if it's not provided
        if (this.showLabel === undefined) {
            this.showLabel = true
        }
    }

    copyToClipboard() {
        this.ClipboardService.copyToClipboard(this.elementNode.querySelector('.copyable-value'), this.label);
    }

    openSupportLink() {
        this.$window.open(this.supportLink,'_blank');
    }
}
