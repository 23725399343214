import { getHighchartTimeFormat} from 'services/utils';

export default {
    navigator: {
        enabled: false,
    },
    rangeSelector: {
        enabled: false
    },
    chart: {
        type: 'column'
    },
    tooltip: {
        enabled: true,
        shared: false,
        shadow: false,
        split: false,
        formatter() {

            let tooltipFormat = '';
            const rangeStart = this.x;
            const dataGrouping = this.series.currentDataGrouping;
            const rangeEnd = rangeStart + dataGrouping.totalRange;

            switch (dataGrouping.unitName) {
                
                case 'month':
                    tooltipFormat = '%B %Y';
                    break;
                case 'week':
                    tooltipFormat = 'Week from %e %b %Y';
                    break;
                case 'day':
                    tooltipFormat = '%e %b %Y';
                    break;
                case 'hour':
                case 'minute':
                    tooltipFormat = `%A, %e %b ${getHighchartTimeFormat()} - ${window.Highcharts.dateFormat(getHighchartTimeFormat(), rangeEnd)}`;
                    break;
                default:
                    tooltipFormat = '%A, %b %e, %Y';
                    break;
            }

            const formattedTime = window.Highcharts.dateFormat(tooltipFormat, rangeStart);

            const chart = this.series.chart;
            const currentPoint = this;            
            let pressLabel = 'Sensor touched ';
            const timeLabel = `on <span class="dt-highcharts-number">${formattedTime}</span>`;
            if (currentPoint.y > 1) { // It's a Highcharts aggregation, get x values from series.data array
                pressLabel += ` <span class="dt-highcharts-number">${currentPoint.y}</span> times `;
            }
            if (!chart.lbl) {
                chart.lbl = chart.renderer.text('', 40, 20, true)
                    .addClass('highchart-custom-tooltip')
                    .add();
            }

            if (currentPoint.y > 0) {
                chart.lbl
                    .show()
                    .attr({
                        text: pressLabel + timeLabel
                    });
            } else {
                chart.lbl
                    .hide();
            }
            return false;
        }
    },
    plotOptions: {
        column: {
            borderWidth: 0,
            borderRadius: 2,
            getExtremesFromAll: true,
            groupPadding: 0.2,
            pointPadding: 0.1
        },
        series: {
            events: {
                mouseOut() {
                    const chart = this.chart;
                    if (chart.lbl) {
                        chart.lbl
                            .hide();
                    }
                },
            },
        },
    },
    yAxis: [
        {
            tickInterval: 1,
            allowDecimals: false,
            opposite: false,
        }
    ]
};
