import RulesDeviceListController from './device-list.controller';

const template = require('./device-list.html');

export const RulesDeviceListComponent = {
    selector: 'rulesDeviceList',
    options: {
        template,
        controller: RulesDeviceListController,
        bindings: {
            devices: '=',
            deviceList: '=',
            rule: '<',
            onChange: '&',
            showCount: '<',
            count: '<'
        }
    }
};
