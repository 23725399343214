import { getResourceName } from 'services/api/helper';
import { cloneEntity } from 'services/utils';
import { States } from '../../app.router';

/* @ngInject */
export default function projectsRouter($stateProvider) {
    $stateProvider
        .state(States.DATA_CONNECTORS, {
            url: '/dataconnectors',
            views: {
                'content@main': {
                    component: 'dtDataConnectorsPage'
                }
            }
        })
        .state(States.DATA_CONNECTOR_DETAILS, {
            url: '/{dataConnectorId}',
            params: {
                dataConnector: null
            },
            resolve: {
                isNew: /* @ngInject */ $stateParams => $stateParams.dataConnectorId === 'new',
                dataConnector: /* @ngInject */ (DataConnectorService, isNew, $stateParams, ToastService) => {
                    if (isNew) {
                        return {
                            displayName: '',
                            type: 'HTTP_PUSH',
                            status: 'ACTIVE',
                            events: [],
                            labels: ['name'],
                            httpConfig: {
                                url: '',
                                signatureSecret: '',
                                headers: {}
                            }
                        };
                    }

                    if ($stateParams.dataConnector && $stateParams.dataConnector.id === $stateParams.dataConnectorId) {
                        return cloneEntity($stateParams.dataConnector);
                    }

                    return DataConnectorService.getDataConnector(
                        getResourceName(
                            ['projects', $stateParams.projectId],
                            ['project.dataConnectors', $stateParams.dataConnectorId]
                        )
                    ).catch((serverResponse) => {
                        ToastService.showSimpleTranslated('dataconnector_not_found', {
                            serverResponse
                        });
                        return null;
                    });
                }
            },
            onEnter: /* @ngInject */ (dataConnector, $state, $stateParams) => { // eslint-disable-line consistent-return
                if (!dataConnector) {
                    return $state.target(States.DATA_CONNECTORS, {
                        projectId: $stateParams.projectId
                    });
                }
            },
            component: 'dtDataConnectorDetailsPage'
        });
}
