import _cloneDeep from 'lodash/cloneDeep';
import { ISO3166Countries, ISO3166States } from 'services/utils'
import { FETCHED_BILLING_INFO } from 'services/StudioEvents'

/* @ngInject */
export default class BillingInformationController {
    constructor(DialogService, IAMService, ToastService, ProjectManager, StorageService, $rootScope, billingInfo, AnalyticsService) {
        this.DialogService = DialogService;
        this.IAMService = IAMService;
        this.ToastService = ToastService;
        this.ProjectManager = ProjectManager;
        this.StorageService = StorageService;
        this.$rootScope = $rootScope;
        this.billingInfo = billingInfo;
        this.AnalyticsService = AnalyticsService;
    }

    $onInit() {
        this.info = _cloneDeep(this.billingInfo)
        this.isSavingInfo = false
        
        this.countries = ISO3166Countries()
        this.statesUS = ISO3166States()
    }

    saveInformation() {
        this.isSavingInfo = true
        if (this.info.billingAddress.country !== 'US') {
            this.info.billingAddress.state = ''
        }

        delete this.info.billingReference
        this.IAMService.updateBillingInformation(this.info).then(() => {
            Object.assign(this.billingInfo, this.info)
            this.cancel()
            this.ToastService.showSimpleTranslated('billing_info_was_updated')
            this.$rootScope.$broadcast(FETCHED_BILLING_INFO)
            this.AnalyticsService.trackEvent('billing.billing_info.updated')
            
        }).catch((serverResponse) => {
            this.AnalyticsService.trackEvent('billing.billing_info.update_failed')
            this.ToastService.showSimpleTranslated('billing_info_wasnt_updated', {
                serverResponse
            });
        }).finally(() => {
            this.isSavingInfo = false
        })
    }

    cancel() {
        this.DialogService.cancel();
    }
}
