import Resource from './Resource';
import { getResourceName } from './helper';

/* @ngInject */
export default class ServiceAccountService {
    constructor($http, ProjectManager) {
        this.$http = $http;
        this.ApiService = new Resource($http);
        this.ProjectManager = ProjectManager;
    }

    exports(params) {
        return this.ApiService.list(getResourceName(
            ['projects', this.ProjectManager.currentProjectId],
            ['project.exports']
        ), params, 'exports');
    }

    createExport(data) {
        return this.ApiService.create(getResourceName(
            ['projects', this.ProjectManager.currentProjectId],
            ['project.exports']
        ), data);
    }
}
