import _get from 'lodash/get';
/* @ngInject */
export default class EmulatorEthernetStatusController {
    constructor(
        EventEmitter,
    ) {
        this.EventEmitter = EventEmitter;
    }


    $onInit() {
        this.event = {
            ethernetStatus: {
                macAddress: _get(this.device, "reported.ethernetStatus.macAddress", ""),
                ipAddress: _get(this.device, "reported.ethernetStatus.ipAddress", ""),
            }
        }
    }

    sendEvent() {
        this.onPublishEvent(
            this.EventEmitter({
                event: this.event
            })
        );
    }
}