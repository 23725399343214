import Config from './organization-alerts.router';
import options from './organization-alerts.component';

const Component = {
    selector: 'dtOrganizationAlertsPage',
    options
};

export {
    Config,
    Component
};
