import controller from './rules.controller';

const template = require('./rules.html');

export default {
    selector: 'studioRulesPage',
    options: {
        controller,
        template
    }
};
