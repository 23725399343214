const TRACKED_PARAMS = ['q', 'tabId'];

/* @ngInject */
export default class SensorsController {
    constructor(EventEmitter, $state, $stateParams, $location) {
        this.EventEmitter = EventEmitter;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$location = $location;
    }

    $onInit() {
        TRACKED_PARAMS.forEach((paramName) => {
            this[paramName] = this.$stateParams[paramName];
        });
    }

    uiOnParamsChanged(params) {
        TRACKED_PARAMS.forEach((paramName) => {
            if (paramName in params) {
                this[paramName] = params[paramName];
            }
        });
    }
}
