import { MEASUREMENT_SYSTEM_CHANGED_EVENT } from 'services/StudioEvents';

// These are legacy, and are only present 
const TEMPERATURE_UNIT_STORAGE_KEY = 'temperature-unit';
const TEMPERATURE_UNIT_FAHRENHEIT = 'fahrenheit';
const TEMPERATURE_UNIT_CELSIUS = 'celsius';

const MEASUREMENT_SYSTEM_STORAGE_KEY = 'measurement-system';
export const MEASUREMENT_SYSTEM_METRIC = 'metric';
export const MEASUREMENT_SYSTEM_IMPERIAL = 'imperial';


/* @ngInject */
export default class UserPreferencesManager {
    constructor(StateService, $q, StudioProfileService, ToastService, $rootScope, $exceptionHandler) {
        this.StateService = StateService;
        this.$q = $q;
        this.$exceptionHandler = $exceptionHandler;
        this.StudioProfileService = StudioProfileService;
        this.ToastService = ToastService;
        this.$rootScope = $rootScope;
    }

    getMeasurementSystem() {
        // Check first if there is a measurement system set for the Studio profile
        let measurementSystem = this.StudioProfileService.get(MEASUREMENT_SYSTEM_STORAGE_KEY);
        if (measurementSystem) {
            return measurementSystem;
        }

        // If no measurement system is set, check if there's a temperature unit 
        // specified in localStorage. If not, default to celsius.
        const tempUnit = this.StateService.getItem(TEMPERATURE_UNIT_STORAGE_KEY, TEMPERATURE_UNIT_CELSIUS);
    
        // Try to get the location from the browser to determine the measurement system
        let timeZone = null;
        try {
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {
            console.error('Error getting time zone', e); // eslint-disable-line no-console
        }
    
        // Use the temperature unit and country code to determine the measurement system
        if (tempUnit === TEMPERATURE_UNIT_FAHRENHEIT || (timeZone && timeZone.startsWith('America'))) {
            measurementSystem = MEASUREMENT_SYSTEM_IMPERIAL;
        } else {
            measurementSystem = MEASUREMENT_SYSTEM_METRIC; // Default to metric
        }
    
        return measurementSystem;
    }
    

    setMeasurementSystem(measurementSystem, broadcastChange = true) {
        return this.StudioProfileService.set(MEASUREMENT_SYSTEM_STORAGE_KEY, measurementSystem)
            .then(() => {
                if (broadcastChange) {
                    // TODO: Change this to a measurement system toast when UI shows measurement system
                    this.ToastService.showSimpleTranslated('temperature_unit_saved');
                    this.$rootScope.$broadcast(MEASUREMENT_SYSTEM_CHANGED_EVENT);
                }
            })
            .catch((e) => {
                this.$exceptionHandler(e);
                if (broadcastChange) {
                    // TODO: Change this to a measurement system toast when UI shows measurement system
                    this.ToastService.showSimpleTranslated('temperature_unit_not_saved');
                }
            });
    }

    // Refreshes the local cache of user preferences, and broadcasts a 
    // MEASUREMENT_SYSTEM_CHANGED_EVENT event if the temperature unit has changed.
    refresh() {
        const original = this.StudioProfileService.get(MEASUREMENT_SYSTEM_STORAGE_KEY);

        return this.StudioProfileService.refresh()
            .then(() => {
                const updated = this.StudioProfileService.get(MEASUREMENT_SYSTEM_STORAGE_KEY);

                if (original !== updated) {
                    this.$rootScope.$broadcast(MEASUREMENT_SYSTEM_CHANGED_EVENT);
                }
            })
    }

    get useFahrenheit() {
        return this.getMeasurementSystem() === MEASUREMENT_SYSTEM_IMPERIAL;
    }

    get use24HourClock() {
        return this.getMeasurementSystem() === MEASUREMENT_SYSTEM_METRIC;
    }
}
