import moment from 'moment';
import { getTemperatureUnitSuffix, getHoursMinutesFormat } from 'services/utils';

export default {
    chart: {
        type: 'line',
        marginLeft: 70,
        marginRight: 10,
        height: 400,
        className: 'multi-series-chart'
    },
    scollbar: {
        margin: 40
    },
    yAxis: [
        {
            tickAmount: 4,
            title: {
                align: 'high',
                textAlign: 'left',
                useHTML: true,
                offset: 0,
                text: '<span style="white-space: nowrap;"><b>Temperature</b> <span style="opacity: 0.6; font-size: 0.95em;">(°C / °F)</span></span>',
                rotation: 0,
                y: -8
            },
            labels: {
                get format() {
                    return `{value}${getTemperatureUnitSuffix()}`;
                }
            },
            height: '42%',
        },
        {
            visible: true,
            top: '42%',
            height: '16%',
            min: 0,
            max: 1,
            className: 'spacer-series',
            plotBands: [{
                className: 'highcharts-white-plot-band',
                from: 0,
                to: 1
            }]
        },
        {
            top: '58%',
            height: '42%',
            tickAmount: 4,
            offset: 0,
            minRange: 0.01,
            title: {
                align: 'high',
                textAlign: 'left',
                useHTML: true,
                offset: 0,
                text: '<span style="white-space: nowrap;"><b>Pressure</b> <span style="opacity: 0.6; font-size: 0.95em;">(psi)</span></span>',
                rotation: 0,
                y: -8
            },
            labels: {
                get format() {
                    return `{value:.2f} psi`;
                }
            }
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds    
    },
    legend: {
        enabled: false,
        floating: true,
        verticalAlign: "top",
        itemDistance: 12
    },
    tooltip: {
        enabled: true,
        followPointer: true,
        outside: true,
        shadow: false,
        animation: false,
        hideDelay: 100,
        borderRadius: 12,
        padding: 10,
        shared: true,
        useHTML: true,
        formatter() {
            const momentFormat = `${getHoursMinutesFormat()} ddd, MMM D`;
            const timestamp = moment(this.x).format(momentFormat);
            let tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${timestamp}</p>`;

            let tempAvgOrRaw = null; // Average if min/max is present, raw if not
            let pressureAvgOrRaw = null; // Average if min/max is present, raw if not

            let tempMin = null;
            let tempMax = null;
            
            let pressureMin = null;
            let pressureMax = null;

            this.points.forEach(entry => {
                if (entry.series.name === 'Temperature') {
                    tempAvgOrRaw = entry.y.toFixed(2);
                }
                if (entry.series.name === 'Pressure') {
                    pressureAvgOrRaw = entry.y.toFixed(2);
                }
                if (entry.series.name === 'Temperature-Min/Max') {
                    tempMin = entry.point.low.toFixed(2);
                    tempMax = entry.point.high.toFixed(2);
                }
                if (entry.series.name === 'Pressure-Min/Max') {
                    pressureMin = entry.point.low.toFixed(2);
                    pressureMax = entry.point.high.toFixed(2);
                }
            })
            
            tooltipHtml += "<table>";

            if (pressureMin !== null) {
                tooltipHtml += `
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        <br>Temperature (${getTemperatureUnitSuffix()})
                    </td>
                    <td style=" text-align: right; color: white; min-width: 45px;">
                        Max<br><b style="padding-top: 2px;">${tempMax}</b>
                    </td>
                    <td style="text-align: right; color: white; min-width: 45px;">
                        Avg<br><b style="padding-top: 2px;">${tempAvgOrRaw}</b>
                    </td>
                    <td style="text-align: right; color: white; min-width: 45px;">
                        Min<br><b style="padding-top: 2px;">${tempMin}</b>
                    </td>
                </tr>
                <tr style="margin-bottom: 10px;">
                    <td style="color: white; padding-right: 12px; padding-top: 2px;">
                        Pressure (psi)
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${pressureMax}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${pressureAvgOrRaw}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${pressureMin}
                    </td>
                </tr>
                `;
            } else {
                tooltipHtml += `
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        Temperature
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${tempAvgOrRaw}${getTemperatureUnitSuffix()}
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        Pressure
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${pressureAvgOrRaw} psi
                    </td>
                </tr>
                `;
            }
            
            tooltipHtml += "</table>";
            return tooltipHtml;
        }
    },
    plotOptions: {
        line: {
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            },
        },
    }
};
