import { UserManager } from 'oidc-client';
import AuthConfig from './auth.config';
import { SILENT_REFRESH } from 'services/StudioEvents';

const CURRENT_STATE_STORAGE_KEY = 'dt-auth-preserved-state';

/* @ngInject */
export default class AuthService {
    constructor($urlService, $exceptionHandler, $rootScope) {
        this.$urlService = $urlService;
        this.$exceptionHandler = $exceptionHandler;

        this.storage = window.localStorage;
        this.client = new UserManager(AuthConfig);
        /** @type {AuthData} */
        this.authData = null;
        this.$rootScope = $rootScope;

        this.client.events.addAccessTokenExpiring(() => {
            this.silentLogin().catch((e) => {
                this.$exceptionHandler(e);
                setTimeout(() => {
                    this.login();
                }, 1000);
            });
        });

        this.storeUser = this.storeUser.bind(this);
    }

    getUser() {
        return this.client.getUser().then(this.storeUser);
    }

    storeUser(data) {
        this.authData = data;
        return this.client.storeUser(data)
            .then(() => data)
    }

    getAuthData() {
        return this.authData;
    }

    getUserId() {
        return this.authData?.profile?.sub;
    }

    getAccessToken() {
        return this.authData?.access_token;
    }

    login() {
        return this.client.signinRedirect();
    }

    silentLogin() {
        return this.client.signinSilent()
            .then(this.storeUser)
            .then(() => {
                this.$rootScope.$broadcast(SILENT_REFRESH);
            });
    }

    authorize() {
        return this.client.signinRedirectCallback()
            .then(this.storeUser)
            .catch(err => {
                // It seems like we're getting both "exp is in the past" and "iat is in the future"
                // errors from Studio, which is likely causing the infinite login loop some customers have
                // encountered. The error message also includes the "exp" and "iat" which seems to match
                // the timestamp we received the error message (meaning the ID token the browser received
                // is correct).
                // Source: https://github.com/IdentityModel/oidc-client-js/blob/a8a70ce68bd07373abd7d33e016a97adc5d204bc/src/JoseUtilImpl.js#L97
                // 
                // The oidc-client library has a 5 minute leeway by default, so it seems
                // like the computer of the customers who have encountered this is _more_ than 5 minutes
                // wrong. Daylight Savings Time should NOT matter in this case as we're looking at unix time. 
                // 
                // This just prefixes the error message with the current timestamp of the computer Studio
                // is running on. Hopefully this will let us pin down whether this hypothesis is correct.

                const errMsg = `Now: ${parseInt(Date.now() / 1000, 10)}. ${err.message}`;
                throw new Error(errMsg);
            })
    }

    signOut() {
        this.client.signoutRedirect();
    }

    clearStorage() {
        const { _stateStore: { _prefix, _store } } = this.client;
        Object.keys(_store)
            .filter(key => key.startsWith(_prefix))
            .forEach((key) => {
                _store.removeItem(key);
            });
    }

    storeCurrentState() {
        const match = this.$urlService.match({
            path: window.location.pathname
        });

        if (!match.rule.state) {
            return;
        }

        const state = {
            name: match.rule.state.name,
            params: match.match
        };

        this.storage.setItem(CURRENT_STATE_STORAGE_KEY, JSON.stringify(state));
    }

    getStoredState() {
        const stateRaw = this.storage.getItem(CURRENT_STATE_STORAGE_KEY);

        if (!stateRaw) {
            return null;
        }

        let json;

        try {
            json = JSON.parse(stateRaw);
        } catch (e) {
            json = null;
        }

        this.storage.removeItem(CURRENT_STATE_STORAGE_KEY);

        if (json && json.name && json.params) {
            return {
                name: json.name,
                params: json.params
            };
        }

        return null;
    }
}
