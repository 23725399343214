import controller from './radio-status.controller';

const template = require('./radio-status.html');

export default {
    bindings: {
        offline: '<',
        radio: '<',
        boostMode: '<'
    },
    template,
    controller
};
