import { States } from '../../app.router';

/* @ngInject */
export default function orgAlertsRouter($stateProvider) {
    $stateProvider
        .state(States.ORGANIZATION_ALERTS, {
            url: '/organization-alerts',
            views: {
                'content@main': {
                    component: 'dtOrganizationAlertsPage'
                }
            }
        })

}
