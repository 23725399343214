import { cloneProject } from 'services/ProjectHelper';
import { ORGANIZATION_CHANGED, PROJECT_CHANGED } from 'services/StudioEvents';
import { LAST_VISITED_PROJECTS } from './StudioProfileService';

const EMPTY_PROJECT = {};
const LAST_VISITED_PROJECT_STORAGE_KEY = 'dt-last-visited-project';

const getLastVisitedProjectStorageKey = authService => `${LAST_VISITED_PROJECT_STORAGE_KEY}:${authService.getUserId()}`;

/* @ngInject */
export default class ProjectManager {
    constructor(IAMService, StudioProfileService, $q, StorageService, AuthService, $rootScope) {
        this.IAMService = IAMService;
        this.StudioProfileService = StudioProfileService;
        this.$q = $q;
        this.StorageService = StorageService;
        this.AuthService = AuthService;
        /** @type {$rootScope} */
        this.$rootScope = $rootScope;

        const currentProject = this.StorageService.getItem(getLastVisitedProjectStorageKey(this.AuthService));
        this.currentProject = currentProject ? cloneProject(currentProject) : EMPTY_PROJECT;
    }

    loadLastVisitedProject() {
        let projectPromise;
        if (this.currentProjectId) {
            projectPromise = this.IAMService.getProject(this.currentProjectId);
        } else {
            projectPromise = this.IAMService.projects()
                .then(({ data }) => data[0] || EMPTY_PROJECT);
        }
        projectPromise
            .catch(() => EMPTY_PROJECT)
            .then((project) => {
                if (!this.currentProjectId || project.id === this.currentProjectId) {
                    this.setCurrentProject(project);
                }
            });
    }

    setCurrentProject(project) {
        const lastOrganizationId = this.currentProject.organizationId
        this.currentProject = project;
        if (project.id) {
            // Store the Project ID of the 4 latest visited projects in an array
            let lastVisitedProjects = this.StudioProfileService.get(LAST_VISITED_PROJECTS) || []
            if (lastVisitedProjects.length === 0) {
                this.StudioProfileService.set(LAST_VISITED_PROJECTS, [project.id])
            } else {
                if (lastVisitedProjects.includes(project.id)) { 
                    // Remove ID if already in the array 
                    const index = lastVisitedProjects.indexOf(project.id)
                    lastVisitedProjects.splice(index, 1)
                }
                lastVisitedProjects.push(project.id) // Top is shown first in the project list
                lastVisitedProjects = lastVisitedProjects.splice(-4) // Only the last 4 projects are stored
                this.StudioProfileService.set(LAST_VISITED_PROJECTS, lastVisitedProjects)
            }

            this.StorageService.setItem(getLastVisitedProjectStorageKey(this.AuthService), project);
        } else {
            this.StorageService.removeItem(getLastVisitedProjectStorageKey(this.AuthService));
        }
        this.$rootScope.$broadcast(PROJECT_CHANGED, {project})
        if (lastOrganizationId !== project.organizationId) {
            this.$rootScope.$broadcast(ORGANIZATION_CHANGED)
        }
    }

    get currentProjectId() {
        return this.currentProject.id;
    }

    get currentProjectTimeLocation() {
        return this.currentProject.location ? this.currentProject.location.timeLocation : '';
    }

    get currentProjectName() {
        return this.currentProject.displayName;
    }
}
