import { selectNodeContents } from 'services/utils';

/* @ngInject */
export default class ClipboardService {
    constructor(ToastService) {
        this.ToastService = ToastService;
    }

    copyToClipboard(node, label) {
        selectNodeContents(node);

        let copied;

        try {
            copied = document.execCommand('copy');
        } catch (e) {
            copied = false;
        }

        if (copied) {
            this.ToastService.showSimpleTranslated('copy_to_clipboard_was_successful', {}, {
                label
            });
        } else {
            this.ToastService.showSimpleTranslated('copy_to_clipboard_wasnt_successful');
        }
    }
}
