import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import * as DeviceTypes from 'services/config/SensorTypes';

const SUPPORTED_DEVICES = [
    "temperature",
    "proximity",
    "touch",
    "humidity",
    "touchCounter",
    "proximityCounter",
    "waterDetector",
    "ccon"
]

/* @ngInject */
export default class ProjectFilterController {
    constructor(projects, filters) {
        this.allProjects = projects
        this.filters = filters
    }

    $onInit() {
        this.setupFilters()
    }
    
    clear() {
        this.filters = []
        this.setupFilters()
        this.updateCount()
        this.onUpdateFilters(_cloneDeep(this.filters))
        this.onClose()
    }

    close() {
        this.onClose()
    }

    updateCount() {
        let count = 0
        Object.keys(this.filters).forEach(key => {
            if (this.filters[key].active) {
                count += 1
            }
        });
        this.onUpdateFilterCount(count)
    }

    update() {
        this.updateCount()
        this.onUpdateFilters(_cloneDeep(this.filters))
        this.onClose()
    }

    setupFilters() {
        this.deviceTypes = _pick(DeviceTypes, SUPPORTED_DEVICES);
        
        this.projectOptions = []
        Object.keys(this.allProjects).forEach(project => {
            this.projectOptions.push({
                displayName: this.allProjects[project].displayName,
                id: project,
                active: false
            })
        });

        this.deviceOptions = []
        Object.keys(this.deviceTypes).forEach(type => {
            this.deviceOptions.push({
                displayValue: this.deviceTypes[type].title.replace('sensor', ''),
                value: type
            })
        });
        
        if (this.filters.length === 0) {
            this.filters.push(
                {
                    displayName: "Project",
                    active: false,
                    options: this.projectOptions
                },
                {
                    displayName: "Device type",
                    active: false,
                    dropdown: true,
                    options: this.deviceOptions,
                    optionValue: "temperature"
                    
                },
                {
                    displayName: "Last seen online",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "More than 3 days ago",
                            value: '3_days'
                        },
                        {
                            displayValue: "More than 1 week ago",
                            value: '1_weeks'
                        },
                        {
                            displayValue: "More than 1 month ago",
                            value: '1_months'
                        },
                        {
                            displayValue: "More than 3 months ago",
                            value: '3_months'
                        },
                        {
                            displayValue: "More than 12 months ago",
                            value: '12_months'
                        },
                    ],
                    optionValue: '3_months'
                },
                {
                    displayName: "Connectivity status",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "Online",
                            value: "Online"
                        },
                        {
                            displayValue: "Offline",
                            value: "Offline"
                        }
                    ],
                    optionValue: "Offline"
                },
                {
                    displayName: "Battery status",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "Less than 90%",
                            value: "90"
                        },
                        {
                            displayValue: "Less than 75%",
                            value: "75"
                        },
                        {
                            displayValue: "Less than 50%",
                            value: "50"
                        },
                        {
                            displayValue: "Less than 25%",
                            value: "25"
                        },
                        {
                            displayValue: "Less than 15%",
                            value: "15"
                        },
                    ],
                    optionValue: "15"
                },
                {
                    displayName: "Auto-renew subscription",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "On",
                            value: "On"
                        },
                        {
                            displayValue: "Off",
                            value: "Off"
                        }
                    ],
                    optionValue: "On"
                },
            )
        }
    }
}
