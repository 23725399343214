/* @ngInject */
export default function () {
    function link($scope, $element) {
        const dragHandler = $element[0].querySelector('[dt-drag-handle]');

        function getCoords(elem) {
            const box = elem.getBoundingClientRect();

            return {
                top: box.top + window.pageYOffset,
                left: box.left + window.pageXOffset,
                width: box.width,
                height: box.height
            };
        }

        dragHandler.onmousedown = (e) => {
            e.stopPropagation();

            $element.addClass('opacity-60');
            const coords = getCoords($element[0]);
            const shiftX = e.pageX - coords.left;
            const shiftY = e.pageY - coords.top;
            const maxPosY = document.documentElement.clientHeight - getCoords($element[0]).height;
            const maxPosX = document.documentElement.clientWidth - getCoords($element[0]).width;

            function moveAt(ev) {
                let posX;
                let posY;

                if (ev.pageX - shiftX > 10) {
                    if (ev.pageX - shiftX > maxPosX - 10) {
                        posX = maxPosX;
                    } else {
                        posX = ev.pageX - shiftX;
                    }
                } else {
                    posX = 0;
                }

                if (ev.pageY - shiftY > 10) {
                    if (ev.pageY - shiftY > maxPosY - 10) {
                        posY = maxPosY;
                    } else {
                        posY = ev.pageY - shiftY;
                    }
                } else {
                    posY = 0;
                }

                $element[0].style.left = `${posX}px`;
                $element[0].style.top = `${posY}px`;
            }

            moveAt(e);

            document.onmousemove = (ev) => {
                ev.stopPropagation();
                moveAt(ev);
            };

            document.onmouseup = () => {
                $element.removeClass('opacity-60');
                document.onmousemove = null;
                document.onmouseup = null;
            };
        };

        dragHandler.ondragstart = () => false;
    }

    return {
        restrict: 'A',
        link
    };
}
