import { States } from '../../app.router';

/* @ngInject */
export default function projectsRouter($stateProvider) {
    $stateProvider
        .state(States.DASHBOARD, {
            url: '/dashboard',
            views: {
                'content@main': {
                    component: 'dtDashboardPage'
                }
            },
        })
        .state(States.DASHBOARD_SPECIFIED, {
            url: '/{dashboardId}',
        })
}
