import Config from './project-settings.router';
import options from './project-settings.component';
import ProjectMemberDetailsComponent from './member-details';
import InviteMemberFormComponent from './components/create-member-form';

const Component = {
    selector: 'dtProjectSettingsPage',
    options
};

export {
    Config,
    Component,
    ProjectMemberDetailsComponent,
    InviteMemberFormComponent
};
