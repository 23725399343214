import controller from './header.controller';

const template = require('./header.html');

export default {
    bindings: {
        onShowMobileNav: '&',
        newUserFlow: '<',
    },
    template,
    controller
};
