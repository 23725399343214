import moment from 'moment/moment';
import { getHoursMinutesFormat } from 'services/utils';
import { UPDATE_MEMBERSHIP, CREATE_MEMBERSHIP, DELETE_MEMBERSHIP } from 'services/Permissions';
import { ORGANIZATION_CHANGED } from 'services/StudioEvents';
import { States } from '../../app.router';

const ORG_ADMIN_ROLE = 'roles/organization.admin';
const ORG_VIEWER_ROLE = 'roles/organization.viewer';
const SPECIFIC_PROJECTS_ROLE = 'roles/projects'; // Imaginary role

/* @ngInject */
export default class OrganizationSettingsController {
    constructor(
        IAMService,
        Loader,
        DialogService,
        ToastService,
        $q,
        $state,
        RoleManager,
        AuthService,
        $stateParams,
        ProjectManager,
        $timeout,
        $scope
    ) {
        this.IAMService = IAMService;
        this.Loader = Loader;
        this.DialogService = DialogService;
        this.toastService = ToastService;
        this.$q = $q;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.RoleManager = RoleManager;
        this.AuthService = AuthService;
        this.ProjectManager = ProjectManager;
        this.$timeout = $timeout;
        this.$scope = $scope;

        this.organizations = [];
    }

    get isDetailView() {
        return this.$state.is(States.ORGANIZATION_SETTINGS_MEMBER);
    }

    get numberOfProjectMembers() {
        return this.members.filter(member => !member.isOrgAdmin && !member.isOrgViewer && member.accountType !== 'SERVICE_ACCOUNT').length
    }

    get numberOfOrgAdmins() {
        return this.members.filter(member => member.isOrgAdmin && member.accountType !== 'SERVICE_ACCOUNT').length
    }

    get numberOfServiceAccounts() {
        return this.members.filter(member => member.accountType === 'SERVICE_ACCOUNT').length
    }

    get numberOfOrgViewers() {
        return this.members.filter(member => member.isOrgViewer && member.accountType !== 'SERVICE_ACCOUNT').length
    }

    get orgAdminAccessDescription() {
        return this.roles.find(r => r.name === ORG_ADMIN_ROLE).description || ''
    }

    get orgViewerAccessDescription() {
        return this.roles.find(r => r.name === ORG_VIEWER_ROLE).description || ''
    }

    get projectMemberAccessDescription() {
        return this.roles.find(r => r.name === SPECIFIC_PROJECTS_ROLE).description || ''
    }

    $onInit() {

        this.stateTitle = 'Organization Members';
        
        this.query = '';
        
        this.userEmail = ''
        const authData = this.AuthService.getAuthData();
        if (authData) {
            this.userEmail = authData.profile.email;
        }
    
        this.members = []
        this.filteredMembers = []
        this.roles = this.RoleManager.getOrganizationRoles(true)

        this.newMemberRole = this.roles[0]
        this.temporaryMember = false
        this.selected = []

        this.updateMembersList();

        if(this.isDetailView) {
            this.$state.go(States.ORGANIZATION_SETTINGS_MEMBER);
        }

        // Get a callback when isDetailView changes
        this.$scope.$watch(() => this.isDetailView, (newIsDetailView, oldIsDetailView) => {
            if (newIsDetailView !== oldIsDetailView) {
                if (!newIsDetailView) {
                    this.updateMembersList();
                }
            }
        })

        this.$scope.$on(ORGANIZATION_CHANGED, () => { 
            this.$state.go(States.ORGANIZATION_SETTINGS);
            this.updateMembersList();
        })

        this.$scope.$on('updateMembersList', () => {
            this.updateMembersList();
        })   
    }

    updateMembersList() {
        this.Loader.promise = this.fetchAllMembers().then(mergedMembers => {
            this.members = mergedMembers;
            this.queryChanged()
        }).catch(error => {
            console.error('Error merging members:', error); // eslint-disable-line no-console
        });
    }

    async fetchAllMembers() {
        try {
            const [admins, summary] = await Promise.all([this.fetchAllOrgAdmins(), this.fetchMembersSummary()]);
            const mergedMembers = this.mergeMembersByEmail(admins, summary);
            return mergedMembers;
        } catch (error) {
            console.error("Failed to fetch all members", error); // eslint-disable-line no-console
            throw error;
        }
    }

    async fetchAllOrgAdmins() {
        let allAdmins = [];
        try {
            allAdmins = await this.getOrgAdminsPage(null, []);
        } catch (error) {
            this.toastService.showSimpleTranslated('organization_list_error');
            throw error;
        }
        return allAdmins;
    }
    
    async getOrgAdminsPage(pageToken, previousData) {
        try {
            const response = await this.IAMService.organizationMembers({ pageToken });
            const { data, nextPageToken } = response;
            const allData = [...previousData, ...data];
            if (nextPageToken) {
                return this.getOrgAdminsPage(nextPageToken, allData);
            } 
            return allData;    
        } catch (error) {
            throw error;
        }
    }
    
    async fetchMembersSummary() {
        try {
            const data = await this.IAMService.organizationMembersSummary();
            return data.summaries;
        } catch (serverResponse) {
            this.toastService.showSimpleTranslated('members_wasnt_fetched', { serverResponse });
            throw serverResponse;
        }
    }

    mergeMembersByEmail(admins, summary) {
        const summaryMap = new Map();
        // Sort summary by projectAccessCount in descending order
        const sortedSummary = summary.sort((a, b) => b.projectAccessCount - a.projectAccessCount); 
        sortedSummary.forEach(member => {
            summaryMap.set(member.memberId, member);
        });
        
        admins.forEach(admin => {
            const summaryMember = summaryMap.get(admin.id);
            if (summaryMember) {
                Object.assign(summaryMember, admin);
                summaryMember.isOrgAdmin = admin.roles.includes(ORG_ADMIN_ROLE);
                summaryMember.isOrgViewer = admin.roles.includes(ORG_VIEWER_ROLE);
                summaryMember.memberId = admin.id; // Ensure id is not overwritten if different
            } else {
                admin.memberId = admin.id;
                admin.isOrgAdmin = admin.roles.includes(ORG_ADMIN_ROLE);
                admin.isOrgViewer = admin.roles.includes(ORG_VIEWER_ROLE);
                summaryMap.set(admin.id, admin);
            }
        });

        // Sort merged members based on access
        return Array.from(summaryMap.values()).sort((a, b) => {
            if (a.isOrgAdmin && !b.isOrgAdmin) {
                return -1;
            }
            if (!a.isOrgAdmin && b.isOrgAdmin) {
                return 1;
            }
            if (a.isOrgViewer && !b.isOrgViewer) {
                return -1;
            }
            if (!a.isOrgViewer && b.isOrgViewer) {
                return 1;
            }
            if (a.projectAccessCount < b.projectAccessCount) {
                return 1;
            }
            if (a.projectAccessCount > b.projectAccessCount) {
                return -1;
            }
            if (a.accountType < b.accountType) {
                return 1;
            }
            if (a.accountType > b.accountType) {
                return -1;
            }
            if (a.email < b.email) {
                return -1;
            }
            if (a.email > b.email) {
                return 1;
            }
            return 0;
        });
    }
    
    queryChanged() {
        if (this.query) {
            this.filteredMembers = this.members.filter(member => 
                member.displayName.toLowerCase().includes(this.query.toLowerCase()) || member.email.toLowerCase().includes(this.query.toLowerCase()));
        } else {
            this.filteredMembers = this.members;
        }
    }

    clearQuery() {
        this.query = '';
        this.queryChanged();
    }

    formattedTime(timestamp) {
        return moment(timestamp).format(`MMMM D, yyyy ${getHoursMinutesFormat()}`)
    }

    get canCreateMember() {
        return this.RoleManager.hasOrganizationPermissionTo(CREATE_MEMBERSHIP);
    }

    get canDeleteMember() {
        return this.RoleManager.hasOrganizationPermissionTo(DELETE_MEMBERSHIP);
    }

    get canUpdateMember() {
        return this.RoleManager.hasOrganizationPermissionTo(UPDATE_MEMBERSHIP);
    }

    iconForMember(member) {
        if (member.accountType === 'SERVICE_ACCOUNT') {
            return 'vpn_key'
        } 
        if (member.isOrgAdmin) {
            return 'admin'
        }
        if (member.isOrgViewer) {
            return 'organization_viewer'
        }
        return 'person'
    }
    
    setNewMemberRole(role) {
        this.newMemberRole = role;
        this.hideDropdown();
    }

    hideDropdown() {
        this.showRoleDropdown = false
    }

    inviteMember() {
        const member = {
            email: this.email,
            roles: [this.newMemberRole.name]
        }
        this.addMember({ member });
    }

    addMember({ member }) {
        const promise = this.IAMService.createOrganizationMember(member)
            .then((createdMember) => {
                createdMember.isOrgAdmin = createdMember.roles.includes(ORG_ADMIN_ROLE);
                createdMember.isOrgViewer = createdMember.roles.includes(ORG_VIEWER_ROLE);
                createdMember.memberId = createdMember.id;
                this.members.unshift(createdMember);
                this.toastService.showSimpleTranslated('member_was_created');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('member_wasnt_created', {
                    serverResponse
                });
            });
        this.Loader.promise = promise;
        return promise;
    }

    showDeleteConfirmation({ member }) {
        this.DialogService.confirm({
            title: 'Remove organization access?',
            textContent: `Do you really want to remove access from "${member.email}"?`,
            ariaLabel: 'remove-access',
            ok: 'Remove',
            cancel: 'Cancel',
            multiple: true,
            hasBackdrop: false
        }).then(() => {
            this.deleteMember(member);
        }).catch(() => {});
    }

    showSelectProjects() {
        // Create a fake member object to be used in the select projects dialog
        const toBeInvitedMember = {
            memberId: "TO_BE_INVITED",
            accountType: 'USER',
            createTime: null,
            displayName: this.email,
            email: this.email,
            name: null,
            roles: [],
            status: null
        }

        this.$state.go(States.ORGANIZATION_SETTINGS_MEMBER, {
            memberId: "TO_BE_INVITED",
            member: toBeInvitedMember
        });
    }

    deleteMember(member) {
        this.Loader.promise = this.IAMService.deleteMember(member.name)
            .then(() => {
                this.members = this.members.filter(user => user.name !== member.name);

                this.toastService.showSimpleTranslated('member_was_removed');
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('member_wasnt_removed', {
                    serverResponse
                });
            });
    }

    clearSelected() {
        this.selected = []
    }

    closeModal() {
        this.DialogService.cancel()
    }
}
