import StickyFill from 'stickyfilljs';

/* @ngInject */
export default function stickyDirectiveConfig() {
    function link($scope, $element, $attrs) {
        $element.addClass(`sticky-${$attrs.dtSticky}`);
        StickyFill.add($element);
    }

    return {
        restrict: 'A',
        scope: false,
        link
    };
}
