import {
    getValueFromStore, hasOwnProperty, removeItemFromStore, setValueToStore
} from '../../services/utils';

const STATE_SERVICE_STORAGE_PREFIX = 'state-service:';

export const PERSISTENCE_DRIVER_SESSION_STORAGE = 'sessionStorage';
export const PERSISTENCE_DRIVER_LOCAL_STORAGE = 'localStorage';

const withPrefix = key => `${STATE_SERVICE_STORAGE_PREFIX}${key}`;
const withoutPrefix = key => key.substr(STATE_SERVICE_STORAGE_PREFIX.length);

/* @ngInject */
export default class StateService {
    constructor() {
        this.preloadStateFromStorage();

        this.removeItem = this.removeItem.bind(this);
    }

    static loadValuesFromStore(store) {
        return Object.keys(store)
            .filter(key => key.startsWith(STATE_SERVICE_STORAGE_PREFIX))
            .reduce((acc, key) => ({
                ...acc,
                [withoutPrefix(key)]: getValueFromStore(store, key)
            }), {});
    }

    preloadStateFromStorage() {
        this.state = {
            ...StateService.loadValuesFromStore(window.localStorage),
            ...StateService.loadValuesFromStore(window.sessionStorage)
        };
    }

    setItem(key, value, options = {}) {
        this.state[key] = value;
        if (options.persistenceDriver === PERSISTENCE_DRIVER_SESSION_STORAGE) {
            setValueToStore(window.sessionStorage, withPrefix(key), value);
        }
        if (options.persistenceDriver === PERSISTENCE_DRIVER_LOCAL_STORAGE) {
            setValueToStore(window.localStorage, withPrefix(key), value);
        }
    }

    getItem(key, defaultValue = null) {
        if (hasOwnProperty(this.state, key)) {
            return this.state[key];
        }
        return defaultValue;
    }

    removeItem(key) {
        delete this.state[key];
        removeItemFromStore(window.sessionStorage, withPrefix(key));
        removeItemFromStore(window.localStorage, withPrefix(key));
    }

    removeItems(keys) {
        keys.forEach(this.removeItem);
    }
}
