import controller from './icon.controller';

const template = require('./icon.html');

export default {
    bindings: {
        iconClass: '<',
        colorClass: '<',
        iconSize: '@',
        showAriaLabel: '<'
    },
    template,
    controller
};
