
import { NEW_USER_FLOW, NEW_USER_FLOW_FINISHED } from 'services/StudioEvents';
import { MEASUREMENT_SYSTEM_METRIC, MEASUREMENT_SYSTEM_IMPERIAL } from 'services/UserPreferencesManager';
import { ISO3166Countries, ISO3166States } from 'services/utils'
import { CREATE_MEMBERSHIP } from 'services/Permissions';
import { States } from '../../app.router';

/* @ngInject */
export default class NewUserFlowController {
    
    constructor(AuthService, ProjectManager, RoleManager, IAMService, $state, $scope, $rootScope, ToastService, UserPreferencesManager, BillingCheckHelper) {
        this.AuthService = AuthService;
        this.ProjectManager = ProjectManager;
        this.RoleManager = RoleManager;
        this.IAMService = IAMService;
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.ToastService = ToastService;
        this.UserPreferencesManager = UserPreferencesManager;
        this.BillingCheckHelper = BillingCheckHelper
    }

    get missingBillingInfo() {
        // Check if info object has all required fields
        const info = this.info
        let missingInfo = true
        missingInfo = !info.billingAddress.companyName || !info.billingAddress.country || !info.billingAddress.addr1 || !info.billingAddress.city || !info.billingAddress.zip || !info.subscriptionContact.firstName || !info.subscriptionContact.lastName || !info.subscriptionContact.email
        if (this.separateInvoiceEmail) { // If separate invoice email is enabled, check if that is missing too
            return missingInfo || !info.invoiceContact.email
        }

        return missingInfo
    }

    get billingAddress() {
        const address = this.info.billingAddress

        function hasValue(string) {
            return string.length > 0
        }

        // Check if any required values are missing
        if (Object.keys(address).length > 0 && 
            hasValue(address.companyName) && hasValue(address.country) && 
            hasValue(address.addr1) && hasValue(address.zip) && hasValue(address.city)) {
            const country = ISO3166Countries()[address.country]
            /* eslint-disable prefer-template */ 
            return `${address.companyName}, 
                    ${address.addr1}${address.addr2 !== '' ? (', ' + address.addr2) : ''}, 
                    ${address.zip} ${address.city}${address.country === "US" ? (' ' + ISO3166States()[address.state]) : ''},
                    ${country}`;
            /* eslint-enable prefer-template */ 
        }
        return ""
    }

    $onInit() {
        const authData = this.AuthService.getAuthData();

        this.info = {
            invoiceContact: {
                email: ''
            },
            subscriptionContact: {
                firstName: '',
                lastName: '',
                email: ''
            },
            billingAddress: {
                companyName: '',
                country: '',
                addr1: '',
                addr2: '',
                city: '',
                state: '',
                zip: ''
            },
                
        }
        if (authData) {
            this.userProfile = authData.profile;
            this.info.subscriptionContact.firstName = this.userProfile.given_name
            this.info.subscriptionContact.lastName = this.userProfile.family_name
            this.info.subscriptionContact.email = this.userProfile.email
        }

        this.$rootScope.$broadcast(NEW_USER_FLOW);
        this.flowStep = 0

        this.setHeaderText()
        this.descriptionText = "You'll be up and running shortly"
        
        this.organizationName = ''
        this.countries = ISO3166Countries()
        this.statesUS = ISO3166States()
        this.countryCode = ''
        this.separateInvoiceEmail = false
        this.skippedBillingInfo = false

        this.savingOrganization = false
        this.animateOut = false
    }

    setHeaderText() {
        if (this.userProfile?.given_name?.length > 1) {
            this.headerText = `Hi ${this.userProfile.given_name} – Welcome to Studio`
        } else {
            this.headerText = `Welcome to Studio`
        }
    }

    showIntroSection() {
        this.setHeaderText()
        this.descriptionText = "You'll be up and running shortly"
        this.flowStep = 0
        this.fadeTransition = false
        this.$scope.$applyAsync();
    }

    showCompanyInformation() {
        this.headerText = 'Tell us about your company'
        this.flowStep = 1
        this.$scope.$applyAsync();
    }
    
    showBillingContact() {
        this.headerText = 'Billing information'
        this.flowStep = 2

        this.info.billingAddress.companyName = this.organizationName
        this.info.billingAddress.country = this.countryCode

        this.$scope.$applyAsync();
    }

    skipBillingInfo() {
        this.skippedBillingInfo = true
        this.showReviewAndSave()
    }

    showReviewAndSave() {
        this.headerText = 'Ready to save, does everything look good?'
        this.flowStep = 3
        this.$scope.$applyAsync();
    }

    goBack() {
        if (this.savingOrganization) { // Prevent going back while saving
            return
        }

        this.flowStep -= 1
        if (this.flowStep === 0) {
            this.showIntroSection()
        } else if (this.flowStep === 1) {
            this.showCompanyInformation()
        } else if (this.flowStep === 2) {
            this.skippedBillingInfo = false
            this.showBillingContact()
        }
    }

    changedCountry() {
        // Automatically updating measurement system without showing toast
        if (this.countryCode === 'US') {
            this.UserPreferencesManager.setMeasurementSystem(MEASUREMENT_SYSTEM_IMPERIAL, false) 
        } else {
            this.UserPreferencesManager.setMeasurementSystem(MEASUREMENT_SYSTEM_METRIC, false)
        }
    }

    saveOrganization() {
        this.savingOrganization = true
        this.$scope.$applyAsync();

        if (!this.separateInvoiceEmail) { // If separate invoice email is not enabled, use the same email for invoice contact
            this.info.invoiceContact.email = this.info.subscriptionContact.email
        }

        this.IAMService.createOrganization({
            displayName: this.organizationName,
            countryCode: this.countryCode
        }).then(response => {
            this.fetchNewProject(response.name)
        }).catch(serverResponse => {
            console.error(serverResponse) // eslint-disable-line no-console
            this.ToastService.showSimpleTranslated('organization_wasnt_created', {
                serverResponse
            });
            this.saveOrganization = false
        })
    }

    // Repeated check until the new organization has a project
    fetchNewProject(organization) {
        this.IAMService.projects({ organization }).then(({ data }) => {
            if (data.length === 0) {
                setTimeout(() => {
                    this.fetchNewProject(organization)
                }, 500);
            } else {
                const project = data[0]
                this.ProjectManager.setCurrentProject(project)
                this.confirmProjectPermissions(project)
            }
        }).catch(error => {
            console.error(error) // eslint-disable-line no-console
        });
    }

    // Repeated check until the user has been granted access to the new project
    confirmProjectPermissions(project) {
        this.RoleManager.loadPermissions().then(() => {
            if (this.RoleManager.hasProjectPermissionTo(CREATE_MEMBERSHIP)) {
                if (!this.skippedBillingInfo) {
                    this.BillingCheckHelper.setBillingInfo(this.info)
                }
                this.finishNewUserFlow(project)
            } else {
                setTimeout(() => {
                    this.confirmProjectPermissions(project)
                }, 500);
            }
        })
    }

    finishNewUserFlow(project) {
        this.animateOut = true
        this.$scope.$applyAsync()
        setTimeout(() => {
            this.$rootScope.$broadcast(NEW_USER_FLOW_FINISHED);
            this.$state.go(States.SENSORS, {
                projectId: project.id,
            });
        }, 500);
    }
}
