const StatusBreakpoints = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const getStatus = (rawValue) => {
    const status = StatusBreakpoints.reduce((acc, breakpoint) => (rawValue > acc ? breakpoint : acc), 0);
    return StatusBreakpoints.indexOf(status);
};

export default class RadioWideController {
    $onInit() {
        if (!this.radio) {
            this.status = getStatus(0);
        }
    }

    $onChanges(changes) {
        if (changes.radio) {
            const radioValue = parseInt(changes.radio.currentValue, 10);
            this.status = isNaN(radioValue) ? getStatus(0) : getStatus(radioValue);
        }
    }
}
