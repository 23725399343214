class StorageService {
    constructor() {
        this.store = window.localStorage;
    }

    getItem(key, defaultValue = null) {
        const value = this.store.getItem(key);
        if (value !== null) {
            return JSON.parse(value);
        }
        return defaultValue;
    }

    setItem(key, value) {
        return this.store.setItem(key, JSON.stringify(value));
    }

    removeItem(key) {
        this.store.removeItem(key);
    }

    clear() {
        return this.store.clear();
    }
}

export default StorageService;
