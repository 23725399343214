/* @ngInject */
export default class ChartRangeSelectorController {
    constructor(EventEmitter) {
        this.EventEmitter = EventEmitter;
    }

    setRange(range) {
        this.onSetRange(this.EventEmitter({
            range
        }));
    }
}
