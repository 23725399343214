import angular from 'angular';
import ServiceDefinition from './emulator.service';
import Routes from './emulator.router';
import EmulatorComponent from './emulator.component';
import EmulatorDetailsComponent from './emulator-details';
import DetailsTemperature from './emulator-details/temperature';
import DetailsProximity from './emulator-details/proximity';
import DetailsContact from './emulator-details/contact';
import DetailsTouch from './emulator-details/touch';
import DetailsHumidity from './emulator-details/humidity';
import DetailsCounter from './emulator-details/counter';
import DetailsDeskOccupancy from './emulator-details/deskOccupancy';
import DetailsCO2 from './emulator-details/CO2';
import DetailsMotion from './emulator-details/motion';
import DetailsNetworkStatus from './emulator-details/networkstatus';
import DetailsWaterDetector from './emulator-details/waterDetector';
import DetailsBatteryStatus from './emulator-details/batterystatus';
import DetailsConnectionStatus from './emulator-details/connectionStatus'; 
import DetailsCellularStatus from './emulator-details/cellularstatus';
import DetailsEthernetStatus from './emulator-details/ethernetstatus';
import dtCommon from '../../common';

const deviceEmulator = 'deviceEmulator';
export default deviceEmulator;

angular.module(deviceEmulator, [dtCommon])
    .config(Routes)
    .service('DeviceEmulator', ServiceDefinition)
    .component('dtEmulatorPage', EmulatorComponent)
    .component(EmulatorDetailsComponent.selector, EmulatorDetailsComponent.options)
    .component(DetailsTemperature.selector, DetailsTemperature.options)
    .component(DetailsProximity.selector, DetailsProximity.options)
    .component(DetailsContact.selector, DetailsContact.options)
    .component(DetailsTouch.selector, DetailsTouch.options)
    .component(DetailsCounter.selector, DetailsCounter.options)
    .component(DetailsDeskOccupancy.selector, DetailsDeskOccupancy.options)
    .component(DetailsCO2.selector, DetailsCO2.options)
    .component(DetailsMotion.selector, DetailsMotion.options)
    .component(DetailsNetworkStatus.selector, DetailsNetworkStatus.options)
    .component(DetailsWaterDetector.selector, DetailsWaterDetector.options)
    .component(DetailsBatteryStatus.selector, DetailsBatteryStatus.options)
    .component(DetailsConnectionStatus.selector, DetailsConnectionStatus.options)
    .component(DetailsCellularStatus.selector, DetailsCellularStatus.options)
    .component(DetailsEthernetStatus.selector, DetailsEthernetStatus.options)
    .component(DetailsHumidity.selector, DetailsHumidity.options);
