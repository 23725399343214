/* @ngInject */
export default class EmulatorTouchController {
    constructor(
        EventEmitter,
    ) {
        this.EventEmitter = EventEmitter;
    }

    sendTouchEvent() {
        this.onPublishEvent(
            this.EventEmitter({
                event: {
                    touch: {}
                }
            })
        )
    }
}