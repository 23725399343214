import CO2Image from '../../../../../assets/images/announcement-CO2-emulator.jpg';
import motionImage from '../../../../../assets/images/announcement-motion-emulator.jpg';
import subscriptionImage from '../../../../../assets/images/announcement-subscription-controls.jpg';
import QRImage from '../../../../../assets/images/QR-studio-mobile.jpg';
import motionConfiguration from '../../../../../assets/images/announcement-motion-configuration.png';
import motionHeatmap from '../../../../../assets/images/announcement-motion-heatmap.png';
import proximityOpenClosed from '../../../../../assets/images/announcement-proximity-open-closed.png';
import resolvedNotification from '../../../../../assets/images/announcement-resolved-notification.png';
import cellularConnection from '../../../../../assets/images/announcement-cellular-connection.png';
import recentlyViewedProjects from '../../../../../assets/images/announcement-recently-viewed-projects.png';
import countBasedNotifications from '../../../../../assets/images/announcement-count-based-notifications.png';
import deskMotionOccupancy from '../../../../../assets/images/announcement-desk-motion-occupancy.png';
import veryHighSensitivity from '../../../../../assets/images/announcement-very-high-sensitivity.png';
import datePicker from '../../../../../assets/images/announcement-date-picker.png';
import occupancyHistory from '../../../../../assets/images/announcement-occupancy-history.png';
import multipleDashboards from '../../../../../assets/images/announcement-multiple-dashboards.png';
import springCleaning from '../../../../../assets/images/announcement-spring-cleaning.png';
import heartbeatInterval from '../../../../../assets/images/announcement-heartbeat-interval.png';
import temperature3rdGen from '../../../../../assets/images/announcement-temperature-3rd-gen.png';
import seenSensors from '../../../../../assets/images/announcement-seen-sensors.png';
import co2HB from '../../../../../assets/images/announcement-co2-heartbeat.png';
import orderHistory from '../../../../../assets/images/announcement-order-history.png';
import notificationDevices from '../../../../../assets/images/announcement-notification-devices.png';
import offlineNotification from '../../../../../assets/images/announcement-sensor-offline.png';
import doorWindowSensor from '../../../../../assets/images/announcement-door-window-sensor.png';
import temperatureProbeSensor from '../../../../../assets/images/announcement-temperature-probe-sensor.png';
import deskOccupancy1000Card from '../../../../../assets/images/announcement-1000-desk-occupancy.png';
import deskOccupancyDetectionMode from '../../../../../assets/images/announcement-desk-occupancy-detection-mode.png';
import timeZone from '../../../../../assets/images/announcement-time-zone.png';
import membersOverview from '../../../../../assets/images/announcement-members-overview.png';
import organizationMap from '../../../../../assets/images/announcement-organization-map.png';

import ExtendedStorageInfoController from '../../../../extended-storage-info/controller';
import ExtendedStorageInfoTemplate from '../../../../extended-storage-info/template.html';

/* @ngInject */
export default class NewAnnouncementController {
    constructor(DialogService, AnalyticsService) {
        this.DialogService = DialogService
        this.AnalyticsService = AnalyticsService
        this.CO2Image = CO2Image
        this.motionImage = motionImage
        this.subscriptionImage = subscriptionImage
        this.QRImage = QRImage
        this.motionConfiguration = motionConfiguration
        this.motionHeatmap = motionHeatmap
        this.proximityOpenClosed = proximityOpenClosed
        this.resolvedNotification = resolvedNotification
        this.cellularConnection = cellularConnection
        this.recentlyViewedProjects = recentlyViewedProjects
        this.countBasedNotifications = countBasedNotifications
        this.deskMotionOccupancy = deskMotionOccupancy
        this.veryHighSensitivity = veryHighSensitivity
        this.datePicker = datePicker
        this.occupancyHistory = occupancyHistory
        this.multipleDashboards = multipleDashboards
        this.springCleaning = springCleaning
        this.heartbeatInterval = heartbeatInterval
        this.temperature3rdGen = temperature3rdGen
        this.seenSensors = seenSensors
        this.co2HB = co2HB
        this.orderHistory = orderHistory
        this.notificationDevices = notificationDevices
        this.offlineNotification = offlineNotification
        this.doorWindowSensor = doorWindowSensor
        this.temperatureProbeSensor = temperatureProbeSensor
        this.deskOccupancy1000Card = deskOccupancy1000Card
        this.deskOccupancyDetectionMode = deskOccupancyDetectionMode
        this.timeZone = timeZone
        this.membersOverview = membersOverview
        this.organizationMap = organizationMap
    }

    $onInit() {
        this.AnalyticsService.trackEvent("announcements.opened")
    }

    showExtendedStorageInfo() {
        this.AnalyticsService.trackEvent("announcements.extended_storage_info_opened")
        
        this.onClose()
        this.DialogService.show({
            controller: ExtendedStorageInfoController,
            controllerAs: '$ctrl',
            template: ExtendedStorageInfoTemplate,
            parent: document.body,
            clickOutsideToClose: true,
        })
    }
}
