import Config from 'services/config/local';

export const LAST_VISITED_PROJECTS = 'last-visited-projects';
export const SHOW_MORE_DEVICE_DETAILS = 'show-more-device-details';

/* @ngInject */
export default class StudioProfileService {
    constructor($http, $httpParamSerializer, AuthService) {
        this.$http = $http;
        this.$httpParamSerializer = $httpParamSerializer;
        this.AuthService = AuthService;

        this.getProfileData = this.getProfileData.bind(this);
    }

    // Returns a locally cached object containing the entire key-value 
    // store of the Studio profile.
    getProfileData() {
        return this.AuthService.getAuthData()?.profile?.data || {}
    }

    // Gets a locally cached value for a given key from the Studio 
    // profile's key-value store.
    get(key) {
        return this.getProfileData()[key]
    }

    // Sets the value for a given key in the Studio profile's key-value store.
    // Returns a promise that resolves when both the backend and the local
    // cache has been updated.
    set(key, value) {
        const url = `${Config.links.identityProvider}account/studio/profile/data/${key}/`
        const payload = { value }
        const options = { headers: { 'Content-Type': 'application/json' }}
        
        return this.$http.post(url, payload, options)
            .then(response => {
                const user = this.AuthService.getAuthData();
                user.profile.data = response.data;
                this.AuthService.storeUser(user);
            })
    }

    // Deletes given key in the Studio profile's key-value store.
    // Returns a promise that resolves when both the backend and 
    // the local cache has been updated.
    remove(key) {
        const url = `${Config.links.identityProvider}account/studio/profile/data/${key}/`
        
        return this.$http.delete(url)
            .then(response => {
                const user = this.AuthService.getAuthData();
                user.profile.data = response.data;
                this.AuthService.storeUser(user);
            })
    }

    // Fetches the latest Studio profile's key-value store, and updates
    // the local cache. Returns a promise that resolves when the local
    // cache has been updated.
    refresh() {
        const url = `${Config.links.identityProvider}openid/userinfo`

        return this.$http.get(url)
            .then(response => {
                const user = this.AuthService.getAuthData();
                user.profile.data = response.data.data;
                this.AuthService.storeUser(user);
            })
    }
}
