import moment from 'moment';
import {
    getHistoryChartType,
    getHistoryEventType
} from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { AbstractChartController } from '../chart-abstract-base';

/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartTouchController extends AbstractChartController {
    constructor(SensorEventsLoader, ToastService, $scope,EventEmitter) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    // Overrides AbstractChartController
    getEventTypes() {
        return [this.eventType, 'networkStatus'];
    }

    // Overrides AbstractChartController
    getAggregationFields() {
        return [
            {"fieldName": "touch", "type": "COUNT"},
            // For offline detection
            {"fieldName": "networkStatus.signalStrength", "type": "COUNT"},
        ];
    }

    // Overrides AbstractChartController
    dataToLoad(days) {
        if (days > 31) {
            return "aggregated";
        } 

        return "events";
    }

    getConfigPreset() {
        return ConfigPresets.Touch;
    }

    shouldUseStockChart() {
        return true;
    }

    // Overrides AbstractChartController
    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.touchSeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax,
            {
                dataGrouping: {
                    type: 'column',
                    forced: true
                }
            }
        );
    }

    // Overrides AbstractChartController
    convertEvents(events) {
        const {
            state,
            networkStatus
        } = DataConverter.splitByStateAndNetworkStatus(events);
        const networkEventsTimestamps = DataConverter.getTimestampsFromNetworkStatusEvents(networkStatus)
        this.plotBands = DataConverter.createOfflineBands(
            networkEventsTimestamps,
            this.chartMin,
            this.chartMax
        );
        this.chartData = DataConverter.syncOfflineTouch(
            DataConverter.touch(state),
            this.plotBands
        );
        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    // Overrides AbstractChartController
    convertAggregated(aggregatedData) {
        this.chartData = aggregatedData.results[0].values.map(aggWindow => {
            return [
                moment(aggWindow.timeWindow).valueOf(),
                aggWindow.values.touch_COUNT
            ]
        })

        this.plotBands = DataConverter.createAggregatedOfflineBands(
            aggregatedData.results[0].values,
            "networkStatus.signalStrength_COUNT",
        );
        
        return {
            data: this.chartData,
            plotBands: this.plotBands,
        }
    }

    onStateEventReceived(eventData) {
        const timestamp = +new Date(eventData.updateTime);
        const point = [timestamp, 1];

        this.dataSeries.addPoint(point);
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);

        super.$onInit();
    }
}
