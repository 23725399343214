import { cloneSensor } from 'services/SensorHelper';
import { States } from '../../app.router';

/* @ngInject */
export default function projectsRouter($stateProvider) {
    $stateProvider
        .state(States.EMULATOR, {
            url: '/emulator',
            views: {
                'content@main': {
                    component: 'dtEmulatorPage'
                }
            }
        }).state(States.EMULATOR_DETAILS, {
            url: '/{deviceId}',
            params: {
                device: null
            },
            resolve: {
                isNew: /* @ngInject */ $stateParams => $stateParams.deviceId === 'new',
                device: /* @ngInject */ (DeviceEmulator, isNew, $stateParams, ToastService) => {
                    if (isNew) {
                        return {
                            type: '',
                            labels: {
                                name: ""
                            }
                        };
                    }

                    if ($stateParams.device && $stateParams.device.id === $stateParams.deviceId) {
                        return cloneSensor($stateParams.device);
                    }

                    return DeviceEmulator.getDevice(
                        $stateParams.projectId,
                        $stateParams.deviceId
                    ).catch((serverResponse) => {
                        ToastService.showSimpleTranslated('dataconnector_not_found', {
                            serverResponse
                        });
                        return null;
                    });
                }
            },
            onEnter: /* @ngInject */ (device, $state, $stateParams) => { // eslint-disable-line consistent-return
                if (!device) {
                    return $state.target(States.EMULATOR, {
                        projectId: $stateParams.projectId
                    });
                }
            },
            component: 'dtEmulatorDetailsPage'
        });
}
