import _get from 'lodash/get';
import QrScanner from 'qr-scanner';
import { LABEL_KEY_NAME, animateSensorIcon, PROBE_STATES } from 'services/SensorHelper';
import { hasOwnProperty, dateFromXID, PREFERRED_CAMERA } from 'services/utils';
import { States } from '../../../app.router';
import imgCcon from '../../../assets/images/img-ccon.svg';
import imgCO2 from '../../../assets/images/img_co2.png';
import imgMotion from '../../../assets/images/img_motion.png';

import imgProbeNotConnected from '../../../assets/images/probe_not_connected.png'
import imgProbe2WireConnected from '../../../assets/images/probe_2_wire.png'
import imgProbe3WireConnected from '../../../assets/images/probe_3_wire.png'
import imgProbe4WireConnected from '../../../assets/images/probe_4_wire.png'

/* @ngInject */
export default class QRScannerController {
    constructor(
        IAMService,
        DialogService,
        $state,
        $stateParams,
        ProjectManager,
        SensorService,
        ToastService,
        AnalyticsService,
        $scope,
        FeatureFlags,
        StorageService
    ) {
        this.IAMService = IAMService;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ProjectManager = ProjectManager;
        this.SensorService = SensorService;
        this.ToastService = ToastService;
        this.cconIcon = imgCcon;
        this.co2Icon = imgCO2;
        this.motionIcon = imgMotion;
        this.imgProbeNotConnected = imgProbeNotConnected
        this.imgProbe2WireConnected = imgProbe2WireConnected
        this.imgProbe3WireConnected = imgProbe3WireConnected
        this.imgProbe4WireConnected = imgProbe4WireConnected
        this.AnalyticsService = AnalyticsService;
        this.scope = $scope;
        this.FeatureFlags = FeatureFlags;
        this.StorageService = StorageService;
    }

    get hasDevice() {
        return Object.keys(this.device).length !== 0;
    }

    // Conditional helping text if Kit ID is detected
    get hasClaimingFeature() {
        const hasNewOrg = this.orgCreatedDate && this.orgCreatedDate > new Date("2022-04-22T00:00:00Z")
        return this.FeatureFlags.isActive('billing_manual_claiming') || hasNewOrg;
    }

    get isProbeSensor() {
        return this.device?.productNumber?.includes('102772') || this.thing?.productNumber?.includes('102774')
    }

    get probeIcon() {
        switch (this.probeState) {
            case PROBE_STATES.NOT_CONNECTED || PROBE_STATES.PROBE_WIRE_STATE_UNKNOWN:
                return this.imgProbeNotConnected
            case PROBE_STATES.TWO_WIRE:
                return this.imgProbe2WireConnected
            case PROBE_STATES.THREE_WIRE:
                return this.imgProbe3WireConnected
            case PROBE_STATES.FOUR_WIRE:
                return this.imgProbe4WireConnected
            default:
                return this.imgProbeNotConnected
        }
    }

    $onInit() { 
        this.QRData = ''
        this.device = {}

        this.deviceNameInputVisible = false
        this.updatedDeviceName = ''
        
        this.detectedKitID = false
        this.detectedUnknownQR = false
        this.detectedDeviceNoAccess = false
        this.detectedClaimable = false

        this.scanner = null
        this.startedScanning = false
        this.loadingDevice = false
        this.selectedCamera = this.StorageService.getItem(PREFERRED_CAMERA) ?? 'environment'
        this.cameraList = []
        this.analyticsCurrentCamera = null

        QrScanner.hasCamera().then(hasCamera => {
            if (hasCamera) {
                this.setupScanning()
            } else {
                this.ToastService.showSimpleTranslated('no_camera');
                this.AnalyticsService.trackEvent("device_qr_lookup.no_camera");
            }
        })

        // Setting the orgId and org created date once here to prevent doing it many times
        // in the `hasClaimingFeature` getter.
        this.orgId = this.ProjectManager.currentProject.organization.split("/")[1]
        if (this.orgId) {
            this.orgCreatedDate = dateFromXID(this.orgId)
        }
    }

    setupScanning() {
        const videoElement = document.getElementById('videoElement');

        this.scanner = new QrScanner(videoElement, result => {
            // Don't check QR code it the same as we have or we're already checking one
            if (result.data && this.QRData !== result.data && !this.loadingDevice) {
                this.QRData = result.data
                this.lookupDevice(this.QRData)
            }
        }, {
            calculateScanRegion: video => {
                
                const width = video.videoWidth
                const height = video.videoHeight

                // Reduce the scan area to a centered square
                const smallestDimension = Math.min(width, height)
                const scanRegionSize = Math.round(0.35 * smallestDimension)
                return {
                    x: (width - scanRegionSize) / 2,
                    y: (height - scanRegionSize) / 2,
                    width: scanRegionSize,
                    height: scanRegionSize
                }

            },
            highlightScanRegion: true, // Style overriden in CSS
            highlightCodeOutline: true, // Style overriden in CSS
            preferredCamera: this.selectedCamera,
            onDecodeError: () => {
                // Need to override to prevent the library from printing to console
            }
        });

        this.scanner.start().then(() => {
            QrScanner.listCameras(true).then(cameras => cameras.forEach(camera => {
                this.cameraList.push(camera)
            })).finally(() => {
                this.startedScanning = true
                this.cameraList.forEach(camera => {
                    // Special handling for iPhones with multiple cameras, choosing the one with best up close focus.
                    if (this.selectedCamera === 'environment' && camera.label === 'Back Ultra Wide Camera') {
                        this.selectedCamera = camera.id
                        this.setCamera(camera.id)
                    }
                })
                this.scope.$applyAsync()
            })
        })
    }
    

    setCamera(facingModeOrId) {
        if (facingModeOrId.length > 0 && this.startedScanning) {

            if (this.analyticsCurrentCamera?.id !== facingModeOrId) {
                // The camera has changed. Check if the previous camera (if any) was able to scan a device
                this.userIsDoneWithCurrentCamera();
                
                // Use the name of the selected camera if possible. Otherwise it's possible the user has
                // selected the default camera, and we can't know which specific camera that is. In this
                // case, just include the full list of cameras.
                let cameraName = this.cameraList.find(c => c.id === facingModeOrId)?.label
                if (!cameraName) {
                    cameraName = `${facingModeOrId}(${this.cameraList.map(c => c.label).join(", ")})`
                }                

                this.analyticsCurrentCamera = {
                    id: facingModeOrId,
                    name: cameraName,
                    timestampSelected: new Date(),
                    successfulDeviceScans: 0,
                }
            }

            // Save the selected camera as the preferred one in local storage
            this.StorageService.setItem(PREFERRED_CAMERA, facingModeOrId)
            this.scanner.setCamera(facingModeOrId)
        }
    }

    userIsDoneWithCurrentCamera() {
        if (!this.analyticsCurrentCamera) {
            return;
        }

        // If the user has been trying to scan a devices for 10 or more seconds without finding anything,
        // we'll track that the camera they're using does not work well with scanning QR codes.
        const expectedTimeBeforeUserGivesUpScanning = 10000; // 10 seconds
        const timeSinceCameraWasSelected = new Date() - this.analyticsCurrentCamera.timestampSelected;
        
        if (timeSinceCameraWasSelected > expectedTimeBeforeUserGivesUpScanning && this.analyticsCurrentCamera.successfulDeviceScans === 0) {
            this.AnalyticsService.trackEvent(`device_qr_lookup.camera_unsuccessful.${this.analyticsCurrentCamera.name}`);
        }
    }

    lookupDevice(lookupId) {
        this.loadingDevice = true
        this.detectedDeviceNoAccess = false
        this.detectedClaimable = false
        if (lookupId.length === 20 || lookupId.length === 23) { // Support for Emulated devices
            this.detectedKitID = false
            this.detectedUnknownQR = false

            if (this.eventSubscription) {
                this.eventSubscription.unsubscribe();
            }

            this.SensorService.lookupDevice(lookupId).then(device => {
                this.AnalyticsService.trackEvent("device_qr_lookup.found");

                // Track that the current camera was able to scan a device
                if (this.analyticsCurrentCamera) {
                    this.analyticsCurrentCamera.successfulDeviceScans += 1;
                }

                const projectId = device.name.split('/')[1]
                // Listen for device events (show live touches)
                this.eventSubscription = this.SensorService
                    .createObservableFromThingUpdates(device.id, projectId)
                    .subscribe(event => this.onNewEvents(event));
                this.IAMService.getProject(projectId).then(project => {
                    this.project = project
                    this.device = device
                    if (this.isProbeSensor) {
                        this.probeState = _get(device, 'reported.probeWireStatus.state', PROBE_STATES.NOT_CONNECTED)
                    }
                    this.detectedUnknownQR = false
                    this.detectedDeviceNoAccess = false
    
                    if (Object.prototype.hasOwnProperty.call(this.device.labels, LABEL_KEY_NAME)) {
                        this.updatedDeviceName = this.device.labels[LABEL_KEY_NAME]
                    }
                    setTimeout(() => {
                        this.loadingDevice = false
                        this.scope.$applyAsync()
                    }, 300); // Visual delay 
    
                });
            }).catch(() => {
                setTimeout(() => {
                    // Provide help if a user tries to scan a device that is claimable
                    this.IAMService.getClaimDetails(lookupId, this.orgId).then(response => {
                        if (response.type === 'DEVICE') { // If a Kit is scanned (and the user can claim), guidance for claiming will be always shown
                            
                            // Track that the current camera was able to scan a device
                            if (this.analyticsCurrentCamera) {
                                this.analyticsCurrentCamera.successfulDeviceScans += 1;
                            }

                            if (response.device.isClaimed) {
                                this.AnalyticsService.trackEvent("device_qr_lookup.found.already_claimed_device_detected");
                            } else {
                                this.AnalyticsService.trackEvent("device_qr_lookup.found.claimable_device_detected");
                            }

                            this.detectedClaimable = !response.device.isClaimed;
                        }
                    }).catch(serverResponse => {
                        console.error(serverResponse) // eslint-disable-line no-console
                    })

                    this.device = {};
                    this.detectedDeviceNoAccess = true
                    this.loadingDevice = false
                    this.scope.$applyAsync()
                }, 300); // Visual delay 
            })
        } else { // Not a device QR code, maybe Kit ID or something unknown
            this.device = {}
            const kitRegex = /^[a-zA-Z]{3}-?[0-9]{2}-?[a-zA-Z]{3}$|^[a-zA-Z]{2,}-[a-zA-Z]{2,}-[a-zA-Z]{2,}$/ // Kit ID or old claim-code
            if (kitRegex.test(lookupId)) {
                this.detectedKitID = true
                this.AnalyticsService.trackEvent("device_qr_lookup.kit_detected");
            } else {
                this.detectedUnknownQR = true
                this.AnalyticsService.trackEvent("device_qr_lookup.unknown_qr_code");
            }
            setTimeout(() => {
                this.loadingDevice = false
                this.scope.$applyAsync()
            }, 300); // Visual delay 
        }

        this.scope.$applyAsync()
    }

    onNewEvents(event) {
        this.device.reported = {
            ...this.device.reported,
            ...event.data
        }
        if (event.eventType === 'probeWireStatus') {
            this.probeState = _get(this.device, 'reported.probeWireStatus.state', PROBE_STATES.NOT_CONNECTED);
        }
        if (event.eventType === 'touch') {    
            this.rippleOnSensor();
        }
        this.scope.$applyAsync();
    }

    rippleOnSensor() {
        this.sensorIconNode = document.getElementById('qr-sensor-icon');
        animateSensorIcon(this.sensorIconNode);
    }

    showDeviceNameInput() {
        this.deviceNameInputVisible = true
    }
    
    cancelDeviceRename() {
        this.deviceNameInputVisible = false
    }

    saveDeviceName() {
        if (hasOwnProperty(this.device.labels, LABEL_KEY_NAME)) { // Check if a new label needs to be created
            this.SensorService.updateLabel(this.device.name, LABEL_KEY_NAME, { value: this.updatedDeviceName }).then((label) => {
                this.device.labels.name = label.value
                this.deviceNameInputVisible = false
                this.AnalyticsService.trackEvent("device_qr_lookup.device_name_updated.success")
            }).catch(() => {
                this.ToastService.showSimpleTranslated('label_name_wasnt_updated');
                this.AnalyticsService.trackEvent("device_qr_lookup.device_name_updated.failed")
            });
        } else {
            this.SensorService.createLabel(this.device.name, { key: LABEL_KEY_NAME, value: this.updatedDeviceName }).then(({ value }) => {
                this.device.labels[LABEL_KEY_NAME] = value
                this.deviceNameInputVisible = false
                this.AnalyticsService.trackEvent("device_qr_lookup.device_name_created.success")
            }).catch(() => {
                this.ToastService.showSimpleTranslated('label_name_wasnt_updated');
                this.AnalyticsService.trackEvent("device_qr_lookup.device_name_created.failed")
            });
        }
        
    }

    openDevicePage() {
        this.closeModal()
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: this.project.id,
            sensorId: this.device.id
        });
    }

    closeModal() {
        this.scanner.stop()
        this.DialogService.cancel()
    }

    $onDestroy() { 
        this.userIsDoneWithCurrentCamera();
        this.scanner.stop()
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    }
}