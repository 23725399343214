const MODIFIER_SYSTEM = 'system';
const MODIFIER_INFO = 'info';
const MODIFIER_WARNING = 'warning';
const MODIFIER_ERROR = 'error';

const MODIFIERS = [
    MODIFIER_SYSTEM,
    MODIFIER_INFO,
    MODIFIER_WARNING,
    MODIFIER_ERROR
];

const MODIFIER_ICONS = {
    [MODIFIER_INFO]: 'info',
    [MODIFIER_WARNING]: 'warning',
    [MODIFIER_ERROR]: 'report',
};

/* @ngInject */
export default class MessageBoxController {
    get modifierClass() {
        return MODIFIERS.includes(this.modifier) ? this.modifier : MODIFIER_SYSTEM;
    }

    get hasIcon() {
        return this.modifierClass !== MODIFIER_SYSTEM;
    }

    get icon() {
        return MODIFIER_ICONS[this.modifier];
    }
}
