import { getTemperatureUnitSuffix } from 'services/utils';

export default {
    chart: {
        type: 'line',
        marginLeft: 58
    },
    yAxis: [
        {
            allowDecimals: true,
            minTickInterval: 0.5,
            tickPixelInterval: 32,
            endOnTick: false,
            labels: {
                get format() {
                    return `{value} ${getTemperatureUnitSuffix()}`;
                }
            },
        },
        {
            visible: false
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
    },
    boost: {
        enabled: false
    },
    tooltip: {
        xDateFormat: '%A, %b %e, %H:%M:%S',
        shared: true,
        enabled: true,
        valueDecimals: 2,
    },

    legend: {
        enabled: true,
        floating: true,
        verticalAlign: "top",
        itemDistance: 12
    },
    plotOptions: {
        line: {
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            }
        }
    }
};
