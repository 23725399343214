import controller from './app.controller';

const template = require('./app.html');

/**
 * Application Component
 */
export default {
    template,
    controller
};
