export const STATE_TRANSITION_START_EVENT = 'dt-studio-state-transition-start';
export const APP_CONTENT_RESIZE_EVENT = 'dt-studio-app-content-resize';
export const HEALTH_CHECKER_NETWORK_UP_EVENT = 'dt-studio-health-checker-network-up';
export const HEALTH_CHECKER_PAGE_VISIBLE_EVENT = 'dt-studio-health-checker-page-visible';
export const MEASUREMENT_SYSTEM_CHANGED_EVENT = 'dt-studio-measurement-system-changed';
export const INTEGRATIONS_DROPDOWN_OPEN_EVENT = 'dt-studio-integrations-dropdown-open';
export const DASHBOARD_REFRESH_EVENT = 'dt-studio-dashboard-refresh';
export const SIMULATE_FULLSCREEN = 'simulate-fullscreen';
export const STOP_SIMULATE_FULLSCREEN = 'stop-simulate-fullscreen';
export const NEW_USER_FLOW = 'dt-new-user-flow';
export const NEW_USER_FLOW_FINISHED = 'dt-new-user-flow-finished';
export const ORGANIZATION_CHANGED = 'dt-organization-changed';
export const FETCHED_BILLING_INFO = 'dt-fetched-billing-info';

export const SHOW_MISSING_BILLING_INFO_BADGE = 'dt-show-missing-billing-info-badge'
export const REMOVE_MISSING_BILLING_INFO_BADGE = 'dt-remove-missing-billing-info-badge'

export const SHOW_MISSING_BILLING_INFO_BANNER = 'dt-show-missing-billing-info-banner'
export const REMOVE_MISSING_BILLING_INFO_BANNER = 'dt-remove-missing-billing-info-banner'

export const SHOW_OVERDUE_INVOICE_BADGE = 'dt-show-overdue-invoice-badge'
export const REMOVE_OVERDUE_INVOICE_BADGE = 'dt-remove-overdue-invoice-badge'

export const SHOW_OVERDUE_INVOICE_BANNER = 'dt-show-overdue-invoice-banner'
export const REMOVE_OVERDUE_INVOICE_BANNER = 'dt-remove-overdue-invoice-banner'

export const SILENT_REFRESH = 'dt-silent-refresh';

export const PROJECT_CHANGED = 'ProjectChanged'
export const FLAGS_CHANGED = 'FlagsChanged'

export const SHOW_ORGANIZATION_ALERTS = 'dt-show-organization-alerts'
export const HIDE_ORGANIZATION_ALERTS = 'dt-hide-organization-alerts'

export const ORG_LOGO_CHANGED = 'dt-org-logo-changed'