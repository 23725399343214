import controller from './data-connector.controller';

const template = require('./data-connector.html');

export default {
    bindings: {
        dataConnector: '<',
        isNew: '<',
        onChange: '&',
        onUpdate: '&',
        onDelete: '&',
        onCreate: '&',
        onMetricsUpdate: '&'
    },
    controller,
    template
};
