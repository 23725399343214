import controller from './live-desk-occupancy-card.controller';

const template = require('./live-desk-occupancy-card.html');

export default {
    bindings: {
        card: '<',
        canUpdate: '<',
    },
    template,
    controller
};