import Config from './service-accounts.router';
import options from './service-accounts.component';
import ServiceAccountDetailsComponent from './account-details';

const Component = {
    selector: 'dtServiceAccountsPage',
    options
};

export {
    Config,
    Component,
    ServiceAccountDetailsComponent
};
