import { 
    FLAGS_CHANGED
} from 'services/StudioEvents';

/**
 * Usage: <span feature-flag="my-feature">
 * @param  {FeatureFlags} FeatureFlags
 * @ngInject
 */
export default function featureFlagDirective(FeatureFlags) {
    return {
        link(scope, element, { featureFlag }) {

            function showOrHide() {
                const show = FeatureFlags.isActive(scope.featureFlag);
                if (show) {
                    element.removeClass('ng-hide');
                } else {
                    element.addClass('ng-hide');
                }
            };

            showOrHide();
            scope.$watch(featureFlag, showOrHide);
            scope.$on(FLAGS_CHANGED, showOrHide);
        },
        restrict: 'A',
        scope: {
            featureFlag: '@'
        }
    };
}
