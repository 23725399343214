import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _round from 'lodash/round';
import { celsiusToFahrenheit, fahrenheitToCelsius, getTemperatureUnitSuffix } from 'services/utils';
import { MEASUREMENT_SYSTEM_CHANGED_EVENT } from 'services/StudioEvents';

/* @ngInject */
export default class EmulatorCO2Controller {
    constructor(
        EventEmitter,
        $scope,
        $rootScope,
        $state,
        $timeout,
        UserPreferencesManager
    ) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.UserPreferencesManager = UserPreferencesManager;
    }

    $onInit() {
        this.determineValues()

        this.$rootScope.$on(MEASUREMENT_SYSTEM_CHANGED_EVENT, () => {
            this.determineValues()
        })
    }

    determineValues() {
        this.events = {
            co2: {
                ppm: _get(this.device, 'reported.co2.ppm', 0)
            },
            humidity: {
                temperature: this.convertedTemperature(_get(this.device, 'reported.humidity.temperature', 0)),
                relativeHumidity: _get(this.device, 'reported.humidity.relativeHumidity', 0)
            },
            pressure: {
                pascal: _get(this.device, 'reported.pressure.pascal', 50000) / 100 // Convert from Pascal to hPa, default to 500 hPa
            } 
        }
        
        this.minTemp = this.convertedTemperature(0)
        this.maxTemp = this.convertedTemperature(50)
        this.unitSuffix = getTemperatureUnitSuffix()
    }

    convertedTemperature(temperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? _round(celsiusToFahrenheit(temperature), 2)
            : temperature;
    }

    ensureCelsius(temperature) {
        return this.UserPreferencesManager.useFahrenheit
            ? _round(fahrenheitToCelsius(temperature), 2)
            : temperature;
    }

    sendMultipleEvents() {

        this.onPublishEvent(
            this.EventEmitter({
                event: {
                    co2: this.events.co2
                }    
            })
        )

        // Send value as Celsius, keep local state if Fahrenheit
        const humidityEvent = _cloneDeep(this.events.humidity)
        humidityEvent.temperature = this.ensureCelsius(humidityEvent.temperature)
        
        this.onPublishEvent(
            this.EventEmitter({
                event: {
                    humidity: humidityEvent
                } 
            })
        )

        this.onPublishEvent(
            this.EventEmitter({
                event: {
                    pressure: {
                        pascal: this.events.pressure.pascal * 100 // Convert from hPa to Pascal before emitting
                    }
                }
            })
        )
    }
}