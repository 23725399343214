import { States } from '../../app.router';

/* @ngInject */
export default function projectsRouter($stateProvider) {
    $stateProvider
        .state(States.EXPORTS, {
            url: '/exports',
            views: {
                'content@main': {
                    component: 'dtExportsPage'
                }
            }
        })
        .state(States.EXPORT, {
            url: '/export',
            component: 'dtExportPage'
        })
}
