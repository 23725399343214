
/* @ngInject */
export default class DashboardCardController {
    constructor(EventEmitter, $scope, $state) {
        this.EventEmitter = EventEmitter;
        this.$scope = $scope;
        this.$state = $state;
    }

    $onInit() {
        this.singleDeviceCard = false;
        this.multipleDevicesCard = false;

        if (this.card.deviceConfig.deviceIds.length > 1) {
            this.multipleDevicesCard = true;
        } else {
            this.singleDeviceCard = true;
        }  
    }

    editCard() {
        this.onEditCard(this.EventEmitter({
            card: this.card
        }));
    }

    editDevices() {
        this.onEditDevices(this.EventEmitter({
            card: this.card
        }));
    }
    
    removeMissingDevices() {
        this.onRemoveMissingDevices(this.EventEmitter({
            card: this.card
        }))
    }

    toggleLegend() {
        this.onToggleLegend(this.EventEmitter({
            card: this.card
        }));
    }
    
    updateAppearance() {
        this.onUpdateAppearance(this.EventEmitter({
            card: this.card
        }));
    }

    remove() {
        this.onRemove(this.EventEmitter({
            card: this.card
        }));
    }
}
