import controller from './paginator.controller';

const template = require('./paginator.html');

export default {
    bindings: {
        currentPageToken: '<',
        nextPageToken: '<',
        pageSize: '<',
        hidePageSelector: '<',
        onPageSizeChanged: '&',
        onNavigate: '&'
    },
    template,
    controller
};
