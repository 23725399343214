import { parseQuery } from 'services/QueryParser';
import { States } from '../../../../app.router';
import imgTouchTheSensor from '../../../../assets/images/identify-sensor-touch.svg';
import imgStarterKitLabel from '../../../../assets/images/starter-kit-label.png';

const TIME_TO_STILL = 5000; // 5 seconds
const TIME_TO_FAIL = 15000; // 15 seconds

const MAX_PAGE_SIZE = 100;

/**
 * @param showAll if set, this filter will always return true
 * @param data the subset of sensors we are interested in
 * @return function(*): boolean
 */
function makeEventFilter({ showAll, data }) {
    if (showAll) {
        return () => true;
    }
    const nameSet = new Set(data.map(sensor => sensor.name));
    return event => nameSet.has(event.targetName);
}

/* @ngInject */
export default class IdentifySensorPopupController {
    constructor(DialogService, SensorService, $scope, $timeout, $state, $stateParams, $q, searchTerm, ProjectManager, AnalyticsService) {
        this.DialogService = DialogService;
        this.SensorService = SensorService;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$q = $q;
        this.searchTerm = searchTerm;
        this.ProjectManager = ProjectManager;
        this.AnalyticsService = AnalyticsService;

        this.imgTouchTheSensor = imgTouchTheSensor;
        this.imgStarterKitLabel = imgStarterKitLabel;

        this.maxPageSize = MAX_PAGE_SIZE;
        this.moreSensorsExist = false;

        this.stopSniffing = this.stopSniffing.bind(this);

        this.startListening();
    }

    startListening() {
        this.initSniffing();
        this.AnalyticsService.trackEvent("device.sniffer.opened");
    }

    stopSniffing() {
        if (this.liveDataSubscription) {
            this.liveDataSubscription.stop();
        }
    }

    initSniffing() {
        const filter = this.$stateParams.filter;
        const parsedQuery = parseQuery(filter || '');

        let whenReady = this.$q.resolve({ showAll: true });

        // If a free text search is given, we must filter events against known sensors
        if (parsedQuery.query) {
            whenReady = this.SensorService.sensors({
                pageSize: this.maxPageSize,
                ...parsedQuery
            })
                .catch(() => {});
        }

        whenReady.then(({ data, nextPageToken, showAll }) => {
            if (!showAll && (!data || data.length === 0)) {
                this.status = 'noSensorsMatch';
                return;
            }
            this.initTimers();

            const eventSatisfiesQuery = makeEventFilter({ data, showAll });

            // Display warning if more sensors exist
            this.moreSensorsExist = !!nextPageToken;

            this.liveDataSubscription = this.SensorService.subscribeToAllUpdates({
                filter,
                eventTypes: ['touch']
            }, (event) => {
                if (!eventSatisfiesQuery(event)) {
                    return;
                }
                const sensorId = event.targetName.split('/').pop();
                this.$state.go(States.SENSOR_DETAILS, {
                    sensorId,
                    ref: 'identify_sensor'
                });

                this.AnalyticsService.trackEvent("device.sniffer.identified");

                this.stopSniffing();
            });
        });
        this.$scope.$on('$destroy', this.stopSniffing);
    }

    initTimers() {
        this.status = 'start';

        this.$timeout(() => {
            this.status = 'still';
        }, TIME_TO_STILL);

        this.$timeout(() => {
            this.status = 'fail';
            this.stopSniffing();
        }, TIME_TO_FAIL);
    }

    cancel() {
        this.DialogService.cancel();
    }
}
