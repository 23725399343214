/* @ngInject */
export default function getFocusDirectiveConfig($timeout) {
    function link($scope, $element) {
        $scope.$watch('getFocus', (value) => {
            if (value) {
                $timeout(() => {
                    if ($scope.autoSelect) {
                        $element[0].select();
                    }
                    $element[0].focus();
                }, 1);
            }
        });
    }

    return {
        restrict: 'A',
        scope: {
            getFocus: '<',
            autoSelect: '<'
        },
        link
    };
}
