import { getDisplayName } from 'services/SensorHelper';
import { getEntityProject, hasOwnProperty } from 'services/utils';

const CCON_INFO_STRUCTURE = {
    ccon: null,
    cconId: null,
    cconDisplayName: null,
    project: null,
    projectId: null,
    projectName: null,
    projectDisplayName: null,
    projectAccessible: null,
    projectIsCurrent: null
};

function projectId(projectName) {
    return projectName.split('/').slice(1).join('')
}

/* @ngInject */
export default class CloudConnectorHelper {
    constructor(SensorService, IAMService, $stateParams) {
        this.SensorService = SensorService;
        this.IAMService = IAMService;
        this.$stateParams = $stateParams;

        this.dataMap = {};
    }

    initDataMap(dataMap = {}) {
        this.dataMap = dataMap;
    }

    loadInfo(cconId) {
        if (!hasOwnProperty(this.dataMap, cconId)) {
            this.dataMap[cconId] = this.SensorService
                .lookupDevice(cconId)
                .then(ccon => ({
                    ...CCON_INFO_STRUCTURE,
                    ccon,
                    cconId: ccon.id,
                    cconDisplayName: getDisplayName(ccon),
                    projectAccessible: true,
                    projectName: getEntityProject(ccon.name)
                }))
                .catch(() => ({
                    ...CCON_INFO_STRUCTURE,
                    cconId,
                    projectAccessible: false
                }))
                .then((cconInfo) => {
                    if (cconInfo.projectName) {
                        return this.IAMService
                            .getProject(projectId(cconInfo.projectName))
                            .then(project => ({
                                ...cconInfo,
                                project,
                                projectId: project.id,
                                projectDisplayName: project.displayName,
                                projectIsCurrent: project.id === this.$stateParams.projectId
                            }));
                    }
                    return cconInfo;
                });
        }
        return this.dataMap[cconId];
    }
}
