import Resource from 'services/api/Resource';
import { getResourceName } from 'services/api/helper';

const alpha = '/v2alpha';

/* @ngInject */
export default class RulesApiService {
    constructor($http, ProjectManager) {
        this.http = $http;
        this.ProjectManager = ProjectManager;

        /** @type {Resource} */
        this.ApiService = new Resource($http, alpha);
    }

    /**
     * @private
     * @param {string?} ruleId
     * @return {string}
     */
    resourceName(ruleId) {
        const ruleSpecifier = ['project.rules'];
        if (ruleId) {
            ruleSpecifier.push(ruleId);
        }
        return getResourceName(
            ['projects', this.ProjectManager.currentProjectId],
            ruleSpecifier
        );
    }

    rules(params) {
        const resourceName = this.resourceName();
        return this.ApiService.list(resourceName, params, 'rules');
    }

    alerts(params) {
        return this.ApiService.list("projects/-/rules/-/alerts", params, 'alerts');
    }

    aggregatedAlerts(params) {
        return this.ApiService.listWithMultipleKeys(`projects/-/rules/-/alerts:aggregate`, params, ['projects', 'totals']);
    }
    
    acknowledgeAlert(alert, comment = '') { 
        const resourceName = `${alert.name}:acknowledge`;
        
        return this.ApiService.create(resourceName, {
            comment: comment,
        });
    }

    addComment(alert, comment) {
        const resourceName = `${alert.name}:comment`;
        return this.ApiService.create(resourceName, {
            comment: comment,
        });
    }

    addCorrectiveAction(alert, correctiveAction) {
        const resourceName = `${alert.name}:correctiveAction`;
        return this.ApiService.create(resourceName, {
            comment: correctiveAction,
        });
    }

    archiveAlert(alert) {
        const resourceName = `${alert.name}:archive`;
        return this.ApiService.create(resourceName, {});
    }

    rule(ruleId) {
        return this.ApiService.get(this.resourceName(ruleId));
    }

    deleteRule(ruleId) {
        const resourceName = getResourceName(
            ['projects', this.ProjectManager.currentProjectId],
            ['project.rules', ruleId]
        );
        return this.ApiService.remove(resourceName);
    }

    createRule(rule) {
        return this.ApiService.create(this.resourceName(), rule);
    }

    /**
     * @param {Rule} rule
     */
    updateRule(rule) {
        const resourceName = this.resourceName(rule.id);
        return this.ApiService.put(resourceName, rule);
    }

    listTriggerStatus(id) {
        const resourceName = this.resourceName(id);
        const url = getResourceName(resourceName, ['project.rule.devices']);
        return this.ApiService.list(url, {}, 'devices');
    }
}
