import moment from 'moment';
import { UPDATE_DEVICE } from 'services/Permissions';
import * as SensorTypes from 'services/config/SensorTypes';
import ActivityTimerInfoController from './info-popover/controller';
import ActivityTimerInfoTemplate from './info-popover/template.html';

// Default coefficients for PT100
const STANDARD_PROBE_COEFFICIENTS = {
    R0: 100,
    A: 0.0039083,
    B: -0.0000005775,
    C: -0.000000000004183
}

/* @ngInject */

export default class SensorConfigurationsController {
    constructor(StateService, $scope, SensorService, RoleManager, ToastService, DialogService) {
        this.SensorService = SensorService;
        this.StateService = StateService;
        this.RoleManager = RoleManager;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.$scope = $scope;
    }

    // Device is updating to a new configuration
    get updateInProgress() {
        if (this.currentHeartbeatInterval !== this.wantedHeartbeatInterval) {
            return true
        }
        if (this.thing.type === 'motion') {
            return this.currentSensitivity !== this.wantedSensitivity || this.currentActivityTimer !== this.wantedActivityTimer
        } 
        if (this.thing.type === 'temperature') {
            return this.currentSamplesPerHeartbeat !== this.wantedSamplesPerHeartbeat
        }
        return false
    }

    // Highlight the update button if the user has set a new wanted value
    get readyToUpdate() {
        const hasHeartbeatIntervalChanged = this.localWantedHeartbeatInterval !== this.wantedHeartbeatInterval

        if (this.thing.type === 'motion') {
            const hasSensitivityChanged = this.wantedSensitivity !== this.localWantedSensitivity
            const hasActivityTimerChanged = this.wantedActivityTimer !== this.localWantedActivityTimer
            const isValidActivityTimer = !this.invalidActivityTimer
        
            return (hasSensitivityChanged || hasActivityTimerChanged || hasHeartbeatIntervalChanged) && isValidActivityTimer
        }
        
        if (this.thing.type === 'temperature') {
            const hasSamplesPerHeartbeatChanged = this.wantedSamplesPerHeartbeat !== this.localWantedSamplesPerHeartbeat
        
            return hasHeartbeatIntervalChanged || hasSamplesPerHeartbeatChanged
        }

        if (this.thing.type === 'deskOccupancy') {
            return this.localWantedDeskOccupancyDetectionMode !== this.wantedDeskOccupancyDetectionMode
        }

        return hasHeartbeatIntervalChanged
    }

    get readyToUpdateCoefficients() {
        // Check if the user has changed the coefficients
        if (this.probeCalibration === "CUSTOM") {
            return this.localWantedR0Coefficient !== this.wantedR0Coefficient ||
                    this.localWantedACoefficient !== this.wantedACoefficient ||
                    this.localWantedBCoefficient !== this.wantedBCoefficient ||
                    this.localWantedCCoefficient !== this.wantedCCoefficient
        }
        return false
    }

    // Create a formatted timestamp for when the configuration update began
    get updateStarted() {
        let updatedTime = null
        Object.values(this.configuration.attributes).forEach(attribute => {    
            if (updatedTime === null || attribute.wanted.updateTime > updatedTime) {
                updatedTime = attribute.wanted.updateTime
            }
        })
        
        return `${moment(updatedTime).fromNow()}`
    }

    // Empty array used to visualize samples preview
    get samplesPreviewArray() {
        return this.localWantedSamplesPerHeartbeat >= 1 ? new Array(this.localWantedSamplesPerHeartbeat - 1) : []
    }

    get formattedHeartbeatInterval() {
        const duration = moment.duration(this.localWantedHeartbeatInterval, "seconds")
        return this.createFormattedDuration(duration)
    }
    
    get canUpdateConfiguration() {
        return this.RoleManager.can(UPDATE_DEVICE)
    }

    get isPrototype() {
        return this.thing.productNumber.toLowerCase().includes("prototype")
    }

    get isEmulated() {
        return this.thing.id.startsWith('emu')
    }

    get isProbeSensor() {
        return this.thing.productNumber.includes('102772') || this.thing.productNumber.includes('102774')
    }

    get showTriggerBased() {
        return this.thing.type === SensorTypes.proximity.filter || 
               this.thing.type === SensorTypes.touch.filter || 
               this.thing.type === SensorTypes.waterDetector.filter || 
               this.thing.type === SensorTypes.motion.filter || 
               this.thing.type === SensorTypes.contact.filter
    }

    get sensitivityLevelsKeys() {
        return Object.keys(this.sensitivityLevels)
    }

    get deskOccupancyDetectionModesKeys() {
        return Object.keys(this.deskOccupancyDetectionModes)
    }

    get heartbeatDescription() {
        switch (this.thing.type) {
            case SensorTypes.temperature.filter:
                return "How often should temperature be transmitted?"
            case SensorTypes.humidity.filter:
            case SensorTypes.co2.filter:
            case SensorTypes.analog.filter:
                return "How often should measurements be transmitted?"
            default:
                return "How often should the sensor report signal strength?"
        }
    }

    get configurationPreviewDescription() {
        switch (this.thing.type) {
            case SensorTypes.temperature.filter:
                return "Temperature"
            case SensorTypes.humidity.filter:
            case SensorTypes.co2.filter:
            case SensorTypes.analog.filter:
                return "Measurements"
            default:
                return "Signal strength"
        }
    }

    get heartbeatOptions() {
        const hb_2_5 = 2 * 60 + 30
        const hb5 = 5 * 60
        const hb5_5 = 5 * 60 + 30
        const hb15 = 15 * 60
        const hb30 = 30 * 60
        const hb45 = 45 * 60
        const hb60 = 60 * 60

        switch (this.thing.type) {
            case SensorTypes.proximity.filter:
                return [hb15, hb30, hb45, hb60]
            case SensorTypes.touch.filter:
                return [hb15, hb30, hb45, hb60]
            case SensorTypes.temperature.filter: {
                const optionsMap = new Map([
                    ["100118", [hb15, hb30, hb45, hb60]], // Sensor EU, Temperature Gen. 1
                    ["100583", [hb15, hb30, hb45, hb60]], // Sensor US, Temperature Gen. 1
                    ["100587", [hb5_5]],                 // Sensor EU, Temperature Gen. 1 EN12830
                    ["101770", [hb5_5]],                 // Sensor US, Temperature Gen. 1 330s
                    ["101945", [hb15, hb30, hb45, hb60]],// Sensor EU, Industrial Temperature Gen. 1
                    ["102058", [hb15, hb30, hb45, hb60]],// Sensor EU, Temperature Gen. 1 QR
                    ["102067", [hb5_5]],                 // Sensor EU, Temperature Gen. 1 EN12830 QR
                    ["102090", [hb15, hb30, hb45, hb60]],// Sensor US, Temperature Gen. 1 QR
                    ["102106", [hb5_5]],                 // Sensor US, Temperature Gen. 1 330s QR
                    ["102134", [hb15, hb30, hb45, hb60]],// Sensor US, Industrial Temperature Gen. 1 QR
                    ["102150", [hb15, hb30, hb45, hb60]],// Sensor EU, Temperature Gen. 2 QR
                    ["102152", [hb15, hb30, hb45, hb60]],// Sensor US, Temperature Gen. 2 QR
                    ["102180", [hb15, hb30, hb45, hb60]],// Sensor EU, Extech Temperature Gen. 1 QR
                    ["102184", [hb5_5]],                 // Sensor EU, Extech Temperature Gen. 1 EN12830 QR
                    ["102211", [hb15, hb30, hb45, hb60]],// Sensor EU, Industrial Temperature Gen. 2 QR
                    ["102231", [hb5, hb15, hb30, hb45, hb60]], // Sensor EU, Industrial Temperature 330s Gen. 1 QR
                    ["102233", [hb5, hb15, hb30, hb45, hb60]], // Sensor US, Industrial Temperature 330s Gen. 1 QR
                    ["102289", [hb15, hb30, hb45, hb60]],// Sensor US, Industrial Temperature Gen. 2 QR
                    ["102407", [hb30]],                  // Sensor EU, JCI Industrial Temperature QR w/ Backfill
                    ["102597", [hb30]],                  // Sensor US, JCI Industrial Temperature QR w/ Backfill
                    ["102683", [hb5, hb15, hb30, hb45, hb60]], // Sensor EU, Industrial Temperature QR w/ Backfill
                    ["102685", [hb5, hb15, hb30, hb45, hb60]], // Sensor US, Industrial Temperature QR w/ Backfill
                    ["102739", [hb5, hb15, hb30, hb45, hb60]], // Sensor EU, Temperature Gen. 3 QR
                    ["102740", [hb5, hb15, hb30, hb45, hb60]], // Sensor US, Temperature Gen. 3 QR
                    ["102772", [hb_2_5, hb5, hb15, hb30, hb45, hb60]], // Sensor EU, Temperature Probe
                    ["102774", [hb_2_5, hb5, hb15, hb30, hb45, hb60]]  // Sensor US, Temperature Probe
                ]);
            
                // Get the options for the current product number
                for (const [key, value] of optionsMap) { // eslint-disable-line no-restricted-syntax
                    if (this.thing.productNumber.includes(key)) {
                        return value;
                    }
                }
            
                return [];
            }

            case SensorTypes.proximityCounter.filter:
                return [hb15, hb30, hb45, hb60]
            case SensorTypes.touchCounter.filter:
                return [hb15, hb30, hb45, hb60]
            case SensorTypes.humidity.filter:
                return [hb15, hb30, hb45, hb60]
            case SensorTypes.waterDetector.filter:
                return [hb15, hb30, hb45, hb60]
            case SensorTypes.co2.filter:
                return [hb_2_5, hb5, hb15, hb30, hb45, hb60]
            case SensorTypes.deskOccupancy.filter:
                return [hb5]
            case SensorTypes.motion.filter:
                return [hb30, hb45, hb60]
            case SensorTypes.contact.filter:
                return [hb15, hb30, hb45, hb60]
            case SensorTypes.analog.filter:
                return [hb5, hb15, hb30, hb45, hb60]
            default:
                return []
        }
    }

    $onInit() {
        this.configurationLoaded = false
        this.failedToLoadConfiguration = false
        this.showSensitivityDropdown = false
        this.showDeskOccupancyModeDropdown = false
        this.invalidActivityTimer = false
        this.sensorCanNotBeConfigured = false
        this.updateButtonDisabled = false

        this.sensitivityLevels = {
            VERY_HIGH: {
                displayName: 'Very High Sensitivity',
                description: 'The highest sensitivity available. Experimental, can in very rare cases cause false positive motion events.',
            },
            HIGH: {
                displayName: 'High Sensitivity (default)',
                description: 'The recommended setting for most installations. Minimal movement in front of the sensor will trigger a sensor event.',
            },
            MEDIUM: {
                displayName: 'Medium Sensitivity',
                description: 'Small movements in front of the sensor will trigger a sensor event.',
            },
            LOW: {
                displayName: 'Low Sensitivity',
                description: 'Only large movements in front of the sensor will trigger a sensor event.',
            }
        }

        this.deskOccupancyDetectionModes = {
            BALANCED: {
                displayName: 'Balanced Mode (Default)',
                description: 'Provides our quickest desk occupancy detection, striking a balance between speed and precision for immediate data needs.'
            },
            HIGH_ACCURACY: {
                displayName: 'High Accuracy Mode',
                description: 'Best for analysis and long-term data trends, prioritizing accuracy over immediate data, has a ~30 minutes delay before data is delivered for enhanced precision.'
            }
        }


        if (!this.isEmulated) {
            this.fetchConfiguration()
        } else {
            this.configurationLoaded = true
            this.sensorCanNotBeConfigured = true
        }

        // Determines what to display in the probe coefficient dropdown, 
        // will be overwritten when coefficients are loaded
        this.probeCalibration = "DEFAULT_PT100"
        this.checkCoefficientA = false
        this.checkCoefficientB = false
        this.checkCoefficientC = false
    }

    fetchConfiguration() {
        this.SensorService.getDeviceConfiguration(this.thing.name).then(config => {
            this.updateLocalConfiguration(config)
        }).catch(serverResponse => {
            // Showing an error message to the user in the UI
            this.failedToLoadConfiguration = true
            console.error(serverResponse) // eslint-disable-line no-console
        }).finally(() => {
            this.configurationLoaded = true
        })

        if (this.thing.type === 'deskOccupancy') {
            this.SensorService.getProjectConfiguration().then(config => {
                this.localWantedDeskOccupancyDetectionMode = config.detectionMode
                this.wantedDeskOccupancyDetectionMode = config.detectionMode
            }).catch(serverResponse => {
                this.failedToLoadConfiguration = true
                console.error(serverResponse) // eslint-disable-line no-console
            })
        }
    }

    updateLocalConfiguration(config) {
        this.configuration = config

        // Heartbeat interval can be read for all sensors
        const heartbeatInterval = this.configuration.attributes.heartbeatInterval
        this.currentHeartbeatInterval = heartbeatInterval.current.value
        this.wantedHeartbeatInterval = heartbeatInterval.wanted.value
        this.localWantedHeartbeatInterval = heartbeatInterval.wanted.value

        if (this.currentHeartbeatInterval === null || this.wantedHeartbeatInterval === null) {
            // The configuration is invalid, we won't show the configuration panel
            this.failedToLoadConfiguration = true
        }

        // If the configuration can't be changed, we don't want to show the configuration panel
        if (this.heartbeatOptions.length <= 1) {
            this.sensorCanNotBeConfigured = true
        }

        if (this.thing.type === 'temperature') {
            const samplesPerHeartbeat = this.configuration.attributes.samplesPerHeartbeat
            this.showSamplingConfiguration = samplesPerHeartbeat.writable
            this.currentSamplesPerHeartbeat =  samplesPerHeartbeat.current.value
            this.wantedSamplesPerHeartbeat = samplesPerHeartbeat.wanted.value
            this.localWantedSamplesPerHeartbeat = samplesPerHeartbeat.wanted.value

            if (this.isProbeSensor) {
                const coefficients = this.configuration.attributes.coefficients

                this.wantedR0Coefficient = coefficients.wanted.value.r0
                this.wantedACoefficient = coefficients.wanted.value.a
                this.wantedBCoefficient = coefficients.wanted.value.b
                this.wantedCCoefficient = coefficients.wanted.value.c

                this.localWantedR0Coefficient = coefficients.wanted.value.r0
                this.localWantedACoefficient = coefficients.wanted.value.a
                this.localWantedBCoefficient = coefficients.wanted.value.b
                this.localWantedCCoefficient = coefficients.wanted.value.c

                // Determine what to display in the probe coefficient dropdown
                if (Number(this.localWantedACoefficient) === STANDARD_PROBE_COEFFICIENTS.A &&
                Number(this.localWantedBCoefficient) === STANDARD_PROBE_COEFFICIENTS.B &&
                Number(this.localWantedCCoefficient) === STANDARD_PROBE_COEFFICIENTS.C) {
                    if (Number(this.localWantedR0Coefficient) === 100) {
                        this.probeCalibration = "DEFAULT_PT100"
                    } else if(Number(this.localWantedR0Coefficient) === 1000) {
                        this.probeCalibration = "DEFAULT_PT1000"
                    } else {
                        this.probeCalibration = "CUSTOM"
                    }
                } else {
                    this.probeCalibration = "CUSTOM"
                }
            }
        }

        if (this.thing.type === 'motion') {
            const sensitivity = this.configuration.attributes.sensitivity
            this.currentSensitivity = sensitivity.current.value
            this.wantedSensitivity = sensitivity.wanted.value
            this.localWantedSensitivity = sensitivity.wanted.value
            
            const activityTimer = this.configuration.attributes.activityTimer
            this.currentActivityTimer =  activityTimer.current.value
            this.wantedActivityTimer = activityTimer.wanted.value
            this.localWantedActivityTimer = activityTimer.wanted.value

            if (this.localWantedActivityTimer !== null) {
                this.minutes = Math.floor(this.localWantedActivityTimer / 60)
                this.seconds = this.localWantedActivityTimer - this.minutes * 60
            } else {
                this.minutes = '-'
                this.seconds = '-'
            }
        }
    }

    updateWantedHeartbeatInterval() {
        // Reset the sampling to match the heartbeat interval
        this.localWantedSamplesPerHeartbeat = 1
    }

    // Returns an array of samples per heartbeat options based on the heartbeat interval
    get samplingOptions() {
        const heartbeatInMinutes = this.localWantedHeartbeatInterval / 60 
        switch (heartbeatInMinutes) {
            case 1:
                return [1, 2] // 1m, 30s
            case 2.5:
                return [1, 5] // 2.5m, 30s
            case 5:
                return [1, 5, 10] // 5m, 1m, 30s
            case 10:
                return [1, 2, 10, 20] // 10m, 5m, 1m, 30s
            case 15:
                return [1, 3, 5, 15, 30] // 15m, 5m, 3m, 1m, 30s
            case 20:
                return [1, 2, 4, 20] // 20m, 10m, 5m, 1m
            case 25:
                return [1, 5, 25] // 25m 5m, 1m
            case 30:
                return [1, 2, 3, 6, 10, 15, 30] // 30m, 15m, 10m, 5m, 2m, 1m
            case 45:
                return [1, 3, 9, 15] // 45m, 15m, 5m, 3m
            case 60:
                return [1, 2, 4, 6, 12, 20, 30] // 60m, 30m, 15m, 10m, 5m, 3m, 2m
            default:
                return [1] // Fallback for all other heartbeats
        }
    }

    heartbeatInterval(heartbeatOption) {
        const duration = moment.duration(heartbeatOption, "seconds")
        return this.createFormattedDuration(duration)
    }

    sampleInterval(samplesPerHeartbeat) {
        const sampleInterval = (this.localWantedHeartbeatInterval / 60) / samplesPerHeartbeat // Sampling interval in minutes (float value)
        const sampleIntervalDuration = moment.duration(sampleInterval, "minutes")
        return this.createFormattedDuration(sampleIntervalDuration)
    }
    
    createFormattedDuration(duration) { // eslint-disable-line class-methods-use-this
        const hours = duration.hours()
        const minutes = duration.minutes()
        const seconds = duration.seconds()
        if (hours === 1 && minutes === 0) { 
            return '60 minutes'
        }
        if (seconds === 0) {
            return `${minutes} minute${minutes > 1 ? 's' :'' }`
        } 
        if (minutes === 0) {
            return `${seconds} seconds`
        }
        return `${minutes} min ${seconds} sec`
    }

    updateConfiguration() {
        this.updateButtonDisabled = true

        const newConfig = {
            version: this.configuration.version,
            configuration: {}
        }

        // Only add the configuration if `wanted` and `localWanted` are different
        if (this.configuration.attributes.heartbeatInterval.wanted.value !== this.localWantedHeartbeatInterval) {
            newConfig.configuration.heartbeatInterval = this.localWantedHeartbeatInterval
        }

        if (this.thing.type === 'motion') {
            if (this.configuration.attributes.sensitivity.wanted.value !== this.localWantedSensitivity) {
                newConfig.configuration.sensitivity = this.localWantedSensitivity
            }
            if (this.configuration.attributes.activityTimer.wanted.value !== this.localWantedActivityTimer) {
                newConfig.configuration.activityTimer = this.localWantedActivityTimer
            }
        } else if (this.thing.type === 'temperature') {
            if (this.configuration.attributes.samplesPerHeartbeat.wanted.value !== this.localWantedSamplesPerHeartbeat) {
                newConfig.configuration.samplesPerHeartbeat = this.localWantedSamplesPerHeartbeat
            }
        }

        this.SensorService.updateDeviceConfiguration(this.thing.name, newConfig).then(config => {
            this.updateLocalConfiguration(config)
        }).catch(serverResponse => {
            this.ToastService.showSimpleTranslated('device_configuration_wasnt_updated');
            console.error(serverResponse) // eslint-disable-line no-console
            this.updateButtonDisabled = false
        });

        this.$scope.$applyAsync()
    }

    cancelUpdate() {

        // Reset wanted value to the current configuration
        if (this.thing.type === 'temperature') {
            const samplesPerHeartbeat = this.configuration.attributes.samplesPerHeartbeat
            this.currentSamplesPerHeartbeat = samplesPerHeartbeat.current.value
            this.wantedSamplesPerHeartbeat = samplesPerHeartbeat.current.value
            this.localWantedSamplesPerHeartbeat = samplesPerHeartbeat.current.value
        }

        if (this.thing.type === 'motion') {
            const sensitivity = this.configuration.attributes.sensitivity
            this.currentSensitivity = sensitivity.current.value
            this.wantedSensitivity = sensitivity.current.value
            this.localWantedSensitivity = sensitivity.current.value
            
            const activityTimer = this.configuration.attributes.activityTimer
            this.currentActivityTimer =  activityTimer.current.value
            this.wantedActivityTimer = activityTimer.current.value
            this.localWantedActivityTimer = activityTimer.current.value

            this.minutes = Math.floor(this.localWantedActivityTimer / 60)
            this.seconds = this.localWantedActivityTimer - this.minutes * 60
        }

        const heartbeatInterval = this.configuration.attributes.heartbeatInterval
        this.currentHeartbeatInterval = heartbeatInterval.current.value
        this.wantedHeartbeatInterval = heartbeatInterval.current.value
        this.localWantedHeartbeatInterval = heartbeatInterval.current.value
        
        this.updateConfiguration()
        this.updateButtonDisabled = false
    }

    updateDeskOccupancyMode() {
        this.SensorService.updateProjectConfiguration({ detectionMode: this.localWantedDeskOccupancyDetectionMode }).then(() => {
            this.wantedDeskOccupancyDetectionMode = this.localWantedDeskOccupancyDetectionMode
            this.ToastService.showSimpleTranslated('project_desk_occupancy_configuration_was_updated')
        }).catch(serverResponse => {
            this.ToastService.showSimpleTranslated('project_desk_occupancy_configuration_wasnt_updated')
            console.error(serverResponse) // eslint-disable-line no-console
        })
    }

    hideSensitivityDropdown() {
        this.showSensitivityDropdown = false;
    }

    hideDeskOccupancyModeDropdown() {
        this.showDeskOccupancyModeDropdown = false
    }

    setSensitivity(sensitivity) {
        this.localWantedSensitivity = sensitivity
    }

    setDeskOccupancyDetectionMode(mode) {
        this.localWantedDeskOccupancyDetectionMode = mode
    }
    
    updateActivityTimer() {
        // Check limits of min 60 seconds, max 1 hour
        const totalSeconds = this.minutes * 60 + this.seconds
        if (totalSeconds <= 3600 && totalSeconds >= 60) {
            this.localWantedActivityTimer = totalSeconds
            this.invalidActivityTimer = false
        } else {
            this.invalidActivityTimer = true
        }
    }

    showActivityTimerInfo() {
        this.DialogService.show({
            controller: ActivityTimerInfoController,
            controllerAs: '$ctrl',
            template: ActivityTimerInfoTemplate,
            parent: document.body,
            openFrom: 'top',
            closeTo: 'top',
            clickOutsideToClose: true,
            fullscreen: true,
        });
    }

    saveCoefficients() {

        const newConfig = {
            version: this.configuration.version,
            configuration: {}
        }

        newConfig.configuration.coefficients = {
            r0: Number(this.localWantedR0Coefficient),
            a: Number(this.localWantedACoefficient),
            b: Number(this.localWantedBCoefficient),
            c: Number(this.localWantedCCoefficient)
        }

        this.SensorService.updateDeviceConfiguration(this.thing.name, newConfig).then(config => {
            this.updateLocalConfiguration(config)
            this.ToastService.showSimpleTranslated('probe_coefficients_was_updated')
        }).catch(serverResponse => {
            this.ToastService.showSimpleTranslated('device_configuration_wasnt_updated')
            console.error(serverResponse) // eslint-disable-line no-console
        });

        this.$scope.$applyAsync()    
    }

    changedProbeDropdown() {
        // Reset coefficients to default if the user selects a default calibration
        if (this.probeCalibration === "DEFAULT_PT100") {
            this.resetDefaultCoefficients()
            this.saveCoefficients()
        } else if (this.probeCalibration === "DEFAULT_PT1000") {
            // PT1000 coefficients are the same as PT100, except for R0
            this.resetDefaultCoefficients()
            this.localWantedR0Coefficient = 1000
            this.saveCoefficients()
        }
        this.validateCoefficients()
    }

    resetDefaultCoefficients() {
        // Reset to default PT100 coefficients
        this.localWantedR0Coefficient = STANDARD_PROBE_COEFFICIENTS.R0
        this.localWantedACoefficient = STANDARD_PROBE_COEFFICIENTS.A
        this.localWantedBCoefficient = STANDARD_PROBE_COEFFICIENTS.B
        this.localWantedCCoefficient = STANDARD_PROBE_COEFFICIENTS.C
        this.validateCoefficients()
    }

    calculatePercentageDifference(standard, input) {
        return Math.abs((standard - input) / standard) * 100;
    }

    // A check to see if the coefficients seem reasonable
    validateCoefficients() {
        const THRESHOLD = 200 // 200%

        const inputCoefficients = {
            R0: this.localWantedR0Coefficient,
            A: this.localWantedACoefficient,
            B: this.localWantedBCoefficient,
            C: this.localWantedCCoefficient
        };

        this.checkCoefficientA = false
        this.checkCoefficientB = false
        this.checkCoefficientC = false
    
        Object.keys(STANDARD_PROBE_COEFFICIENTS).forEach(key => {
            const standardValue = STANDARD_PROBE_COEFFICIENTS[key];
            const inputValue = inputCoefficients[key];
        
            // Calculate the percentage difference
            const difference = this.calculatePercentageDifference(standardValue, inputValue);
        
            // Check if the difference exceeds the threshold
            if (difference > THRESHOLD) {
                if (key === "A") {
                    this.checkCoefficientA = true;
                } else if (key === "B") {
                    this.checkCoefficientB = true;
                } else if (key === "C") {
                    this.checkCoefficientC = true;
                }
            }
        });
        
    }

    toScientificFormat(num) {
        // Get the value and exponent of the number
        if (isNaN(num) || num === "" || Number(num) === 0) return { value: "0", exponent: "" }
    
        const sign = num < 0 ? "-" : ""
        const absNum = Math.abs(num)
    
        const exponent = Math.floor(Math.log10(absNum))
        const mantissa = absNum / (10**exponent)
    
        return {
            value: `${sign}${mantissa.toFixed(4)} × 10`,
            exponent: `${exponent}`
        };
    }
}