import { cloneEntity } from 'services/utils';


class RulesService {
    /**
     * @param $q
     * @param {RulesApiService} RulesApiService
     */
    constructor($q, RulesApiService) {
        this.$q = $q;
        this.RulesApiService = RulesApiService;
    }

    /**
     * @param {string} id
     * @return {Rule}
     */
    getRule(id) {
        return this.RulesApiService.rule(id);
    }

    /**
     * Create (when id is missing) or update rule.
     * @param {Rule} originalRule
     */
    saveRule(originalRule) {
        const rule = cloneEntity(originalRule);
        if (!rule.name) {
            return this.RulesApiService.createRule(originalRule).then();
        }
        return this.RulesApiService.updateRule(rule);
    }

    /**
     * @param {string} id
     */
    deleteRule(id) {
        return this.RulesApiService.deleteRule(id);
    }

    /**
     * @return {Rule[]}
     */
    listRules() {
        return this.RulesApiService.rules();
    }

    /**
     * @return {DeviceTriggerStatus[]}
     */
    listTriggerStatus(id) {
        return this.RulesApiService.listTriggerStatus(id);
    }

    /**
     *
     * @param {Rule} rule
     * @param {Device} device
     * @param {DeviceEvent | undefined} deviceEvent
     * @return {boolean | undefined}
     */
    evaluateRule(rule, device, deviceEvent=undefined) { // eslint-disable-line class-methods-use-this
        switch (rule?.trigger?.field) {
            case 'touchCount': // Fallthrough
            case 'touch':
                if (deviceEvent?.eventType === 'touch') {
                    return true
                }
                return undefined;
            case 'temperature': {
                const temperature = device?.reported?.temperature?.value;
                if (temperature === undefined) {
                    return undefined;
                }
                const range = rule.trigger.range;
                let lower = range.lower;
                let upper = range.upper;
                if (range.lower === undefined) {
                    lower = -1e100
                }
                if (range.upper === undefined) {
                    upper = 1e100
                }
                const insideRange = lower <= temperature && temperature <= upper;
                return insideRange && range.type === 'WITHIN' || !insideRange && range.type === 'OUTSIDE';
            }
            case 'proximityCount': // Fallthrough
            case 'objectPresent': {
                const present = device?.reported?.objectPresent?.state;
                if (present === undefined) {
                    return undefined;
                }
                return rule.trigger.presence === present
            }
            default:
                return undefined;
        }
    }
}

/**
 * @ngInject
 */
export default function RulesServiceFactory($q, RulesApiService) {
    return new RulesService($q, RulesApiService);
}


/**
 * @typedef {Object} RuleEvalResult
 * @property {boolean | undefined} result
 */
