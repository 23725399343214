import moment from 'moment';
import _debounce from 'lodash/debounce';
import { forceRedrawOnIOS, isMobileLayout, LAYOUT_MOBILE_BREAKPOINT } from 'services/utils';
import { APP_CONTENT_RESIZE_EVENT, STATE_TRANSITION_START_EVENT, SILENT_REFRESH } from 'services/StudioEvents';
import { getDocumentTitleByState } from 'services/translation/translation.service';
import { SENSOR_CONNECTIVITY_PANEL_STATE_KEY } from 'components/sensors-and-connectors/components/sensor-details/sensor-details.controller';
import { SENSOR_GRAPH_RANGE_STATE_KEY } from 'services/charting/constants';
import { CONNECTIVITY_GRAPH_RANGE_STATE_KEY } from 'components/sensors-and-connectors/components/connectivity-chart/connectivity-chart.controller';
import {
    CCON_GRAPH_RANGE_STATE_KEY,
    CCON_GRAPH_VISIBLE_STATE_KEY
} from 'components/sensors-and-connectors/components/ccon-chart/ccon-chart.controller';
import { States } from './app.router';

const WINDOW_RESIZE_DEBOUNCE_DELAY = 200; // in milliseconds

/* @ngInject */
export default function appBootstrap(
    $transitions,
    HealthCheckerService,
    $rootScope,
    $window,
    TranslationService,
    AnalyticsService,
    StateService
) {
    // Update default rounds for moment.duration.humanize
    moment.relativeTimeThreshold('s', 60);
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24);
    moment.relativeTimeThreshold('d', 31);
    moment.relativeTimeThreshold('M', 12);

    $transitions.onStart({}, trans => {
        const DialogService = trans.injector().get('DialogService');
        DialogService.cancel();
        $rootScope.$broadcast(STATE_TRANSITION_START_EVENT);
    });

    $transitions.onSuccess({}, trans => {
        const targetState = trans.to().name;
        
        let project;
        try {
            project = trans.injector().get('project');
        } catch (e) {
            project = null;
        }
        
        TranslationService.updateDocumentTitle(
            getDocumentTitleByState(targetState),
            project
        );
        
        if (isMobileLayout()) {
            const from = trans.from();
            const to = trans.to();
            if (!(from.url === to.url && to.url.includes('?'))) {
                window.scrollTo(0, 0);
            }
        }
        
        forceRedrawOnIOS();
        
        // Some state transitions are not relevant for analytics. We only care 
        // if there is a new state that is being entered, and that it is different
        // from the exiting state. The exiting state might be null, for example
        // when the user opens Studio.
        const entering = trans.treeChanges().entering[0]?.state?.name;
        const exiting = trans.treeChanges().exiting[0]?.state?.name;
        const doNotTrack = trans.targetState().params().doNotTrack;;
        
        if (!doNotTrack && entering && entering !== exiting) {
            AnalyticsService.trackEvent(`navigation.${targetState}`)
        }
    });

    $transitions.onSuccess(
        {
            from: States.SENSOR_DETAILS,
            to: ({ name }) => name !== States.SENSOR_DETAILS
        },
        () => {
            StateService.removeItems([
                SENSOR_CONNECTIVITY_PANEL_STATE_KEY,
                SENSOR_GRAPH_RANGE_STATE_KEY,
                CONNECTIVITY_GRAPH_RANGE_STATE_KEY,
                CCON_GRAPH_VISIBLE_STATE_KEY,
                CCON_GRAPH_RANGE_STATE_KEY
            ]);
        }
    );

    $window.addEventListener(
        'resize',
        _debounce(() => {
            $rootScope.$broadcast(APP_CONTENT_RESIZE_EVENT);
        }, WINDOW_RESIZE_DEBOUNCE_DELAY)
    );

    // Tracking the device type both when opening Studio, and when the access token is 
    // silently refreshed, to get roughly one device type event per hour.
    const trackDevice = () => {
        const isMobileSizedScreen = window.innerWidth < LAYOUT_MOBILE_BREAKPOINT || window.innerHeight < LAYOUT_MOBILE_BREAKPOINT;
        AnalyticsService.trackEvent(`device.${isMobileSizedScreen ? 'mobile' : 'desktop'}`);
    }
    $rootScope.$on(SILENT_REFRESH, () => {
        AnalyticsService.trackEvent("silent_refresh");
        trackDevice();
    })
    AnalyticsService.trackEvent("studio_opened");
    trackDevice();

    HealthCheckerService.initTracking();
}