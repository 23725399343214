import controller from './auxiliary-chart.controller';
import template from './template.html';

export default {
    bindings: {
        thing: '<',
        extremes: '<',
        events: '<',
        loading: '<',
        onChartSelection: '&'
    },
    template,
    controller
};
