import controller from './CO2.controller';

const template = require('./CO2.html');

export default {
    bindings: {
        device: '<',
        onPublishEvent: '&'
    },
    controller,
    template
};
