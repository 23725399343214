import { parseQuery } from 'services/QueryParser';
import { DEFAULT_TOKEN } from 'services/PaginationHelper';
import { MAX_DEVICES_PER_MULTI_CARD } from 'services/api/DashboardService';
import { isMobileLayout } from 'services/utils';
import { getProductDescription } from 'services/SensorHelper';
import { 
    DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED, 
    DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE,
    DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY
} from 'constants/device';

/* @ngInject */
export default class CreateCardController {
    constructor(
        DialogService, addNewCard, SensorService, ToastService, $q, $scope
    ) {
        this.DialogService = DialogService;
        this.addNewCard = addNewCard;
        this.SensorService = SensorService;
        this.ToastService = ToastService;
        this.$q = $q;
        this.$scope = $scope;

        this.cardOptions = {
		    SingleSensor: "single_sensor",
		    MultipleSensors: "multiple_sensors",
        }
        this.multipleSensorsOptions = {
            Temperature: "temperature",
            Humidity: "humidity",
            LiveProximity: "live_proximity",
            DeskOccupancy: "desk_occupancy",
            LiveMotion: "live_motion"
        }
    }

    productDescription(device) { // eslint-disable-line class-methods-use-this
        return getProductDescription(device)
    }

    $onInit() {

        this.currentStep = 0;
        this.cardType = '';
        
        
        this.singleSensorFlow = [
            ['Create New Card', 'Select card type'],
            ['Select Sensor', ''], 
            ['Set Card Options', 'You can always change this later']];
        
        this.multipleSensorsFlow = [
            ['Create New card', 'Select card type'],
            ['Select an Option', ''],
            ['Select Sensors', ''], 
            ['Set Card Options', 'You can always change this later']];

        this.cardFlow = this.singleSensorFlow;
        this.numberOfSteps = this.cardFlow.length;

        this.selectedSensors = [];
        this.selectedDevice = null;
        this.cardName = '';
        this.cardNamePlaceholder = '';
        this.selectedLayout = '';
        this.selectedAppearance = ''
        this.selectedMultipleSensorsType = ''
        this.deviceTypes = [];

        this.progress = null;
        this.isFetching =  false;
        this.orderBy = 'labels.name';
        this.query = '';
        this.previousQuery = ''; // Allows us to detect when the user has changed the query
        this.currentPageSize = 100;
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;
        this.devices = [];
        this.onReorderCallback = this.listReorder.bind(this);

    }

    // Returns whether or not the user is allowed to select another sensor, or if the maximum 
    // allowed number of sensors has already been selected
    get canSelectAnotherSensor() {
        if (this.selectedMultipleSensorsType === this.multipleSensorsOptions.LiveProximity || 
            this.selectedMultipleSensorsType === this.multipleSensorsOptions.LiveMotion) {
            return this.selectedSensors.length < 100
        }
        if (this.selectedMultipleSensorsType === this.multipleSensorsOptions.DeskOccupancy) {
            return this.selectedSensors.length < 1000
        }
        return this.selectedSensors.length < this.maxDevicesPerMultiCard;
    }

    get maxDevicesPerMultiCard() { // eslint-disable-line class-methods-use-this
        return MAX_DEVICES_PER_MULTI_CARD
    }

    loadList() {
        this.isFetching = true;
        this.$scope.$applyAsync();
        const deferred = this.$q.defer();

        // Reset orderBy when the user has changed the text in the search box.
        // This will let the backend decide the order based on a match score.
        if (this.previousQuery !== this.query) {
            if (this.query.length > 0) {
                // Let the backend decide when the user has entered a search query
                this.orderBy = '';
            } else if (this.orderBy === '') {
                // Default to labels.name if the user has removed the search query
                this.orderBy = 'labels.name';
            }
        }
        this.previousQuery = this.query;

        this.progress = this.SensorService.sensors({
            ...parseQuery(this.query),
            deviceTypes: this.deviceTypes,
            orderBy: this.orderBy,
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        }).then(({ data, nextPageToken}) => {
            this.devices = data;
            this.nextPageToken = nextPageToken;
            this.isFetching = false;
            deferred.resolve([]);
        }).catch((serverResponse) => {
            this.isFetching = false;
            this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
                serverResponse
            });
            deferred.reject();
        });

        return deferred.promise;
    }

    search( query ) {
        this.query = query;
        this.currentPageToken = DEFAULT_TOKEN;
        return this.loadList();
    }

    clearSearchResult() {
        this.query = '';
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }

    listReorder(order) {
        this.orderBy = order;
        this.currentPageToken = DEFAULT_TOKEN;
        this.loadList();
    }

    setPageSize({ pageSize }) {
        this.currentPageToken = DEFAULT_TOKEN;
        this.currentPageSize = pageSize;
        this.loadList();
    }

    setPageToken({ pageToken }) {
        this.currentPageToken = pageToken;
        this.loadList();
        this.scrollToTop();
    }

    setCardType(type) {
        this.cardType = type;
        if (this.cardType === this.cardOptions.SingleSensor) {
            this.cardFlow = this.singleSensorFlow;
        } else if (this.cardType === this.cardOptions.MultipleSensors) {
            this.cardFlow = this.multipleSensorsFlow; 
        }
        this.numberOfSteps = this.cardFlow.length;
        this.loadList();
        this.nextStep();
    }

    setMultipleSensorType(type) {
        this.selectedSensors = []
        this.selectedMultipleSensorsType = type
        switch (this.selectedMultipleSensorsType) {
            case this.multipleSensorsOptions.Temperature:
                this.deviceTypes = ['temperature', 'humidity', 'co2'];
                break
            case this.multipleSensorsOptions.Humidity:
                this.deviceTypes = ['humidity', 'co2'];
                break
            case this.multipleSensorsOptions.LiveProximity:
                this.deviceTypes = ['proximity', 'contact'];
                break
            case this.multipleSensorsOptions.DeskOccupancy:
                this.deviceTypes = ['deskOccupancy'];
                break
            case this.multipleSensorsOptions.LiveMotion:
                this.deviceTypes = ['motion'];
                break
            default:
                this.deviceTypes = [];
        }
        this.setCardType(this.cardOptions.MultipleSensors)
    }
    
    getStepsCount() { // Used to display the step progress indicator
        return new Array(this.numberOfSteps);
    }

    prevStep() {
        if (this.currentStep > 0) {
            this.currentStep -= 1;
            this.scrollToTop();
        } else {
            this.currentStep = this.numberOfSteps;
        }

        if (this.currentStep === 0) {
            this.deviceTypes = [];
            this.selectedSensor = '';
            this.selectedSensors = [];
            this.selectedLayout = '';
            this.selectedMultipleSensorsType = ''
        }
        
        // Reset selected appearance if the user goes back to the first step
        if (this.currentStep === 1) {
            this.selectedAppearance = ''
        }
    }

    nextStep() {
        if (this.currentStep + 1 < this.numberOfSteps) { 
            this.currentStep += 1;
            this.scrollToTop();
            
            setTimeout(() => {
                const focusElement = document.getElementById("input-box");
                if (focusElement) {
                    focusElement.focus();
                }
            }, 300);

            // Set placeholder for cards with multiple sensors
            if (this.selectedSensors.length > 1) {
                this.cardNamePlaceholder = 'Set card name';
                this.selectedDevice = null
            }

            // Default multiple Desk Occupancy sensors to Occupancy History
            if (this.selectedMultipleSensorsType === this.multipleSensorsOptions.DeskOccupancy) {
                this.selectedAppearance = DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED
            }
            if (this.selectedMultipleSensorsType === this.multipleSensorsOptions.Temperature) {
                this.selectedAppearance = DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE
            }
            if (this.selectedMultipleSensorsType === this.multipleSensorsOptions.Humidity) {
                this.selectedAppearance = DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY
                this.selectedLayout = '2,2' // Set a recommended layout
            }
        } else {
            setTimeout(() => {
                if (this.selectedMultipleSensorsType === this.multipleSensorsOptions.LiveProximity ||
                    this.selectedMultipleSensorsType === this.multipleSensorsOptions.DeskOccupancy ||
                    this.selectedMultipleSensorsType === this.multipleSensorsOptions.LiveMotion ||
                    this.selectedMultipleSensorsType === this.multipleSensorsOptions.Temperature ||
                    this.selectedMultipleSensorsType === this.multipleSensorsOptions.Humidity) {
                    this.addNewCard(this.selectedSensors, this.selectedLayout, this.cardName, this.selectedAppearance, true, false);            
                } else {
                    this.addNewCard(this.selectedSensors, this.selectedLayout, this.cardName, this.selectedAppearance, true, true);            
                }
            }, 500);
            this.closeModal()
        }
    }

    activeNextButton() {
        if (this.currentStep === 0) {
            return this.cardType !== '';
        } 

        if (this.cardFlow === this.singleSensorFlow) {
            if (this.currentStep === 1) {
                return this.selectedSensors.length > 0;
            } 
            if (this.currentStep === 2){
                if (isMobileLayout()) {
                    this.selectedLayout = '1,2';
                    return true;
                }
                return this.selectedLayout.length !== 0;
            }  
        }
        if (this.cardFlow === this.multipleSensorsFlow) {
            if (this.currentStep === 1) {
                return true
            } 
            if (this.currentStep === 2){
                return this.selectedSensors.length > 1
            }  
            if (this.currentStep === 3) {
                if (isMobileLayout()) {
                    this.selectedLayout = '1,2';
                    return true
                }
                return this.selectedLayout.length !== 0;
            }
        }
        return true;
    }

    onLastStep() {
        return this.currentStep + 1 === this.numberOfSteps;
    }

    selectSensor(device) {
        if (this.selectedSensors.includes(device.id)) {
            // If the sensor already exists in the list, remove it
            this.selectedSensors.splice(this.selectedSensors.indexOf(device.id), 1);
        } else {

            // If the maximum number of devices have been added, exit
            if (this.canSelectAnotherSensor === false) {
                return;
            }

            // Go to the next step if single sensor
            if (this.cardType === this.cardOptions.SingleSensor) {
                this.selectedSensors = [];    
                this.nextStep();
            }

            // Add the sensor
            this.selectedSensors.push(device.id);
        }

        // Set placeholder for card name input field
        if (this.selectedSensors.length === 1) {
            // Single sensor
            this.cardNamePlaceholder = device.labels.name || device.id;
            this.selectedDevice = device
        }
    }

    selectAll() {
        this.devices.forEach(device => {
            if (this.canSelectAnotherSensor && !this.selectedSensors.includes(device.id)) {
                this.selectedSensors.push(device.id)
            }
        });
    }

    clearAll() {
        this.selectedSensors = []
    }

    selectLayout(layout) {
        this.selectedLayout = layout;
    }

    selectAppearance(appearance) {
        this.selectedAppearance = appearance
    }

    scrollToTop() { // eslint-disable-line class-methods-use-this
        document.getElementsByClassName('main-content')[0].scrollTop = 0;
    }

    closeModal() {
        this.DialogService.cancel()
    }
}
