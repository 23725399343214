import definition from './toast.service';

/**
 * ToastService settings
 * @type {{name: string, definition: ToastService}}
 */
export default {
    name: 'ToastService',
    definition
};
