/* @ngInject */
export default class LabelDialogController {
    constructor($scope, $mdDialog) {
        this.scope = $scope;
        this.dialog = $mdDialog;

        this.scope.label = {
            name: '',
            value: ''
        };

        this.scope.cancel = this.dialog.cancel;
        this.scope.answer = this.dialog.hide;
    }
}
