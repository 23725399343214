import moment from 'moment';
import { getStatus } from '../../../common/radio-status-ccon/radio-ccon.controller';
import { getHoursMinutesSecondsFormat } from 'services/utils';

const cconRadioTemplate = radio => `
    <div class="radio-status radio-status--ccon radio-status--active-${getStatus(radio)}">
        <div class="radio-status__bar"></div>
        <div class="radio-status__bar"></div>
        <div class="radio-status__bar"></div>
        <div class="radio-status__bar"></div>
    </div>
`;

export default {
    chart: {
        type: 'line',
        marginLeft: 58
    },
    yAxis: [
        {
            min: 0,
            max: 100,
            tickAmount: 6,
            labels: {
                format: '{value}%'
            }
        },
        {
            visible: false
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
    },
    plotOptions: {
        line: {
            step: true,
            getExtremesFromAll: false,
            marker: {
                enabled: false
            }
        },
        series: {
            turboThreshold: 0,
            point: {
                events: {
                    mouseOver() {
                        const chart = this.series.chart;
                        const momentFormat = `ddd, MMM D, ${getHoursMinutesSecondsFormat()}`;
                        const timeStamp = moment(this.x).format(momentFormat);
                        if (!chart.lbl) {
                            chart.lbl = chart.renderer.text('', 40, 10, true)
                                .addClass('highcharts-ccon-tooltip')
                                .add();
                        }
                        chart.lbl
                            .show()
                            .attr({
                                text: `${timeStamp}: <span class="cellular-strength">Cellular, ${this.y}%</span> ${cconRadioTemplate(this.y)}` // eslint-disable-line max-len
                            });
                    },
                },
            },
            events: {
                mouseOut() {
                    const chart = this.chart;
                    if (chart.lbl) {
                        chart.lbl
                            .hide();
                    }
                },
            },
        }
    }
};
