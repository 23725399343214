const StatusBreakpoints = [0, 20, 40, 60, 80, 100];

export const getStatus = (rawValue) => {
    const status = StatusBreakpoints.reduce((acc, breakpoint) => (rawValue > acc ? breakpoint : acc), 0);
    return StatusBreakpoints.indexOf(status);
};

export default class RadioStatusController {
    $onInit() {
        if (!this.radio) {
            this.status = getStatus(0);
        }
        this.setClasses();
    }

    setClasses() {
        this.classes = {
            [`radio-status--${this.offline ? 'offline' : `active-${this.status}`}`]: true,
            'radio-status--boost': this.boostMode
        };
    }

    $onChanges(changes) {
        if (changes.radio) {
            const radioValue = parseInt(changes.radio.currentValue, 10);
            this.status = isNaN(radioValue) ? getStatus(0) : getStatus(radioValue);
        }
        this.setClasses();
    }
}
