import moment from 'moment';
import { HEALTH_CHECKER_NETWORK_UP_EVENT, HEALTH_CHECKER_PAGE_VISIBLE_EVENT, DASHBOARD_REFRESH_EVENT } from 'services/StudioEvents';

const NETWORK_ONLINE_TYPE = 'online';
const PAGE_HIDDEN_THRESHOLD = 300000; // 5 minutes
const DASHBOARD_REFRESH_INTERVAL = 900000; // 15 minutes
const HEALTH_CHECKER_HEARTBEAT_INTERVAL = 30000; // 30 seconds
const HEALTH_CHECKER_HEARTBEAT_JITTER = 10000; // 10 seconds

/* @ngInject */
export default class HealthChecker {
    constructor($window, $rootScope, $interval, AuthService) {
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.$interval = $interval;
        this.AuthService = AuthService;

        this.pageHiddenAt = null;

        this.networkStatusUpdated = this.networkStatusUpdated.bind(this);
        this.visibilityChanged = this.visibilityChanged.bind(this);
    }

    startHeartbeatInterval() {
        let prevCalledAt = Date.now();
        this.$interval(() => {
            const now = Date.now();
            if (Math.abs(now - prevCalledAt - HEALTH_CHECKER_HEARTBEAT_INTERVAL) > HEALTH_CHECKER_HEARTBEAT_JITTER) {
                this.performHealthCheck(HEALTH_CHECKER_PAGE_VISIBLE_EVENT);
            }
            prevCalledAt = now;
        }, HEALTH_CHECKER_HEARTBEAT_INTERVAL);
    }

    // The Dashboard has a deviceStream to keep the cards up-to-date, 
    // but to ensure a correct display over multiple days when viewed in 'kiosk mode' (constantly on),
    // a refresh is triggered at a fixed interval to update the dashboard.
    startDashboardRefreshInterval() {
        this.$interval(() => {
            this.performHealthCheck(DASHBOARD_REFRESH_EVENT);
        }, DASHBOARD_REFRESH_INTERVAL);
    }

    networkStatusUpdated({ type }) {
        if (type === NETWORK_ONLINE_TYPE) {
            this.performHealthCheck(HEALTH_CHECKER_NETWORK_UP_EVENT);
        }
    }

    visibilityChanged() {
        if (document.hidden) {
            this.pageHiddenAt = moment();
        } else if (this.pageHiddenAt && moment().diff(this.pageHiddenAt) > PAGE_HIDDEN_THRESHOLD) {
            this.performHealthCheck(HEALTH_CHECKER_PAGE_VISIBLE_EVENT);
        }
    }

    performHealthCheck(eventType) {
        if (this.AuthService.authData === null || this.AuthService.authData.expired) {
            this.AuthService.silentLogin()
                .then(() => {
                    this.$rootScope.$broadcast(eventType);
                })
                .catch(() => {
                    window.location.reload(true);
                });
        } else {
            this.$rootScope.$broadcast(eventType);
        }
    }

    initTracking() {
        this.$window.addEventListener('online', this.networkStatusUpdated);
        this.$window.addEventListener('offline', this.networkStatusUpdated);
        this.$window.addEventListener('visibilitychange', this.visibilityChanged);
        this.startHeartbeatInterval();
        this.startDashboardRefreshInterval();
    }
}
