import Config from './dashboard.router';
import options from './dashboard.component';
import DashboardCard from './dashboard-card';

const Component = {
    selector: 'dtDashboardPage',
    options
};

export {
    Config,
    Component,
    DashboardCard
};
