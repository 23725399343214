import angular from 'angular'; // TODO: remove use of angular here

/* @ngInject */
export default function shyDirectiveConfig($window) {
    function link($scope, $element) {
        const document = $window.document;
        const body = document.body;

        let viewTop;
        let viewBottom;
        let elemRect;
        let elemTop;

        let theyLookedAtMe = false;
        let theyLookAtMe = false;

        function areTheyLookingAtMe() {
            viewTop = body.scrollTop;
            viewBottom = viewTop + body.offsetHeight;
            elemRect = $element[0].getBoundingClientRect();

            const hasSize = ['top', 'right', 'bottom', 'left', 'width', 'height']
                .map(key => elemRect[key])
                .find(value => !!value);

            elemTop = elemRect.top + viewTop;

            return hasSize ? (elemTop <= viewBottom) && (elemTop >= viewTop) : false;
        }

        function onScrolled() {
            $scope.shouldTrigger = false;

            theyLookedAtMe = theyLookAtMe;
            theyLookAtMe = areTheyLookingAtMe();

            if (theyLookAtMe && !theyLookedAtMe) {
                $scope.onVisible();
            }
        }

        function trigger() {
            if (areTheyLookingAtMe()) {
                $scope.onVisible();
            }
        }

        $scope.$watch('shouldTrigger', (triggerNow) => {
            if (triggerNow) {
                trigger();
                $scope.shouldTrigger = false;
            }
        });

        if ($scope.scrollSelector) {
            let scrollNode = document.querySelector($scope.scrollSelector);
            scrollNode = angular.element(scrollNode);

            scrollNode.on('scroll', onScrolled);

            $element.on('$destroy', () => {
                scrollNode.off('scroll', onScrolled);
            });
        }
    }

    return {
        restrict: 'A',
        scope: {
            scrollSelector: '<',
            shouldTrigger: '=',
            onVisible: '&'
        },
        link
    };
}
