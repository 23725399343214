import { parseQuery } from 'services/QueryParser'
import { DEFAULT_TOKEN } from 'services/PaginationHelper'
import { MAX_DEVICES_PER_MULTI_CARD } from 'services/api/DashboardService'
import { DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE, DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY } from 'constants/device';

/* @ngInject */
export default class EditDevices {
    constructor(
        DialogService, SensorService, ToastService, $q, $scope, card, updateCard
    ) {
        this.DialogService = DialogService
        this.SensorService = SensorService
        this.ToastService = ToastService
        this.$q = $q
        this.$scope = $scope
        this.card = card
        this.updateCard = updateCard
    }

    $onInit() {
        this.selectedSensors = this.card.content.devices.map(device => device?.id)

        if (this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE || this.card.content.devices.every(device => device.type === "temperature")) {
            this.deviceTypes = ['temperature', 'humidity', 'co2']

            // Set the appearance for multi-sensor temperature cards created before the appearance option existed. 
            this.card.appearance = DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE
        } else if (this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY) {
            this.deviceTypes = ['humidity', 'co2']
        } else {
            this.deviceTypes = [this.card.content.devices[0].type]
        }

        this.progress = null
        this.isFetching =  false
        this.orderBy = 'labels.name'
        this.query = ''
        this.previousQuery = ''; // Allows us to detect when the user has changed the query
        this.currentPageSize = 100
        this.currentPageToken = DEFAULT_TOKEN
        this.nextPageToken = null
        this.devices = []
        this.onReorderCallback = this.listReorder.bind(this)

        this.loadList()
    }

    get canSelectAnotherSensor() {
        const type = this.card.content.devices[0].type
        if (type === 'proximity' || type === 'motion') {
            return this.selectedSensors.length < 100
        }
        if (type === 'deskOccupancy') {
            return this.selectedSensors.length < 1000
        }
        return this.selectedSensors.length < MAX_DEVICES_PER_MULTI_CARD
    }

    selectSensor(device) {
        
        // If the sensor already exists in the list, remove it
        if (this.selectedSensors.includes(device.id)) {
            // Not allowing there being less than 2 selected devices
            if (this.selectedSensors.length <= 2) {
                this.ToastService.showSimpleTranslated('dashboard_minimum_two_devices')
                return
            }
            this.selectedSensors.splice(this.selectedSensors.indexOf(device.id), 1)
        } else {
            // If the maximum number of devices have been added, exit
            if (!this.canSelectAnotherSensor) { return }

            this.selectedSensors.push(device.id)
        }
    }

    saveUpdates() {
        this.DialogService.cancel()
        this.updateCard(this.selectedSensors, this.card)
    }

    loadList() {
        this.isFetching = true
        this.$scope.$applyAsync()
        const deferred = this.$q.defer()
        
        // Reset orderBy when the user has changed the text in the search box.
        // This will let the backend decide the order based on a match score.
        if (this.previousQuery !== this.query) {
            if (this.query.length > 0) {
                // Let the backend decide when the user has entered a search query
                this.orderBy = '';
            } else if (this.orderBy === '') {
                // Default to labels.name if the user has removed the search query
                this.orderBy = 'labels.name';
            }
        }
        this.previousQuery = this.query;

        this.progress = this.SensorService.sensors({
            ...parseQuery(this.query),
            deviceTypes: this.deviceTypes,
            orderBy: this.orderBy,
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        }).then(({ data, nextPageToken}) => {
            this.devices = data
            this.nextPageToken = nextPageToken
            this.isFetching = false
            deferred.resolve([])
        }).catch((serverResponse) => {
            this.isFetching = false
            this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
                serverResponse
            })
            deferred.reject()
        })

        return deferred.promise
    }

    search( query ) {
        this.query = query
        this.currentPageToken = DEFAULT_TOKEN
        return this.loadList()
    }

    clearSearchResult() {
        this.query = ''
        this.currentPageToken = DEFAULT_TOKEN
        this.loadList()
    }

    listReorder(order) {
        this.orderBy = order
        this.currentPageToken = DEFAULT_TOKEN
        this.loadList()
    }

    setPageSize({ pageSize }) {
        this.currentPageToken = DEFAULT_TOKEN
        this.currentPageSize = pageSize
        this.loadList()
    }

    setPageToken({ pageToken }) {
        this.currentPageToken = pageToken
        this.loadList()
        this.scrollToTop()
    }

    scrollToTop() { // eslint-disable-line class-methods-use-this
        document.getElementsByClassName('main-content')[0].scrollTop = 0
    }

    cancel() {
        this.DialogService.cancel()
    }
}