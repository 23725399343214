import controller from './thing-description.controller';

const template = require('./thing-description.html');

export default {
    bindings: {
        description: '<',
        onUpdate: '&'
    },
    template,
    controller
};
