import { isMobileLayout } from 'services/utils';

/**
 * @ngInject
 */
export default class AlertGuideController {
    constructor(DialogService) {
        this.DialogService = DialogService;
    }

    $onInit() {
        this.isMobile = isMobileLayout();
    }

    close() {
        this.DialogService.cancel()
    }
}
