import controller from './loader.controller';

const template = require('./loader.html');

export default {
    bindings: {
        loaderType: '<'
    },
    template,
    controller
};
