import controller from './temperature.controller';

const template = require('./temperature.html');

export default {
    bindings: {
        device: '<',
        onPublishEvent: '&'
    },
    controller,
    template
};
