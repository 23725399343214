import Utils from 'services/utils';

/* @ngInject */
export default class LabelsAutocompleteController {
    constructor(EventEmitter, LabelsApi) {
        this.eventEmitter = EventEmitter;
        this.LabelsApi = LabelsApi;
    }

    $onInit() {
        this.cache = [];
        this.LabelsApi.fetch().then((data) => {
            this.cache = data.labels || [];
            this.searchIndex = Utils.buildSearchIndex(this.cache);
        });
    }

    added({ entity }) {
        this.onAdd(
            this.eventEmitter({
                label: entity
            })
        );
    }

    removed({ entity }) {
        this.onRemove(
            this.eventEmitter({
                label: entity
            })
        );
    }

    changed({ entities }) {
        this.onChange(
            this.eventEmitter({
                labels: entities
            })
        );
    }

    search({ query }) {
        return Utils.searchInIndex(this.searchIndex, query);
    }
}
